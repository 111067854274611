let config, configRequest;

// It fetches config file
function fetchConfig() {
  let deferred = $.Deferred();

  if (config) {
    deferred.resolve(getConfig());
  } else {
    if (!configRequest) {
      // json configuration file structure:
      // {
      //    "auth": { "application name": #name },
      //    "commands": {
      //      "commands": {  }
      //      "request_body": {  }
      //      ...
      //    },
      //    ...
      // }
      configRequest = axios.get('/config');
    }

    configRequest
      .then(response => {
        config = response.data;
        deferred.resolve(getConfig());
      })
      .catch(error => {
        alert('Inizializzazione fallita');
        deferred.reject(error);
      });
  }

  return deferred.promise();
}

// It returns a copy of the config json object
function getConfig() {
  return _.cloneDeep(config);
}

// It returns the value of the 'name' key from the config object
function getValue(name, defaultValue = null) {
  var res = getConfig();
  name.split('.').forEach(function (key) {
    res = res && res[key];
  })
  return res ?? defaultValue;
}

export default {
  fetchConfig,
  getConfig,
  getValue,
}