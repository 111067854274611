import AbortActionManager from "./events/abort_action_manager.js"
import AcceptActionManager from "./events/accept_action_manager.js"
import CancelActionManager from "./events/cancel_action_manager.js"
import EditActionManager from "./events/edit_action_manager.js"
import NewActionManager from "./events/new_action_manager.js"
import PrintActionManager from "./events/print_action_manager.js"
import RefuseActionManager from "./events/refuse_action_manager.js"
import SaveActionManager from "./events/save_action_manager.js"
import SendMedicinaLegaleActionManager from "./events/send_medicina_legale_action_manager.js"
import SendComuneActionManager from "./events/send_comune_action_manager.js"
import SignDocumentActionManager from "./events/sign_document_action_manager.js"
import DefaultManager from "./default_manager.js"

const events = {
    "Item.New": NewActionManager,
    "Item.Save": SaveActionManager,
    "Item.Edit": EditActionManager,
    "Item.Cancel": CancelActionManager,
    "Item.Print": PrintActionManager,
    "Item.Validate.Accept.Yes": AcceptActionManager,
    "Item.Validate.Accept.No": RefuseActionManager,
    "Process.Abort": AbortActionManager,
    "ZapSign.SignDocument": SignDocumentActionManager,
    "Item.Validate.Ospedale.SendToMedicinaLegale": SendMedicinaLegaleActionManager,
    "Item.Validate.SendToComune": SendComuneActionManager,
}

function handleActionEvent(context, action) {
    try {
        if (events[action.manager.name])
            events[action.manager.name].actionPerformed(context, action);
        else
            DefaultManager.actionPerformed(context, action);
    } catch (error) {
        console.log('UNKNOWN ACTION:', action);
        console.log(error);
        console.log(context);
    }
}

export default {
    handleActionEvent,
}