var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-anagrafica" } },
    [
      _c("c-expansion-sections", {
        attrs: { disabled: _vm.disabled, sections: _vm.expansionSections },
        scopedSlots: _vm._u(
          [
            {
              key: "section1",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "col-num-paziente",
                          attrs: { cols: "5" },
                        },
                        [
                          _c("c-text-field", {
                            attrs: {
                              label: "Anno dell'Atto",
                              "prepend-icon": "mdi-archive-clock",
                              type: "number",
                              "hide-spin-buttons": "",
                            },
                            model: {
                              value: _vm.numeroPazienteAnnoFormatted,
                              callback: function ($$v) {
                                _vm.numeroPazienteAnnoFormatted = $$v
                              },
                              expression: "numeroPazienteAnnoFormatted",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("/")]),
                          _vm._v(" "),
                          _c(
                            "c-text-field",
                            {
                              attrs: {
                                rules: _vm.requiredRule,
                                label: "Numero Paziente",
                                type: "number",
                                "hide-spin-buttons": "",
                              },
                              model: {
                                value: _vm.numeroPazienteIdFormatted,
                                callback: function ($$v) {
                                  _vm.numeroPazienteIdFormatted = $$v
                                },
                                expression: "numeroPazienteIdFormatted",
                              },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { slot: "append", bottom: "" },
                                  slot: "append",
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  ripple: false,
                                                  small: "",
                                                  icon: "",
                                                },
                                                on: {
                                                  click:
                                                    _vm.onRicercaRicoveriClick,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    "mdi-clipboard-search-outline"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Ricerca dati paziente")]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-date-picker", {
                            attrs: {
                              rules: _vm.requiredRule,
                              label: "Data del Ricovero",
                            },
                            model: {
                              value: _vm.dataRicoveroISO,
                              callback: function ($$v) {
                                _vm.dataRicoveroISO = $$v
                              },
                              expression: "dataRicoveroISO",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-text-field", {
                            attrs: {
                              rules: _vm.requiredRule,
                              label: "Cognome",
                              "prepend-icon": "mdi-account-outline",
                            },
                            model: {
                              value: _vm.ad.cognome,
                              callback: function ($$v) {
                                _vm.$set(_vm.ad, "cognome", $$v)
                              },
                              expression: "ad.cognome",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-text-field", {
                            attrs: {
                              label: "Nome",
                              rules: _vm.requiredRule,
                              "prepend-icon": "mdi-account-outline",
                            },
                            model: {
                              value: _vm.ad.nome,
                              callback: function ($$v) {
                                _vm.$set(_vm.ad, "nome", $$v)
                              },
                              expression: "ad.nome",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-date-picker", {
                            attrs: {
                              minimumDate: _vm.minimumDate,
                              label: "Data di Nascita",
                              rules: _vm.requiredRule,
                            },
                            model: {
                              value: _vm.dataNascitaISO,
                              callback: function ($$v) {
                                _vm.dataNascitaISO = $$v
                              },
                              expression: "dataNascitaISO",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "6" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listPaesi,
                              rules: _vm.requiredRule,
                              label: "Comune di Nascita",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-map-marker",
                              clearable: "",
                            },
                            model: {
                              value: _vm.comuneNascita,
                              callback: function ($$v) {
                                _vm.comuneNascita = $$v
                              },
                              expression: "comuneNascita",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listProvince,
                              rules: _vm.requiredRule,
                              label: "Provincia di Nascita",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-map-marker",
                              clearable: "",
                            },
                            model: {
                              value: _vm.provinciaNascita,
                              callback: function ($$v) {
                                _vm.provinciaNascita = $$v
                              },
                              expression: "provinciaNascita",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listStati,
                              rules: _vm.requiredRule,
                              label: "Nazione di Nascita",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-earth",
                              clearable: "",
                            },
                            model: {
                              value: _vm.statoNascita,
                              callback: function ($$v) {
                                _vm.statoNascita = $$v
                              },
                              expression: "statoNascita",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listCittadinanze,
                              rules: _vm.requiredRule,
                              label: "Cittadinanza",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-flag",
                              clearable: "",
                            },
                            model: {
                              value: _vm.cittadinanzaSpecifica,
                              callback: function ($$v) {
                                _vm.cittadinanzaSpecifica = $$v
                              },
                              expression: "cittadinanzaSpecifica",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-select", {
                            attrs: {
                              label: "Sesso",
                              rules: _vm.requiredRule,
                              items: _vm.listSesso,
                              "prepend-icon": "mdi-gender-male-female",
                            },
                            model: {
                              value: _vm.ad.sesso,
                              callback: function ($$v) {
                                _vm.$set(_vm.ad, "sesso", $$v)
                              },
                              expression: "ad.sesso",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-text-field", {
                            attrs: {
                              label: "Codice Fiscale",
                              "prepend-icon": "mdi-credit-card-outline",
                            },
                            model: {
                              value: _vm.ad.codice_fiscale,
                              callback: function ($$v) {
                                _vm.$set(_vm.ad, "codice_fiscale", $$v)
                              },
                              expression: "ad.codice_fiscale",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            !_vm.comunicazioneEnabled
              ? {
                  key: "section2",
                  fn: function () {
                    return [
                      _c("SectionDatiMorte", {
                        ref: "sectionDatiMorte",
                        attrs: { cd: _vm.pratica.comunicazione_decesso },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: _vm.templateResidenza,
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("c-text-field", {
                            attrs: {
                              label: "Via",
                              rules: _vm.requiredRule,
                              "prepend-icon": "mdi-home-map-marker",
                            },
                            model: {
                              value: _vm.ad.via_residenza,
                              callback: function ($$v) {
                                _vm.$set(_vm.ad, "via_residenza", $$v)
                              },
                              expression: "ad.via_residenza",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "1" } }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c("c-text-field", {
                            attrs: {
                              label: "Civico",
                              type: "number",
                              "hide-spin-buttons": "",
                            },
                            model: {
                              value: _vm.ad.civico_residenza,
                              callback: function ($$v) {
                                _vm.$set(_vm.ad, "civico_residenza", $$v)
                              },
                              expression: "ad.civico_residenza",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listPaesi,
                              rules: _vm.requiredRule,
                              label: "Comune",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-map-marker",
                              clearable: "",
                            },
                            model: {
                              value: _vm.comuneResidenza,
                              callback: function ($$v) {
                                _vm.comuneResidenza = $$v
                              },
                              expression: "comuneResidenza",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "col-residenza-via",
                          attrs: { cols: "5" },
                        },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listProvince,
                              rules: _vm.requiredRule,
                              label: "Provincia",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-map-marker",
                              clearable: "",
                            },
                            model: {
                              value: _vm.provinciaResidenza,
                              callback: function ($$v) {
                                _vm.provinciaResidenza = $$v
                              },
                              expression: "provinciaResidenza",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("c-combobox", {
                            attrs: {
                              items: _vm.listStati,
                              rules: _vm.requiredRule,
                              label: "Nazione",
                              "item-text": "descrizione",
                              "prepend-icon": "mdi-earth",
                              clearable: "",
                            },
                            model: {
                              value: _vm.statoResidenza,
                              callback: function ($$v) {
                                _vm.statoResidenza = $$v
                              },
                              expression: "statoResidenza",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: _vm.templateDomicilio,
              fn: function () {
                return [
                  _c(
                    "div",
                    { key: _vm.domicilioState },
                    [
                      _c(
                        "div",
                        { staticClass: "domicilio-title" },
                        [
                          _vm._v(
                            "\n          Domicilio\n          \n          "
                          ),
                          _c("c-switch", {
                            attrs: {
                              "input-value": !_vm.ad.uguali_domicilio_residenza,
                              label: "Diverso da residenza",
                            },
                            on: { change: _vm.changeUgualiDomicilioResidenza },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("c-text-field", {
                                attrs: {
                                  rules: !_vm.ad.uguali_domicilio_residenza
                                    ? _vm.requiredRule
                                    : [],
                                  disabled: _vm.ad.uguali_domicilio_residenza,
                                  label: "Via",
                                  "prepend-icon": "mdi-home-map-marker",
                                },
                                model: {
                                  value: _vm.formattedViaDomicilio,
                                  callback: function ($$v) {
                                    _vm.formattedViaDomicilio = $$v
                                  },
                                  expression: "formattedViaDomicilio",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "1" } }),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("c-text-field", {
                                attrs: {
                                  disabled: _vm.ad.uguali_domicilio_residenza,
                                  label: "Civico",
                                  type: "number",
                                  "hide-spin-buttons": "",
                                },
                                model: {
                                  value: _vm.formattedNumeroDomicilio,
                                  callback: function ($$v) {
                                    _vm.formattedNumeroDomicilio = $$v
                                  },
                                  expression: "formattedNumeroDomicilio",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("c-combobox", {
                                attrs: {
                                  items: _vm.listPaesi,
                                  rules: !_vm.ad.uguali_domicilio_residenza
                                    ? _vm.requiredRule
                                    : [],
                                  disabled: _vm.ad.uguali_domicilio_residenza,
                                  "item-text": "descrizione",
                                  label: "Comune",
                                  "prepend-icon": "mdi-map-marker",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.comuneDomicilio,
                                  callback: function ($$v) {
                                    _vm.comuneDomicilio = $$v
                                  },
                                  expression: "comuneDomicilio",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "col-domicilio-via",
                              attrs: { cols: "5" },
                            },
                            [
                              _c("c-combobox", {
                                attrs: {
                                  items: _vm.listProvince,
                                  rules: !_vm.ad.uguali_domicilio_residenza
                                    ? _vm.requiredRule
                                    : [],
                                  disabled: _vm.ad.uguali_domicilio_residenza,
                                  "item-text": "descrizione",
                                  label: "Provincia",
                                  "prepend-icon": "mdi-map-marker",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.provinciaDomicilio,
                                  callback: function ($$v) {
                                    _vm.provinciaDomicilio = $$v
                                  },
                                  expression: "provinciaDomicilio",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("c-combobox", {
                                attrs: {
                                  items: _vm.listStati,
                                  rules: !_vm.ad.uguali_domicilio_residenza
                                    ? _vm.requiredRule
                                    : [],
                                  disabled: _vm.ad.uguali_domicilio_residenza,
                                  "item-text": "descrizione",
                                  label: "Nazione",
                                  "prepend-icon": "mdi-earth",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.statoDomicilio,
                                  callback: function ($$v) {
                                    _vm.statoDomicilio = $$v
                                  },
                                  expression: "statoDomicilio",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }