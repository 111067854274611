import ListMetaStore from 'services/commands/stores/list_meta_store'

let LIST_ACTION = {}

function init(jsonListMeta) {
    LIST_ACTION = {}
    
    _.forEach(jsonListMeta.actions.action, action => {
        action.decoration = ListMetaStore.resolveAllMetas('TRANSITION_DEF', action.id)
        LIST_ACTION[action.id] = action;
    })
}

function getListAction() {
    return LIST_ACTION
}

export default {
    init,
    getListAction
}