<template>
  <v-bottom-sheet
    v-model="value"
    persistent
    inset
  >
    <v-card id="dialog-interop-error">
      <v-card-title>
        <!-- Title -->
        <v-col cols="auto">Attenzione</v-col>

        <v-spacer />

        <v-col cols="auto">
          <v-checkbox
            v-model="doNotShowAgain"
            v-ripple
            label="Non mostrare più"
            class="px-1 ma-0"
            hide-details
          />
        </v-col>

        <v-col cols="auto">
          <!-- Close Button-->
          <v-btn
            text @click="onCloseClick"
            v-ripple
            class="btn-close"
            outlined
            small
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-divider /><br>

      <v-card-text>
        <p class="text">
          Non è stato trovato il plugin locale.
          <template v-if="isWindows">
            <a @click="onAddPluginClick">
              <span><b>{{ text }}</b></span>
            </a>
          </template>

          <template v-else>
            <br>{{ text }}
          </template>
        </p>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
// getters
import { mapGetters } from 'vuex';
// external libraries
import customProtocolCheck from 'custom-protocol-check'
import platformDetect from 'platform-detect';
// stores
import { AppConfigStore } from 'services/commands/stores';

export default {
  name: "DialogWebInteropError",

  // value:     true to make dialog visible
  //            false otherwise
  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      doNotShowAgain: false,
    };
  },

  computed: {
    ...mapGetters([
      'showableWebInteropAlert'
    ]),

    text() {
      return this.isWindows
        ? "Lancia o installa il Plugin."
        : "Il plugin locale è disponibili solo per sistemi operativi Windows.";
    },

    isWindows: () => platformDetect.windows,
  },

  methods: {
    onAddPluginClick() {
      const url = AppConfigStore.getWebInteropStartUrl();
      const callback = () => { window.location = AppConfigStore.getWebInteropDownloadUrl() };

      customProtocolCheck(url, callback);
    },

    onCloseClick() {
      this.$store.commit('setShowableWebInteropAlert', !this.doNotShowAgain);

      this.$emit('input', false);
    },
  },

  created() {
    this.doNotShowAgain = !this.showableWebInteropAlert;
  }
};
</script>
