var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("HeaderBar", { attrs: { showChipPratiche: _vm.showChipPratiche } }),
      _vm._v(" "),
      _vm.show
        ? _c("div", { staticClass: "page-body" }, [
            _c(
              "div",
              { staticClass: "page-content" },
              [
                _c("MenuBar", {
                  staticClass: "ma-2",
                  on: {
                    "refresh-button-clicked": _vm.onRefreshButtonClicked,
                    "activity-selected-change": _vm.onActivitySelectedChange,
                  },
                }),
                _vm._v(" "),
                _c("TableHome", {
                  staticClass: "mx-2",
                  on: { selectionChange: _vm.onSelectionChange },
                }),
                _vm._v(" "),
                _c("ActionBar", {
                  staticClass: "ma-2",
                  attrs: { selection: this.selection, mode: _vm.actionBarMode },
                  on: { "action-button-clicked": _vm.onActionButtonClick },
                }),
              ],
              1
            ),
          ])
        : _vm.showInternalServerError
        ? _c(
            "div",
            [
              _c("v-container", { staticClass: "ma-2" }, [
                _c("h1", { staticClass: "text-error" }, [
                  _vm._v("Errore interno al Server."),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-error" }, [
                  _vm._v(
                    "Provare a ricaricare la pagina. Se l'errore dovesse persistere, contattare l'assistenza tecnica."
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm.showUnreachableServerError
        ? _c(
            "div",
            [
              _c("v-container", { staticClass: "ma-2" }, [
                _c("h1", { staticClass: "text-error" }, [
                  _vm._v("Errore: Server irraggiungibile."),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-error" }, [
                  _vm._v(
                    "Provare a ricaricare la pagina. Se l'errore dovesse persistere, contattare l'assistenza tecnica."
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("c-dialog-confirm", { ref: "dialogConfirm" }),
      _vm._v(" "),
      _c("dialog-print-pratiche-list", {
        attrs: { "print-pratiche-list": _vm.printPraticheList },
        model: {
          value: _vm.showPrintPraticheList,
          callback: function ($$v) {
            _vm.showPrintPraticheList = $$v
          },
          expression: "showPrintPraticheList",
        },
      }),
      _vm._v(" "),
      _c("dialog-accept", {
        on: { "confirm-accept": _vm.onConfirmAcceptEvent },
        model: {
          value: _vm.showAcceptDialog,
          callback: function ($$v) {
            _vm.showAcceptDialog = $$v
          },
          expression: "showAcceptDialog",
        },
      }),
      _vm._v(" "),
      _c("dialog-refuse", {
        on: { "confirm-refuse": _vm.onConfirmRefuseEvent },
        model: {
          value: _vm.showRefuseDialog,
          callback: function ($$v) {
            _vm.showRefuseDialog = $$v
          },
          expression: "showRefuseDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }