<template>
  <!-- Title -->
  <div class="form-header-container">
    <v-row>
      <v-col cols="7" align-self="center">
        <!-- Title Structure -->
        <!-- Name Surname -->
        <!-- Nato GG/MM/AAAA ~ Morto GG/MM/AAAA (Età XX anni) -->

        <h4>{{ title }}</h4>
        <p class="form-header-subtitle">
          <span>{{ subtitle }}</span>
        </p>
      </v-col>

      <v-spacer />

      <v-col v-if="showInfo" cols="5" align-self="center">
        <v-card variant="tonal" class="v-card-info">
          <p><b>{{ description }}</b></p>
          <p><span>{{ info }}</span></p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FormHeader",
  
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },

    subtitle: {
      type: String,
      required: false,
      default: "",
    },

    showInfo: {
      type: Boolean,
      required: false,
      default: false,
    },

    description: {
      type: String,
      required: false,
      default: "",
    },

    info: {
      type: String,
      required: false,
      default: "",
    }
  },
}
</script>