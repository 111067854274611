import Core from './core'

const COMMAND_NAME = 'Caronte.GetNuovoNumeroAtto'

function requestBody(params) {
  return {
    anno: params.anno
  }
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response)
    },
    error: (response) => {
      _.attempt(options.error, response)
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  })
}

export default {
  promise,
  call
}