/**
 * This module provides functions for formatting, validating and
 * comparing dates and times using the Moment.js library.
 * 
 * @author Marco Baresi
 */
import moment from 'moment-timezone';

/**
 * A constant representing an empty date string.
 * 
 * @constant {string}
 */
const EMPTY_DATE = '0001-01-01T00:00:00';

// Setting the default timezone for moment.js to Europe/Rome.
moment.tz.setDefault("Europe/Rome");

/**
 * Formats a date object as a string in the format [ DD/MM/YYYY ].
 * 
 * @param {Date|string} date - The date object or string to format.
 * @returns {string} - The formatted date string.
 */
function formatDate(date) {
  date = _adjustDST(date);
  return date.format('DD/MM/YYYY');
}

/**
 * Formats a date object as a string in the format [ HH:mm ].
 * 
 * @param {Date|string} date - The date object or string to format.
 * @returns {string} - The formatted time string.
 */
function formatTime(date) {
  date = _adjustDST(date);
  return date.format('HH:mm');
}

/**
 * Formats a date object as a string in the format [ DD/MM/YYYY - HH:mm ].
 * 
 * @param {Date|string} date - The date object or string to format.
 * @returns {string} - The formatted date and time string.
 */

function formatDateTime(date) {
  date = _adjustDST(date);
  return date.format('DD/MM/YYYY - HH:mm');
}

/**
 * Formats a date object as a string in the format [ YYYY-MM-DD ].
 * 
 * @param {Date|string} date - The date object or string to format.
 * @returns {string} - The formatted date string in ISO format.
 */
function formatDateAsIso(date) {
  date = _adjustDST(date);
  return date.format('YYYY-MM-DD');
}

/**
 * Calculates the difference between two dates in the specified units.
 * 
 * @param {Date|string} dateFirst - The first date object or string.
 * @param {Date|string} dateSecond - The second date object or string.
 * @param {string} [units='years'] - The units to calculate the difference in.
 * @returns {number} - The difference between the dates in the specified units.
 */
function dateDiff(dateFirst, dateSecond, units) {
  let diff = moment(_adjustDST(dateFirst)).diff(_adjustDST(dateSecond), units);
  return diff;
}

/**
 * Checks if a date is empty or equal to the constant [ EMPTY_DATE ].
 * 
 * @param {Date|string} date - The date object or string to check.
 * @returns {boolean}   - True if the date is empty or equal to EMPTY_DATE.
 *                      - False otherwise.
 */
function isEmptyDate(date) {
  return !date || moment(EMPTY_DATE).isSame(date, 'date')
}

/**
 * Validates if a date string is in the format [ YYYY-MM-DD ].
 * 
 * @param {string} date - The date string to validate.
 * @returns {boolean}     - True if the date string is valid.
 *                        - False otherwise.
 */
function validateISO(date) {
  return moment(date, 'YYYY-MM-DD', true).isValid()
}

/**
 * Validates if a time string is in the format [ H:mm ].
 * 
 * @param {string} time - The time string to validate.
 * @returns {boolean}     - True if the time string is valid.
 *                        - False otherwise.
 */
function validateTime(time) {
  return moment(time, 'H:mm', true).isValid()
}

/**
 * Adjusts the timezone of a given moment.js date object based on the current UTC offset.
 * If the current UTC offset is greater than zero, the date is parsed and returned with
 * the local timezone; otherwise, the date is returned with its original timezone.
 * 
 * @param {moment.Moment} date - The date to adjust the timezone for.
 * @returns {moment.Moment} - The adjusted date with the correct timezone.
 */
function _adjustDST(date) {
  return moment(date).utcOffset() > 0 ? moment(date).parseZone() : moment(date);
}

export default {
  formatDate,
  formatTime,
  formatDateTime,
  formatDateAsIso,
  dateDiff,
  isEmptyDate,
  validateISO,
  validateTime,
  EMPTY_DATE
}