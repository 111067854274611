var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "form" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between" },
        [
          _c("h4", [_vm._v(" " + _vm._s(_vm.description) + " ")]),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    icon: "",
                    color: "primary dark",
                    disabled: _vm.isPreesistente
                      ? _vm.conditionsNumber >= _vm.maxPre
                      : _vm.conditionsNumber >= _vm.maxNotPre,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addNewCondition()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus-circle")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _vm.conditionsNumber > 0
        ? _c(
            "transition-group",
            { attrs: { name: "flip-list", tag: "v-col" } },
            _vm._l(_vm.conditionsFiltered, function (condizione, index) {
              return _c(
                "v-row",
                {
                  key: condizione.value,
                  staticClass: "ma-2",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("c-text-field", {
                        attrs: { rules: _vm.rules, label: "Condizione" },
                        model: {
                          value: condizione.malattia,
                          callback: function ($$v) {
                            _vm.$set(condizione, "malattia", $$v)
                          },
                          expression: "condizione.malattia",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("c-text-field", {
                        attrs: {
                          label: "Anni",
                          type: "number",
                          "hide-spin-buttons": "",
                        },
                        model: {
                          value: condizione.anni_durata,
                          callback: function ($$v) {
                            _vm.$set(condizione, "anni_durata", $$v)
                          },
                          expression: "condizione.anni_durata",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("c-text-field", {
                        attrs: {
                          label: "Mesi",
                          type: "number",
                          "hide-spin-buttons": "",
                        },
                        model: {
                          value: condizione.mesi_durata,
                          callback: function ($$v) {
                            _vm.$set(condizione, "mesi_durata", $$v)
                          },
                          expression: "condizione.mesi_durata",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("c-text-field", {
                        attrs: {
                          label: "Giorni",
                          type: "number",
                          variant: "solo",
                          "hide-spin-buttons": "",
                        },
                        model: {
                          value: condizione.giorni_durata,
                          callback: function ($$v) {
                            _vm.$set(condizione, "giorni_durata", $$v)
                          },
                          expression: "condizione.giorni_durata",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.disabled
                    ? _c(
                        "v-col",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                          },
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "3" } }),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        icon: "",
                                        color: "primary",
                                        disabled:
                                          index + 1 == _vm.conditionsNumber,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.swapConditions(
                                            index,
                                            index + 1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-down-bold"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        icon: "",
                                        color: "primary",
                                        disabled:
                                          index == 0 ||
                                          _vm.conditionsNumber <= 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.swapConditions(
                                            index,
                                            index - 1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-up-bold"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        icon: "",
                                        color: "red lighten-2",
                                        disabled:
                                          (_vm.isPreesistente &&
                                            _vm.conditionsNumber <=
                                              _vm.minPre) ||
                                          (!_vm.isPreesistente &&
                                            _vm.conditionsNumber <=
                                              _vm.minNotPre),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCondition(
                                            condizione.value
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("v-spacer"),
                ],
                1
              )
            }),
            1
          )
        : _c("p", { staticClass: "p-no-conditions" }, [
            _vm._v("Non sono state specificate Condizioni Morbose."),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }