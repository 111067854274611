var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", attrs: { id: "details" } },
    [
      _vm.documentFixed
        ? _c("DocumentViewer", {
            attrs: { document: _vm.documentFixed },
            model: {
              value: _vm.dialogDocument,
              callback: function ($$v) {
                _vm.dialogDocument = $$v
              },
              expression: "dialogDocument",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("HeaderBar", {
        staticClass: "mb-2",
        attrs: { disabled: _vm.isEditMode },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-body" },
        [
          _c(
            "div",
            { staticClass: "page-content" },
            [
              _c("TableDetails", {
                staticClass: "ml-2",
                attrs: { disabled: _vm.isEditMode },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "forms mx-2" },
                [
                  _c("FormHeader", {
                    attrs: {
                      title: _vm.formHeaderTitle,
                      subtitle: _vm.formHeaderSubtitle,
                      "show-info": _vm.showPraticaInfo,
                      description: _vm.showPraticaInfo
                        ? _vm.formPraticaDescription
                        : "",
                      info: _vm.showPraticaInfo ? _vm.formPraticaInfo : "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.showSkeletonLoader
                    ? _c("v-skeleton-loader", {
                        staticClass: "mb-6",
                        attrs: {
                          type: "table-thead, divider, card-heading, image",
                        },
                      })
                    : _c("FormContainer", {
                        ref: "formContainer",
                        attrs: {
                          isEditMode: _vm.isEditMode,
                          moduleState: _vm.moduleState,
                          pratica: _vm.pratica,
                        },
                        on: {
                          "pratica-updated": _vm.onPraticaUpdate,
                          "documents-generated": _vm.onDocumentsGenerated,
                          "document-selected": _vm.onDocumentSelected,
                          "ricerca-ricoveri": _vm.onRicercaRicoveri,
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showDocumentList
                ? _c(
                    "div",
                    { staticClass: "attachements-bar mr-2" },
                    [
                      _vm.showSkeletonLoader
                        ? _c("v-skeleton-loader", {
                            staticClass: "mb-6",
                            attrs: { type: "image" },
                          })
                        : _c("DocumentList", {
                            key: _vm.moduleState,
                            attrs: {
                              documentList: _vm.pratica.documenti.documento,
                              name: _vm.pratica.anagrafica_deceduto.nome,
                              surname: _vm.pratica.anagrafica_deceduto.cognome,
                            },
                            on: { "document-selected": _vm.onDocumentSelected },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("ActionBar", {
            staticClass: "ma-2",
            attrs: { mode: _vm.mode, selection: [this.pratica] },
            on: { "action-button-clicked": _vm.onActionButtonClick },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("dialog-ricoveri", {
        attrs: { ricoveriList: _vm.ricoveriList },
        on: { "ricovero-choosed": _vm.associaRicoveri },
        model: {
          value: _vm.showDialogRicoveri,
          callback: function ($$v) {
            _vm.showDialogRicoveri = $$v
          },
          expression: "showDialogRicoveri",
        },
      }),
      _vm._v(" "),
      _c("c-dialog-confirm", { ref: "dialogConfirmDeletePratica" }),
      _vm._v(" "),
      _c("c-dialog-confirm", {
        ref: "dialogConfirmSignDocument",
        attrs: { title: "Firma documento" },
      }),
      _vm._v(" "),
      _c("c-dialog-confirm", {
        ref: "dialogConfirmSendToMedicinaLegale",
        attrs: { title: "Invio pratica" },
      }),
      _vm._v(" "),
      _c("dialog-print-pratiche-list", {
        attrs: { "print-pratiche-list": _vm.printPraticheList },
        model: {
          value: _vm.showPrintPraticheList,
          callback: function ($$v) {
            _vm.showPrintPraticheList = $$v
          },
          expression: "showPrintPraticheList",
        },
      }),
      _vm._v(" "),
      _c("dialog-accept", {
        on: { "confirm-accept": _vm.onConfirmAcceptEvent },
        model: {
          value: _vm.showAcceptDialog,
          callback: function ($$v) {
            _vm.showAcceptDialog = $$v
          },
          expression: "showAcceptDialog",
        },
      }),
      _vm._v(" "),
      _c("dialog-refuse", {
        on: { "confirm-refuse": _vm.onConfirmRefuseEvent },
        model: {
          value: _vm.showRefuseDialog,
          callback: function ($$v) {
            _vm.showRefuseDialog = $$v
          },
          expression: "showRefuseDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }