import store from 'store';
// stores
import { UserInformationStore } from "services/commands/stores";

/**
 * It performs the Sign action.
 * 
 * @param {Object} context - The context object.
 * @param {Object} action - The action object.
 * @returns {Promise<void>} - A promise that resolves when the Sign action is completed.
 */
async function actionPerformed(context, action) {
  const signMethod = UserInformationStore.getSignMethod();
  const listPraticheSelected = store.getters.listPraticheSelected;
  
  let listOfCompletedProcesses = [];
  let signResult = false;

  for(const pInfo of listPraticheSelected) {
    // selectedAction sample structure:
    //
    // {
    //   [...]
    //   manager {
    //     parameters: {
    //       element: [
    //         {
    //           key: "tipoDocumento",
    //           value: "2|3"
    //         },
    //         {
    //           key: "docin1",
    //           value: "IDENTIFIER_DOC_1"
    //         },
    //         {
    //           key: "docout1",
    //           value: ""
    //         },
    //         {
    //           key: "docin2",
    //           value: "IDENTIFIER_DOC_2"
    //         },
    //         {
    //           key: "docout2",
    //           value: ""
    //         }
    //       ]
    //     }
    //   }
    // }
    const selectedAction = _.find(pInfo.azioni.action, ['manager.name', action.manager.name]);
    
    // praticaDocuments sample structure:
    //
    // [
    //   {
    //     code: 1,
    //     docIn: "IDENTIFIER_DOC_1",
    //     docOut: ""
    //   },
    //   {
    //     code: 2,
    //     docIn: "IDENTIFIER_DOC_2",
    //     docOut: ""
    //   }
    // ]
    const praticaDocuments = _generatePraticaDocuments(selectedAction);

    switch (signMethod) {
      case "SISS":
        signResult = await context.signSISS(selectedAction, pInfo.id, praticaDocuments);
        break;

      case "NORMAL":
        // adding documents names
        _.forEach(praticaDocuments, (doc) => {
          doc.name = _.find(pInfo.documenti.documento, d => d.identifier == doc.docIn)?.name;
        });

        signResult = await context.signNormal(selectedAction, pInfo.id, praticaDocuments);
        break;

      default:
        console.log("Unknown sign method");
    }

    if (signResult) {
      listOfCompletedProcesses.push(pInfo.id);
    }
  }

  if (listOfCompletedProcesses.length) {
    await context.onSignCompleted(listOfCompletedProcesses);
  }
}

/**
 * Generates an array of pratica documents based on the selected action.
 * 
 * @param {Object} action - The selected action object.
 * @returns {Array<Object>} - The generated pratica documents.
 * @private
 */
function _generatePraticaDocuments(action) {
  const result = [];
  let params = {};

  const { element } = action.manager.parameters;

  // params sample structure
  // (note that 'tipoDocumento' is required only to know the number of documents)
  //
  // {
  //   "tipoDocumento": ["2", "3"],
  //   "docin1": "IDENTIFIER_DOC_1",
  //   "docout1": "",
  //   "docin2": "IDENTIFIER_DOC_2",
  //   "docout2": "",
  // }

  _.forEach(element, e => {
    params[e.key] = e.key === "tipoDocumento"
      ? e.value.split("|")
      : e.value;
  });

  _.forEach(params["tipoDocumento"], (_, index) => {
    result.push({
      code: index + 1,
      docIn: params[`docin${index + 1}`],
      docOut: params[`docout${index + 1}`]
    });
  });
  
  return result;
}

export default {
  actionPerformed,
}