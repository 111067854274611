import { AppConfigStore } from 'services/commands/stores';

function info(timeout = 500) {
  return axios.get(`${AppConfigStore.getWebInteropUrl()}/@info`, {
    headers: {
      'Content-Type': 'application/json'
    },
    'timeout': timeout
  })
}

function configure() {
  return axios.put(`${AppConfigStore.getWebInteropUrl()}/@configuration`, {
    '@browser': document.title
  })
}

export default {
  info,
  configure,
}
