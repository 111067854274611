<template>
  <div v-if="init" class="router-view-container">
    <v-app id="inspire">

      <!-- Dialog Error or Warning -->
      <c-dialog-information
        v-model="showDialog"
        :title="titleDialog"
        :text="textDialog"
      />

      <!-- Overlay -->
      <v-overlay :value="showOverlay" style="z-index:100;">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>

      <!-- Default route: 'Home' -->
      <router-view />
    </v-app>
  </div>
</template>

<script>
// services
import Config from 'services/config';
// vuex
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  data() {
    return {
      // true   if initialization is completed
      // false  otherwise
      init: false
    }
  },

  computed: {
    ...mapGetters([
      'showOverlay',
      'dialog',
    ]),

    showDialog: {
      get() {
        return this.dialog.showDialog;
      },
      set(newValue) {
        newValue || this.$store.commit('hideDialog');
      }
    },

    textDialog() {
      return this.dialog.textDialog;
    },

    titleDialog() {
      return this.dialog.titleDialog;
    }
  },

  mounted () {
    // fetches the configuration Object
    Config.fetchConfig()
      .then(() => this.init = true);
  }
}
</script>