<template>
  <div id="form-istat-a">
    <c-expansion-sections
      :disabled="disabled"
      :sections="[
        'DECEDUTO',
        'LUOGO DECESSO',
        'CONDIZIONI MORBOSE',
        'MORTE TRAUMATISMO/AVVELENAMENTO',
        'MEDICO'
      ]"
    >
      <!-- "DECEDUTO" section -->
      <template #section1>
        <v-form :disabled="true">

          <!-- "Cognome" and "Nome" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="5">
              <c-text-field
                label="Cognome"
                :value="pratica.anagrafica_deceduto.cognome"
                prepend-icon="mdi-account-outline"
              />
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="5">
              <c-text-field
                label="Nome"
                :value="pratica.anagrafica_deceduto.nome"
                prepend-icon="mdi-account-outline"
              />
            </v-col>
          </v-row>

          <!-- "Sesso" and "Età (anni)" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="5">
              <c-text-field label="Sesso"
                :rules="requiredRule"
                :value="pratica.anagrafica_deceduto.sesso"
                prepend-icon="mdi-gender-male-female"
              />
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="5">
              <c-text-field
                :value="anni"
                label="Età (anni)"
                prepend-icon="mdi-calendar"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>

      <!-- "LUOGO DECESSO" section-->
      <template #section2>
        <!-- "Luogo" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="12">
            <c-select
              :items="listLuoghiDecesso"
              :rules="requiredRule"
              v-model="module.luogo_decesso"
              item-text="text" item-value="key"
              prepend-icon="mdi-office-building-marker"
              label="Luogo"
            />
          </v-col>
        </v-row>

        <!-- "Specificare Luogo Decesso" -->
        <transition name="slide-fade">
          <v-row no-gutters class="ma-2" v-show="showSpecificareLuogoDecesso">
            <v-col cols="12">
              <c-text-field
                v-model="module.altro_luogo_decesso"
                :rules="requiredRule"
                label="Specificare altro luogo decesso"
                prepend-icon="mdi-text"
              />
            </v-col>
          </v-row>
        </transition>

        <!-- "Comune", "Provincia" and "Codice ASL" -->
        <v-row no-gutters class="ma-2">

          <!-- "Comune" -->
          <v-col cols="3">
            <c-text-field
              :value="module.comune_luogo_decesso_specifico"
              :disabled="true"
              label="Comune"
              prepend-icon="mdi-map-marker"
            />
          </v-col>

          <v-spacer></v-spacer>

          <!-- "Provincia" -->
          <v-col cols="3">
            <c-text-field
              :value="module.provincia_luogo_decesso_specifica"
              :disabled="true"
              label="Provincia"
              prepend-icon="mdi-map-marker"
            />
          </v-col>

          <v-spacer></v-spacer>

          <!-- "Codice ASL" -->
          <v-col cols="3">
            <c-text-field 
              label="Codice ASL" 
              :rules="requiredRule" 
              v-model="module.codice_asl_decesso"
              prepend-icon="mdi-hospital-marker"
            />
          </v-col>
        </v-row>

        <!-- "Riscontro Diagnostico Richiesto" -->
        <v-row no-gutters class="ma-2 mb-4">
          <v-col cols="12" class="riscontro-diagnostico-col">
            <v-icon class="pl-1">{{ iconRiscontroDiagnostico }}</v-icon>

            Riscontro diagnostico richiesto:

            <c-switch
              v-model="riscontroDiagnostico"
              :label="riscontroDiagnostico ? 'SI' : 'NO'"
            />
          </v-col>
        </v-row>

        <!-- "Stato Gravidanza" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="12">
            <c-select
              :items="listStatoGravidanza"
              :rules="requiredRule"
              v-model="module.stato_gravidanza_deceduta"
              item-text="text"
              item-value="key"
              prepend-icon="mdi-human-pregnant"
              label="Stato Gravidanza"
              :disabled="!showStatoGravidanza"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "CONDIZIONI MORBOSE" section -->
      <template #section3>
        <!-- "Sequenza di condizioni morbose" -->
        <c-condition-list
          v-model="module.condizioni.condizione_morbosa"
          :description="descriptionCondizioniNonPreesistenti"
          :isPreesistente="false"
          :rules="requiredRule"
          :disabled="disabled"
          :minNotPre="minNotPre"
          :maxNotPre="maxNotPre"
          :minPre="minPre"
          :maxPre="maxPre"
        />

        <!-- "Condizioni morbose pre-esistenti" -->
        <c-condition-list
          v-model="module.condizioni.condizione_morbosa"
          :description="descriptionCondizioniPreesistenti"
          :isPreesistente="true"
          :rules="requiredRule"
          :disabled="disabled"
          :minNotPre="minNotPre"
          :maxNotPre="maxNotPre"
          :minPre="minPre"
          :maxPre="maxPre"
        />
      </template>

      <!-- "MORTE TRAUMATISMO/AVVELENAMENTO" section -->
      <template #section4>
        <div class="morte-avvelenamento-col">
          <!-- <v-icon>{{ iconMorteTraumaAvvelenamento }}</v-icon> -->
          Morte da traumatismo/avvelenamento:

          <c-switch
            v-model="morteTraumaAvvelenamento"
            :label="morteTraumaAvvelenamento ? 'SI' : 'NO'"
          />
        </div>
        
        <v-form :disabled="disabled || !morteTraumaAvvelenamento">

          <!-- "Circostanza" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12">
              <c-text-field
                v-model="circostanzaTraumatismoAvvelenamento"
                :disabled="!morteTraumaAvvelenamento"
                :rules="requiredRule"
                label="Circostanza"
                prepend-icon="mdi-text"
              />
            </v-col>
          </v-row>

          <!-- "Modalità" -->
          <v-row no-gutters class="ma-2">

            <v-col cols="5" class="modalita-morte-col">
              <c-select
                :disabled="!morteTraumaAvvelenamento"
                :rules="requiredRule"
                :items="listModalitaMorte"
                v-model="modalitaTraumatismoAvvelenamento"
                item-text="text"
                item-value="key"
                label="Modalità"
                prepend-icon="mdi-format-list-bulleted-square"
              />
            </v-col>

            <v-spacer></v-spacer>

            <!-- "Infortunio sul lavoro" -->
            <v-col cols="5" class="morte-lavoro-col">
              <v-icon :disabled="!morteTraumaAvvelenamento" class="pl-1">mdi-account-hard-hat</v-icon>
              Infortunio sul lavoro:

              <c-switch
                v-model="lavoro"
                :disabled="!morteTraumaAvvelenamento"
                :label="lavoro ? 'SI' : 'NO'"
              />
            </v-col>
          </v-row>

          <!-- "Incidente da trasporto" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12" class="trasporto-col">
              <!-- <v-icon :disabled="!morteTraumaAvvelenamento">mdi-car-side</v-icon> -->
              Incidente da trasporto:

              <c-switch
                v-model="trasporto"
                :disabled="!morteTraumaAvvelenamento"
                :label="trasporto ? 'SI' : 'NO'"
              />
            </v-col>
          </v-row>

          <!-- "Mezzo trasporto vittima" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12" class="pl-8">
              <c-text-field 
                v-model="mezzoIncidenteTrasporto"
                :disabled="!trasporto"
                :rules="requiredRule"
                label="Mezzo trasporto vittima"
                prepend-icon="mdi-train-car"
              />
            </v-col>
          </v-row>

          <!-- "Ruolo vittima" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12" class="pl-8">
              <c-select
                v-model="ruoloIncidenteTrasporto"
                :disabled="!trasporto"
                :rules="requiredRule"
                :items="listRuoloVittima"
                item-text="text"
                item-value="key"
                label="Ruolo vittima"
                prepend-icon="mdi-human-male"
              />
            </v-col>
          </v-row>

          <!-- "Tipo di incidente" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12" class="pl-8">
              <c-text-field 
                v-model="tipoIncidenteTrasporto"
                :disabled="!trasporto"
                :rules="requiredRule"
                label="Tipo di incidente"
                prepend-icon="mdi-car-multiple"
              />
            </v-col>
          </v-row>

          <!-- "Veicolo coinvolto" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12" class="pl-8 pb-3">
              <c-text-field 
                v-model="veicoloIncidenteTrasporto"
                :disabled="!trasporto"
                :rules="requiredRule"
                label="Veicolo Coinvolto"
                prepend-icon="mdi-car"
              />
            </v-col>
          </v-row>

          <v-row no-gutters class="ma-2">
            
            <!-- "Data accidente" -->
            <c-date-picker
              v-model="dataAccidenteISO"
              :rules="requiredRule"
              label="Data accidente"
            />

            <v-spacer></v-spacer>

            <!-- "Orario accidente" -->
            <c-time-picker v-model="oraAccidenteFormatted" label="Orario accidente" />
          </v-row>

          <!-- "Luogo accidente" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12">
              <c-select
                v-model="luogoAccidente"
                :disabled="!morteTraumaAvvelenamento"
                :rules="requiredRule"
                :items="listLuogoAccidente"
                item-text="text"
                item-value="key"
                label="Luogo accidente"
                prepend-icon="mdi-crosshairs-gps"
              />
            </v-col>
          </v-row>

          <!-- "Specificare Altro Luogo Accidente" -->
          <transition name="slide-fade">
            <v-row no-gutters class="ma-2" v-show="showSpecificareLuogoAccidente">
              <v-col cols="12">
                <c-text-field
                  v-model="altroLuogoAccidente"
                  :rules="requiredRule"
                  label="Specificare altro luogo accidente"
                  prepend-icon="mdi-text"
                />
              </v-col>
            </v-row>
          </transition>
        </v-form>
      </template>

      <!-- "MEDICO" section -->
      <template #section5>
        <v-row no-gutters class="ma-2">
          <!-- "Cognome" -->
          <v-col cols="5">
            <c-text-field
              v-model="module.cognome_medico"
              :rules="requiredRule"
              label="Cognome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>

          <v-spacer></v-spacer>

          <!-- "Nome" -->
          <v-col cols="5">
            <c-text-field 
              v-model="module.nome_medico" 
              :rules="requiredRule"
              label="Nome" 
              prepend-icon="mdi-badge-account"
            />
          </v-col>
        </v-row>

        <!-- "Tipo Medico" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              label="Tipo Medico"
              :rules="requiredRule"
              v-model="module.tipo_medico"
              prepend-icon="mdi-hospital"
              :items="listTipoMedico"
            ></c-select>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-2">
          <!-- "Telefono" -->
          <v-col cols="5">
            <c-text-field
              v-model="module.telefono_medico"  
              label="Telefono"
              type="number"
              prepend-icon="mdi-phone"
              hide-spin-buttons
            />
          </v-col>

          <v-spacer></v-spacer>

          <!-- "Telefono 2" -->
          <v-col cols="5">
            <c-text-field
              v-model="module.telefono2_medico"
              label="Telefono 2"
              type="number"
              hide-spin-buttons
              prepend-icon="mdi-phone"
            />
          </v-col>
        </v-row>
      </template>
    </c-expansion-sections>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />

    <!-- Dialog Condizioni Morte Violenta -->
    <dialog-condizioni-morte-violenta
      v-model="dialogCondizioniMorteViolenta"
      :conditions="condizioniMorteViolenta"
      @edit-conditions="onEditConditionsEvent"
      @confirm-conditions="onConfirmConditionsEvent"
      @morte-violenta-conditions="onMorteViolentaConditionsEvent"
    />

    <!-- Dialog Condizioni Riscontro Autoptico -->
    <dialog-condizioni-riscontro-autoptico
      v-model="dialogCondizioniRiscontroAutoptico"
      :condition="condizioneRiscontroAutoptico"
      :maxNewConditionsNumber="maxNotPre - 1"
      @delete-condition="onDeleteConditionEvent"
      @open-new-conditions="onOpenNewConditionsEvent"
      @riscontro-autoptico-conditions="onRiscontroAutopticoConditionsEvent"
    />
  </div>
</template>

<script>
// stores
import {
  ListLuoghiDecessoStore,
  ListStatoGravidanzaStore,
  ListModalitaMorteStore,
  ListRuoloVittimaStore,
  ListLuogoAccidenteStore,
  ListTipoMedicoStore,
} from 'services/commands/stores'
// services
import DateUtils from 'services/date_util'
import PraticaUtil from 'services/pratica_util'
import condizioniMorbose from 'mixins/condizioni_morbose';


export default {
  name: 'FormIstatA',
  
  mixins: [condizioniMorbose],
  
  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixIstatA(this.praticaDetails, !this.disabled);

    return {
      pratica: pratica,

      // 'module' instead of 'ia' required from [condizioniMorbose] mixin
      module: pratica.istat_a,

      requiredRule: [ value => !!value || 'Obbligatorio' ],

      // necessary to validate time
      timeValid: true,

      dialogError: false,
      errors: [],
    };
  },

  computed: {
    descriptionCondizioniNonPreesistenti() {
      return `Sequenza di condizione morbose (massimo ${this.maxNotPre}), lesioni o avvelenamenti che hanno
        condotto alla morte, dove l'ultima descritta è quella che ha causato la morte`;
    },

    descriptionCondizioniPreesistenti() {
      return `Condizioni morbose pre-esistenti (massimo ${this.maxPre})`;
    },

    // "Anni" field of the form.
    anni() {
      return DateUtils.dateDiff(this.pratica.comunicazione_decesso.dt_morte, this.pratica.anagrafica_deceduto.dt_nascita, 'years');
    },

    // "Riscontro Diagnostico" field of the form.
    riscontroDiagnostico: {
      get() { return this.module.richiesto_riscontro_diagnostico.toUpperCase() == 'SI' },
      set(newValue) { this.module.richiesto_riscontro_diagnostico = newValue ? 'SI' : 'NO' }
    },

    // "Morte da traumatismo/avvelenamento" field of the form.
    morteTraumaAvvelenamento: {
      get() { return this.module.morte_per_traumatismo_avvelenamento.toUpperCase() == 'SI' },
      set(newValue) { this.module.morte_per_traumatismo_avvelenamento = newValue ? 'SI' : 'NO' }
    },

    // "Lavoro" field of the form.
    lavoro: {
      get() {
        return this.morteTraumaAvvelenamento
          && this.module.infortunio_lavoro_traumatismo_avvelenamento.toUpperCase() == 'SI';
      },
      set(newValue) { this.module.infortunio_lavoro_traumatismo_avvelenamento = newValue ? 'SI' : 'NO' }
    },

    // "Trasporto" field of the form.
    trasporto: {
      get() {
        return this.morteTraumaAvvelenamento
          && this.module.incidente_trasporto_traumatismo_avvelenamento.toUpperCase() == 'SI';
      },
      set(newValue) { this.module.incidente_trasporto_traumatismo_avvelenamento = newValue ? 'SI' : 'NO' }
    },

    // "Data Accidente" is ISO format; used in the c-date-picker.
    dataAccidenteISO: {
      get() {
        return DateUtils.formatDateAsIso(this.module.dt_accidente);
      },
      set(newValue) {
        this.setDataAccidente(newValue, this.oraAccidenteFormatted)
      }
    },

    // "Ora Accidente" formatted (hh:mm); used in the c-date-picker.
    oraAccidenteFormatted: {
      get() {
        return DateUtils.formatTime(this.module.dt_accidente);
      },
      set(newValue) {
        if (DateUtils.validateTime(newValue)) {
          this.setDataAccidente(this.dataAccidenteISO, newValue);
          this.timeValid = true;
        } else {
          this.timeValid = false;
        }
      }
    },

    // If true, it enables the "Stato Gravidanza" field of the form.
    showStatoGravidanza() {
      return this.pratica.anagrafica_deceduto.sesso.toUpperCase() == 'FEMMINA';
    },

    // If true, it shows the "Specifica Luogo Decesso" field of the form.
    showSpecificareLuogoDecesso() {
      return this.module.luogo_decesso.toUpperCase() == 'ALTRO';
    },

    // If true, it shows the "Specifica Luogo Accidente" field of the form.
    showSpecificareLuogoAccidente() {
      return this.module.luogo_accidente.toUpperCase() == 'ALTRO';
    },

    iconRiscontroDiagnostico() {
      return this.riscontroDiagnostico ? "mdi-needle" : "mdi-needle-off";
    },

    iconMorteTraumaAvvelenamento() {
      return this.morteTraumaAvvelenamento ? "mdi-bottle-tonic-skull-outline" : "mdi-bottle-tonic-skull";
    },

    // "conditional" fields
    circostanzaTraumatismoAvvelenamento: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.circostanza_traumatismo_avvelenamento : '';
      },
      set(value) { this.module.circostanza_traumatismo_avvelenamento = value },
    },

    modalitaTraumatismoAvvelenamento: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.modalita_traumatismo_avvelenamento : '';
      },
      set(value) { this.module.modalita_traumatismo_avvelenamento = value },
    },
    
    mezzoIncidenteTrasporto: {
      get() {
        return this.trasporto ? this.module.mezzo_incidente_trasporto : '';
      },
      set(value) { this.module.mezzo_incidente_trasporto = value },
    },

    ruoloIncidenteTrasporto: {
      get() {
        return this.trasporto ? this.module.ruolo_incidente_trasporto : '';
      },
      set(value) { this.module.ruolo_incidente_trasporto = value },
    },

    tipoIncidenteTrasporto: {
      get() {
        return this.trasporto ? this.module.tipo_incidente_trasporto : '';
      },
      set(value) { this.module.tipo_incidente_trasporto = value },
    },

    veicoloIncidenteTrasporto: {
      get() {
        return this.trasporto ? this.module.oggetto_veicolo_incidente_trasporto : '';
      },
      set(value) { this.module.oggetto_veicolo_incidente_trasporto = value },
    },

    luogoAccidente: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.luogo_accidente : '';
      },
      set(value) { this.module.luogo_accidente = value },
    },

    altroLuogoAccidente: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.altro_luogo_accidente : '';
      },
      set(value) { this.module.altro_luogo_accidente = value },
    },

    ///
    /// Utilities computed values.
    ///
    listLuoghiDecesso: () =>  _.toArray(ListLuoghiDecessoStore.getLuoghiDecesso()),
    listStatoGravidanza: () => _.toArray(ListStatoGravidanzaStore.getStatoGravidanza()),
    listModalitaMorte: () => _.toArray(ListModalitaMorteStore.getModalitaMorte()),
    listRuoloVittima: () => _.toArray(ListRuoloVittimaStore.getRuoloVittima()),
    listLuogoAccidente: () => _.toArray(ListLuogoAccidenteStore.getLuogoAccidente()),
    listTipoMedico: () => ListTipoMedicoStore.getListTipoMedico(),
  },

  watch: {
    // Watch used to notify parent component when 'pratica' updates.
    'pratica.istat_a': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },

    // Watch used to update 'Medico' informations
    disabled(newValue) {
      if (!newValue) {
        // adding user information 
        this.pratica = PraticaUtil.fixIstatA(this.praticaDetails, true);
        this.module = this.pratica?.istat_a;
      }
    },

    showSpecificareLuogoDecesso(newValue) {
      newValue || (this.module.altro_luogo_decesso = "");
    },

    showSpecificareLuogoAccidente(newValue) {
      newValue || (this.module.altro_luogo_accidente = "");
    },
  },

  methods: {
    // Method used to update 'dt_morte' with date and time.
    setDataAccidente(date, time) {
      let hour = time.split(":")[0];
      let minute = time.split(":")[1];

      this.module.dt_accidente = moment(date).set({
        hour: hour,
        minute: minute
      }).format();
    },

    ///
    /// Exposed methods.
    ///
    validate() {
      let errors = [];

      let preCount = 0;
      let notPreCount = 0;
      
      if (this.showSpecificareLuogoDecesso && !this.module.altro_luogo_decesso)
        errors.push("Altro luogo decesso");
      
      this.module.codice_asl_decesso || errors.push("Codice ASL decesso");

      this.module.condizioni.condizione_morbosa.forEach((c, index) => {
        c.malattia || errors.push(`Malattia della ${index + 1}ᵃ condizione morbosa`);
        if (c.is_preesistente)
          preCount++;
        else
          notPreCount++;
      });

      if (preCount < this.minPre)
        errors.push(`Sono necessarie almeno ${this.minPre} condizioni preesistenti`);

      if (notPreCount < this.minNotPre)
        errors.push(`Sono necessarie almeno ${this.minNotPre} condizioni non preesistenti`);

      if (this.morteTraumaAvvelenamento) {
        this.module.circostanza_traumatismo_avvelenamento || errors.push("Circostanza di morte");

        if (this.trasporto) {
          // mezzo - tipo - oggetto_veicolo (incidente)
          this.module.mezzo_incidente_trasporto || errors.push("Mezzo di trasporto della vittima");
          this.module.tipo_incidente_trasporto || errors.push("Tipo di incidente");
          this.module.oggetto_veicolo_incidente_trasporto || errors.push("Veicolo coinvolto nell'incidente");
        }

        const dataAccidenteValid = this.dataAccidenteISO && !DateUtils.isEmptyDate(this.dataAccidenteISO);
        dataAccidenteValid || errors.push("Data dell'accidente (vuota o invalida)");

        this.timeValid || errors.push("Orario dell'Accidente (vuoto o invalido)");

        // dataNascita <= dataAccidente <= dataRicovero (dataMorte)
        const dataAccidente = this.module.dt_accidente;
        const dataNascita = this.pratica.anagrafica_deceduto.dt_nascita;
        const dataMorte = this.pratica.comunicazione_decesso.dt_morte;

        if (dataAccidenteValid && this.timeValid) {
          if (DateUtils.dateDiff(dataAccidente, dataNascita) < 0) {
            errors.push("Data dell'Accidente invalida (deve essere successiva alla data di Nascita)");
          } else if (DateUtils.dateDiff(dataAccidente, dataMorte) > 60*1000*60*24) {
            // dataAccidente 10/10/2000-23:59 and dataMorte 10/10/2000-00:00 must be accepted
            // offset of 24 hours on diff (60*1000*60*24 milliseconds)
            errors.push("Data dell'Accidente invalida (deve essere precedente alla data di Morte)");
          }
        }

        if (this.showSpecificareLuogoAccidente && !this.module.altro_luogo_accidente)
          errors.push("Altro luogo dell'accidente");
      }

      // nome - cognome - tipo (medico)
      this.module.nome_medico || errors.push("Nome del medico");
      this.module.cognome_medico || errors.push("Cognome del medico");
      this.module.tipo_medico || errors.push("Tipo del medico");

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }

      // checking "morte violenta" conditions
      if (this.condizioniMorteViolenta.length) {
        this.dialogCondizioniMorteViolenta = true;
        return new Promise((resolve) => { this.resolveValidation = resolve });
      }

      // checking "riscontro autoptico" conditions
      if (this.condizioneRiscontroAutoptico) {
        this.dialogCondizioniRiscontroAutoptico = true;
        return new Promise((resolve) => { this.resolveValidation = resolve });
      }

      this._clearUnusedFields();
      return true;
    },

    // Clear fields if "morteTraumaAvvelenamento" or "incidenteDaTrasporto" are disabled.
    _clearUnusedFields() {
      if (!this.morteTraumaAvvelenamento) {
        this.module.circostanza_traumatismo_avvelenamento = "";
        this.module.modalita_traumatismo_avvelenamento = 'Accidentale';
        this.module.infortunio_lavoro_traumatismo_avvelenamento = 'NO';
        this.module.incidente_trasporto_traumatismo_avvelenamento = 'NO';
        this.module.dt_accidente = DateUtils.EMPTY_DATE;
        this.module.luogo_accidente = 'Casa';
        this.module.altro_luogo_accidente = "";
      }
      if (!this.trasporto) {
        this.module.mezzo_incidente_trasporto = "";
        this.module.ruolo_incidente_trasporto = 'Pedone';
        this.module.tipo_incidente_trasporto = "";
        this.module.oggetto_veicolo_incidente_trasporto = "";
      }
    }
  },
}
</script>