<template>
  <!-- Dislay the category -->
  <!-- In case on validation error, selects the error style -->
  <div :class="errorStyle ? 'error-single-category' : 'single-category'">
    <div :class="errorStyle ? 'error-category-name' : 'category-name'">
      <div class="custom-row">

        <!-- "*" only for required categories -->
        <div class="custom-col">
          <p>
            {{ category.label }}
            <span v-if="category.required" class="required">*</span>
          </p>
        </div>

        <div class="custom-spacer"></div>

        <!-- Display number of pages -->
        <div class="custom-col">
          <p :class="errorStyle ? 'error-custom-end-text' : 'custom-end-text'">
            <b>{{ pages.length }}</b> pagin{{ pages.length == 1 ? "a" : "e" }}
          </p>
        </div>
      </div>
    </div>

    <!-- Container for displaying size toggle -->
    <div class="toggle">
      <v-btn-toggle
        v-model="toggleSize"
        class="toggle-size"
        mandatory
        dense
      >
        <!-- Small page preview-->
        <v-btn x-small>
          <v-icon small>mdi-image-size-select-actual</v-icon>
        </v-btn>

        <!-- Large page preview -->
        <v-btn x-small>
          <v-icon small>mdi-image-size-select-large</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <!-- Display pages list -->
    <div :class="pagesClass" :data-category-id="category.id">
      <div class="dz-message"></div>
      <div class="uploaded-file-box">
        <div class="uploaded-file-queue dropzone-previews"></div>
      </div>

      <SinglePage
        v-for="page in pages"
        :page="page"
        :key="`cpage_${page.id}`"
        :imgStyle="imgStyle"
        :previewUrl="previewUrl"
      >
        <!-- Tooltip for the "remove from category" button -->
        <template #left-button>
          <v-tooltip bottom>
            <!-- "Remove from category" button for the page -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon :disabled="false" @click="removeFromCategory(page.id)">
                <v-icon v-bind="attrs" v-on="on" color="white">mdi-minus-thick</v-icon>
              </v-btn>
            </template>
            <span>Rimuovi dalla categoria</span>
          </v-tooltip>
        </template>
      </SinglePage>
    </div>
  </div>
</template>

<script>
// components
import SinglePage from './_commons/SinglePage.vue'
// mixins
import Dropzone from './_mixins/Dropzone.vue'

export default {
  name: 'Category',
  
  mixins: [Dropzone],

  components: {
    SinglePage
  },

  props: {
    category: {
      type: Object,
      required: true,
    },
    categoriesMode: {
      type: String,
      required: false,
      default: 'vertical'
    },
    pages: {
      type: Array,
      required: true,
    },
    previewUrl: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      // imgStyle 
      imgStyle: {},

      // Default toggle value
      // 0 : large page preview
      // 1 : small page preview
      toggleSize: 0,

      // Validation error state
      isHighlighted: false,
    }
  },

  computed: {
    // Element required by the dropzone.
    element() {
      if (!this.category?.id) return null
      return document.querySelector(`.pages-list[data-category-id='${this.category.id}']`)
    },

    // Returns the "pagesClass" based on [toggleSize] value
    pagesClass() {
      const gridClass = this.toggleSize === 0
        ? 'grid-large-pages'
        : 'grid-small-pages'
      return `pages-list ${gridClass} dropzone`
    },

    // If true, it indicates that style must adapt error state
    errorStyle() {
      return this.isHighlighted && this.pages.length === 0
    }
  },

  methods: {
    removeFromCategory(pageId) {
      this.$emit('removeFromCategory', pageId)
    },

    // Initialize the image styles based on the category displaye mode.
    initImageStyles() {
      let style = {}

      if (this.categoriesMode === 'horizontal') {
        let height = this._calcImageHeight()

        style["height"] = `${height}px`
      }

      return style
    },

    _calcImageHeight() {
      let calculatedStyle = window.getComputedStyle(this.element)

      let height = parseInt(calculatedStyle.height)
      let padding = (parseInt(calculatedStyle.paddingTop) + parseInt(calculatedStyle.paddingBottom))

      return parseInt(height - padding)
    },

    highlight() {
      this.isHighlighted = true
    }
  },

  mounted() {
    this.imgStyle = this.initImageStyles()

    // initializing dropzone component
    this.initDropzone(
      {
        element: this.element,
        // empty url (upload not needed in Category)
        url: `http://`,
        clickable: false,
        previewDiv: this.element.querySelector('.uploaded-file-queue'),
        orientation: this.categoriesMode,
        autoProcessQueue: false,
        autoQueue: false
      }
    )

    window.addEventListener('resize', () => {
      this.imgStyle = this.initImageStyles()
    })
  }
}
</script>