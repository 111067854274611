let LIST_PROCESS_DEF = {}

function init(jsonListMeta) {
    LIST_PROCESS_DEF = {}
    
    _.forEach(jsonListMeta.process_definition_list.element, process_def => {
        LIST_PROCESS_DEF[process_def.id] = process_def.value
    })
}

export default {
    init
}