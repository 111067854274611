var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-istat-a" } },
    [
      _c("c-expansion-sections", {
        attrs: {
          disabled: _vm.disabled,
          sections: [
            "DECEDUTO",
            "LUOGO DECESSO",
            "CONDIZIONI MORBOSE",
            "MORTE TRAUMATISMO/AVVELENAMENTO",
            "MEDICO",
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "section1",
            fn: function () {
              return [
                _c(
                  "v-form",
                  { attrs: { disabled: true } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Cognome",
                                value: _vm.pratica.anagrafica_deceduto.cognome,
                                "prepend-icon": "mdi-account-outline",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Nome",
                                value: _vm.pratica.anagrafica_deceduto.nome,
                                "prepend-icon": "mdi-account-outline",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Sesso",
                                rules: _vm.requiredRule,
                                value: _vm.pratica.anagrafica_deceduto.sesso,
                                "prepend-icon": "mdi-gender-male-female",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                value: _vm.anni,
                                label: "Età (anni)",
                                "prepend-icon": "mdi-calendar",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section2",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listLuoghiDecesso,
                            rules: _vm.requiredRule,
                            "item-text": "text",
                            "item-value": "key",
                            "prepend-icon": "mdi-office-building-marker",
                            label: "Luogo",
                          },
                          model: {
                            value: _vm.module.luogo_decesso,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "luogo_decesso", $$v)
                            },
                            expression: "module.luogo_decesso",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    _c(
                      "v-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSpecificareLuogoDecesso,
                            expression: "showSpecificareLuogoDecesso",
                          },
                        ],
                        staticClass: "ma-2",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                rules: _vm.requiredRule,
                                label: "Specificare altro luogo decesso",
                                "prepend-icon": "mdi-text",
                              },
                              model: {
                                value: _vm.module.altro_luogo_decesso,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.module,
                                    "altro_luogo_decesso",
                                    $$v
                                  )
                                },
                                expression: "module.altro_luogo_decesso",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            value: _vm.module.comune_luogo_decesso_specifico,
                            disabled: true,
                            label: "Comune",
                            "prepend-icon": "mdi-map-marker",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            value: _vm.module.provincia_luogo_decesso_specifica,
                            disabled: true,
                            label: "Provincia",
                            "prepend-icon": "mdi-map-marker",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Codice ASL",
                            rules: _vm.requiredRule,
                            "prepend-icon": "mdi-hospital-marker",
                          },
                          model: {
                            value: _vm.module.codice_asl_decesso,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "codice_asl_decesso", $$v)
                            },
                            expression: "module.codice_asl_decesso",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2 mb-4", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "riscontro-diagnostico-col",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-icon", { staticClass: "pl-1" }, [
                          _vm._v(_vm._s(_vm.iconRiscontroDiagnostico)),
                        ]),
                        _vm._v(
                          "\n\n          Riscontro diagnostico richiesto:\n\n          "
                        ),
                        _c("c-switch", {
                          attrs: {
                            label: _vm.riscontroDiagnostico ? "SI" : "NO",
                          },
                          model: {
                            value: _vm.riscontroDiagnostico,
                            callback: function ($$v) {
                              _vm.riscontroDiagnostico = $$v
                            },
                            expression: "riscontroDiagnostico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listStatoGravidanza,
                            rules: _vm.requiredRule,
                            "item-text": "text",
                            "item-value": "key",
                            "prepend-icon": "mdi-human-pregnant",
                            label: "Stato Gravidanza",
                            disabled: !_vm.showStatoGravidanza,
                          },
                          model: {
                            value: _vm.module.stato_gravidanza_deceduta,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.module,
                                "stato_gravidanza_deceduta",
                                $$v
                              )
                            },
                            expression: "module.stato_gravidanza_deceduta",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section3",
            fn: function () {
              return [
                _c("c-condition-list", {
                  attrs: {
                    description: _vm.descriptionCondizioniNonPreesistenti,
                    isPreesistente: false,
                    rules: _vm.requiredRule,
                    disabled: _vm.disabled,
                    minNotPre: _vm.minNotPre,
                    maxNotPre: _vm.maxNotPre,
                    minPre: _vm.minPre,
                    maxPre: _vm.maxPre,
                  },
                  model: {
                    value: _vm.module.condizioni.condizione_morbosa,
                    callback: function ($$v) {
                      _vm.$set(_vm.module.condizioni, "condizione_morbosa", $$v)
                    },
                    expression: "module.condizioni.condizione_morbosa",
                  },
                }),
                _vm._v(" "),
                _c("c-condition-list", {
                  attrs: {
                    description: _vm.descriptionCondizioniPreesistenti,
                    isPreesistente: true,
                    rules: _vm.requiredRule,
                    disabled: _vm.disabled,
                    minNotPre: _vm.minNotPre,
                    maxNotPre: _vm.maxNotPre,
                    minPre: _vm.minPre,
                    maxPre: _vm.maxPre,
                  },
                  model: {
                    value: _vm.module.condizioni.condizione_morbosa,
                    callback: function ($$v) {
                      _vm.$set(_vm.module.condizioni, "condizione_morbosa", $$v)
                    },
                    expression: "module.condizioni.condizione_morbosa",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "section4",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "morte-avvelenamento-col" },
                  [
                    _vm._v(
                      "\n        Morte da traumatismo/avvelenamento:\n\n        "
                    ),
                    _c("c-switch", {
                      attrs: {
                        label: _vm.morteTraumaAvvelenamento ? "SI" : "NO",
                      },
                      model: {
                        value: _vm.morteTraumaAvvelenamento,
                        callback: function ($$v) {
                          _vm.morteTraumaAvvelenamento = $$v
                        },
                        expression: "morteTraumaAvvelenamento",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-form",
                  {
                    attrs: {
                      disabled: _vm.disabled || !_vm.morteTraumaAvvelenamento,
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                rules: _vm.requiredRule,
                                label: "Circostanza",
                                "prepend-icon": "mdi-text",
                              },
                              model: {
                                value: _vm.circostanzaTraumatismoAvvelenamento,
                                callback: function ($$v) {
                                  _vm.circostanzaTraumatismoAvvelenamento = $$v
                                },
                                expression:
                                  "circostanzaTraumatismoAvvelenamento",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "modalita-morte-col",
                            attrs: { cols: "5" },
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                rules: _vm.requiredRule,
                                items: _vm.listModalitaMorte,
                                "item-text": "text",
                                "item-value": "key",
                                label: "Modalità",
                                "prepend-icon":
                                  "mdi-format-list-bulleted-square",
                              },
                              model: {
                                value: _vm.modalitaTraumatismoAvvelenamento,
                                callback: function ($$v) {
                                  _vm.modalitaTraumatismoAvvelenamento = $$v
                                },
                                expression: "modalitaTraumatismoAvvelenamento",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "morte-lavoro-col",
                            attrs: { cols: "5" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pl-1",
                                attrs: {
                                  disabled: !_vm.morteTraumaAvvelenamento,
                                },
                              },
                              [_vm._v("mdi-account-hard-hat")]
                            ),
                            _vm._v(
                              "\n            Infortunio sul lavoro:\n\n            "
                            ),
                            _c("c-switch", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                label: _vm.lavoro ? "SI" : "NO",
                              },
                              model: {
                                value: _vm.lavoro,
                                callback: function ($$v) {
                                  _vm.lavoro = $$v
                                },
                                expression: "lavoro",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "trasporto-col",
                            attrs: { cols: "12" },
                          },
                          [
                            _vm._v(
                              "\n            Incidente da trasporto:\n\n            "
                            ),
                            _c("c-switch", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                label: _vm.trasporto ? "SI" : "NO",
                              },
                              model: {
                                value: _vm.trasporto,
                                callback: function ($$v) {
                                  _vm.trasporto = $$v
                                },
                                expression: "trasporto",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pl-8", attrs: { cols: "12" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                disabled: !_vm.trasporto,
                                rules: _vm.requiredRule,
                                label: "Mezzo trasporto vittima",
                                "prepend-icon": "mdi-train-car",
                              },
                              model: {
                                value: _vm.mezzoIncidenteTrasporto,
                                callback: function ($$v) {
                                  _vm.mezzoIncidenteTrasporto = $$v
                                },
                                expression: "mezzoIncidenteTrasporto",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pl-8", attrs: { cols: "12" } },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: !_vm.trasporto,
                                rules: _vm.requiredRule,
                                items: _vm.listRuoloVittima,
                                "item-text": "text",
                                "item-value": "key",
                                label: "Ruolo vittima",
                                "prepend-icon": "mdi-human-male",
                              },
                              model: {
                                value: _vm.ruoloIncidenteTrasporto,
                                callback: function ($$v) {
                                  _vm.ruoloIncidenteTrasporto = $$v
                                },
                                expression: "ruoloIncidenteTrasporto",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pl-8", attrs: { cols: "12" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                disabled: !_vm.trasporto,
                                rules: _vm.requiredRule,
                                label: "Tipo di incidente",
                                "prepend-icon": "mdi-car-multiple",
                              },
                              model: {
                                value: _vm.tipoIncidenteTrasporto,
                                callback: function ($$v) {
                                  _vm.tipoIncidenteTrasporto = $$v
                                },
                                expression: "tipoIncidenteTrasporto",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pl-8 pb-3", attrs: { cols: "12" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                disabled: !_vm.trasporto,
                                rules: _vm.requiredRule,
                                label: "Veicolo Coinvolto",
                                "prepend-icon": "mdi-car",
                              },
                              model: {
                                value: _vm.veicoloIncidenteTrasporto,
                                callback: function ($$v) {
                                  _vm.veicoloIncidenteTrasporto = $$v
                                },
                                expression: "veicoloIncidenteTrasporto",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c("c-date-picker", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Data accidente",
                          },
                          model: {
                            value: _vm.dataAccidenteISO,
                            callback: function ($$v) {
                              _vm.dataAccidenteISO = $$v
                            },
                            expression: "dataAccidenteISO",
                          },
                        }),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c("c-time-picker", {
                          attrs: { label: "Orario accidente" },
                          model: {
                            value: _vm.oraAccidenteFormatted,
                            callback: function ($$v) {
                              _vm.oraAccidenteFormatted = $$v
                            },
                            expression: "oraAccidenteFormatted",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                rules: _vm.requiredRule,
                                items: _vm.listLuogoAccidente,
                                "item-text": "text",
                                "item-value": "key",
                                label: "Luogo accidente",
                                "prepend-icon": "mdi-crosshairs-gps",
                              },
                              model: {
                                value: _vm.luogoAccidente,
                                callback: function ($$v) {
                                  _vm.luogoAccidente = $$v
                                },
                                expression: "luogoAccidente",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "slide-fade" } },
                      [
                        _c(
                          "v-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showSpecificareLuogoAccidente,
                                expression: "showSpecificareLuogoAccidente",
                              },
                            ],
                            staticClass: "ma-2",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("c-text-field", {
                                  attrs: {
                                    rules: _vm.requiredRule,
                                    label: "Specificare altro luogo accidente",
                                    "prepend-icon": "mdi-text",
                                  },
                                  model: {
                                    value: _vm.altroLuogoAccidente,
                                    callback: function ($$v) {
                                      _vm.altroLuogoAccidente = $$v
                                    },
                                    expression: "altroLuogoAccidente",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section5",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Cognome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.module.cognome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "cognome_medico", $$v)
                            },
                            expression: "module.cognome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Nome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.module.nome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "nome_medico", $$v)
                            },
                            expression: "module.nome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            label: "Tipo Medico",
                            rules: _vm.requiredRule,
                            "prepend-icon": "mdi-hospital",
                            items: _vm.listTipoMedico,
                          },
                          model: {
                            value: _vm.module.tipo_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "tipo_medico", $$v)
                            },
                            expression: "module.tipo_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Telefono",
                            type: "number",
                            "prepend-icon": "mdi-phone",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.module.telefono_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "telefono_medico", $$v)
                            },
                            expression: "module.telefono_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Telefono 2",
                            type: "number",
                            "hide-spin-buttons": "",
                            "prepend-icon": "mdi-phone",
                          },
                          model: {
                            value: _vm.module.telefono2_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.module, "telefono2_medico", $$v)
                            },
                            expression: "module.telefono2_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
      _vm._v(" "),
      _c("dialog-condizioni-morte-violenta", {
        attrs: { conditions: _vm.condizioniMorteViolenta },
        on: {
          "edit-conditions": _vm.onEditConditionsEvent,
          "confirm-conditions": _vm.onConfirmConditionsEvent,
          "morte-violenta-conditions": _vm.onMorteViolentaConditionsEvent,
        },
        model: {
          value: _vm.dialogCondizioniMorteViolenta,
          callback: function ($$v) {
            _vm.dialogCondizioniMorteViolenta = $$v
          },
          expression: "dialogCondizioniMorteViolenta",
        },
      }),
      _vm._v(" "),
      _c("dialog-condizioni-riscontro-autoptico", {
        attrs: {
          condition: _vm.condizioneRiscontroAutoptico,
          maxNewConditionsNumber: _vm.maxNotPre - 1,
        },
        on: {
          "delete-condition": _vm.onDeleteConditionEvent,
          "open-new-conditions": _vm.onOpenNewConditionsEvent,
          "riscontro-autoptico-conditions":
            _vm.onRiscontroAutopticoConditionsEvent,
        },
        model: {
          value: _vm.dialogCondizioniRiscontroAutoptico,
          callback: function ($$v) {
            _vm.dialogCondizioniRiscontroAutoptico = $$v
          },
          expression: "dialogCondizioniRiscontroAutoptico",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }