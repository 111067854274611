let LIST_REPARTI_STORE = {};

function init(jsonListMeta) {
  LIST_REPARTI_STORE = {
    reparti: [
      {
        id: -1,
        name: ""
      }
    ]
  }

  _.forEach(jsonListMeta.reparti.reparto, reparto => {
    if (reparto.selezionabile) {
      LIST_REPARTI_STORE.reparti.push({
        ...reparto,
        'sotto_reparti': undefined
      });
    }

    if (reparto?.sotto_reparti?.reparto?.length) {
      _initSottoReparti(reparto.sotto_reparti, reparto.name);
    }
  });

  LIST_REPARTI_STORE.reparti = _.sortBy(LIST_REPARTI_STORE.reparti, 'name');
}

function _initSottoReparti(sottoReparti, previousPath) {
  _.forEach(sottoReparti.reparto, reparto => {
    const path = `${previousPath} / ${reparto.name}`;

    if (reparto.selezionabile) {
      const sottoReparto = {
        ...reparto,
        'nome_completo': path,
        'sotto_reparti': undefined
      };

      LIST_REPARTI_STORE.reparti.push(sottoReparto);
    }

    if (reparto?.sotto_reparti?.reparto?.length) {
      _initSottoReparti(reparto.sotto_reparti, path);
    }
  });
}

function getListReparto() {
  return _.cloneDeep(LIST_REPARTI_STORE.reparti);
}

export default {
  init,
  getListReparto,
}