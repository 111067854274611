<template>
  <div>
    <v-alert v-if="status == 'off'"
      @click="showAlert"
      class="ma-0 alert"
      border="left"
      type="error"
      v-ripple
      colored-border
      light
      dense
    >
      <b>Attenzione: Plugin locale mancante!</b>
    </v-alert>

    <!-- DIALOG -->
    <DialogWebInteropError v-model="dialogError" />
  </div>
</template>

<script>
// getters
import { mapGetters } from 'vuex';
// services
import WebInterop from 'services/webinterop';
// components
import DialogWebInteropError from './DialogWebInteropError.vue';


export default {
  name: "WebInteropStatus",

  components: { DialogWebInteropError },

  data() {
    return {
      interval: null,
      intervalDelay: 2000,
      intervalDelayRelaxed: 5000,

      // webinterop status
      status: null,

      dialogError: false,
    };
  },

  computed: {
    ...mapGetters([
      'showableWebInteropAlert'
    ]),
  },

  methods: {
    /**
     * Sets the interval for updating the web interop status.
     * @param {number} delay - The delay in milliseconds.
     */
    setInterval(delay) {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.updateStatus();
      }, delay);
    },

    // Updates the webinterop status
    updateStatus() {
      WebInterop.info()
        .then(() => {
          if (this.status != 'on') {
            WebInterop.configure();
            this.hideAlert();
          }
          this.status = 'on';
          this.setInterval(this.intervalDelayRelaxed);
        }).catch(() => {
          if (this.status != 'off' && this.showableWebInteropAlert) {
            this.showAlert();
          }
          this.status = 'off';
          this.setInterval(this.intervalDelay);
        });
    },

    showAlert() {
      this.dialogError = true;
    },

    hideAlert() {
      this.dialogError = false;
    }
  },

  mounted() {
    this.updateStatus();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
}

</script>