<template>
  <div id="documents-table-details" :style="disabled ? divStyle : {}">
    <!-- Pratiche grid -->
    <ag-grid-vue
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :gridOptions="gridOptions"
      :rowData="listPraticaInfo"
      style="width: 100%; height: 100%;"
      class="ag-theme-alpine"
      overlayNoRowsTemplate="Nessuna pratica"
    />
  </div>
</template>

<script>
// ag-grid-vue
import { AgGridVue } from "ag-grid-vue";
// vuex
import { mapGetters } from "vuex";
// components
import TableDetailsHeader from "./TableDetailsHeader";
import TableCellRenderer from "components/TableCellRenderer";

export default {
  name: "TableDetails",

  components: {
    AgGridVue,
    TableCellRenderer,
    TableDetailsHeader,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      // style applied in case of TableDetails disabled
      divStyle: {
        pointerEvents: "none",
        opacity: 0.6,
      },

      // required by ag-grid-vue
      columnDefs: [
        {
          headerComponent: "TableDetailsHeader",
          field: "nome",
          sortable: false,
          resizable: false,
          autoHeight: true,
          minWidth: 200,
          cellRenderer: 'TableCellRenderer',
        },
      ],

      // required by ag-grid-vue
      gridOptions: {
        rowSelection: "single",
        animateRows: true,

        getRowId: (params) => params.data.id,
  
        onRowClicked: this.updateSelectedPraticaId,

        // invoked when a new 'Pratica' is added
        onRowDataUpdated: this.setSelectedNodes,

        // It ensures that selected row is always visible.
        onRowContainerHeightChanged() {
          let selected = this.api.getSelectedNodes();
          if (selected.length)
            this.api.ensureIndexVisible(selected[0].rowIndex, null);
        }
      },
    };
  },

  computed: {
    ...mapGetters([
      'selectedPraticaId',
      'listPraticaInfo',
    ]),
  },

  watch: {
    // updating selectedNodes on selectedPraticaId's updated.
    'selectedPraticaId'() {
      this.setSelectedNodes();
    },

    // refreshing cells built from CellRenderer
    'listPraticaInfo'() {
      this.gridOptions.api.refreshCells({ force: true });
    }
  },

  methods: {
    /**
     * Handler for the grid ready event that sets up the grid API and column API,
     * resizes the columns to fit the container,and adds a listener for the resize window event.
     * 
     * @param {Object} params - The parameters for the grid ready event.
     * @param {Object} params.api - The grid API object.
     * @param {Object} params.columnApi - The column API object.
     */
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      
      // DEBUG PURPOSE
      
      // params.api.addGlobalListener(function (type, event) {
      //   debugger;
      //   console.log('EVENT', event);
      // });

      params.api.sizeColumnsToFit();
      window.addEventListener("resize", this.resizeWindowHandler);

      // setting the selected row
      this.setSelectedNodes();
    },

    // Sets the selected nodes in the grid and ensures the visibility of the selected row.
    setSelectedNodes() {
      const { api } = this.gridOptions;

      if (!api) return;
  
      const id = this.selectedPraticaId;
      let deselect = true;

      if (id) {
        const selected = api.getRowNode(id);
        if(selected) {
          selected.setSelected(true);
          api.ensureIndexVisible(selected.rowIndex, null);

          deselect = false;
        }
      }
      if (deselect)
        api.deselectAll();
    },

    // Updates the selected Pratica IDs in the store and emits a selection change event.
    updateSelectedPraticaId(event) {
      this.$store.commit("changeSelectedPraticaIds", [event.data.id])
    },

    // Handler for the resize window event that resizes the columns in the grid.
    resizeWindowHandler() {
      let api = this.gridApi;
      setTimeout(function () {
        if (api) {
          api.sizeColumnsToFit();
        }
      });
    },
  },

  beforeDestroy() {
    // removing resize window listener
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
};
</script>
