<template>
  <div class="page">

    <!-- Header Bar containing title, version, profile and logout option -->
    <HeaderBar :showChipPratiche="showChipPratiche" />

    <div v-if="show" class="page-body">
      <div class="page-content">

        <!-- Menu Bar containing activity and period selectors -->
        <MenuBar
          @refresh-button-clicked="onRefreshButtonClicked"
          @activity-selected-change="onActivitySelectedChange"
          class="ma-2"
        />

        <!-- Home Table containing the list of filtered pratiche -->
        <TableHome
          @selectionChange="onSelectionChange"
          class="mx-2"
        />
        
        <ActionBar
          @action-button-clicked="onActionButtonClick"
          :selection="this.selection"
          :mode="actionBarMode"
          class="ma-2"
        />
      </div>
    </div>

    <!-- Internal Server error -->
    <div v-else-if="showInternalServerError">
      <v-container class="ma-2">
          <h1 class="text-error">Errore interno al Server.</h1>
          <p class="text-error">Provare a ricaricare la pagina. Se l'errore dovesse persistere, contattare l'assistenza tecnica.</p>
      </v-container>
    </div>

    <!-- Unreachable Server error -->
    <div v-else-if="showUnreachableServerError">
      <v-container class="ma-2">
          <h1 class="text-error">Errore: Server irraggiungibile.</h1>
          <p class="text-error">Provare a ricaricare la pagina. Se l'errore dovesse persistere, contattare l'assistenza tecnica.</p>
      </v-container>
    </div>

    <!-- Dialog Confirm shown before the pratica deletion -->
    <c-dialog-confirm ref="dialogConfirm" />

    <!-- Dialog Print Pratiche List shown after print command execution -->
    <dialog-print-pratiche-list
      v-model="showPrintPraticheList"
      :print-pratiche-list="printPraticheList"
    />

    <!-- Dialog shown before AcceptPratiche command execution -->
    <dialog-accept
      v-model="showAcceptDialog"
      @confirm-accept="onConfirmAcceptEvent"
    />

    <!-- Dialog shown before RefusePratiche command execution -->
    <dialog-refuse
      v-model="showRefuseDialog"
      @confirm-refuse="onConfirmRefuseEvent"
    />
  </div>
</template>
<script>
// components
import HeaderBar from "components/header/HeaderBar";
import TableHome from "components/home/table/TableHome";
import MenuBar from "components/MenuBar";
import ActionBar from "components/ActionBar";

// services
import Startup from "services/commands/core/startup";
import CommandUtil from "services/commands/core/command_util";
// stores
import { AppConfigStore } from "services/commands/stores";
// handlers
import ActionHandler from "handlers";
// vuex
import { mapGetters } from 'vuex';
// mixins
import sharedCommands from "mixins/shared_commands";


export default {
  name: "Home",

  mixins: [sharedCommands],

  components: {
    HeaderBar,
    MenuBar,
    TableHome,
    ActionBar,
  },

  data() {
    const defaultActivity = AppConfigStore.getAttivitaDaFareId();

    return {
      // pratiche selected
      selection: null,

      // default activity selected id
      defaultActivity: defaultActivity,

      // activity selected id
      activitySelectedId: defaultActivity,

      // default ActionBar mode
      actionBarMode: 'view',

      showInternalServerError: false,
    }
  },

  computed: {
    ...mapGetters([
      'startup',
      'showOverlay',
    ]),

    show() {
      return this.startup;
    },

    // show unreachable server error if, after loading ended, startup is not completed
    showUnreachableServerError() {
      return !this.startup && !this.showOverlay;
    },

    // show chip pratiche only if "Attivita' da fare" activity is selected
    showChipPratiche() {
      return this.show && this.activitySelectedId == this.defaultActivity;
    },
  },
  
  watch: {
    // watch used to call 'LIST_PRATICA_INFO' command on startup completed
    startup: (newValue) => newValue && CommandUtil.listPraticaInfo(),
  },

  methods: {
    /**
     * Method invoked on Action Button click.
     * Events are handled by the ActionHandler.
     */
    onActionButtonClick(action) {
      ActionHandler.handleActionEvent(this, action);
    },

    onSelectionChange(selectedPraticaList) {
      this.selection = selectedPraticaList;
    },

    onActivitySelectedChange(activitySelectedId) {
      this.activitySelectedId = activitySelectedId;
    },

    async onRefreshButtonClicked(params) {
      await this._CMDListPraticaInfo(params);
    },

    // Exposed method used to pop confirm dialog value.
    pop(text) {
      return this.$refs.dialogConfirm.pop(text);
    },
  },

  async mounted() {
    try {
      await Startup.startup();
    } catch (err) {
      this.showInternalServerError = true;
    }
  }
};
</script>
