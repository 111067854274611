import list from 'data/list_attivita_da_fare.json';
var STORE;

/**
 * Initializes the sections and adds corresponding attivita sections to the STORE object.
 * 
 * @param {Object} sections - The sections object.
 * @param {string} [specification=""] - The specification string.
 */
function init(sections, specification = "") {
  STORE = _.cloneDeep(list);

  // section object example:
  // {
  //   "name": "Relazione",
  //   "description": "Relazione di Morte",
  //   "doc_name": "Relazione di Morte",
  //   "name.ospedale.954": "Dichiarazione",
  //   "description.ospedale.954": "Dichiarazione di Morte",
  //   "doc_name.ospedale.954": "Dichiarazione di Morte"
  // }
  // specification example value: "ospedale.954"

  // WARNING:
  // I can't do that in a bettter way (forEach) because section.name != name used in list_attivita_da_fare
  let description;
  const property = `description.${specification}` || 'description';

  description = sections['1']?.[property] ?? 'Anagrafica';
  _addAttivitaSection('Anagrafica', description, { complete: true, validate: true });

  description = sections['2']?.[property] ?? 'Comunicazione di decesso';
  _addAttivitaSection('ComunicazioneDecesso', description, { complete: true, validate: true, sign: true });

  description = sections['3']?.[property] ?? 'Istat (Parte A)';
  _addAttivitaSection('IstatA', description, { complete: true, validate: true, sign: true });

  description = sections['4']?.[property] ?? 'Relazione di Morte';
  _addAttivitaSection('RelazioneMorte', description, { complete: true, validate: true, sign: true });
  
  description = sections['5']?.[property] ?? 'Accertamento di Morte';
  _addAttivitaSection('AccertamentoMorte', description, { complete: true, validate: true, sign: true });

  description = sections['6']?.[property] ?? 'Modulo di Trasporto';
  _addAttivitaSection('ModuloTrasporto', description, { complete: true, validate: true, sign: true });

  description = sections['7']?.[property] ?? 'Istat (Parte B)';
  _addAttivitaSection('IstatB', description, { complete: true, validate: true });

  description = sections['8']?.[property] ?? 'Nulla Osta';
  _addAttivitaSection('NullaOsta', description, { complete: true, validate: true, sign: true });
}

function _addAttivitaSection(sectionKey, description, { complete = false, validate = false, sign = false }) {
  let value;

  if (complete) {
    value = `Completare${sectionKey}`;
    STORE.attivita[value] = { text: `Completare ${description}`, value };
  }
  if (validate) {
    value = `Validare${sectionKey}`;
    STORE.attivita[value] = { text: `Validare ${description}`, value };
  }
  if (sign) {
    value = `Firmare${sectionKey}`;
    STORE.attivita[`Firmare${sectionKey}`] = { text: `Firmare ${description}`, value };
  }
}

/**
 * Retrieves the attivita text by its value.
 * 
 * @param {string} value - The value of the attivita.
 * @returns {string|undefined} - The attivita text, or undefined if not found.
 */
function getAttivityByValue(value) {
  return STORE.attivita[value]?.text;
}

export default {
  init,
  getAttivityByValue
}