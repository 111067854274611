import Core from './core'

const COMMAND_NAME = 'Caronte.ListPraticaInfo'

function requestBody(params) {
    let body = {
        activities: {
            element: params.activities
        },
        modules: params.modules
    }

    if (params.numero_atto) {
        body.numero_atto = params.numero_atto;
    }

    if (params.data_inizio == null && params.data_fine == null) {
        return body
    }

    body.data_inizio = params.data_inizio.startOf('day').add(1, 'days').format();
    body.data_fine = params.data_fine.startOf('day').add(1, 'days').format();

    return body;
}

function promise(params) {
    return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
    Core.send(COMMAND_NAME, requestBody(params), {
        success: (response) => {
            _.attempt(options.success, response)
        },
        error: (response) => {
            _.attempt(options.error, response)
        },
        failure: (error) => {
            _.attempt(options.failure, error);
        }
    })
}

export default {
    promise,
    call
}