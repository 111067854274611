import { v4 as uuidv4 } from 'uuid'

import User from 'services/user'
import { AppConfigStore } from 'services/commands/stores'
import moment from 'moment'


function authParams() {
  if (User.getSessionId()) {
    return {
      "mittente": {
        "identificativo": User.getSessionId()
      }
    }
  } else {
    return {}
  }
}

function requestBody(command, body) {
  return _.merge(
    _.cloneDeep(AppConfigStore.getCommandRequestBody()),
    {
      id: uuidv4(),
      comando: command,
      timestamp: moment().format(),
      body: body
    },
    authParams()
  )
}

function commandType(command) {
  return AppConfigStore.getCommandType(command)
}

function url(command) {
  return AppConfigStore.getCommandTypeUrl(commandType(command))
}

function headers(command) {
  let headers = {
    'Content-Type': 'application/json',
    'X-Command': command
  }

  if (commandType(command) == 'local') {
    headers['X-RemoteUrl'] = AppStoreConfig.getCommandDefaultUrl()
  }

  return headers
}

export default function promise(command, body) {
  return axios.post(url(command), requestBody(command, body), {
    headers: headers(command)
  })
}