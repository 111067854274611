var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.startup
    ? _c(
        "div",
        { staticClass: "d-flex align-center", attrs: { id: "action-bar" } },
        [
          _vm._l(_vm.documentAction, function (action) {
            return _c(
              "div",
              {
                key: action.nome,
                on: {
                  click: function ($event) {
                    return _vm.fireAction(action)
                  },
                },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      title: _vm.getActionTitle(action),
                      color: "primary",
                      elevation: "3",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { dark: "", left: "" } }, [
                      _vm._v(_vm._s(_vm.getActionIcon(action))),
                    ]),
                    _vm._v(
                      "\n      " + _vm._s(_vm.getActionName(action)) + "\n    "
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-logo-intersail",
            attrs: { src: "images/logo_intersail.png", alt: "Logo intersail" },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }