<template>
  <!-- "Deceduto" section -->
  <v-form :disabled="true">

    <!-- [ cognome, nome ] -->
    <v-row no-gutters class="ma-2" v-if="showNomeCognome">

      <!-- "Cognome" deceduto -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.cognome"
          label="Cognome"
          prepend-icon="mdi-account-outline"
        />
      </v-col>

      <v-spacer />

      <!-- "Nome" deceduto -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.nome"
          label="Nome"
          prepend-icon="mdi-account-outline"
        />
      </v-col>
    </v-row>

    <!-- [ data_nascita ] -->
    <v-row no-gutters class="ma-2" v-if="showDataNascita">

      <!-- "Data di Nascita" deceduto -->
      <v-col cols="5">
        <c-date-picker
          :value="dataNascitaDecedutoISO"
          label="Data di Nascita"
        />
      </v-col>
    </v-row>

    <!-- [ comune_nascita, provincia_nascita ] -->
    <v-row no-gutters class="ma-2" v-if="showComuneProvinciaNascita">

      <!-- "Comune di Nascita" deceduto -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.comune_nascita_specifico"
          label="Comune di Nascita"
          prepend-icon="mdi-map-marker"
        />
      </v-col>

      <v-spacer />

      <!-- "Provincia di Nascita" deceduto -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.provincia_nascita_specifica"
          label="Provincia di Nascita"
          prepend-icon="mdi-map-marker"
        />
      </v-col>
    </v-row>

    <!-- [ cittadinanza_specifica ] -->
    <v-row no-gutters class="ma-2" v-if="showCittadinanza">

      <!-- "Cittadinanza" deceduto -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.cittadinanza_specifica"
          label="Cittadinanza"
          prepend-icon="mdi-flag"
        />
      </v-col>
    </v-row>

    <!-- [ via_residenza, civico_residenza ] -->
    <v-row no-gutters class="ma-2" v-if="showViaCivicoResidenza">

      <!-- "Via di Residenza" -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.via_residenza"
          label="Via di Residenza"
          prepend-icon="mdi-home-map-marker"
        />
      </v-col>

      <v-spacer />

      <!-- "Civico" di Residenza -->
      <v-col cols="5">
        <c-text-field
          :value="anagraficaDeceduto.civico_residenza"
          label="Civico"
          prepend-icon="mdi-home-map-marker"
        />
      </v-col>
    </v-row>

    <!-- [ comune_residenza, provincia_residenza ] -->
    <v-row no-gutters class="ma-2" v-if="showComuneProvinciaResidenza">

      <!-- "Comune di Residenza" deceduto -->
      <v-col cols="5">
        <c-combobox
          :value="anagraficaDeceduto.comune_residenza_specifico"
          label="Comune di Residenza"
          prepend-icon="mdi-map-marker"
        />
      </v-col>

      <v-spacer />

      <!-- "Provincia di Residenza" deceduto -->
      <v-col cols="5">
        <c-combobox
          :value="anagraficaDeceduto.provincia_residenza_specifica"
          label="Provincia di Residenza"
          prepend-icon="mdi-map-marker"
        />
      </v-col>
    </v-row>

    <!-- [ via_domicilio, civico_domicilio ] -->
    <v-row no-gutters class="ma-2" v-if="showViaCivicoDomicilio">

      <!-- "Via domicilio" -->
      <v-col cols="5">
        <c-text-field
          label="Via di Domicilio"
          :value="domicilio.via"
          prepend-icon="mdi-home-account"
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Civico" domicilio -->
      <v-col cols="5">
        <c-text-field
          label="Civico"
          :value="domicilio.civico"
          prepend-icon="mdi-home-account"
        />
      </v-col>
    </v-row>

    <!-- [ comune_domicilio, provincia_domicilio ] -->
    <v-row no-gutters class="ma-2" v-if="showComuneProvinciaDomicilio">

      <!-- "Comune" domicilio -->
      <v-col cols="5">
        <c-text-field
          :value="domicilio.comune"
          label="Comune di Domicilio"
          prepend-icon="mdi-map-marker"
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Provincia" domicilio -->
      <v-col cols="5">
        <c-text-field
          :value="domicilio.provincia"
          label="Provincia di Domicilio"
          prepend-icon="mdi-map-marker"
        />
      </v-col>
    </v-row>

    <!-- [ data_morte, orario_morte ] -->
    <v-row no-gutters class="ma-2" v-if="showDataOrarioMorte">

      <!-- "Data di Morte" -->
      <v-col cols="5">
        <c-date-picker
          :value="dataMorteDecedutoISO"
          label="Data di Morte"
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Orario di Morte" -->
      <v-col cols="5">
        <c-time-picker
          :value="oraMorteFormatted"
          label="Orario di Morte"
        />
      </v-col>
    </v-row>
    
    <!-- [ anni_deceduto, mesi_deceduto, giorni_deceduto, ore_deceduto ] -->
    <v-row no-gutters class="ma-2" v-if="showAnniMesiGiorniOre">

      <!-- "Anni" deceduto -->
      <v-col cols="3">
        <c-text-field
          label="Anni"
          :value="deathAge.years"
          prepend-icon="mdi-coffin"
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Mesi" deceduto -->
      <v-col cols="3">
        <c-text-field
          label="Mesi"
          :value="deathAge.months"
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Giorni" deceduto -->
      <v-col cols="3">
        <c-text-field
          label="Giorni"
          :value="deathAge.days"  
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Ore" deceduto -->
      <v-col cols="2">
        <c-text-field
          label="Ore"
          :value="deathAge.hours"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DateUtils from 'services/date_util';


export default {
  name: 'SectionDeceduto',

  props: {
    anagraficaDeceduto: {
      type: Object,
      required: true,
    },

    comunicazioneDecesso: {
      type: Object,
      required: false,
      default: () => {},
    },

    showNomeCognome: {
      type: Boolean,
      required: false,
      default: true,
    },

    showDataNascita: {
      type: Boolean,
      required: false,
      default: true,
    },

    showComuneProvinciaNascita: {
      type: Boolean,
      required: false,
      default: true,
    },

    showCittadinanza: {
      type: Boolean,
      required: false,
      default: true,
    },

    showViaCivicoResidenza: {
      type: Boolean,
      required: false,
      default: true,
    },

    showComuneProvinciaResidenza: {
      type: Boolean,
      required: false,
      default: true,
    },

    showViaCivicoDomicilio: {
      type: Boolean,
      required: false,
      default: true,
    },

    showComuneProvinciaDomicilio: {
      type: Boolean,
      required: false,
      default: true,
    },

    showDataOrarioMorte: {
      type: Boolean,
      required: false,
      default: true,
    },

    showAnniMesiGiorniOre: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    // "Data di Morte" in ISO format used in the c-date-picker.
    dataMorteDecedutoISO() {
      return DateUtils.formatDateAsIso(this.comunicazioneDecesso.dt_morte);
    },

    // "Data di Nascita" in ISO format used in the c-date-picker.
    dataNascitaDecedutoISO() {
      return DateUtils.formatDateAsIso(this.anagraficaDeceduto.dt_nascita);
    },

    /**
     * "Anni", "Mesi", "Giorni" and "Ore" fields of the form.
     * 
     * deathAge: {
     *   years:   dt_morte.years - dt_nascita.years,
     *   months:  dt_morte.months - dt_nascita.months,
     *   days:    dt_morte.days - dt_nascita.days,
     *   hours:   dt_morte.hours - dt_nascita.hours
     * }
     */
    deathAge() {
      let dt_morte = moment(this.comunicazioneDecesso.dt_morte);
      let dt_nascita = moment(this.anagraficaDeceduto.dt_nascita);
      
      let years = DateUtils.dateDiff(dt_morte, dt_nascita, 'years');
      let months = DateUtils.dateDiff(dt_morte = dt_morte.subtract(years, 'years'), dt_nascita, 'months');
      let days = DateUtils.dateDiff(dt_morte = dt_morte.subtract(months, 'months'), dt_nascita, 'days');
      // hour of dt_nascita is 00:00 by default
      let hours = dt_morte.hours();
      
      return {
        years: years,
        months: months,
        days: days,
        hours: hours,
      };
    },

    /**
     * "Via Domicilio", "civico", "comune" and "provincia" fields of the form.
     * 
     * domicilio: {
     *   via:         via_domicilio ?? via_residenza,
     *   civico:      civico_domicilio ?? civico_residenza,
     *   comune:      comune_domicilio ?? comune_residenza,
     *   provincia:   provincia_domicilio ?? provincia_residenza  
     * }
     */
    domicilio() {
      let domicilio = {};
      let uguali_domicilio_residenza = this.anagraficaDeceduto.uguali_domicilio_residenza;

      // via domicilio
      domicilio.via = uguali_domicilio_residenza
        ? this.anagraficaDeceduto.via_residenza
        : this.anagraficaDeceduto.via_domicilio;

      // civico domicilio
      domicilio.civico = uguali_domicilio_residenza
        ? this.anagraficaDeceduto.civico_residenza
        : this.anagraficaDeceduto.civico_domicilio;

      // comune domicilio
      domicilio.comune = uguali_domicilio_residenza
        ? this.anagraficaDeceduto.comune_residenza_specifico
        : this.anagraficaDeceduto.comune_domicilio_specifico;
      
      // provincia domicilio
      domicilio.provincia = uguali_domicilio_residenza
        ? this.anagraficaDeceduto.provincia_residenza_specifica
        : this.anagraficaDeceduto.provincia_domicilio_specifica;
      
      return domicilio;
    },

    // "Ora di Morte" formatted (hh:mm); used in the c-date-picker.
    oraMorteFormatted() {
      return DateUtils.formatTime(this.comunicazioneDecesso.dt_morte);
    },
  }
}
</script>