var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "document-list" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-card",
                    _vm._g(
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "download-all mb-2 pa-2",
                        attrs: {
                          href: _vm.onDownloadAllClick(),
                          attrs: attrs,
                          align: "center",
                          flat: "",
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "white", size: "35px" } },
                        [
                          _vm._v(
                            "\n            mdi-download-multiple\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v("Scarica tutti i documenti")])]
      ),
      _vm._v(" "),
      _vm._l(_vm.documents, function (doc) {
        return _c(
          "div",
          { key: doc.identifier },
          [
            _c(
              "v-card",
              {
                staticClass: "pt-2 mb-2",
                attrs: { align: "center", outlined: "" },
              },
              [
                _c(
                  "v-card",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: { flat: "" },
                    on: {
                      click: function ($event) {
                        return _vm.onDocumentClick({ data: doc })
                      },
                    },
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { left: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          attrs: attrs,
                                          color: _vm.getIcon(doc.stato).color,
                                          size: "30px",
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getIcon(doc.stato).mdi) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.fixDocumentState(doc.stato)) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-card-text", { staticClass: "text-subtitle-2" }, [
                      _vm._v("\n          " + _vm._s(doc.name) + "\n        "),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider", { staticClass: "mx-1" }),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      [
                        _vm.canDownloadPDFDirectly()
                          ? _c(
                              "v-col",
                              { attrs: { "align-self": "start" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        href: _vm.onPDFDownload(
                                                          _vm.documentFixed(doc)
                                                        ),
                                                        icon: "",
                                                        tile: "",
                                                        download: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "20px" } },
                                                    [_vm._v("mdi-download")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "28px" } },
                                                    [_vm._v("mdi-file-pdf-box")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Scarica pdf")]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.canDownloadP7MDirectly(doc)
                          ? _c(
                              "v-col",
                              { attrs: { "align-self": "end" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        href: _vm.onP7MDownload(
                                                          doc
                                                        ),
                                                        icon: "",
                                                        tile: "",
                                                        download: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "20px" } },
                                                    [_vm._v("mdi-download")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "26px" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-file-certificate-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Scarica p7m")]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }