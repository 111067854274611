<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card id="dialog-confirm">
      <!-- Title -->
      <v-card-title>{{ title }}</v-card-title>

      <v-divider /><br>

      <!-- Text -->
      <v-card-text>
        <p class="text">{{ text }}</p>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        
        <!-- "No" Button -->
        <v-btn
          @click="onCancelClick()"
          class="btn-confirm"
          outlined
          text
        >
          NO
        </v-btn>

        <!-- "Yes" Button -->
        <v-btn
          @click="onYesClick()"
          class="btn-confirm"
          outlined
          text
        >
          SI
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CDialogConfirm",

  props: {
    // value: true to make dialog visible
    //        false otherwise
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    
    title: {
      type: String,
      required: false,
      default: 'Attenzione',
    },
  },

  data() {
    return {
      dialog: false,
      resolve: null,
      text: '',
    };
  },

  watch: {
    // watch on prop 'value'
    value: {
      immediate: true,
      handler(_) {
        this.dialog = this.value;
      },
    },
  },

  methods: {
    // method invoked on 'NO' button click
    onCancelClick() {
      this.dialog = false;
      this.resolve(false);
    },

    // method invoked on 'SI' button click
    onYesClick() {
      this.dialog = false;
      this.resolve(true);
    },

    // method used to return the choise of the user
    // choice = 'SI' ? 'true' : 'false'
    pop(text) {
      this.text = text;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
  },
};
</script>
