var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex",
      class: _vm.categoryStyle,
      style: _vm.disabled ? _vm.disabledStyle : {},
      attrs: { id: "app-container" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex", attrs: { id: "app-content-container" } },
        [
          _c("Uncategorized", {
            attrs: {
              pages: _vm.groupedPages[null],
              categoriesMode: _vm.categoriesMode,
              splitUrl: _vm.splitUrl,
              previewUrl: _vm.previewUrl,
            },
            on: {
              insertNewPages: _vm.insertNewPages,
              deletePage: _vm.deletePage,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "categories-panel" } },
            _vm._l(_vm.categories, function (category) {
              return _c("Category", {
                key: category.id,
                ref: category.id,
                refInFor: true,
                attrs: {
                  categoriesMode: _vm.categoriesMode,
                  category: category,
                  pages: _vm.groupedPages[category.id],
                  previewUrl: _vm.previewUrl,
                },
                on: {
                  insertNewPages: _vm.insertNewPages,
                  removeFromCategory: _vm.removeFromCategory,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }