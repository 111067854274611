let LIST_ACTIVITIES = {}

function init(jsonListMeta) {
    LIST_ACTIVITIES = {}
    
    _.forEach(jsonListMeta.activities.element, activity => {
        LIST_ACTIVITIES[activity.id] = _.reduce(activity, function(result, value, key) {
            if (key != 'id') {
                result[key] = value
            }

            return result;
        }, {});
    })
}

export default {
    init
}