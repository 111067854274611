// algotithm based on constraints from:
// https://www.agenziaentrate.gov.it/portale/web/guest/schede/istanze/richiesta-ts_cf/informazioni-codificazione-pf
const EVEN_MAP = {
  0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9,
  A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6, H: 7, I: 8, J: 9,
  K: 10, L: 11, M: 12, N: 13, O: 14, P: 15, Q: 16, R: 17, S: 18,
  T: 19, U: 20, V: 21, W: 22, X: 23, Y: 24, Z: 25
};

const ODD_MAP = {
  0: 1, 1: 0, 2: 5, 3: 7, 4: 9, 5: 13, 6: 15, 7: 17, 8: 19, 9: 21,
  A: 1, B: 0, C: 5, D: 7, E: 9, F: 13, G: 15, H: 17, I: 19, J: 21,
  K: 2, L: 4, M: 18, N: 20, O: 11, P: 3, Q: 6, R: 8, S: 12, T: 14,
  U: 16, V: 10, W: 22, X: 25, Y: 24, Z: 23
};


/**
 * Validates an Italian fiscal code based on the control character.
 * 
 * @param {string} codiceFiscale - The fiscal code to validate.
 * @returns {boolean} - Indicates whether the fiscal code is valid or not.
 */
function validateCodiceFiscale(cod) {
  cod = cod.toUpperCase();

  // check on right length
  if (cod?.length != 16) {
    return false;
  }

  // check on valid characters
  const validChars = /^[A-Z0-9]+$/;
  if (!validChars.test(cod)) {
    return false;
  }

  // check on control character
  let sum = 0;

  for (var i = 0; i < 15; i++) {
    const char = cod[i];
    const value = (i+1) % 2 === 0 ? EVEN_MAP[char] : ODD_MAP[char];
    sum += value;
  }

  var controlChar = String.fromCharCode(65 + (sum % 26));

  // Verifica se il carattere di controllo corrisponde
  if (cod[15] !== controlChar) {
    return false;
  }

  return true;
}

export default {
  validateCodiceFiscale,
}