import router from "../router"

const route = 'Details';

async function actionPerformed(context, action) {
  let options = {
    enter_edit_mode: action.decoration.ENTER_EDIT_MODE,
    action_id: action.id
  }
  if (context.$route.name != route) {
    router.push({
      name: route, params: {
        command: 'actionPratiche',
        options: options
      }
    });
  } else {
    return await context.actionPratiche(options);
  }
}

export default {
  actionPerformed,
}