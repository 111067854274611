<template>
  <!-- v-date-picker handler -->
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <!-- container of formatted date -->
    <template v-slot:activator="{ on, attrs }">
      <c-text-field
        v-model="dateFormatted"
        :label="label"
        :rules="rules"
        placeholder="GG/MM/AAAA"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        v-mask="'##/##/####'"
      />
    </template>

    <!-- date picker -->
    <v-date-picker
      v-model="dateISO"
      :min="minDate"
      :max="maxDate"
      no-title
      @input="showDatePicker = false"
    />
  </v-menu>
</template>

<script>
import DateUtils from "services/date_util";
import { VueMaskDirective } from "v-mask";

export default {
  name: 'CDatePicker',

  directives: {
    mask: VueMaskDirective,
  },

  // value:         date in moment format
  // label:         label of the v-text-field
  // rules:         rules of the v-text-field
  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: "Date",
    },

    rules: {
      required: false,
    },

    minimumDate: {
      type: String,
      required: false,
      default: moment().subtract(150, 'years').format(),
    }
  },

  data() {
    return {
      // min date option available in date picker
      minDate: this.minimumDate,

      // max date option available in date picker
      maxDate: moment().format(),

      // used from 'v-menu' to display v-date-picker when needed
      showDatePicker: false,

      // format: DD/MM/YYYY
      dateFormatted: "",

      // format: YYYY-MM-DD
      dateISO: null,
    };
  },

  watch: {
    // watch on prop 'value'
    value: {
      immediate: true,
      handler(_) {
        // if (!DateUtils.isEmptyDate(this.value) && DateUtils.dateDiff(this.value, minDate) > 0 && DateUtils.dateDiff(this.value, maxDate) < 0) {
        if (!DateUtils.isEmptyDate(this.value)) {
          this.dateFormatted = DateUtils.formatDate(this.value);
          this.dateISO = this.value;
        }
      }
    },

    // watch on dateFormatted: dateISO updated in case of valid date
    dateFormatted() {
      let date = moment(this.dateFormatted, 'DD/MM/YYYY', true);

      if (date.isValid()) {
        if (!moment(date).isSame(moment(this.dateISO)))
          this.dateISO = date.format('YYYY-MM-DD');
        else
          this.$emit('input', this.dateISO);
      } else {
        this.$emit('input', moment(DateUtils.EMPTY_DATE).format('YYYY-MM-DD'));
      }

    },
    
    // watch on dateISO: event emitted in case of change
    dateISO() {
      if (!moment(this.value).isSame(moment(this.dateISO))) {

        // validating date with min and max values
        if (DateUtils.dateDiff(this.dateISO, this.minDate) < 0 || DateUtils.dateDiff(this.dateISO, this.maxDate) > 0) {
          this.$emit(moment(DateUtils.EMPTY_DATE).format('YYYY-MM-DD'));
        } else {
          this.$emit('input', this.dateISO);
        }
      }
    },
  }
};
</script>