import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from 'views/Home.vue'

import User from 'services/user'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('views/Login.vue')
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('views/Details.vue'),
    props: true
  },
]

const router = new VueRouter({
  routes
})

/**
 * Checks if the previous route was not 'Login' or the user is not logged:
 * in that case the next route will be 'Login';
 * otherwise invokes 'next()'.
 */
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !User.getSessionId() ) {
    next({ name: 'Login' })
  } else if (from.name !== 'Home' && to.name == 'Details') {
    next({ name: 'Home' })
  } else {
    next()
  }
})

// DEPRECATED
// router.replace("/home").catch(error => {})

export default router
