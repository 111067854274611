<template>
  <v-container class="form">
    <!-- 'Description' and 'Add condition' section -->
    <div class="d-flex align-center justify-space-between">
      <h4> {{ description }} </h4>
      <v-btn
        v-if="!disabled"
        text icon color="primary dark"
        :disabled="isPreesistente ? conditionsNumber >= maxPre : conditionsNumber >= maxNotPre"
        @click="addNewCondition()"
      >
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>

    <!-- Transition group used to perform swap animation -->
    <transition-group v-if="(conditionsNumber > 0)" name="flip-list" tag="v-col">
      <!-- List of conditions -->
      <v-row
        no-gutters class="ma-2"
        v-for="(condizione, index) in conditionsFiltered"
        :key="condizione.value"
      >
        <!-- 'Condizione' -->
        <v-col cols="5">
          <c-text-field
            v-model="condizione.malattia"
            :rules="rules"
            label="Condizione"
          />
        </v-col>
        
        <v-spacer></v-spacer>

        <!-- 'Anni' -->
        <v-col cols="1">
          <c-text-field
            v-model="condizione.anni_durata"
            label="Anni"
            type="number"
            hide-spin-buttons
          />
        </v-col>
        
        <v-spacer></v-spacer>
        
        <!-- 'Mesi' -->
        <v-col cols="1">
          <c-text-field
            v-model="condizione.mesi_durata"
            label="Mesi"
            type="number"
            hide-spin-buttons
          />
        </v-col>

        <v-spacer></v-spacer>

        <!-- 'Giorni' -->
        <v-col cols="1">
          <c-text-field
            v-model="condizione.giorni_durata"
            label="Giorni"
            type="number"
            variant="solo"
            hide-spin-buttons
          />
        </v-col>

        <!-- Arrows and Delete buttons -->
        <v-col v-if="!disabled" cols="2" style="display: flex; justify-content: center; align-items: center" >

          <v-row>
            <v-col cols="3"/>

            <!-- Arrow down -->
            <v-col cols="2">
              <v-btn
                text icon color="primary"
                :disabled="index + 1 == conditionsNumber"
                @click="swapConditions(index, index + 1)"
              >
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
            </v-col>

            <!-- Arrow up -->
            <v-col cols="2">
              <v-btn
                text icon color="primary"
                :disabled="index == 0 || conditionsNumber <= 1"
                @click="swapConditions(index, index - 1)"
              >
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
            </v-col>

            <!-- Delete -->
            <v-col cols="2">
              <v-btn
                text icon color="red lighten-2"
                :disabled="(isPreesistente && conditionsNumber <= minPre) || (!isPreesistente && conditionsNumber <= minNotPre)"
                @click="removeCondition(condizione.value)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="3" />
          </v-row>
        </v-col>
        <v-spacer v-else></v-spacer>
      </v-row>
    </transition-group>

    <p v-else class="p-no-conditions">Non sono state specificate Condizioni Morbose.</p>
  </v-container>
</template>

<script>
import PraticaUtil from 'services/pratica_util';

export default {
  name: 'CConditionList',

  // value:           conditions
  // description:     description of the condition list
  // isPreesistente:  true if pre-conditions
  //                  false otherwise
  props: {
    value: {
      type: Array,
      required: true,
    },

    description: {
      type: String,
      required: false,
      default: "",
    },

    isPreesistente: {
      type: Boolean,
      required: true,
    },

    rules: {
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    // Minimum number of conditions 'non preesistenti'
    minNotPre: {
      type: Number,
      required: false,
      default: 0,
    },

    // Maximum number of conditions 'non preesistenti'
    maxNotPre: {
      type: Number,
      required: true,
    },

    // Minimum number of conditions 'preesistenti'
    minPre: {
      type: Number,
      required: false,
      default: 0,
    },

    // Maximum number of conditions 'preesistenti'
    maxPre: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      // Condition's object structure:
      // {
      //    giorni_durata: int,
      //    mesi_durata: int
      //    anni_durata: int,
      //    is_preesistente: bool,
      //    malattia: String,
      //    ordine: int,
      //    value: int,
      // }
      conditions: null,
    };
  },

  computed: {
    // List of conditions 
    // isPreesistente = true   => 'preesistenti'
    // isPreesistente = false  => 'non preesistenti'
    conditionsFiltered() {
      return this.conditions.filter((c) => this.isPreesistente == c.is_preesistente);
    },

    // Lenght of filtered conditions list
    conditionsNumber() {
      return this.conditionsFiltered.length;
    },

    // Maximum 'ordine' of the conditions list
    orderMax() {
      return Math.max(...this.conditions.map(c => c.ordine));
    },

    // Maximum 'value' of the conditions list
    valueMax() {
      return Math.max(...this.conditions.map(c => Math.abs(c.value)));
    }
  },

  watch: {
    // watch on prop 'value'
    value: {
      immediate: true,
      handler(_) {
        this.conditions = this.value;
      },
    },
  },

  methods: {
    /**
     * Method used to swap conditions.
     * It emits an event with updated conditions list.
     */
    swapConditions(index1, index2) {
      if (this.isPreesistente) {
        index1 += this.conditions.length - this.conditionsNumber
        index2 += this.conditions.length - this.conditionsNumber
      }
      let arr = _.cloneDeep(this.conditions);

      // swapping 'condizioni' and 'ordine'
      [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
      [arr[index1].ordine, arr[index2].ordine] = [arr[index2].ordine, arr[index1].ordine];

      this.$emit("input", arr);
    },

    /**
     * Method used to add a new condition.
     * It emits an event with updated conditions list.
     */
    addNewCondition() {
      if (this.conditionsNumber < (this.isPreesistente ? this.maxPre : this.maxNotPre)) {
        let arr = _.cloneDeep(this.conditions);
        // adding a new empty conditions
        arr.splice(
          this.isPreesistente ? this.conditions.length : this.conditionsNumber, 0,
          PraticaUtil.emptyCondizioneMorbosa({
            is_preesistente: this.isPreesistente,
            ordine: this.orderMax + 1,
            value: -(this.valueMax + 1),
          })
        );

        this.$emit("input", arr);
      }
    },
    
    /**
     * Method used to remove a condition.
     * It emits an event with updated conditions list.
     */
    removeCondition(value) {
      let arr = this.conditions.filter(
        (c) => c.value != value
      );

      this.$emit("input", arr);
    },
  }
};
</script>
<style scoped>
.flip-list-move {
  transition: transform 0.3s;
}

.p-no-conditions {
  color: #00000061;
}
</style>