import md5 from 'crypto-js/md5'
import Core from './core'

const COMMAND_NAME = 'Auth.ChangePassword'

function hash(value) {
  return md5(value).toString().toUpperCase();
}

function requestBody(params) {
  return {
    username: params.username,
    old_password: hash(params.old_password),
    new_password: hash(params.new_password),
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params));
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response);
    },
    error: (response) => {
      _.attempt(options.error, response);
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  });
}

export default {
  promise,
  call,
}