<template>
  <div id="document-list">
    
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card
            :href="onDownloadAllClick()"
            :attrs="attrs"
            v-on="on"
            class="download-all mb-2 pa-2"
            align="center"
            v-ripple
            flat
          >
            
            <!-- Header icon -->
            <v-icon
              color="white"
              size="35px"
            >
              mdi-download-multiple
            </v-icon>
          </v-card>
        </template>

        <span>Scarica tutti i documenti</span>
      </v-tooltip>

    <!-- List of documents card -->
    <div v-for="doc in documents" :key="doc.identifier">
      
      <!-- Document Card -->
      <v-card class="pt-2 mb-2" align="center" outlined>
        
        <!-- Clickable document -->
        <v-card
          @click="onDocumentClick({ data: doc })"
          v-ripple
          flat
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              
              <!-- Header icon -->
              <v-icon
                :attrs="attrs"
                :color="getIcon(doc.stato).color"
                v-on="on"
                size="30px"
              >
                {{ getIcon(doc.stato).mdi }}
              </v-icon>
            </template>

            <!-- Tooltip about document state -->
            {{ fixDocumentState(doc.stato) }}
          </v-tooltip>

          <!-- Document name -->
          <v-card-text class="text-subtitle-2">
            {{ doc.name }}
          </v-card-text>
        </v-card>

        <v-divider class="mx-1" />

        <!-- Download buttons -->
        <v-card-actions>
          <v-row>

            <!-- Implement it with a computed -->
            <v-col v-if="canDownloadPDFDirectly()" align-self="start">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">

                  <!-- Download .pdf button -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :href="onPDFDownload(documentFixed(doc))"
                    icon
                    tile
                    download
                  >

                    <!-- Icons -->
                    <v-icon size="20px">mdi-download</v-icon>
                    <v-icon size="28px">mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>

                <span>Scarica pdf</span>
              </v-tooltip>
            </v-col>

            <!-- Implement it with a computed -->
            <v-col v-if="canDownloadP7MDirectly(doc)" align-self="end">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">

                  <!-- Download .p7m button -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :href="onP7MDownload(doc)"
                    icon
                    tile
                    download
                  >

                    <!-- Icons -->
                    <v-icon size="20px">mdi-download</v-icon>
                    <v-icon size="26px">mdi-file-certificate-outline</v-icon>
                  </v-btn>
                </template>

                <span>Scarica p7m</span>
              </v-tooltip>
            </v-col>
            
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import UrlUtil from 'services/url_util';
import AppConfigStore from "services/commands/stores/app_config_store";
import { ICONS } from 'services/icon_util';

export default {
  name: "DocumentList",

  props: {
    // array containing list of documents to be shown
    documentList: {
      type: Array,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    surname: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      // filter on documents to be shown
      documents: _.filter(this.documentList, doc => doc.stato != 'Annullato'),
    }
  },

  methods: {
    /**
     * It returns the icon associated to 'document state' param
     * @param {String} state
     *        'Firmato'             file grey sign icon
     *        'FirmatoIncoerente'   file orange sign icon
     *        'Generato'            file generated icon
     *        [ Others ]            file alert icon
     */
    getIcon(state) {
      let icon;

      switch (state) {
        case 'Firmato':
          icon = ICONS.ICO_SIGN;
          break;
        case 'Incoerente':
        case 'FirmatoIncoerente':
          icon = ICONS.ICO_SIGN_ERROR;
          break;
        case 'Generato':
          icon = ICONS.ICO_GENERATED;
          break;
        default:
          icon = ICONS.ICO_WARNING;
      }

      return icon;
    },

    canDownloadPDFDirectly() {
      return AppConfigStore.canDownloadPDFDirectly();
    },
    
    canDownloadP7MDirectly(doc) {
      return AppConfigStore.canDownloadP7MDirectly() && doc.type == '.pdf.p7m';
    },

    fixDocumentState(state) {
      return state == 'FirmatoIncoerente' ? 'Firmato Incoerente' : state;
    },

    // It notifies parent component to display selected document
    onDocumentClick(event) {
      this.$emit('document-selected', event.data);
    },

    // if the document type is 'ModuleIstatB', identifier of istatA is required
    documentFixed(document) {
      if (document?.tipo_documento == 'ModuloIstatB') {
        const docIstatA = _.find(this.documentList, doc => doc.tipo_documento == 'ModuloIstatA');
        return {
          ...document,
          'istat_a_identifier': docIstatA?.identifier
        };
      }
      return document;
    },

    onPDFDownload(document) {
      if (document.tipo_documento == 'ModuloIstatB') {
        return UrlUtil.getPDFdownloadUrlIstatB(document);
      } else {
        return UrlUtil.getPDFdownloadUrl(document);
      }
    },

    onDownloadAllClick() {
      return UrlUtil.getDownloadPDFZipUrl({
        name: this.name,
        surname: this.surname,
        doc_ids: _.map(this.documents, doc => doc.identifier),
        doc_names: _.map(this.documents, doc => doc.name)
      });
    },

    onP7MDownload(document) {
      return UrlUtil.getP7MdownloadUrl(document);
    },
  },
};
</script>