<template>
  <v-dialog
    v-model="value"
    max-width="600"
    persistent
  >
    <v-card id="dialog-accept">
      <!-- Title -->
      <v-card-title>Accetta pratica</v-card-title>

      <v-divider />
      
      <!-- Body -->
      <v-card-text class="mt-2">
        <p class="text">Per completare l'accettazione, assegna alla pratica l'anno ed il numero:</p>
      </v-card-text>

      <!-- 'Anno' and 'Numero' -->
      <v-row no-gutters class="mx-5">
        <v-col cols="5">
          <c-text-field
            v-model="year"
            label="Anno"
            type="number"
            hide-spin-buttons
            prepend-icon="mdi-archive-clock"
          />
        </v-col>

        <v-spacer />

        <v-col cols="6">
          <c-text-field
            v-model="number"
            label="Numero"
            type="number"
            hide-spin-buttons
            prepend-icon="mdi-clipboard-list-outline"
          />
        </v-col>
      </v-row>

      <!-- Actions -->
      <v-card-actions class="mt-10">
        <v-spacer />

        <!-- 'Annulla' action -->
        <v-btn
          @click="onCancelClick"
          class="btn-action"
          outlined
        >
          ANNULLA
        </v-btn>

        <!-- 'OK' action -->
        <v-btn
          @click="onConfirmClick"
          :disabled="!ready"
          :class="ready && 'btn-action'"
          outlined
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'DialogAccept',

  props: {
    // value:     true to make dialog visible
    //            false otherwise
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      year: null,
      number: null,
    };
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.year = `${moment().year()}`;
      }
    }
  },

  computed: {
    ready() {
      return this.number && this.year && this.year.length === 4;
    }
  },
  
  methods: {
    closeDialog() {
      // restoring default values
      this.year = null;
      this.number = null;

      // closing the dialog
      this.$emit('input', false);
    },

    onCancelClick() {
      this.closeDialog();
    },

    onConfirmClick() {
      this.$emit('confirm-accept', Number(this.year), Number(this.number));
      this.closeDialog();
    },
  },
}
</script>