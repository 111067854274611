var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        persistent: "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "document-viewer" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "toolbar", attrs: { dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: { click: _vm.onCloseDialog },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _vm._v(" "),
              _c("v-toolbar-title", [
                _vm._v(_vm._s(_vm.document ? _vm.document.name : "")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-overlay",
            { attrs: { value: _vm.showSkeletonLoader } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showSkeletonLoader,
                  expression: "!showSkeletonLoader",
                },
              ],
              key: _vm.iframeState,
              class: _vm.showDocumentToolbar
                ? "iframe-container"
                : "iframe-container-hidden-toolbar",
            },
            [
              _c("iframe", {
                attrs: { src: _vm.url, height: "auto", width: "100%" },
                on: { load: _vm.hideSkeletonLoader },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }