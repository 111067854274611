<template>
  <div id="form-accertamento">
    <c-expansion-sections
      :disabled="disabled"
      :sections="[
        'MEDICO',
        'RESIDENZA MEDICO',
        'DECEDUTO'
      ]"
    >
      <!-- "MEDICO" section -->
      <template #section1>
        <!-- [ cognome, nome ]-->
        <v-row no-gutters class="ma-2">

          <!-- "Cognome" medico -->
          <v-col cols="5">
            <c-text-field
              v-model="am.cognome_medico"
              :rules="requiredRule"
              label="Cognome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>

          <v-spacer />

          <!-- "Nome" medico -->
          <v-col cols="5">
            <c-text-field
              v-model="am.nome_medico"
              :rules="requiredRule"
              label="Nome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>
        </v-row>

        <!-- [ data_nascita ]-->
        <v-row no-gutters class="ma-2">

          <!-- "Data di Nacita" medico -->
          <v-col cols="5">
            <c-date-picker
              v-model="dataNascitaMedicoISO"
              label="Data di Nascita"
            />
          </v-col>
        </v-row>

          <!-- [ comune, provincia ] -->
        <v-row no-gutters class="ma-2">

          <!-- "Comune di Nascita" medico -->
          <v-col cols="5">
            <c-combobox
              v-model="comuneNascitaMedico"
              :items="listPaesi"
              label="Comune di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />

          <!-- "Provincia di Nascita" medico -->
          <v-col cols="5">
            <c-combobox
              v-model="provinciaNascitaMedico"
              :items="listProvince"
              label="Provincia di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>
      </template>

      <!-- "RESIDENZA MEDICO" section -->
      <template #section2>
        <!-- [ via, civico, comune ]-->
        <v-row no-gutters class="ma-2">
        
          <!-- "Via" residenza medico -->
          <v-col cols="3">
            <c-text-field
              v-model="am.via_residenza_medico"
              label="Via"
              prepend-icon="mdi-home-map-marker"
            />
          </v-col>

          <v-col cols="1"></v-col>

          <!-- "Civico" residenza medico -->
          <v-col cols="1">
            <c-text-field
              v-model="am.civico_residenza_medico"
              label="Civico"
              type="number"
              hide-spin-buttons
            />
          </v-col>

          <v-spacer />

          <!-- "Comune" residenza medico -->
          <v-col cols="5">
            <c-combobox
              v-model="comuneResidenzaMedico"
              :items="listPaesi"
              label="Comune"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>

        <!-- [ provincia, telefono ] -->
        <v-row no-gutters class="ma-2">

          <!-- "Provincia" residenza medico -->
          <v-col cols="5">
            <c-combobox
              v-model="provinciaResidenzaMedico"
              :items="listProvince"
              label="Provincia"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />

          <!-- "Telefono" medico -->
          <v-col cols="5">
            <c-text-field
              v-model="am.telefono_residenza_medico"
              label="Telefono"
              prepend-icon="mdi-phone"
              type="number"
              hide-spin-buttons
            />
          </v-col>
        </v-row>
      </template>

      <!-- "DECEDUTO" section -->
      <template #section3>
        <SectionDeceduto
          :anagrafica-deceduto="pratica.anagrafica_deceduto"
          :show-cittadinanza="false"
          :show-via-civico-domicilio="false"
          :show-comune-provincia-domicilio="false"
          :show-data-orario-morte="false"
          :show-anni-mesi-giorni-ore="false"
        />
      </template>
    </c-expansion-sections>


    <!-- "Pace-maker" section -->
    <v-container class="ma-2">
      <!-- "Cadavere portatore di pace-maker" switch -->
      <div class="pace-maker-col pl-0 pb-1">
        Cadavere portatore di pace-maker:

        <c-switch
          v-model="paceMaker"
          :label="paceMaker ? 'SI' : 'NO'"
        />
      </div>

      <v-divider />

      <!-- "Note pace-maker" textarea -->
      <v-form :disabled="disabled || !paceMaker">
        <c-textarea
          v-model="paceMakerNota"
          :prepend-icon="iconPaceMaker"
          label="Note pace-maker"
          class="ma-2"
        />
      </v-form>
    </v-container>

    <!-- "Misure precauzionali" section -->
    <v-container class="ma-2">
      <v-divider />

      <!-- "Misure precauzionali igienico-sanitarie" -->
      <c-textarea
        v-model="am.misure_igienico_sanitarie"
        label="Misure precauzionali igienico-sanitare"
        prepend-icon="mdi-lotion-plus"
        class="ma-2"
      />
    </v-container>

    <!-- "Riduzione periodo osservazione" section -->
    <v-container class="ma-2">
      <v-divider />

      <!-- "Motivi per la riduzione del periodo di osservazione" -->
      <c-textarea
        v-model="am.riduzione_periodo_osservazione"
        label="Motivi per la riduzione del periodo di osservazione"
        prepend-icon="mdi-account-eye"
        class="ma-2"
      />
    </v-container>

    <!-- "Morte dovuta a reato" section -->
    <v-container class="ma-2">
      <!-- "Sospetto di morte dovuta a reato" switch -->
      <div class="morte-reato-col pl-0 pb-1">
        Sospetto di morte dovuta a reato:

        <c-switch
          v-model="morteReato"
          :label="morteReato ? 'SI' : 'NO'"
        />
      </div>

      <v-divider />

      <!-- "Note morte reato" textarea -->
      <c-textarea
        v-model="am.morte_reato_nota"
        prepend-icon="mdi-account-tie-hat"
        label="Note relative al sospetto di morte dovuta a reato"
        class="ma-2"
      />
    </v-container>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />
  </div>
</template>
<script>
// stores
import { GeographicStore } from 'services/commands/stores';
// services
import PraticaUtil from 'services/pratica_util';
import DateUtils from 'services/date_util';
import GeographicUtil from 'services/geographic_util';
// components
import SectionDeceduto from '../SectionDeceduto.vue';


export default {
  name: 'FormAccertamento',

  components: { SectionDeceduto },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixAccertamento(this.praticaDetails, !this.disabled);

    return {
      pratica: pratica,
      // am --> accertaemnto module
      am: pratica.accertamento_morte,

      requiredRule: [ value => !!value || 'Obbligatorio' ],

      dialogError: false,
      errors: [],
    };
  },

  computed: {
    ///
    /// Medico section
    ///
    dataNascitaMedicoISO: {
      get() { return DateUtils.formatDateAsIso(this.am.dt_nascita_medico) },
      set(newValue) { this.am.dt_nascita_medico = moment(newValue).format() },
    },

    morteReato: {
      get() { return this.am.morte_reato.toUpperCase() == 'SI' },
      set(newValue) { this.am.morte_reato = newValue ? 'SI' : 'NO' }
    },

    paceMaker: {
      get() { return this.am.portatore_pacemaker.toUpperCase() == 'SI' },
      set(newValue) { this.am.portatore_pacemaker = newValue ? 'SI' : 'NO' }
    },

    paceMakerNota: {
      get() { return this.paceMaker ? this.am.note_pacemaker : '' },
      set(newValue) { this.am.note_pacemaker = newValue },
    },

    iconPaceMaker() {
      return this.paceMaker ? "mdi-heart-pulse" : "mdi-heart-off";
    },

    ///
    /// Geographic fields
    ///
    comuneNascitaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.am, newValue, 'comune_nascita_medico', 'comune_nascita_medico_specifico') },
      get() { return GeographicUtil.getGeographicField(this.am, 'comune_nascita_medico', 'comune_nascita_medico_specifico') }
    },

    comuneResidenzaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.am, newValue, 'comune_residenza_medico', 'comune_residenza_medico_specifico') },
      get() { return GeographicUtil.getGeographicField(this.am, 'comune_residenza_medico', 'comune_residenza_medico_specifico') }
    },

    provinciaNascitaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.am, newValue, 'provincia_nascita_medico', 'provincia_nascita_medico_specifico') },
      get() { return GeographicUtil.getGeographicField(this.am, 'provincia_nascita_medico', 'provincia_nascita_medico_specifico') }
    },

    provinciaResidenzaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.am, newValue, 'provincia_residenza_medico', 'provincia_residenza_medico_specifica') },
      get() { return GeographicUtil.getGeographicField(this.am, 'provincia_residenza_medico', 'provincia_residenza_medico_specifica') }
    },

    ///
    /// Utilities computed values.
    ///
    listPaesi: () => GeographicStore.listPaesi(),    
    listProvince: () => GeographicStore.listProvince(),
  },

  watch: {
    // Watch used to update 'Medico' informations
    disabled(newValue) {
      if (!newValue) {
        // adding user information 
        this.pratica = PraticaUtil.fixAccertamento(this.praticaDetails, true);
        this.am = this.pratica?.accertamento_morte;
      }
    },

    // Watch used to notify parent component when 'pratica' updates.
    'pratica.accertamento_morte': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },

    comuneNascitaMedico(newValue) {
      GeographicUtil.setComuneSpecificoProvincia(
        this.am,
        newValue,
        'comune_nascita_medico_specifico',
        'provincia_nascita_medico',
        'provincia_nascita_medico_specifico'
      );
    },

    comuneResidenzaMedico(newValue) {
      GeographicUtil.setComuneSpecificoProvincia(
        this.am,
        newValue,
        'comune_residenza_medico_specifico',
        'provincia_residenza_medico',
        'provincia_residenza_medico_specifica'
      );
    },
  },
  
  methods: {
    ///
    /// Exposed methods.
    ///
    validate() {
      let errors = [];
      
      // nome - cognome (medico)
      this.am.cognome_medico || errors.push('Cognome Medico');
      this.am.nome_medico || errors.push('Nome Medico');

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }
      this._clearUnusedFields();
      return true;
    },

    _clearUnusedFields() {
      if (!this.paceMaker) {
        this.am.note_pacemaker = "";
      }
    }
  },
}
</script>
