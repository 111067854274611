import store from 'store';
// commands
import {
  ZapDocWriteDocument,
  ZapDocReadDocument,
  ZapFlowAbortProcess,
  ZapFlowActionComplete,
  ZapSignSiss,
  Login,
  ChangePassword,
  CaronteActionPratiche,
  CaronteListPraticaInfo,
  CaronteWritePratica,
  CaronteReadPratica,
  CaronteGetNuovoNumeroAtto,
  CaronteSaveUserInformations,
  CaronteGetPrintPraticheList,
  LocalPrintRemotePdf,
  CaronteWaitForManualActivity,
  CaronteRicercaRicoveri,
  CaronteAccettazionePratiche,
} from "services/commands";
import Core from 'services/commands/core';
// stores
import {
  ListMetaStore,
  UserInformationStore,
  AppConfigStore,
  ListIntervalloTemporaleStore
} from "../stores"
// services
import PraticaUtil from "services/pratica_util"

///
/// SC commands utilities
///

///
/// IMPROVEMENT: use only [ options, onSuccess, onError ] as functions arguments
///

// default command execution error handler
function defaultErrorHandler(response, reject) {
  const textDialogError = _.map(response.errore, "messaggio").join(", ");
  store.commit('showDialogError', textDialogError);
  _.attempt(reject, response.errore);
}

// default command execution failure handler
function defaultFailureHandler(response, reject) {
  const textFailureError = response?.message;
  store.commit('showDialogError', textFailureError);
  _.attempt(reject, response);
}

// It performs 'Auth.Login' command.
function login(username, password, onSuccess, onError) {
  Login.call(
    {
      username: username,
      password: password,
    },
    {
      success: (response) => {
        _.attempt(onSuccess, response);
        resolve();
      },
      error: (response) => {
        let errors = _.map(response.errore, "messaggio").join(", ");

        if (errors.includes("Invalid username or password")) {
          errors = "Errore: Username o Password non corretti";
        }

        _.attempt(onError, errors);
        reject(errors);
      },
      failure: defaultFailureHandler,
    }
  );
}

// It performs 'Auth.ChangePassword' command.
function changePassword(username, oldPassword, newPassword, onSuccess, onError) {
  ChangePassword.call(
    {
      username: username,
      old_password: oldPassword,
      new_password: newPassword,
    },
    {
      success: (response) => {
        _.attempt(onSuccess, response);
        resolve();
      },
      error: (response) => {
        let errors = _.map(response.errore, "messaggio").join(", ");

        if (errors.includes("Invalid username or password")) {
          errors = "Errore: Username o Password non corretti";
        }

        _.attempt(onError, errors);
        reject(errors);
      },
      failure: defaultFailureHandler,
    }
  );
}

// Default SC command.
function actionPratiche(praticaId, actionId, year, number) {
  return new Promise((resolve, reject) => CaronteActionPratiche.call(
    {
      ids: [praticaId],
      action_id: actionId,
      anno_atto: year,
      numero_atto: number,
    },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

// It performs 'ZapFlow.ActionComplete' command.
function actionComplete(praticaId, action, outDocuments) {
  return new Promise((resolve, reject) => ZapFlowActionComplete.call(
    {
      process_id: praticaId,
      action: action,
      out_params: {
        element: outDocuments,
      },
    },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

// It reads the list containing informations about "pratiche".
function listPraticaInfo({ sub_time, activities, numero_atto } = {}) {
  // default activities value
  if (!activities) {
    activities = Number(store.getters.selectedActivity?.code) || AppConfigStore.getAttivitaDaFareId();
  }
  
  // default sub_time value
  if (!sub_time) {
    const periods = ListIntervalloTemporaleStore.getListIntervalloTemporale();
    sub_time = store.getters.selectedPeriod != -1
      ? store.getters.selectedPeriod
      : periods[AppConfigStore.getCaronteDefaultIntervalloTemporaleIndex()].sub_time;
  }

  activities = activities.length ? activities : [ activities ];
  
  return new Promise((resolve, reject) => CaronteListPraticaInfo.call(
    {
      activities: activities,
      // numero_atto used to search a specific 'pratica'
      numero_atto,
      data_inizio: sub_time == -1 ? null : moment().subtract(sub_time, 'days'),
      data_fine: sub_time == -1 ? null : moment(),
      modules: store.getters.userModules,
    },
    {
      success: (response) => {
        // filling activity decoration
        _.map(response.values.pratica, (pratica) => {
          return pratica.activity_decoration = ListMetaStore.resolveAllMetas(
            'ACTIVITY_DEF', pratica.activity_definition_id
          );
        });
        
        store.commit(
          'updateListPraticaInfo',
          response.values.pratica
        );
        resolve();
      },
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

// It writes the "pratica" object with "Caronte.WritePratica" command.
async function writePratica(pratica, urrId) {
  const nullaOstaFixed = await _checkNullaOstaAttachment(pratica);
  if (!nullaOstaFixed) return;

  const documentsUploaded = await _checkForNewDocuments(pratica);
  if (!documentsUploaded) return;

  return new Promise((resolve, reject) => CaronteWritePratica.call(
    {
      pratica: pratica,
      urr_id: urrId,
    },
    {
      success: (response) => {
        let pratica = response.pratica;

        pratica.azioni.action = PraticaUtil.fixAzioni(pratica.azioni);

        // resolving Promise
        resolve(pratica);
      },
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ));
}

// It checks if the nulla_osta has a new attachment: in that case invokes writeDocument method
async function _checkNullaOstaAttachment(pratica) {
  if (pratica?.nulla_osta?.rilasciato_nulla_osta_attachment?.data) {
    const { rilasciato_nulla_osta_attachment } = pratica.nulla_osta;

    try {
      const response = await writeDocument(rilasciato_nulla_osta_attachment);

      rilasciato_nulla_osta_attachment.data = null;
      rilasciato_nulla_osta_attachment.identifier = response.document_identifier;
      rilasciato_nulla_osta_attachment.copiaConformeScansione = false;
    } catch (error) {
      alert(`Errore durante il caricamento del file: ${rilasciato_nulla_osta_attachment.original_name}.`);
      return false;
    }
  }
  return true;
}

// It checks if there are new documents: in that case invokes writeDocument method for each of them
async function _checkForNewDocuments(pratica) {
  const docs = _.cloneDeep(pratica?.documenti?.documento);
  if (docs) {
    const newDocs = []
    const oldDocs = []

    // newDocs are the "uploaded documents"
    _.forEach(docs, doc => {
      if (doc.data) {
        newDocs.push(doc)
      } else {
        oldDocs.push(doc)
      }
    });

    if (newDocs) {
      for(const doc of newDocs) {
        try {
          // write new documents
          const response = await writeDocument(doc);

          doc.data = null;
          doc.dt_emissione = moment().format();
          doc.identifier = response.document_identifier;
        } catch (error) {
          alert(`Errore durante il caricamento del file: ${doc.name}.`);
          return false;
        }
      }

      // new document list must contains only one document for each type 
      const updatedDocs = _.unionWith(
        newDocs,
        oldDocs,
        (nd, od) => nd.tipo_documento === od.tipo_documento
      );

      // update document list
      pratica.documenti.documento = updatedDocs;
    }
  }
  return true;
}

// It writes a document through ZapDocWrite command
function writeDocument(document) {
  return new Promise((resolve, reject) => ZapDocWriteDocument.call(
    { document },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    },
  ));
}

function _readDocument(docId) {
  return new Promise((resolve, reject) => ZapDocReadDocument.call(
    { documentIdentifier: docId },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    },
  ));
}

// It reads the Pratica associated with "praticaId" with an SC call.
function readPratica(praticaId) {
  return new Promise((resolve, reject) => CaronteReadPratica.call(
    { id: praticaId },
    {
      success: (response) => {
        let pratica = response.pratica

        pratica.azioni.action = PraticaUtil.fixAzioni(pratica.azioni);

        // used to fill activity_decoration of "pratica" object
        pratica.activity_decoration = ListMetaStore.resolveAllMetas(
          'ACTIVITY_DEF', pratica.activity_definition_id
        );

        // resolving Promise
        resolve(pratica);
      },
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    })
  );
}

// It calls 'Caronte.GetNuovoNumeroAtto' and genereates a new 'Pratica' object.
function newPratica() {
  let year = moment().year();

  return new Promise((resolve, reject) => CaronteGetNuovoNumeroAtto.call(
    { anno: year },
    {
      success: (response) => {
        let pratica = {};

        // initializing pratica object
        pratica.anno_atto = response.nuovo_atto.anno;
        pratica.numero_atto = response.nuovo_atto.numero;
        pratica.parte_atto = response.nuovo_atto.parte;
        pratica.serie_atto = response.nuovo_atto.serie;
        pratica.in_carico_a_medicina_legale = false;
        pratica.dt_creazione = moment().format();

        // initializing anagrafica_deceduto
        pratica.anagrafica_deceduto = PraticaUtil.emptyAnagraficaDeceduto();
        pratica.anagrafica_deceduto.numero_paziente = moment().year() + '/';

        // initializing comunicazione_decesso
        pratica.comunicazione_decesso = PraticaUtil.emptyComunicazioneDecesso();
        pratica.comunicazione_decesso.reparto = response.reparto;

        pratica.azioni = {
          action: ListMetaStore.getEditTransitionDef()
        }

        pratica.azioni.action = PraticaUtil.fixAzioni(pratica.azioni);

        // resolving Promise
        resolve(pratica);
      },
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    })
  );
}

// It calls 'ZapFlowAbortProcess' to delete the pratica with 'praticaId'.
function deletePratica(praticaId) {
  return new Promise((resolve, reject) => ZapFlowAbortProcess.call(
    { process_id: praticaId },
    {
      success: (response) => {
        let praticaInfo = response.praticaInfo;
        resolve(praticaInfo);
      },
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    })
  );
}

// It calls 'Caronte.SaveUserInformations' to update user informations.
function saveUserInformations(userInformations, urrId, onSuccess, onError) {
  return new Promise((resolve, reject) => CaronteSaveUserInformations.call(
    {
      urr_id: urrId,
      user_informations: userInformations,
    },
    {
      success: (response) => {
        UserInformationStore.setUserInformations(response.user_informations);
        UserInformationStore.setCustomer(response.customer);
        _.attempt(onSuccess, response);
        resolve();
      },
      error: (response) => {
        _.attempt(onError, response);
        reject(response);
      },
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ));
}

// It calls 'Caonte.getPrintPraticaList' to get the list of 'pratiche' to print.
function getPrintPraticheList() {
  const listPraticaInfo = store.getters.listPraticaInfo;

  return new Promise((resolve, reject) => CaronteGetPrintPraticheList.call(
    {
      id_list: store.getters.selectedPraticaIds,
      modules: store.getters.userModules,
    },
    {
      success: (response) => {
        _.map(response.element.element, (e) => {
          const praticaInfo = _.find(listPraticaInfo, pi => pi.descrizione == e.parent);
          e.id = praticaInfo.id;
          return e;
        });
        resolve(response.element.element);
      },
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ));
}

// It calls 'Local.printRemotePdf' to use the local plugin.
function localPrintRemotePdf(urls, onSuccess, onError) {
  return new Promise((resolve, reject) => LocalPrintRemotePdf.call(
    // params
    { urls: urls },
    // callbacks
    {
      success: (response) => {
        _.attempt(onSuccess, response);
        resolve(response);
      },
      error: (response) => {
        _.attempt(onError, response);
        reject(response);
      },
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ));
}

// It calls 'ZapSign.SignSISS' to use the local plugin and sign the document.
async function signSISS(docId) {
  const response = await _readDocument(docId);

  // read document error callback
  if (!response?.document?.data) {
    return;
  }

  return new Promise((resolve, reject) => ZapSignSiss.call(
    { data: response.document.data },
    // callbacks
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ));
}

// default hash type
const HASH_TYPE = 'SHA1';

// It invokes a sequence of commands to use the local plugin and sign the document.
async function signNormal(docId, praticaId, action, docName, docCode, pinCode, hashType = HASH_TYPE) {
  return new Promise((resolve, reject) => Core.sendSequence(
    [
      // [GetDocumentHash] result structure:
      // {
      //   hash:        [document_hash],
      //   hash_type:   [document_hash_type],
      //   signer_info
      // }
      {
        name: 'ZapDocGetDocumentHash',
        params: {
          document_identifier: docId,
          hash_type: hashType
        }
      },

      // [SignData] result structure:
      // {
      //   signature:   [user_signature],
      //   certificate
      // }
      {
        name: 'ZapSignData',
        params: function (stack) {
          return {
            data: stack[0].response.hash,
            hash_algorithm: stack[0].response.hash_type,
            pin_code: pinCode
          }
        }
      },

      // [AddSignature] result structure:
      // {
      //   new_document_identifier: {}
      // }
      {
        name: 'ZapDocAddSignature',
        params: function (stack) {
          return {
            document_identifier: docId,
            signature: stack[1].response.signature,
            hash_type: stack[0].response.hash_type,
            certificate: stack[1].response.certificate,
            signer_info: stack[0].response.signer_info
          }
        }
      },
      
      {
        name: 'ZapFlowActionComplete',
        params: function (stack) {
          return {
            process_id: praticaId,
            action: action,
            out_params: {
              element: [{
                key: `docout${docCode}`,
                value: `${stack[2].response.new_document_identifier}|.pdf.p7m|${docName}Firmato`,
              }]
            },
          }
        }
      }
    ],
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ));
}

// It calls 'Caronte.WaitForManualActivity'.
function waitForManualActivity(praticheIds) {
  return new Promise((resolve, reject) => CaronteWaitForManualActivity.call(
    {
      ids: praticheIds,
    },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

// It calls 'Caronte.RicercaRicoveri'.
function ricercaRicoveri(codicePaziente) {
  return new Promise((resolve, reject) => CaronteRicercaRicoveri.call(
    {
      codice_paziente: codicePaziente,
    },
    {
      success: (response) => resolve(response?.ricoveri?.ricovero),
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

// It calls 'Caronte.AccettazionePratiche' with refuse message.
function refusePratiche(praticheIds, actionId, message) {
  return new Promise((resolve, reject) => CaronteAccettazionePratiche.call(
    {
      ids: praticheIds,
      action_id: actionId,
      message: message,
    },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

// It calls 'Caronte.AccettazionePratiche' to accept selected pratica.
function acceptPratica(praticaId, actionId, year, number) {
  return new Promise((resolve, reject) => CaronteAccettazionePratiche.call(
    {
      ids: [praticaId],
      action_id: actionId,
      anno_atto: year,
      numero_atto: number,
    },
    {
      success: resolve,
      error: (response) => defaultErrorHandler(response, reject),
      failure: (response) => defaultFailureHandler(response, reject),
    }
  ))
}

export default {
  login,
  changePassword,
  actionPratiche,
  actionComplete,
  listPraticaInfo,
  writePratica,
  writeDocument,
  readPratica,
  newPratica,
  deletePratica,
  saveUserInformations,
  getPrintPraticheList,
  localPrintRemotePdf,
  signSISS,
  signNormal,
  waitForManualActivity,
  ricercaRicoveri,
  refusePratiche,
  acceptPratica,
}