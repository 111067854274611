let LIST_CONDIZIONI_MORBOSE = {}

function init(jsonListMeta) {

  LIST_CONDIZIONI_MORBOSE = _.reduce(jsonListMeta.condizioni_morbose_dictionary_list.condizione_morbosa_dictionary, function(result, value, key) {
    result[key] = value
    return result;
  }, {});
}

const getListCondizioniMorbose = () => LIST_CONDIZIONI_MORBOSE;

const getListCondizioniMorteViolenta = () => _filterCondizioniMorbose('is_morte_violenta');

const getListCondizioniRiscontroAutoptico = () => _filterCondizioniMorbose('is_riscontro_autoptico');

function _filterCondizioniMorbose(filter) {
  const conditions = [];

  _.forEach(LIST_CONDIZIONI_MORBOSE, (c) => {
    if (c[filter]) {
      conditions.push(c.descrizione);
    }
  })

  return conditions;
}

export default {
    init,
    getListCondizioniMorbose,
    getListCondizioniMorteViolenta,
    getListCondizioniRiscontroAutoptico,
    
}