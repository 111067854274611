<template>
  <div id="form-documenti">
    <!-- Table containing generated documents -->
    <ag-grid-vue
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :gridOptions="gridOptions"
      :rowData="praticaDetails.documenti.documento"
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      overlayNoRowsTemplate="Nessun documento"
    />
  </div>
</template>

<script>
// ag-grid-vue
import { AgGridVue } from "ag-grid-vue";
// services
import DateUtils from "services/date_util";
// components
import DocumentCellRenderer from "../document/DocumentCellRenderer";


export default {
  name: "FormDocumenti",

  components: {
    AgGridVue,
    DocumentCellRenderer,
  },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      gridOptions: {
        rowSelection: "single",
        animateRows: true,

        overlayLoadingTemplate: /*html*/`<span class='ag-overlay-loading-center'>Caricamento...</span>`,

        // It notifies parent component to display double-clicked document
        onRowDoubleClicked: (event) => this.$emit('document-selected', event.data),
      },

      // default column definition
      defaultColDef: {
        autoHeight: true,
        resizable: true,
        sortable: false,
      },

      columnDefs: [
        // "Documento" column
        {
          headerName: "Documento",
          suppressMovable: true,
          field: "name",
          cellRenderer: 'DocumentCellRenderer',
        },

        // "Data" column
        {
          headerName: "Data",
          sortable: true,
          sort: 'desc',
          maxWidth: 250,
          field: "dt_emissione",
          cellRenderer: (params) => `<p>${DateUtils.formatDateTime(params.value)}</p>`,
        },
        
        // "Stato" column
        {
          resizable: false,
          headerName: "Stato",
          maxWidth: 250,
          field: "stato",
          // "Firmato Incoerente" instead of "Firmato Incoerente"
          cellRenderer: (params) => `<p>${params.value == 'FirmatoIncoerente' ? 'Firmato Incoerente' : params.value}</p>`,
        }
      ],
    };
  },

  methods: {
    /**
     * Handler for the grid ready event that sets up the grid API and column API,
     * resizes the columns to fit the container,and adds a listener for the resize window event.
     * 
     * @param {Object} params - The parameters for the grid ready event.
     * @param {Object} params.api - The grid API object.
     * @param {Object} params.columnApi - The column API object.
     */
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      params.api.showLoadingOverlay();
      params.api.sizeColumnsToFit();

      // loading overlay animation
      setTimeout(function () {
        params.api.hideOverlay();
      }, 300);

      window.addEventListener("resize", this.resizeWindowHandler);
    },

    // it invokes 'sizeColumnsToFit()' on window resize
    resizeWindowHandler() {
      let api = this.gridApi;
      setTimeout(function () {
        if (api)
          api.sizeColumnsToFit();
      });
    },
  },

  beforeDestroy() {
    // removing resize window listener
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
};
</script>

