// some icons are kept for backward compatibility
const ICONS = {
  ICO_DEFAULT:    { mdi: "mdi-help-rhombus" },
  ICO_TOMBSTONE:  { mdi: "mdi-grave-stone" },

  // ACTIVITIES ICONS
  ICO_TODO:       { mdi: "mdi-cog-outline", color: "grey darken-4" }, // OR mdi-book-cog
  ICO_FAVORITE:   { mdi: "mdi-heart", color: "red lighten-2" }, // OR mdi-book-heart
  ICO_RIFIUTA:    { mdi: "mdi-minus-circle", color: "red darken-2" }, // OR mdi-book-cancel
  ICO_ERROR:      { mdi: "mdi-minus-circle", color: "red darken-2" }, // OR mdi-book-cancel OR mdi-close-circle
  ICO_INFOR:      { mdi: "mdi-lightbulb", color: "yellow accent-3" },
  ICO_STATS:      { mdi: "mdi-clipboard-pulse" },
  ICO_ACCETTA:    { mdi: "mdi-check-circle", color: "green darken-1" }, // OR mdi-book-check OR mdi-check-bold
  ICO_ARCHIVE:    { mdi: "mdi-archive", color: "brown darken-" },
  ICO_WORKING:    { mdi: "mdi-book-clock" },
  ICO_QUESTION:   { mdi: "mdi-help-circle" },

  // ACTIVITY ICONS
  ICO_NEW:        { mdi: "mdi-text-box-plus" },
  ICO_EDIT:       { mdi: "mdi-text-box-edit" },
  ICO_SAVE:       { mdi: "mdi-content-save" },
  ICO_CANCEL:     { mdi: "mdi-text-box-remove" },
  ICO_PRINT:      { mdi: "mdi-printer" },
  ICO_ASSIGN:     { mdi: "mdi-clipboard-arrow-down" },
  ICO_VALIDA:     { mdi: "mdi-clipboard-arrow-down" },
  ICO_REVISIONA:  { mdi: "mdi-file-undo" },
  ICO_ABORT:      { mdi: "mdi-text-box-remove" },

  // STATUS ICONS
  ICO_ANAGRAFICA:       { mdi: "mdi-card-account-details", outlined: "mdi-card-account-details-outline" }, // OR mdi-format-text-variant-outline OR mdi-account-arrow-left OR mdi-badge-account-horizontal OR mdi-table-account mdi-file-account
  ICO_COMUNICAZIONE:    { mdi: "mdi-calendar-clock", outlined: "mdi-calendar-clock" }, // OR mdi-book-clock
  ICO_ISTAT_A:          { mdi: "mdi-clipboard-pulse", outlined: "mdi-clipboard-pulse-outline" },
  ICO_ACCERTAMENTO:     { mdi: "mdi-doctor" }, // OR mdi-human-male-height
  ICO_NULLA_OSTA:       { mdi: "mdi-stamper" }, // OR mdi-archive-arrow-down
  ICO_RELAZIONE:        { mdi: "mdi-file-document-edit", outlined: "mdi-file-document-edit-outline" },
  ICO_TRASPORTO:        { mdi: "mdi-ambulance" }, // OR mdi-bus-school
  ICO_MEDICINA_LEGALE:  { mdi: "mdi-scale-balance" },
  ICO_COMUNE:           { mdi: "mdi-home-city", outlined: "mdi-home-city-outline" }, // OR mdi-home-city OR mdi-office-building-outline
  ICO_ISTAT_B:          { mdi: "mdi-clipboard-pulse", outlined: "mdi-clipboard-pulse-outline"},
  ICO_GESTIONALE:       { mdi: "mdi-file-cabinet" }, // OR mdi-dresser

  ICO_PDF:                 { mdi: "mdi-file-pdf-box"},
  ICO_P7M:                 { mdi: "mdi-file-certificate-outline" },
  ICO_TEMPORARY:           { mdi: "mdi-timer-sand-complete" },
  ICO_UNKNOWN_FILE_TYPE:   { mdi: "mdi-file-question" },

  ICO_FIRMA:            { mdi: "mdi-fountain-pen-tip" }, // OR mdi-signature-freehand OR mdi-draw-pen mdi-sign
  ICO_REVISIONA:        { mdi: "mdi-arrow-u-left-bottom-bold" },
  ICO_VALIDAZIONE:      { mdi: "mdi-checkbox-multiple-marked-circle" },
  ICO_UNKNOWN:          { mdi: "mdi-alert" },
  

  // DOCUMENT ICONS
  ICO_SIGN:         { mdi: 'mdi-file-sign', color: 'teal darken-2' },
  ICO_SIGN_ERROR:   { mdi: 'mdi-file-sign', color: 'red darken-3' },
  ICO_GENERATED:    { mdi: 'mdi-file-check-outline', color: 'blue darken-1' },
  ICO_WARNING:      { mdi: 'mdi-file-alert-outline', color: 'red darken-3' },
}

export { ICONS };