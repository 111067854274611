import Login from "./auth_login"
import ChangePassword from "./auth_change_password"

import ZapDocAddSignature from "./zapdoc_add_signature"
import ZapDocGetDocumentHash from "./zapdoc_get_document_hash"
import ZapDocWriteDocument from "./zapdoc_write_document"
import ZapDocReadDocument from "./zapdoc_read_document"

import ZapFlowListMeta from "./zapflow_list_meta"
import ZapFlowListProcessDef from "./zapflow_list_process_def"
import ZapFlowListActivities from "./zapflow_list_activities"
import ZapFlowListUnitRoleResource from "./zapflow_list_unit_role_resource"
import ZapFlowListAction from "./zapflow_list_action"
import ZapFlowAbortProcess from "./zapflow_abort_process"
import ZapFlowActionComplete from "./zapflow_action_complete"

import ZapSignData from "./zapsign_sign_data"
import ZapSignSiss from "./zapsign_sign_siss"

import CaronteListStato from "./caronte_list_stato"
import CaronteListCondizioneMorbosa from "./caronte_list_condizione_morbosa"
import CaronteListReparto from "./caronte_list_reparto"
import CaronteGetUserInformations from "./caronte_get_user_informations"
import CaronteSaveUserInformations from "./caronte_save_user_informations"
import CaronteListPraticaInfo from "./caronte_list_pratica_info"
import CaronteReadPratica from "./caronte_read_pratica"
import CaronteWritePratica from "./caronte_write_pratica"
import CaronteActionPratiche from "./caronte_action_pratiche"
import CaronteGetNuovoNumeroAtto from "./caronte_get_nuovo_numero_atto"
import CaronteGetPrintPraticheList from "./caronte_get_print_pratiche_list"
import CaronteAccettazionePratiche from "./caronte_accettazione_pratiche"

import LocalPrintRemotePdf from "./local_print_remote_pdf"

import CaronteWaitForManualActivity from "./caronte_wait_for_manual_activity"
import CaronteRicercaRicoveri from "./caronte_ricerca_ricoveri"

export {
    Login,
    ChangePassword,

    ZapDocAddSignature,
    ZapDocGetDocumentHash,
    ZapDocWriteDocument,
    ZapDocReadDocument,
    
    ZapFlowListMeta,
    ZapFlowListProcessDef,
    ZapFlowListActivities,
    ZapFlowListUnitRoleResource,
    ZapFlowListAction,
    ZapFlowAbortProcess,
    ZapFlowActionComplete,

    ZapSignData,
    ZapSignSiss,

    CaronteListStato,
    CaronteListCondizioneMorbosa,
    CaronteListReparto,
    CaronteGetUserInformations,
    CaronteSaveUserInformations,
    CaronteListPraticaInfo,
    CaronteReadPratica,
    CaronteWritePratica,
    CaronteActionPratiche,
    CaronteGetNuovoNumeroAtto,
    CaronteGetPrintPraticheList,
    CaronteAccettazionePratiche,

    LocalPrintRemotePdf,

    CaronteWaitForManualActivity,
    CaronteRicercaRicoveri,
}
