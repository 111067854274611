import Core from './core'

const COMMAND_NAME = 'Caronte.GetUserInformations'

function requestBody(params) {
    return {
        u_r_r_id: params.urr_id
    }
}

function promise(params) {
    return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
    Core.send(COMMAND_NAME, requestBody(params), {
        success: (response) => {
            _.attempt(options.success, response)
        },
        error: (response) => {
            _.attempt(options.error, response)
        },
        failure: (error) => {
            _.get(options, 'failure', alert)(error)
        }
    })
}

export default {
    promise,
    call
}