<script>
import { VTextField } from "vuetify/lib"
import inputUpperCase from "mixins/input_upper_case";

export default {
  name: 'CTextField',

  extends: VTextField,

  mixins: [inputUpperCase],
  
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    
    dense: {
      type: Boolean,
      default: true,
    },
  },
};
</script>