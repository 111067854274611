import router from "../../router"

const route = 'Details';

function actionPerformed(context, action) {
  if (context.$route.name != route)
    router.push({ name: route, params: { detailsMode: 'edit' } });
  else
    context.enterEditMode()
}

export default {
  actionPerformed,
}