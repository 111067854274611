/**
 * This module provides functions for manipulating geographic objects.
 * 
 * @author Marco Baresi
 */
import GeographicStore from 'services/commands/stores/geographic_store'

/**
 * Sets specific geographic data for a given "comune", "provincia", and "stato".
 * 
 * @param {Object} module - The module or object where the data will be set.
 * @param {Object} objComune - The object containing comune data, with a "descrizione" property.
 * @param {string} specificComunePath - The path to set the specific comune data in the module.
 * @param {string} objProvinciaPath - The path to the provincia object in the module.
 * @param {string} specificProvinciaPath - The path to set the specific provincia data in the module.
 * @param {string} objStatoPath - The path to the stato object in the module.
 * @param {string} specificStatoPath - The path to set the specific stato data in the module.
 */
function setComuneSpecificoProvinciaStato(module, objComune, specificComunePath, objProvinciaPath, specificProvinciaPath, objStatoPath, specificStatoPath) {
  if (objComune?.descrizione) {
    module[specificComunePath] = objComune.descrizione;
    
    if (objProvinciaPath) {
      const objProvincia = GeographicStore.getProvinciaObjectByPaese(objComune?.value) ?? null;
      setGeographicField(module, objProvincia, objProvinciaPath, specificProvinciaPath);
    }

    if (objStatoPath) {
      const objStato = GeographicStore.getStatoObjectByPaese(objComune?.value) ?? null;
      setGeographicField(module, objStato, objStatoPath, specificStatoPath);
    }
  }
}

/**
 * Sets specific geographic data for a given "comune" and "provincia".
 * 
 * @param {Object} module - The module or object where the data will be set.
 * @param {Object} objComune - The object containing comune data, with a "descrizione" property.
 * @param {string} specificComunePath - The path to set the specific comune data in the module.
 * @param {string} objProvinciaPath - The path to the provincia object in the module.
 * @param {string} specificProvinciaPath - The path to set the specific provincia data in the module.
 */
function setComuneSpecificoProvincia(module, objComune, specificComunePath, objProvinciaPath, specificProvinciaPath) {
  setComuneSpecificoProvinciaStato(module, objComune, specificComunePath, objProvinciaPath, specificProvinciaPath, null, null);
}

/**
 * Sets geographic data in the module based on the newValue object.
 * 
 * @param {Object} module - The module or object where the data will be set.
 * @param {Object} newValue - The new value object to set in the module.
 * @param {string} objPath - The path to set the newValue object in the module.
 * @param {string} specificPath - The path to set specific data from the newValue object in the module.
 */
function setGeographicField(module, newValue, objPath, specificPath) {
  // newValue.descrizione might be null:
  // newValue = null
  // newValue = "COMUNE SCONOSCIUTO"
  if (newValue?.descrizione == null) {
    module[objPath] = null;
    module[specificPath] = newValue;
  } else {
    module[objPath] = newValue;
    module[specificPath] = newValue.descrizione;
  }
}

/**
 * Gets the geographic field from the module.
 * If the objPath contains a "descrizione" property, it will be returned,
 * otherwise, the specificPath value will be returned.
 * 
 * @param {Object} module - The module or object containing the geographic data.
 * @param {string} objPath - The path to the geographic object in the module.
 * @param {string} specificPath - The path to the specific geographic data in the module.
 * @returns {string} The geographic field description.
 */
function getGeographicField(module, objPath, specificPath) {
  return module[objPath]?.descrizione
    ? module[objPath]
    : module[specificPath];
}

export default {
  setComuneSpecificoProvinciaStato,
  setComuneSpecificoProvincia,
  setGeographicField,
  getGeographicField,
}