<template>
  <div id="form-trasporto">
    <c-expansion-sections
      :disabled="disabled"
      :sections="[
        'MEDICO CHIRURGO',
        'DECEDUTO',
        'TRASPORTO SALMA'
      ]"
    >
      <!-- "MEDICO CHIRURGO" section -->
      <template #section1>
        <!-- [ cognome, nome ] -->
        <v-row no-gutters class="ma-2">

          <!-- "Cognome" medico chirurgo -->
          <v-col cols="5">
            <c-text-field
              v-model="mt.cognome_medico"
              :rules="requiredRule"
              label="Cognome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>

          <v-spacer />

          <!-- "Nome" medico chirurgo -->
          <v-col cols="5">
            <c-text-field
              v-model="mt.nome_medico"
              :rules="requiredRule"
              label="Nome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>
        </v-row>

        <!-- [ sesso ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              v-model="mt.sesso_medico"
              :items="listSesso"
              :rules="requiredRule"
              label="Sesso"
              prepend-icon="mdi-gender-male-female"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "DECEDUTO" section -->
      <template #section2>
        <SectionDeceduto
          :anagrafica-deceduto="pratica.anagrafica_deceduto"
          :comunicazione-decesso="pratica.comunicazione_decesso"
          :show-via-civico-domicilio="false"
          :show-comune-provincia-domicilio="false"
          :show-anni-mesi-giorni-ore="false"
        />
      </template>

      <!-- "TRASPORTO SALMA" section -->
      <template #section3>
        <!-- "Destinazione" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="12">
            <c-select
              v-model="mt.luogo_destinazione"
              :items="destinazioneSalma"
              :rules="requiredRule"
              label="Destinazione"
              prepend-icon="mdi-coffin"
            />
          </v-col>
        </v-row>

        <!-- "Ragione Sociale" -->
        <v-row no-gutters class="ma-2" v-show="showRagioneSociale">
          <v-col cols="12">
            <c-text-field
              v-model="mt.ragione_sociale_luogo_destinazione"
              :rules="requiredRule"
              label="Ragione Sociale"
              prepend-icon="mdi-text-box-outline"
            />
          </v-col>
        </v-row>

        <!-- "Via" destinazione -->
        <v-row no-gutters class="ma-2" v-show="showViaDestinazione">
          <v-col cols="12">
            <c-text-field
              v-model="mt.via_luogo_destinazione"
              :rules="requiredRule"
              label="Via"
              prepend-icon="mdi-home-map-marker"
            />
          </v-col>
        </v-row>

        <!-- [ comune, provincia ] -->
        <v-row no-gutters class="ma-2">

          <!-- "Comune di Nascita" medico -->
          <v-col cols="5">
            <c-combobox
              v-model="comuneLuogoDestinazione"
              :items="listPaesi"
              :rules="requiredRule"
              label="Comune"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />

          <!-- "Provincia di Nascita" medico -->
          <v-col cols="5">
            <c-combobox
              v-model="provinciaLuogoDestinazione"
              :items="listProvince"
              :rules="requiredRule"
              label="Provincia"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>
      </template>
    </c-expansion-sections>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />
  </div>
</template>

<script>
// stores
import {
  GeographicStore,
  ListDestinazioneSalmaStore,
  ListSessoStore,
} from 'services/commands/stores';
// services
import PraticaUtil from 'services/pratica_util';
import GeographicUtil from 'services/geographic_util';
// components
import SectionDeceduto from '../SectionDeceduto.vue';

export default {
  name: 'FormTrasporto',

  components: { SectionDeceduto },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },
    
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixTrasporto(this.praticaDetails, !this.disabled);

    return {
      pratica: pratica,
      mt: pratica.modulo_trasporto,

      requiredRule: [ value => !!value || 'Obbligatorio' ],

      dialogError: false,
      errors: [],
    }
  },

  computed: {
    showRagioneSociale() {
      return this.mt.luogo_destinazione == 'StrutturaSanitaria';
    },

    showViaDestinazione() {
      const luogo = this.mt.luogo_destinazione;
      return luogo != 'StrutturaSanitaria' && luogo != 'Undefined';
    },

    ///
    /// Geographic fields
    ///
    comuneLuogoDestinazione: {
      set(newValue) { GeographicUtil.setGeographicField(this.mt, newValue, 'comune_luogo_destinazione', 'comune_luogo_destinazione_specifico') },
      get() { return GeographicUtil.getGeographicField(this.mt, 'comune_luogo_destinazione', 'comune_luogo_destinazione_specifico') }
    },

    provinciaLuogoDestinazione: {
      set(newValue) { GeographicUtil.setGeographicField(this.mt, newValue, 'provincia_luogo_destinazione', 'provincia_luogo_destinazione_specifica') },
      get() { return GeographicUtil.getGeographicField(this.mt, 'provincia_luogo_destinazione', 'provincia_luogo_destinazione_specifica') }
    },

    ///
    /// Utilities computed values.
    ///
    listPaesi: () => GeographicStore.listPaesi(),
    listProvince: () => GeographicStore.listProvince(),
    destinazioneSalma: () => ListDestinazioneSalmaStore.getListDestinazioneSalma(),
    listSesso: () => ListSessoStore.getListSesso(),
  },

  watch: {
    // watch used to update 'Medico' informations
    disabled(newValue) {
      if (!newValue) {
        // adding user information 
        this.pratica = PraticaUtil.fixTrasporto(this.praticaDetails, !this.disabled);
        this.mt = this.pratica?.modulo_trasporto;
      }
    },

    // watch used to notify parent component when 'pratica' updates.
    'pratica.modulo_trasporto': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },

    // watch used to automatically fill 'provincia_luogo_destinazione' field.
    comuneLuogoDestinazione(newValue) {
      GeographicUtil.setComuneSpecificoProvincia(
        this.mt,
        newValue,
        'comune_luogo_destinazione_specifico',
        'provincia_luogo_destinazione',
        'provincia_luogo_destinazione_specifica'
      );
    },
  },

  methods: {

    ///
    /// Exposed method.
    ///
    validate() {
      let errors = [];
      
      // cognome - nome - sesso (medico)
      this.mt.cognome_medico || errors.push('Cognome Medico');
      this.mt.nome_medico || errors.push('Nome Medico');
      this.mt.sesso_medico || errors.push('Sesso Medico');

      if (this.showRagioneSociale && !this.mt.ragione_sociale_luogo_destinazione)
        errors.push('Ragione sociale della struttura sanitaria');

      if (this.showViaDestinazione && !this.mt.via_luogo_destinazione)
        errors.push('Via del luogo di destinazione');

      // comune - provincia (luogo di destinazione)
      this.mt.comune_luogo_destinazione_specifico || errors.push('Comune del luogo di destinazione');
      this.mt.provincia_luogo_destinazione_specifica || errors.push('Provincia del luogo di destinazione');

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }

      this._clearUnusedFields();
      return true;
    },

    _clearUnusedFields() {
      if (this.showRagioneSociale) {
        this.mt.via_luogo_destinazione = "";
      } else if (this.showViaDestinazione) {
        this.mt.ragione_sociale_luogo_destinazione = "";
      }
    }
  },
}
</script>
