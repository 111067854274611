/**
 * This module provides functions for generating URLs to view or dowload a specific document.
 * 
 * @author Marco Baresi
 */
import AppConfigStore from "services/commands/stores/app_config_store"

/**
 * Generates a URL to view a specific document.
 * 
 * @param {Object} document - The document object.
 * @param {string} document.identifier - The identifier of the document.
 * @param {boolean} document.is_signed - Whether the document is signed.
 * @returns {string} - The generated URL.
 */
function getViewUrl({ identifier, is_signed } = {}) {
  return `${AppConfigStore.getViewUrl()
    .replace("{0}", identifier)
    .replace("{1}", is_signed ? 1 : 0)}`;
}

/**
 * Generates a URL to view an IstatB document.
 * 
 * @param {Object} document - The document object.
 * @param {string} document.identifier - The identifier of the document.
 * @param {boolean} document.is_signed - Whether the document is signed.
 * @param {boolean} document.istat_a_identifier - The istatA identifier.
 * @returns {string} - The generated URL.
 */
function getViewUrlIstatB({ identifier, is_signed, istat_a_identifier } = {}) {
  return `${AppConfigStore.getViewUrlIstatB()
    .replace("{0}", identifier)
    .replace("{1}", is_signed ? 1 : 0)
    .replace("{2}", istat_a_identifier)}`;
}

/**
 * Generates a URL to download a document in .pdf format.
 * 
 * @param {Object} document - The document object.
 * @param {string} document.identifier - The identifier of the document.
 * @param {boolean} document.is_signed - Whether the document is signed.
 * @param {boolean} document.is_analogico - Whether the document is in analog format.
 * @param {string} document.name - The name of the document.
 * @returns {string} The generated URL.
 */
function getPDFdownloadUrl({ identifier, is_signed, is_analogico, name } = {}) {
  return `${AppConfigStore.getDownloadPDFUrl()
    .replace("{0}", identifier)
    .replace("{1}", is_signed ? 1 : 0)
    .replace("{2}", is_analogico ? 1 : 0)
    .replace("{3}", encodeURIComponent(name))}`;
}

/**
 * Generates a URL to download a document in .pdf format.
 * 
 * @param {Object} document - The document object.
 * @param {string} document.identifier - The identifier of the document.
 * @param {boolean} document.is_signed - Whether the document is signed.
 * @param {boolean} document.is_analogico - Whether the document is in analog format.
 * @param {string} document.name - The name of the document.
 * @returns {string} The generated URL.
 */
function getPDFdownloadUrlIstatB({ identifier, is_signed, is_analogico, name, istat_a_identifier } = {}) {
  return `${AppConfigStore.getDownloadPDFUrlIstatB()
    .replace("{0}", identifier)
    .replace("{1}", is_signed ? 1 : 0)
    .replace("{2}", is_analogico ? 1 : 0)
    .replace("{3}", istat_a_identifier)
    .replace("{4}", encodeURIComponent(name))}`;
}

/**
 * Generates a URL to download a cumulative pdf.
 * 
 * @param {Array} doc_ids - The identifier list of documents to be joined.
 * @param {Array} name - The name of cumulative pdf.
 * @returns {string} The generated URL.
 */
function getPDFCumulativeDownloadUrl({ doc_ids, name } = {}) {
  const ids = _.join(doc_ids, ',');

  return `${AppConfigStore.getDownloadPDFCumulative()
    .replace("{0}", ids)
    // 'copia_conforme' default value '1' for cumulative pdf
    .replace("{1}", 1)
    .replace("{2}", name)
  }`;
}

/**
 * Generates a URL to download a zip containing documents in .pdf format.
 * 
 * @param {Array} name - The name of deceased.
 * @param {Array} surname - The surname of deceased.
 * @param {Array} doc_ids - The identifier list of required documents.
 * @param {Array} doc_names - The name of the required documents.
 * @returns {string} The generated URL.
 */
function getDownloadPDFZipUrl({ name, surname, doc_ids, doc_names } = {}) {
  const zipName = `Fascicolo di ${surname} ${name}`;

  const ids = _.join(doc_ids, ',');

  const names_pdf = _.map(doc_names, n => `${n}.pdf`);
  const names = _.join(names_pdf, ',');

  return `${AppConfigStore.getDownloadPDFZipUrl()
    .replace("{0}", ids)
    // 'copia_conforme' default value '1' for zip files
    .replace("{1}", 1)
    .replace("{2}", names)
    .replace("{3}", encodeURIComponent(zipName))
  }`;
}

/**
 * Generates a URL to download a document in .p7m format.
 * 
 * @param {Object} document - The document object.
 * @param {string} document.identifier - The identifier of the document.
 * @param {string} document.name - The name of the document.
 * @returns {string} The generated URL.
 */
function getP7MdownloadUrl({ identifier, name } = {}) {
  return `${AppConfigStore.getDownloadP7MUrl()
    .replace("{0}", identifier)
    .replace("{1}", encodeURIComponent(name))
  }`;
}

/**
 * Generates a URL to print a document.
 * 
 * @param {Object} document - The document object.
 * @param {string} document.identifier - The identifier of the document.
 * @param {boolean} document.is_signed - Whether the document is signed.
 * @returns {string} The generated URL.
 */
function getPrintUrl({ identifier, id, is_signed } = {}) {
  return `${window.location.origin}/${AppConfigStore.getPrintUrl()
    .replace("{0}", is_signed ? 1 : 0)
    .replace("{1}", identifier)
    .replace("{2}", id)}`;
}

/**
 * Generates a URL to the pdf service preview.
 * 
 * @param {String} path - The preview path.
 * @param {string} resolution - Maximum between pdf width and height.
 * @returns {string} The generated URL.
 */
function getPdfServicePreviewUrl({ path, resolution } = {}) {
  return `${AppConfigStore.pdfServicePreviewUrl()
    .replace("{0}", path)
    .replace("{1}", resolution)
  }`;
}

export default {
  getViewUrl,
  getViewUrlIstatB,
  getPDFdownloadUrl,
  getPDFdownloadUrlIstatB,
  getPDFCumulativeDownloadUrl,
  getDownloadPDFZipUrl,
  getP7MdownloadUrl,
  getPrintUrl,
  getPdfServicePreviewUrl,
}