import store from 'store'

import promise from './promise'
import Util from './util'

/**
 * Callback eseguita in caso di successo del comando.
 *
 * @callback commandSuccessCallback
 * @param {Object} response - Il risultato del comando
 */

/**
 * Callback eseguita se il comando ritorna degli errori.
 *
 * @callback commandErrorCallback
 * @param {Object} errors - Gli errori del comando
 */

/**
 * Callback eseguita in caso di fallimento del comando.
 *
 * @callback commandFailureCallback
 * @param {Object} error - L'errore di rete
 */

/**
 * Invia un comando all'SC.
 *
 * @param {string}                    command                 - Nome del comando.
 * @param {Object}                    body                    - Parametri del comando.
 * @param {Object}                    [options]               - Opzioni.
 * @param {commandSuccessCallback}    [options.success]       - Callback eseguita in caso di successo del comando.
 * @param {commandErrorCallback}      [options.error]         - Callback eseguita se il comando ritorna degli errori.
 * @param {commandFailureCallback}    [options.failure]       - Callback eseguita in caso di fallimento del comando.
 * @param {boolean}                   [options.cache=false]   - Se mettere in cache o meno la risposta del comando.
 */
export default function send(command, body, options = {}) {
  store.dispatch('incrementRequestCountAsync')

  promise(command, body)
  .then(function (response) {
    if (Util.isSuccess(response.data)) {
      _.attempt(options.success, response.data.body)

      if (options.cache) {
        store.commit('cacheCommand', {
          command: command.name,
          body: response.data.body
        })
      }
    } else {
      if (Util.isAuthorized(response)) {
        _.attempt(options.error, response.data.errori)
      } else {
        Util.logout()
      }
    }
  })
  .catch(function (error) {
    _.attempt(options.failure, error)
  })
  .then(function () {
    store.dispatch('decrementRequestCountAsync')
  })
}