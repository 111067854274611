import Config from 'services/config';

function getConfig() {
  return Config.getConfig();
}

const getVersion = () => getConfig().version;
const getApplicationName = () => getConfig().auth.application_name;

// Customer
const getLogoName = () => getConfig().customer.icon;
const getLogoUrl = () => getConfig().customer.url;
const getManuale = () => getConfig().customer.manuale;

// Commands
const getCommandRequestBody = () => getConfig().commands.request_body;
const getCommandType = (command) => getConfig().commands.commands[command] || 'default';
const getCommandTypeUrl = (commandType) => getConfig().commands[`${commandType}_url`];
const getCommandDefaultUrl = () => getConfig().commands.default_url;

// Urls
const getViewUrl = () => getConfig().urls.viewUrl
const getViewUrlIstatB = () => getConfig().urls.viewUrlIstatB
const getPrintUrl = () => getConfig().urls.printUrl
const getDownloadPDFCumulative = () => getConfig().urls.downloadPDFCumulative
const getDownloadPDFUrl = () => getConfig().urls.downloadPDFUrl
const getDownloadPDFUrlIstatB = () => getConfig().urls.downloadPDFUrlIstatB
const getDownloadPDFZipUrl = () => getConfig().urls.downloadPDFZipUrl
const getDownloadP7MUrl = () => getConfig().urls.downloadP7MUrl
const getLoginModuleUrl = () => getConfig().urls.loginModuleUrl
const getStatsModuleUrl = () => getConfig().urls.statsModuleUrl

// PyPDF
const pdfServiceSplitUrl = () => getConfig().pypdf.pdfServiceSplitUrl;
const pdfServiceJoinUrl = () => getConfig().pypdf.pdfServiceJoinUrl;
const pdfServicePreviewUrl = () => getConfig().pypdf.pdfServicePreviewUrl;

// WebInterop
const isWebInteropPluginEnabled = () => getConfig().webinterop.enabled;
const getWebInteropStartUrl = () => getConfig().webinterop.start_url;
const getWebInteropDownloadUrl = () => getConfig().webinterop.download_url;
const getWebInteropUrl = () => getConfig().webinterop.url;

// Default
const getCaronteDefaultIntervalloTemporaleIndex = () => getConfig().default.caronteDefaultIntervalloTemporaleIndex;
const getAnnoMinimoNascita = () => getConfig().default.annoMinimoNascita;
const getNumeroMinimoCondizioniMorbose = () => getConfig().default.numeroMinimoCondizioniMorbose;
const getNumeroMassimoCondizioniMorbose = () => getConfig().default.numeroMassimoCondizioniMorbose;
const getNumeroMinimoCondizioniMorbosePreesistenti = () => getConfig().default.numeroMinimoCondizioniMorbosePreesistenti;
const getNumeroMassimoCondizioniMorbosePreesistenti = () => getConfig().default.numeroMassimoCondizioniMorbosePreesistenti;
const getStatoItaliano = () => getConfig().default.statoItaliano;
const getCittadinanzaItaliana = () => getConfig().default.cittadinanzaItaliana;
const getIstatAComuneLuogoDecesso = () => getConfig().default.istatAComuneLuogoDecesso;
const getIstatAProvinciaLuogoDecesso = () => getConfig().default.istatAProvinciaLuogoDecesso;
const getAttivitaDaFareId = () => getConfig().default.attivitaDaFareId;
const canDownloadPDFDirectly = () => getConfig().default.canDownloadPDFDirectly;
const canDownloadP7MDirectly = () => getConfig().default.canDownloadP7MDirectly;
const maxFileSize = () => getConfig().default.maxFileSize;


export default {
  getVersion,
  getApplicationName,
  isWebInteropPluginEnabled,
  getWebInteropStartUrl,
  getWebInteropDownloadUrl,
  getWebInteropUrl,
  getCommandRequestBody,
  getCommandType,
  getCommandTypeUrl,
  getCommandDefaultUrl,
  getViewUrl,
  getViewUrlIstatB,
  getPrintUrl,
  getDownloadPDFCumulative,
  getDownloadPDFUrl,
  getDownloadPDFUrlIstatB,
  getDownloadPDFZipUrl,
  getDownloadP7MUrl,
  getLoginModuleUrl,
  getStatsModuleUrl,
  pdfServiceSplitUrl,
  pdfServiceJoinUrl,
  pdfServicePreviewUrl,
  getManuale,
  getCaronteDefaultIntervalloTemporaleIndex,
  getAnnoMinimoNascita,
  getNumeroMinimoCondizioniMorbose,
  getNumeroMassimoCondizioniMorbose,
  getNumeroMinimoCondizioniMorbosePreesistenti,
  getNumeroMassimoCondizioniMorbosePreesistenti,
  getStatoItaliano,
  getCittadinanzaItaliana,
  getIstatAComuneLuogoDecesso,
  getIstatAProvinciaLuogoDecesso,
  getLogoName,
  getLogoUrl,
  getAttivitaDaFareId,
  canDownloadPDFDirectly,
  canDownloadP7MDirectly,
  maxFileSize,
}