<template>
  <v-dialog
    v-model="value"
    max-width="800"
    persistent
  >
    <v-card id="dialog-morte-violenta">
      <!-- Title -->
      <v-card-title>Condizioni Morbose - Morte Violenta</v-card-title>

      <v-divider />

      <v-container>
        <!-- Text -->
        <p class="text">
          La diagnosi appena compilata contiene termini ({{ formattedConditions }}) che
          potrebbero far pensare ad una causa di morte violenta. E' consigliabile
          eliminare una diagnosi che contenga tali termini nei casi in cui essa
          non sia in relazione diretta con la causa di morte: questa è l'evenienza
          più frequente di interventi del Servizio di Medicina Legale per
          correzioni/riformulazioni della descrizione degli eventi
          morbosi.
          <br>
          Diversamente, la conferma della diagnosi potrebbe comportare
          il passaggio alla compilazione della Scheda di morte violenta.
        </p>

        <v-radio-group v-model="selected">
          <!-- Modifica della diagnosi -->
          <v-radio value="edit-conditions">
            <template v-slot:label><p class="radio-button-text">Modifica della diagnosi</p></template>
          </v-radio>

          <!-- Conferma e resta in Scheda morte naturale -->
          <v-radio value="confirm-conditions">
            <template v-slot:label><p class="radio-button-text">Conferma la diagnosi e resta in Scheda di morte naturale</p></template>
          </v-radio>

          <!-- Conferma e passa in Scheda morte violenta -->
          <v-radio value="morte-violenta-conditions">
            <template v-slot:label><p class="radio-button-text">Conferma la diagnosi e passa in Scheda di morte violenta</p></template>
          </v-radio>
        </v-radio-group>
      </v-container>

      <v-card-actions>
        <v-spacer />
        
        <!-- Confirm Button -->
        <v-btn
          @click="onConfirm"
          class="btn-confirm"
          outlined
        >
          CONFERMA SCELTA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogCondizioniMorteViolenta",

  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },

    conditions: {
      type: Array,
      required: true,
    },
  },

  // declaring events that will be emitted
  emits: ['edit-conditions', 'confirm-conditions', 'morte-violenta-conditions'],

  data() {
    return {
      // selected radioButton value
      selected: "edit-conditions",
    };
  },

  computed: {
    // concatenated and formatted conditions
    formattedConditions() {
      const conditionsClone = _.cloneDeep(this.conditions);
      let formattedConditions = conditionsClone.shift();

      _.forEach(this.conditionsClone, (c) => {
        formattedConditions = formattedConditions.concat(", ", c);
      });

      return formattedConditions;
    }
  },

  methods: {
    // emitting selected radio button corresponding event
    onConfirm(_) {
      this.$emit(this.selected);
      this.$emit('input', false);
    }
  },
};
</script>