import Vue from 'vue'
import Vuetify from 'vuetify'

import it from 'vuetify/lib/locale/it'

Vue.use(Vuetify)


export default new Vuetify(
    {
        icons: {
            iconfont: 'mdi',
        },
        theme: {
            themes: {
                light: {
                    // primary: '#174DA6',
                    primary: '#205295',
                    secondary: '#c9c9c9'
                },
            },
        },
        lang: {
            locales: { it },
            current: 'it',
        }
    }
)
