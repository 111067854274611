var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    {
      attrs: {
        disabled: _vm.disabled,
        accordion: "",
        multiple: "",
        hover: "",
        tile: "",
      },
      model: {
        value: _vm.panels,
        callback: function ($$v) {
          _vm.panels = $$v
        },
        expression: "panels",
      },
    },
    _vm._l(_vm.sections, function (section, index) {
      return _c(
        "v-expansion-panel",
        { key: index, staticClass: "panel" },
        [
          _c(
            "v-expansion-panel-header",
            { class: _vm.disabled ? {} : "panel-title" },
            [_c("b", [_vm._v(_vm._s(section))])]
          ),
          _vm._v(" "),
          _c("v-expansion-panel-content", [_vm._t("section" + (index + 1))], 2),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }