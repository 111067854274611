<template>
  <v-dialog
    v-model="value"
    max-width="600"
    persistent
  >
    <v-card id="dialog-print-pratiche-list">
      <!-- Title -->
      <v-card-title>{{ !this.empty ? "Stampa" : "Attenzione" }}</v-card-title>
      
      <v-divider /><br>

      <!-- Body -->
      <!-- Structure:
        [] 1_DOCUMENT_CATEGORY N/TOT
        [] 2_DOCUMENT_CATEGORY N/TOT
        - [...]
        [] N_DOCUMENT_CATEGORY N/TOT
      -->
      <v-card-text v-if="!this.empty">
        <v-list dense>

          <!-- Item sample format: -->
          <!-- [] DOCUMENT_CATEGORY_NAME N/TOT -->
          <v-list-item-group v-model="selected" multiple>
            <v-list-item v-for="document in groupedByCategory"
              :key="document.category"
              :value="document"
            >
              <template v-slot:default="{ active }">
                <!-- Item Checkbox -->
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <!-- Item Text -->
                <v-list-item-content>
                  <v-list-item-title class="list-item-title">
                    {{ formatPrintPratica(document) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>

      <v-card-text v-else class="text">
        {{ emptyText }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <!-- 'Annulla' action -->
        <v-btn
          @click="onCancelClick"
          class="btn-action"
          outlined
        >
          ANNULLA
        </v-btn>
      
        <!-- 'Stampa' action -->
        <v-btn v-if="!this.empty"
          @click="onPrintClick"
          :disabled="!selected.length"
          :class="selected.length && 'btn-action'"
          outlined
        >
          STAMPA
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import printJS from 'print-js'
// store
import { mapGetters } from 'vuex';
// services
import UrlUtil from 'services/url_util';
import CommandUtil from 'services/commands/core/command_util';

const PRINT_MODE = {
  Browser: "Browser",
  Plugin: "Plugin",
}

export default {
  name: "DialogPrintPraticheList",

  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },

    printPraticheList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      // Selected mokup list:
      // [
      //   {
      //       "category": "COMUNICAZIONE_DI_DECESSO FIRMATO",
      //       "documents": [
      //         {
      //           "identifier": "B70D8R2ZDUTCMY2V",
      //           "id": "0",
      //           "is_signed": "1",
      //         },
      //         { ... }
      //       ],
      //       "count": 2
      //   },
      //   { ... }
      // ]
      selected: [],

      printMode: PRINT_MODE.Browser,

      errorMessage: "Errore durante la stampa dei documenti",
      printResultMessage: "",
    };
  },

  computed: {
    ...mapGetters([
      'selectedPraticaIds'
    ]),

    empty() {
      return this.printPraticheList.length === 0;
    },

    emptyText() {
      return this.selectedPraticaIds.length === 1
        ? "La pratica selezionata non ha documenti da stampare!"
        : "Le pratiche selezionate non hanno documenti da stampare!";
    },

    /**
     * Returns a filtered list of printPratiche grouped by category
     * and containing the identifiers of and count for each category.
     * 
     * @returns {Array} An array of objects, each one representing a category.
     */
    groupedByCategory() {
      const groupedByCategory = _.groupBy(this.printPraticheList, 'category');

      const result = _.map(groupedByCategory, (group) => {
        const documents = _.map(group, d => UrlUtil.getPrintUrl({
          identifier: d.identifier,
          id: d.id,
          is_signed: d.is_signed
        }));

        const identifiers = _.map(group, 'identifier');
        return {
          category: group[0].category,
          documents,
          identifiers,
          count: group.length,
        };
      });

      return result;
    },
  },

  methods: {
    /**
     * Formats a document as "DOCUMENT_CATEGORY_NAME #COUNT/#TOT".
     * 
     * @param {Object} document - The document to format, with a 'category' and 'count' property.
     * @returns {string} A string containing the formatted category and count information.
     */
    formatPrintPratica(document) {
      const { category, count } = document;
      const selectedPraticaCount = this.selectedPraticaIds.length;
      return `${category} (${count}/${selectedPraticaCount})`;
    },

    onCancelClick() {
      this.selected = [];
      this.$emit('input', false);
    },

    onPrintClick() {
      if (this.printMode === PRINT_MODE.Plugin) {
        // [WebInterop] print
        this.localPrint();
      } else if (this.printMode === PRINT_MODE.Browser) {
        // [printJs] print
        this.browserPrint();
      }
    },

    // print performed through printJs library
    async browserPrint() {
      this.$store.dispatch('incrementRequestCountAsync');
      this.$emit('input', false);
      const ids = [];

      _.forEach(this.selected, (category) => {
        ids.push(...category.identifiers);
      });

      const cumulativeDownloadUrl = UrlUtil.getPDFCumulativeDownloadUrl({
        doc_ids: ids,
        name: 'anteprima',
      });

      // onError callback
      const onError = () => {
        this.$store.commit('showDialogError', this.errorMessage);
      };

      try {
        const response = await fetch(cumulativeDownloadUrl);
        
        const pdfBlob = await response.blob();
        const _url = window.URL.createObjectURL(pdfBlob);

        printJS({
          printable: _url,
          onError,
        });
      } catch (error) {
        onError();
        return;
      } finally {
        this.selected = [];
        this.$store.dispatch('decrementRequestCountAsync');
      }
    },

    // print performed through the local plugin
    async localPrint() {
      const urls = [];

      _.forEach(this.selected, (category) => {
        urls.push(...category.documents);
      });

      // onSuccess callback
      const onSuccess = () => {
        this.printResultMessage = "Procedura di stampa avviata con successo";
      };

      // onError callback
      const onError = () => {
        this.printResultMessage = this.errorMessage;
      };
      
      // onFailure callback
      const onFailure = () => {
        this.printResultMessage = `
          Errore di rete: verificare di aver installato correttamente
          il plugin "WebInterop" o contattare l'assistenza tecnica.
        `;
      }

      // updates v-model in parent component
      this.$emit('input', false);
      
      try {
        await CommandUtil.localPrintRemotePdf(urls, onSuccess, onError);
      } catch (err) {
        onFailure();
      } finally {
        this.$store.commit('showDialog', {
          title: 'Stampa',
          text: this.printResultMessage,
        });
        this.selected = [];
      }
    },
  },
}
</script>