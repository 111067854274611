var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-comunicazione" } },
    [
      _c(
        "v-container",
        { staticClass: "form" },
        [
          _c("SectionDatiMorte", {
            ref: "sectionDatiMorte",
            attrs: { cd: _vm.pratica.comunicazione_decesso },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "form" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("c-textarea", {
                    staticClass: "ma-2",
                    attrs: {
                      label: "Diagnosi",
                      "prepend-icon": "mdi-microscope",
                      rows: "5",
                    },
                    model: {
                      value: _vm.cd.diagnosi,
                      callback: function ($$v) {
                        _vm.$set(_vm.cd, "diagnosi", $$v)
                      },
                      expression: "cd.diagnosi",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "form" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("c-textarea", {
                    staticClass: "ma-2",
                    attrs: {
                      label: "Osservazioni",
                      "prepend-icon": "mdi-medical-bag",
                      rows: "5",
                    },
                    model: {
                      value: _vm.cd.osservazioni,
                      callback: function ($$v) {
                        _vm.$set(_vm.cd, "osservazioni", $$v)
                      },
                      expression: "cd.osservazioni",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }