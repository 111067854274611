<template>
  <v-dialog v-model="value" max-width="600" persistent>
    <v-card id="dialog-information">
      <!-- Title -->
      <v-card-title>{{ title }}</v-card-title>

      <v-divider /><br>

      <!-- Text -->
      <v-card-text class="custom-card-text">
        <p class="text">{{ text }}</p>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        
        <!-- "OK" Button -->
        <v-btn
          @click="onConfirmClick()"
          class="btn-confirm"
          outlined
          text
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CDialogInformation",

  props: {
    // value: true to make dialog visible
    //        false otherwise
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    
    title: {
      type: String,
      required: false,
      default: 'Attenzione',
    },

    text: {
      type: String,
      required: true,
    }
  },

  data() {
    return {};
  },

  methods: {
    // method invoked on 'OK' button click
    onConfirmClick() {
      this.$emit('input', false);
    },
  },
};
</script>
