var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-refuse" } },
        [
          _c("v-card-title", [_vm._v("Rifiuto pratica")]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("c-textarea", {
            staticClass: "ma-3",
            attrs: { label: "Motivo del rifiuto" },
            model: {
              value: _vm.message,
              callback: function ($$v) {
                _vm.message = $$v
              },
              expression: "message",
            },
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-action",
                  attrs: { outlined: "" },
                  on: { click: _vm.onCancelClick },
                },
                [_vm._v("\n        ANNULLA\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  class: _vm.message && "btn-action",
                  attrs: { disabled: !_vm.message, outlined: "" },
                  on: { click: _vm.onConfirmClick },
                },
                [_vm._v("\n        OK\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }