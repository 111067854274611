var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1", "align-self": "start" } },
            [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("p", { staticClass: "doc-name" }, [
              _vm._v(_vm._s(_vm.params.node.data.name)),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }