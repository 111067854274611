import Util from './util'
import * as Commands from 'services/commands'
import store from 'store'

const DEFAULT_OPTIONS = {
  successWithErrors: false,
  failure: alert
}

function handleResponses(responses, commands, options) {
  if (Util.isAllAuthorized(responses)) {
    let success = _.every(responses, (response) => {
      return Util.isSuccess(response.data)
    }) || options.successWithErrors

    if (success) {
      _.each(responses, (response, index) => {
        let command = commands[index]
        let commandOptions = command.options || {}

        if (Util.isSuccess(response.data)) {
          _.attempt(commandOptions.success, response.data.body)

          if (commandOptions.cache || options.cache) {
            store.commit('cacheCommand', {
              command: command.name,
              body: response.data.body
            })
          }
        } else {
          _.attempt(commandOptions.error, response.data.errori)
        }
      })
    }

    if (success) {
      _.attempt(options.success, responses)
    } else {
      _.attempt(options.error, responses)
    }
  } else {
    Util.logout()
  }
}

/**
 * Callback eseguita in caso di successo del comando.
 *
 * @callback commandSuccessCallback
 * @param {Object} response - Il risultato del comando
 */

/**
 * Callback eseguita se il comando ritorna degli errori.
 *
 * @callback commandErrorCallback
 * @param {Object} errors - Gli errori del comando
 */

/**
 * Callback eseguita se tutti i comandi vengono eseguiti con successo.
 *
 * @callback successCallback
 * @param {Array} response - I risultati dei comandi
 */

/**
 * Callback eseguita se almeno uno dei comandi ritorna degli errori.
 *
 * @callback errorCallback
 * @param {Array} response - I risultati dei comandi
 */

/**
 * Callback eseguita in caso di fallimento del comando.
 *
 * @callback failureCallback
 * @param {Object} error - L'errore di rete
 */

/**
 * Invia un insieme di comandi all'SC.
 *
 * @param {Object[]}                  commands                            - I comandi da eseguire.
 * @param {string}                    commands[].name                     - Nome del comando.
 * @param {Object}                    commands[].params                   - Parametri del comando.
 * @param {Object}                    [commands[].options]                - Opzioni.
 * @param {commandSuccessCallback}    [commands[].options.success]        - Callback eseguita in caso di successo del comando.
 * @param {commandErrorCallback}      [commands[].options.error]          - Callback eseguita se il comando ritorna degli errori.
 * @param {boolean}                   [commands[].options.cache=false]    - Se mettere in cache o meno la risposta del comando.
 * @param {Object}                    [options]                           - Opzioni.
 * @param {boolean}                   [options.successWithErrors=false]   - Se eseguire successCallback e le callback dei singoli comandi anche se alcuni comandi presentano degli errori.
 * @param {successCallback}           [options.success]                   - Callback eseguita se tutti i comandi vengono eseguiti con successo o se successWithErrors=true.
 * @param {errorCallback}             [options.error]                     - Callback eseguita se almeno uno dei comandi ritorna degli errori e se successWithErrors=false.
 * @param {failureCallback}           [options.failure]                   - Callback eseguita in caso di fallimento di uno dei comandi.
 * @param {boolean}                   [options.cache=false]               - Se mettere in cache o meno la risposta dei comandi.
 */
export default function sendAll(commands = [], options = {}) {
  store.dispatch('incrementRequestCountAsync')

  options = _.merge({}, DEFAULT_OPTIONS, options)

  let promises = _.map(commands, (command) => {
    return Commands[command.name].promise(command.params)
  })

  Promise.all(promises)
    .then((responses) => {
      handleResponses(responses, commands, options)
    })
    .catch(error => {
      console.log('Error: ', error)
      _.get(options, 'failure', alert)(error)
    })
    .then(() => {
      store.dispatch('decrementRequestCountAsync')
    })
}
