import md5 from 'crypto-js/md5'
import User from 'services/user'
import Core from './core'
// store
import { AppConfigStore } from 'services/commands/stores';

const COMMAND_NAME = 'Auth.Login'

function hash(value) {
  return md5(value).toString().toUpperCase();
}

function requestBody(params) {
  return {
    username: params.username,
    password: hash(params.password),
    application_name: AppConfigStore.getApplicationName()
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params));
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      User.setUserData(response)
      _.attempt(options.success, response);
    },
    error: (response) => {
      _.attempt(options.error, response);
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  });
}

export default {
  promise,
  call,
}