import store from 'store';

import Core from 'services/commands/core';
import User from 'services/user';

import {
  ListActionStore,
  ListActivitiesStore,
  ListCondizioniMorboseStore,
  ListMetaStore,
  ListProcessDefStore,
  ListUnitRoleResourceStore,
  ListRepartoStore,
  GeographicStore,
  UserInformationStore
} from 'services/commands/stores';

function commands() {
  return [
    {
      name: 'ZapFlowListMeta',
      params: { processDefId: 0 },
    },
    {
      name: 'ZapFlowListProcessDef',
      params: { permission: "r" },
    },
    {
      name: 'ZapFlowListActivities',
      params: {},
    },
    {
      name: 'ZapFlowListUnitRoleResource',
      params: {},
    },
    {
      name: 'ZapFlowListAction',
      params: { id: 0 },
    },
    {
      name: 'CaronteListStato',
      params: {},
    },
    {
      name: 'CaronteListCondizioneMorbosa',
      params: {},
    },
    {
      name: 'CaronteListReparto',
      params: {},
    },
    {
      name: 'CaronteGetUserInformations',
      params: { urr_id: User.getUserId() },
      options: { cache: true },
    },
  ];
}

// It preloads data in each dedicated store
function preloadData() {
  Core.sendAll(commands(), {
    cache: false,
    success: (responses) => {
      // Init UserInformation
      UserInformationStore.init(responses[8].data.body);

      // Init META
      ListMetaStore.init(responses[0].data.body);
      ListProcessDefStore.init(responses[1].data.body);
      ListActivitiesStore.init(responses[2].data.body);
      ListUnitRoleResourceStore.init(responses[3].data.body);
      ListActionStore.init(responses[4].data.body);
      
      // ListStatoStore.init(responses[5].data.body);
      GeographicStore.init(responses[5].data.body);

      ListCondizioniMorboseStore.init(responses[6].data.body);

      ListRepartoStore.init(responses[7].data.body);

      store.commit('startupComplete');
    },
    error: (responses) => {
      console.log(responses);
      alert('Startup fallito');
      reject(responses);
    },
  })
}

// It invokes 'preloadData' only the first time
function startup() {
  if (!store.getters.startup) {
    preloadData();
  }
}

export default {
  startup,
}