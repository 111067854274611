<template>
  <v-dialog v-model="value" max-width="600" persistent>
    <v-card id="dialog-error">
      <!-- Title -->
      <v-card-title>Attenzione</v-card-title>

      <v-divider /><br>

      <!-- Body -->
      <!-- Structure:
        I seguenti campi sono obbligatori:
        - [required_field_1]
        - [required_filed_2]
        - [...]
        - [required_field_n]
      -->
      <v-card-text>
        <p class="text">I seguenti campi sono obbligatori:</p>

        <!-- Required field list-->
        <ul>
          <li v-for="(e, index) in errors" :key="index" class="li-text">
            {{ e }}
          </li>
        </ul>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- Close Button-->
        <v-btn
          text @click="onCloseClick"
          class="btn-close"
          outlined
        >
          CHIUDI
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CDialogError",

  // value:     true to make dialog visible
  //            false otherwise
  // errors:    array containing errors to display
  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
    errors: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {};
  },

  methods: {
    onCloseClick() {
      this.$emit('input', false);
    },
  }
};
</script>
