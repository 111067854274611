import Core from './core'

const COMMAND_NAME = 'ZapSign.SignData'

function requestBody(params) {
  return {
    data: params.data,
    hash_algorithm: params.hash_algorithm,
    pin_code: params.pin_code
  }
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response)
    },
    error: (response) => {
      _.attempt(options.error, response)
    },
    failure: (error) => {
      _.get(options, 'failure', alert)(error)
    }
  })
}

export default {
  call,
  promise
}