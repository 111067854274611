<template>
  <v-dialog
    v-model="value"
    max-width="600"
    persistent
  >
    <v-card id="dialog-ricoveri">
      <!-- Title -->
      <v-card-title>Seleziona paziente</v-card-title>

      <!-- Body -->
      <!-- Structure:
        [N. Paziente] [Data Ricovero] [Ragione Sociale]
      -->
      <div class="ag-grid-form">
        <ag-grid-vue
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :gridOptions="gridOptions"
          :rowData="ricoveriList"
          style="width: 100%; height: 300px"
          class="ag-theme-alpine"
          overlayNoRowsTemplate="Nessun ricovero"
        />
      </div>

      <!-- Alternative solution -->
      <!-- <v-list dense>
        <v-subheader>
          <v-breadcrumbs
            divider="-"
            :items="[
              {'text': 'N. Paziente'},
              {'text': 'Data Ricovero'},
              {'text': 'Ragione Sociale'},
            ]"
          />
        </v-subheader>

        <v-list-item-group v-model="selected">
          <v-list-item v-for="(ricovero, index) in ricoveriList"
            :key="index"
            :value="ricovero"
            class="custom-list-item"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title class="list-item-title">
                  <v-breadcrumbs
                    divider="-"
                    :items="[
                      {'text': ricovero.ricovero_numero},
                      {'text': ricovero.ricovero_data},
                      {'text': `${ricovero.cognome} ${ricovero.nome}`},
                    ]"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list> -->

      <v-card-actions>
        <v-spacer />

        <!-- 'Annulla' action -->
        <v-btn
          @click="onCancelClick"
          class="btn-action"
          outlined
        >
          ANNULLA
        </v-btn>
      
        <!-- 'OK' action -->
        <v-btn
          @click="onConfirmClick"
          :disabled="!selected"
          :class="selected && 'btn-action'"
          outlined
        >
          OK
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";

import DateUtils from "services/date_util";

export default {
  name: "DialogRioveri",

  components: {
    AgGridVue,
  },

  props: {
    // value:     true to make dialog visible
    //            false otherwise
    value: {
      type: Boolean,
      required: false,
      default: true,
    },

    // example of required object structure:
    // {
    //   ricovero_numero: {number},
    //   ricovero_data: {iso_format},
    //   nome: {string},
    //   cognome: {string},
    // }
    ricoveriList: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      // selected ricovero object
      selected: null,

      gridOptions: {
        rowSelection: "single",
        animateRows: true,

        onRowClicked: this.updateSelectedRicovero,

        overlayLoadingTemplate: /*html*/`<span class='ag-overlay-loading-center'>Caricamento...</span>`,
      },

      // default column definition
      defaultColDef: {
        maxWidth: 250,
        autoHeight: true,
        resizable: true,
        sortable: false,
      },

      columnDefs: [
        // "N. Ricovero" column
        {
          headerName: "N. Ricovero",
          suppressMovable: true,
          field: "ricovero_numero",
          cellRenderer: (params) => `<p>${params.value}</p>`,
        },
        // "Data Ricovero" column
        {
          headerName: "Data Ricovero",
          field: "ricovero_data",
          cellRenderer: (params) => `<p>${DateUtils.formatDate(params.value)}</p>`,
        },
        // "Ragione Sociale" column
        {
          resizable: false,
          headerName: "Ragione Sociale",
          cellRenderer: (params) => `<p>${params.data.cognome} ${params.data.nome}</p>`,
        }
      ]
    };
  },

  methods: {
    /**
     * Handler for the grid ready event that sets up the grid API and column API,
     * resizes the columns to fit the container,and adds a listener for the resize window event.
     * 
     * @param {Object} params - The parameters for the grid ready event.
     * @param {Object} params.api - The grid API object.
     * @param {Object} params.columnApi - The column API object.
     */
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      params.api.showLoadingOverlay();
      params.api.sizeColumnsToFit();

      // loading overlay animation
      setTimeout(function () {
        params.api.hideOverlay();
      }, 300);

      window.addEventListener("resize", this.resizeWindowHandler);
    },

    // it invokes 'sizeColumnsToFit()' on window resize
    resizeWindowHandler() {
      let api = this.gridApi;
      setTimeout(function () {
        if (api)
          api.sizeColumnsToFit();
      });
    },

    onCancelClick() {
      this.selected = null;
      this.$emit('input', false);
    },

    onConfirmClick() {
      this.$emit('ricovero-choosed', [this.selected]);
      this.selected = null;
      this.$emit('input', false);
    },

    updateSelectedRicovero(event) {
      this.selected = event.data;
    },
  },

  beforeDestroy() {
    // removing resize window listener
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
}
</script>