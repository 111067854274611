var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-relazione" } },
    [
      _c("c-expansion-sections", {
        attrs: {
          disabled: _vm.disabled,
          sections: [
            "DECEDUTO",
            "CONDIZIONI MORBOSE",
            "MORTE TRAUMATISMO/AVVELENAMENTO",
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "section1",
            fn: function () {
              return [
                _c("SectionDeceduto", {
                  attrs: {
                    "anagrafica-deceduto": _vm.pratica.anagrafica_deceduto,
                    "comunicazione-decesso": _vm.pratica.comunicazione_decesso,
                    "show-cittadinanza": false,
                    "show-via-civico-residenza": false,
                    "show-comune-provincia-residenza": false,
                  },
                }),
                _vm._v(" "),
                _vm.isNumeroRegistroVisible
                  ? _c(
                      "v-row",
                      { staticClass: "ma-2 mt-6", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                rules: _vm.isNumeroRegistroRequired
                                  ? _vm.requiredRule
                                  : [true],
                                label: "Numero del Registro",
                                "prepend-icon": "mdi-clipboard-list-outline",
                              },
                              model: {
                                value: _vm.module.numero_registro,
                                callback: function ($$v) {
                                  _vm.$set(_vm.module, "numero_registro", $$v)
                                },
                                expression: "module.numero_registro",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "section2",
            fn: function () {
              return [
                _c("c-condition-list", {
                  attrs: {
                    description: _vm.descriptionCondizioniNonPreesistenti,
                    isPreesistente: false,
                    rules: _vm.requiredRule,
                    disabled: _vm.disabled,
                    minNotPre: _vm.minNotPre,
                    maxNotPre: _vm.maxNotPre,
                    minPre: _vm.minPre,
                    maxPre: _vm.maxPre,
                  },
                  model: {
                    value: _vm.module.condizioni.condizione_morbosa,
                    callback: function ($$v) {
                      _vm.$set(_vm.module.condizioni, "condizione_morbosa", $$v)
                    },
                    expression: "module.condizioni.condizione_morbosa",
                  },
                }),
                _vm._v(" "),
                _c("c-condition-list", {
                  attrs: {
                    description: _vm.descriptionCondizioniPreesistenti,
                    isPreesistente: true,
                    rules: _vm.requiredRule,
                    disabled: _vm.disabled,
                    minNotPre: _vm.minNotPre,
                    maxNotPre: _vm.maxNotPre,
                    minPre: _vm.minPre,
                    maxPre: _vm.maxPre,
                  },
                  model: {
                    value: _vm.module.condizioni.condizione_morbosa,
                    callback: function ($$v) {
                      _vm.$set(_vm.module.condizioni, "condizione_morbosa", $$v)
                    },
                    expression: "module.condizioni.condizione_morbosa",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "section3",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "morte-avvelenamento-col" },
                  [
                    _vm._v(
                      "\n        Morte da traumatismo/avvelenamento:\n        \n        "
                    ),
                    _c("c-switch", {
                      attrs: {
                        label: _vm.morteTraumaAvvelenamento ? "SI" : "NO",
                      },
                      model: {
                        value: _vm.morteTraumaAvvelenamento,
                        callback: function ($$v) {
                          _vm.morteTraumaAvvelenamento = $$v
                        },
                        expression: "morteTraumaAvvelenamento",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-form",
                  {
                    attrs: {
                      disabled: _vm.disabled || !_vm.morteTraumaAvvelenamento,
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                rules: _vm.requiredRule,
                                disabled: !_vm.morteTraumaAvvelenamento,
                                label: "Circostanza",
                                "prepend-icon": "mdi-text",
                              },
                              model: {
                                value: _vm.circostanzaTraumatismoAvvelenamento,
                                callback: function ($$v) {
                                  _vm.circostanzaTraumatismoAvvelenamento = $$v
                                },
                                expression:
                                  "circostanzaTraumatismoAvvelenamento",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "modalita-morte-col",
                            attrs: { cols: "5" },
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                rules: _vm.requiredRule,
                                items: _vm.listModalitaMorte,
                                "item-text": "text",
                                "item-value": "key",
                                label: "Modalità",
                                "prepend-icon":
                                  "mdi-format-list-bulleted-square",
                              },
                              model: {
                                value: _vm.modalitaTraumatismoAvvelenamento,
                                callback: function ($$v) {
                                  _vm.modalitaTraumatismoAvvelenamento = $$v
                                },
                                expression: "modalitaTraumatismoAvvelenamento",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "morte-lavoro-col",
                            attrs: { cols: "5" },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pl-1 pb-3",
                                attrs: {
                                  disabled: !_vm.morteTraumaAvvelenamento,
                                },
                              },
                              [_vm._v("mdi-account-hard-hat")]
                            ),
                            _vm._v(
                              "\n            Infortunio sul lavoro:\n\n            "
                            ),
                            _c("c-switch", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                label: _vm.lavoro ? "SI" : "NO",
                              },
                              model: {
                                value: _vm.lavoro,
                                callback: function ($$v) {
                                  _vm.lavoro = $$v
                                },
                                expression: "lavoro",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("c-select", {
                              attrs: {
                                disabled: !_vm.morteTraumaAvvelenamento,
                                rules: _vm.requiredRule,
                                items: _vm.listLuogoAccidente,
                                "item-text": "text",
                                "item-value": "key",
                                label: "Luogo accidente",
                                "prepend-icon": "mdi-crosshairs-gps",
                              },
                              model: {
                                value: _vm.luogoAccidente,
                                callback: function ($$v) {
                                  _vm.luogoAccidente = $$v
                                },
                                expression: "luogoAccidente",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "slide-fade" } },
                      [
                        _c(
                          "v-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showSpecificareLuogoAccidente,
                                expression: "showSpecificareLuogoAccidente",
                              },
                            ],
                            staticClass: "ma-2",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("c-text-field", {
                                  attrs: {
                                    rules: _vm.requiredRule,
                                    label: "Specificare altro luogo accidente",
                                    "prepend-icon": "mdi-text",
                                  },
                                  model: {
                                    value: _vm.altroLuogoAccidente,
                                    callback: function ($$v) {
                                      _vm.altroLuogoAccidente = $$v
                                    },
                                    expression: "altroLuogoAccidente",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "riscontro-diagnostico-col",
                  attrs: { cols: "5" },
                },
                [
                  _c("v-icon", { staticClass: "pl-1" }, [
                    _vm._v(_vm._s(_vm.iconRiscontroDiagnostico)),
                  ]),
                  _vm._v(
                    "\n        Riscontro diagnostico richiesto:\n\n        "
                  ),
                  _c("c-switch", {
                    attrs: { label: _vm.riscontroDiagnostico ? "SI" : "NO" },
                    model: {
                      value: _vm.riscontroDiagnostico,
                      callback: function ($$v) {
                        _vm.riscontroDiagnostico = $$v
                      },
                      expression: "riscontroDiagnostico",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "nulla-osta-col", attrs: { cols: "5" } },
                [
                  _c("v-icon", { staticClass: "pl-1" }, [
                    _vm._v(_vm._s(_vm.iconNullaOsta)),
                  ]),
                  _vm._v("\n        Nulla osta richiesto:\n\n        "),
                  _c("c-switch", {
                    attrs: { label: _vm.nullaOsta ? "SI" : "NO" },
                    model: {
                      value: _vm.nullaOsta,
                      callback: function ($$v) {
                        _vm.nullaOsta = $$v
                      },
                      expression: "nullaOsta",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
      _vm._v(" "),
      _c("dialog-condizioni-morte-violenta", {
        attrs: { conditions: _vm.condizioniMorteViolenta },
        on: {
          "edit-conditions": _vm.onEditConditionsEvent,
          "confirm-conditions": _vm.onConfirmConditionsEvent,
          "morte-violenta-conditions": _vm.onMorteViolentaConditionsEvent,
        },
        model: {
          value: _vm.dialogCondizioniMorteViolenta,
          callback: function ($$v) {
            _vm.dialogCondizioniMorteViolenta = $$v
          },
          expression: "dialogCondizioniMorteViolenta",
        },
      }),
      _vm._v(" "),
      _c("dialog-condizioni-riscontro-autoptico", {
        attrs: {
          condition: _vm.condizioneRiscontroAutoptico,
          maxNewConditionsNumber: _vm.maxNotPre - 1,
        },
        on: {
          "delete-condition": _vm.onDeleteConditionEvent,
          "open-new-conditions": _vm.onOpenNewConditionsEvent,
          "riscontro-autoptico-conditions":
            _vm.onRiscontroAutopticoConditionsEvent,
        },
        model: {
          value: _vm.dialogCondizioniRiscontroAutoptico,
          callback: function ($$v) {
            _vm.dialogCondizioniRiscontroAutoptico = $$v
          },
          expression: "dialogCondizioniRiscontroAutoptico",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }