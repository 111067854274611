import Core from './core';

const COMMAND_NAME = 'ZapSign.SignSISS';
const DOCUMENT_TYPE = "PDF";
const SIGNATURE_TYPE = "LEGALE_OPERATORE";


function requestBody(params) {
  return {
    document_list: {
      document: [
        {
          document_type: DOCUMENT_TYPE,
          signature_type: SIGNATURE_TYPE,
          
          // byte array of "PDF" document
          data: params.data
        }
      ]
    }
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params));
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response);
    },
    error: (response) => {
      _.attempt(options.error, response);
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  })
}

export default {
  promise,
  call
}