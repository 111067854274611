<script>
import Dropzone from 'dropzone'

export default {
  data() {
    return {
      dropzone: null,
      autoClearQueue: true,
      orientation: null
    }
  },

  methods: {
    _getPreviewHTML() {
      return /*html*/`
        <div class="dz-preview-box">
          <div class="dz-preview dz-file-preview">
            <div class="dz-details">
              <div class="dz-filename"><span data-dz-name></span></div>
              <div class="dz-size" data-dz-size></div>
              <img data-dz-thumbnail />
            </div>
            <div class="dz-progress">
              <span class="dz-upload" data-dz-uploadprogress></span>
            </div>
            <div class="dz-success-mark"><span>✔</span></div>
            <div class="dz-error-mark"><span>✘</span></div>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
          </div>  
        </div>
      `
    },

    initDropzone({
      element,
      url,
      clickable,
      previewDiv,
      orientation,
      autoUpload = true,
      message = null
    }) {
      this.orientation = orientation

      // creating the dropzone imperatively
      // look at: https://docs.dropzone.dev/getting-started/setup/imperative
      //
      // configuration options are described at the following
      // https://docs.dropzone.dev/configuration/basics/configuration-options
      this.dropzone = new Dropzone(element, {
        // dropzone POST url
        // the default param name that gets transferred from dropzone is [file]
        url,
        acceptedFiles: 'application/pdf',

        // instead of clickable, you can also pass a HTML element
        // in that case, that element will trigger an upload when clicked
        clickable,
        createImageThumbnails: false,

        previewsContainer: previewDiv,
        previewTemplate: this._getPreviewHTML(),

        parallelUploads: 1,
        autoProcessQueue: autoUpload,
        autoQueue: autoUpload,

        dictDefaultMessage: message,
        dictInvalidFileType: "Tipo di file non supportato. Sono supportati solo file pdf",
        dictFileTooBig: "File troppo grande: {{filesize}}MB. Dimensione massima impostata: {{maxFilesize}}MB.",
        dictMaxFilesExceeded: "Numero di files massimo raggiunto ({{maxFiles}})"
      })

      // sending event
      this.dropzone.on('sending', (file, xhr, formData) => {
        var _send = xhr.send

        // to send raw file
        xhr.send = function () {
          _send.call(xhr, file)
        };

        // authenticity token
        // formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'))
      })

      // EXAMPLE OF REQUIRED RESPONSE FROM SERVICE:
      // [
      //   {
      //     "page_num": 1,
      //     "page_path": "data/tmp8lqh4_e2/outputc_0001",
      //     "preview_path": "/api/preview/data/tmp8lqh4_e2/outputc_0001"
      //   }
      // ]
      this.dropzone.on('success', (file, response) => {
        const t = file
        
        // mapping all pages
        const newPages = _.map(response, p => {
          return {
            'id': p.page_path,
            'category_id': null,
            'order_index': p.page_num,
            'preview_path': p.preview_path,
            'original_name': t.name
          }
        });

        this.$emit('insertNewPages', newPages)

        if (this.autoClearQueue) {
          let propertyToAnimate = 'width'

          if (this.orientation === 'vertical') propertyToAnimate = 'height'

          let animation = {}
          animation[propertyToAnimate] = 0

          $(file.previewElement).fadeTo(1200, 0, function () {
            $(this).addClass('closable').animate(animation, 500, function () {
              $(this).remove()
            })
          })
        }
      })

      this.dropzone.on('error', (file, error) => {
        this.dropzone.removeFile(file)

        // TODO to be generalized with a callback
        this.$store.commit('showDialogError', error);
      })
    }
  },
}
</script>