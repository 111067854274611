<template>
  <div id="form-uploader">
    <DocumentSplitter
      v-model="documents"
      :categories="categories"
      :splitUrl="splitUrl"
      :joinUrl="joinUrl"
      :previewUrl="previewUrl"
      :disabled="disabled"
      ref="document-splitter"
    />
  </div>
</template>

<script>
// components
import DocumentSplitter from "../document_splitter/DocumentSplitter.vue";
// config
import AppConfigStore from "services/commands/stores/app_config_store";
import DetailsUtil from "services/details_util";

export default {
  components: {
    DocumentSplitter
  },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      pratica: _.cloneDeep(this.praticaDetails),
      categories: DetailsUtil.getDocumentCategories(this.praticaDetails),
      documents: [],
    };
  },

  computed: {
    documentNameMap() {
      const { nome } = this.pratica.anagrafica_deceduto;
      const { cognome } = this.pratica.anagrafica_deceduto;
      const fullName = `${cognome} ${nome}`;

      const docNameMap = {}

      _.forEach(this.categories, c => {
        docNameMap[c.document_type] = `${c.document_name} di ${fullName}`
      })

      return docNameMap;
      // return {
      //   'ComunicazioneDecesso': `Comunicazione di decesso di ${fullName}`,
      //   'ModuloIstatA': `Istat (Parte A) di ${fullName}`,
      //   'RelazioneMorte': `Relazione di Morte di ${fullName}`,
      //   'AccertamentoMorte': `Accertamento di Morte di ${fullName}`,
      //   'NullaOsta': `Nulla Osta di ${fullName}`,
      //   'ModuloTrasporto': `Modulo di Trasporto di ${fullName}`,
      //   'ModuloIstatB': `Istat di ${fullName}`
      // };
    },

    splitUrl: () => AppConfigStore.pdfServiceSplitUrl(),
    previewUrl: () => AppConfigStore.pdfServicePreviewUrl(),
    joinUrl: () => AppConfigStore.pdfServiceJoinUrl(),
  },

  methods: {
    getNameFromDocumentType(docType) {
      return this.documentNameMap[docType] || docType;
    },

    async validate() {
      if (await this.$refs['document-splitter'].validate()) {
        let newDocs = [];

        for(const doc of this.documents) {
          newDocs.push(this._mapDocument(doc));
        }

        // Not working because emit must be invoked before validation
        // Not possible to invoke before to avoid unnecessary pdf join
        // this.pratica.documenti.documento.push(...newDocs);
        // this.$emit('pratica-updated', this.pratica);
        // FIXME: find another way to do so
        this.$emit('documents-generated', newDocs);
        
        return true;
      }
      this.$store.commit('showDialogWarning',
        "E' necessario creare un documento per ogni sezione obbligatoria."
      );
      return false;
    },

    _mapDocument(doc) {
      return {
        data: doc.data,
        name: this.getNameFromDocumentType(doc.document_type),
        description: doc.document_type,
        tipo_documento: doc.document_type,
        type: '.pdf',
        stato: 'Undefined',
        is_signed: true,
        is_analogico: true,
        copia_conforme_scansione: true,
      };
    },
  },
}
</script>