var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-information" } },
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("v-divider"),
          _c("br"),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "custom-card-text" }, [
            _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-confirm",
                  attrs: { outlined: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onConfirmClick()
                    },
                  },
                },
                [_vm._v("\n        OK\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }