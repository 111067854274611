<template>
  <div id="form-relazione">
    <c-expansion-sections
      :disabled="disabled"
      :sections="[
        'DECEDUTO',
        'CONDIZIONI MORBOSE',
        'MORTE TRAUMATISMO/AVVELENAMENTO'
      ]"
    >
      <!-- "DECEDUTO" section -->
      <template #section1>
        <SectionDeceduto
          :anagrafica-deceduto="pratica.anagrafica_deceduto"
          :comunicazione-decesso="pratica.comunicazione_decesso"
          :show-cittadinanza="false"
          :show-via-civico-residenza="false"
          :show-comune-provincia-residenza="false"
        />

        <!-- "Numero del Registro" section -->
        <v-row v-if="isNumeroRegistroVisible" no-gutters class="ma-2 mt-6">
          <v-col cols="5">
            <!-- "Numero del Registro" -->
            <c-text-field
              v-model="module.numero_registro"
              :rules="isNumeroRegistroRequired ? requiredRule : [true]"
              label="Numero del Registro"
              prepend-icon="mdi-clipboard-list-outline"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "CONDIZIONI MORBOSE" section -->
      <template #section2>
        <!-- "Sequenza di condizioni morbose" -->
        <c-condition-list
          v-model="module.condizioni.condizione_morbosa"
          :description="descriptionCondizioniNonPreesistenti"
          :isPreesistente="false"
          :rules="requiredRule"
          :disabled="disabled"
          :minNotPre="minNotPre"
          :maxNotPre="maxNotPre"
          :minPre="minPre"
          :maxPre="maxPre"
        />

        <!-- "Condizioni morbose pre-esistenti" -->
        <c-condition-list v-model="module.condizioni.condizione_morbosa"
          :description="descriptionCondizioniPreesistenti"
          :isPreesistente="true"
          :rules="requiredRule"
          :disabled="disabled"
          :minNotPre="minNotPre"
          :maxNotPre="maxNotPre"
          :minPre="minPre"
          :maxPre="maxPre"
        />
      </template>
      
      <!-- "MORTE TRAUMATISMO/AVVELENAMENTO" -->
      <template #section3>
        <div class="morte-avvelenamento-col">
          <!-- <v-icon>{{ iconMorteTraumaAvvelenamento }}</v-icon> -->
          Morte da traumatismo/avvelenamento:
          
          <c-switch
            v-model="morteTraumaAvvelenamento"
            :label="morteTraumaAvvelenamento ? 'SI' : 'NO'"
          />
        </div>

        <v-form :disabled="disabled || !morteTraumaAvvelenamento">
          
          <!-- "Circostanza" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="12">
              <c-text-field
                v-model="circostanzaTraumatismoAvvelenamento"
                :rules="requiredRule"
                :disabled="!morteTraumaAvvelenamento"
                label="Circostanza"
                prepend-icon="mdi-text"
              />
            </v-col>
          </v-row>

          <v-row no-gutters class="ma-2">

            <!-- "Modalità" -->
            <v-col cols="5" class="modalita-morte-col">
              <c-select
                v-model="modalitaTraumatismoAvvelenamento"
                :disabled="!morteTraumaAvvelenamento"
                :rules="requiredRule"
                :items="listModalitaMorte"
                item-text="text"
                item-value="key"
                label="Modalità"
                prepend-icon="mdi-format-list-bulleted-square"
              />
            </v-col>
            
            <v-spacer></v-spacer>

            <!-- "Infortunio sul lavoro" -->
            <v-col cols="5" class="morte-lavoro-col">
              <v-icon :disabled="!morteTraumaAvvelenamento" class="pl-1 pb-3">mdi-account-hard-hat</v-icon>
              Infortunio sul lavoro:

              <c-switch
                v-model="lavoro"
                :disabled="!morteTraumaAvvelenamento"
                :label="lavoro ? 'SI' : 'NO'"
              />
            </v-col>
          </v-row>

          <!-- "Luogo accidente"-->
          <v-row no-gutters class="ma-2">
            <v-col cols="12">
              <c-select
                v-model="luogoAccidente"
                :disabled="!morteTraumaAvvelenamento"
                :rules="requiredRule"
                :items="listLuogoAccidente"
                item-text="text"
                item-value="key"
                label="Luogo accidente"
                prepend-icon="mdi-crosshairs-gps"
              />
            </v-col>
          </v-row>

          <!-- "Specificare Altro Luogo Accidente" -->
          <transition name="slide-fade">
            <v-row no-gutters class="ma-2" v-show="showSpecificareLuogoAccidente">
              <v-col cols="12">
                <c-text-field
                  v-model="altroLuogoAccidente"
                  :rules="requiredRule"
                  label="Specificare altro luogo accidente"
                  prepend-icon="mdi-text"
                />
              </v-col>
            </v-row>
          </transition>
        </v-form>
      </template>
    </c-expansion-sections>

    <!-- "Riscontro Diagnostico" and "Nulla Osta" section -->
    <v-container>
      <v-row no-gutters class="ma-2">

        <!-- "Riscontro Diagnostico Richiesto" -->
        <v-col cols="5" class="riscontro-diagnostico-col">
          <v-icon class="pl-1">{{ iconRiscontroDiagnostico }}</v-icon>
          Riscontro diagnostico richiesto:

          <c-switch
            v-model="riscontroDiagnostico"
            :label="riscontroDiagnostico ? 'SI' : 'NO'"
          />
        </v-col>

        <v-spacer></v-spacer>

        <!-- "Nulla Osta Richiesto" -->
        <v-col cols="5" class="nulla-osta-col">
          <v-icon class="pl-1">{{ iconNullaOsta }}</v-icon>
          Nulla osta richiesto:

          <c-switch
            v-model="nullaOsta"
            :label="nullaOsta ? 'SI' : 'NO'"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />

    <!-- Dialog Condizioni Morte Violenta -->
    <dialog-condizioni-morte-violenta
      v-model="dialogCondizioniMorteViolenta"
      :conditions="condizioniMorteViolenta"
      @edit-conditions="onEditConditionsEvent"
      @confirm-conditions="onConfirmConditionsEvent"
      @morte-violenta-conditions="onMorteViolentaConditionsEvent"
    />

    <!-- Dialog Condizioni Riscontro Autoptico -->
    <dialog-condizioni-riscontro-autoptico
      v-model="dialogCondizioniRiscontroAutoptico"
      :condition="condizioneRiscontroAutoptico"
      :maxNewConditionsNumber="maxNotPre - 1"
      @delete-condition="onDeleteConditionEvent"
      @open-new-conditions="onOpenNewConditionsEvent"
      @riscontro-autoptico-conditions="onRiscontroAutopticoConditionsEvent"
    />
  </div>
</template>

<script>
// stores
import {
  AppConfigStore,
  ListLuogoAccidenteStore,
  ListModalitaMorteStore,
  ListMetaStore,
} from 'services/commands/stores'
// services
import PraticaUtil from 'services/pratica_util';
// components
import SectionDeceduto from '../SectionDeceduto';
import condizioniMorbose from 'mixins/condizioni_morbose';

export default {
  name: 'FormRelazione',

  mixins: [condizioniMorbose],

  components: { SectionDeceduto },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixRelazione(this.praticaDetails);

    return {
      pratica: pratica,

      // 'module' instead of 'rm' required from [condizioniMorbose] mixin
      module: pratica.relazione_morte,

      // Maximum and minimum lenght of conditions 'non preesistenti'
      minNotPre: AppConfigStore.getNumeroMinimoCondizioniMorbose(),
      maxNotPre: AppConfigStore.getNumeroMassimoCondizioniMorbose(),

      // Maximum and minimum lenght of conditions 'preesistenti'
      minPre: AppConfigStore.getNumeroMinimoCondizioniMorbosePreesistenti(),
      maxPre: AppConfigStore.getNumeroMassimoCondizioniMorbosePreesistenti(),

      requiredRule: [ value => !!value || 'Obbligatorio'],

      dialogError: false,
      errors: [],
    }
  },

  computed: {
    descriptionCondizioniNonPreesistenti() {
      return `Sequenza di condizione morbose (massimo ${this.maxNotPre}), lesioni o avvelenamenti che hanno
        condotto alla morte, dove l'ultima descritta è quella che ha causato la morte`;
    },

    descriptionCondizioniPreesistenti() {
      return `Condizioni morbose pre-esistenti (massimo ${this.maxPre})`;
    },

    isNumeroRegistroVisible() {
      const isNumeroRegistroVisible = ListMetaStore.getMetaValue('VISIBILITY_FIELDS', 'relazione_numero_registro');
      return isNumeroRegistroVisible == '1';
    },

    isNumeroRegistroRequired() {
      const isNumeroRegistroRequired = ListMetaStore.getMetaValue('MANDATORY_FIELDS', 'relazione_numero_registro');
      return isNumeroRegistroRequired == '1';
    },

    iconMorteTraumaAvvelenamento() {
      return this.morteTraumaAvvelenamento ? "mdi-bottle-tonic-skull-outline" : "mdi-bottle-tonic-skull";
    },

    iconNullaOsta() {
      return this.nullaOsta ? "mdi-check-decagram" : "mdi-check-decagram-outline";
    },

    iconRiscontroDiagnostico() {
      return this.riscontroDiagnostico ? "mdi-needle" : "mdi-needle-off";
    },

    // "Lavoro" field of the form.
    lavoro: {
      get() {
        return this.morteTraumaAvvelenamento
          && this.module.infortunio_lavoro_traumatismo_avvelenamento.toUpperCase() == 'SI';
      },
      set(newValue) { this.module.infortunio_lavoro_traumatismo_avvelenamento = newValue ? 'SI' : 'NO' }
    },
    
    // "Morte da traumatismo/avvelenamento" field of the form.
    morteTraumaAvvelenamento: {
      get() { return this.module.morte_per_traumatismo_avvelenamento.toUpperCase() == 'SI' },
      set(newValue) { this.module.morte_per_traumatismo_avvelenamento = newValue ? 'SI' : 'NO' }
    },

    // "Riscontro Diagnostico" field of the form.
    riscontroDiagnostico: {
      get() { return this.module.richiesto_riscontro_diagnostico.toUpperCase() == 'SI' },
      set(newValue) { this.module.richiesto_riscontro_diagnostico = newValue ? 'SI' : 'NO' },
    },

    // "Nulla Osta" field of the form.
    nullaOsta: {
      get() { return this.module.richiesto_nulla_osta.toUpperCase() == 'SI' },
      set(newValue) { this.module.richiesto_nulla_osta = newValue ? 'SI' : 'NO' },
    },

    // If true, it shows the "Specifica Luogo Accidente" field of the form.
    showSpecificareLuogoAccidente() {
      if (this.module.luogo_accidente.toUpperCase() == 'ALTRO') {
        return true;
      } else {
        this.module.altro_luogo_accidente = "";
        return false;
      }
    },

    // "conditional" fields
    circostanzaTraumatismoAvvelenamento: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.circostanza_traumatismo_avvelenamento : '';
      },
      set(value) { this.module.circostanza_traumatismo_avvelenamento = value },
    },

    modalitaTraumatismoAvvelenamento: {
      get() {
        return this.module.modalita_traumatismo_avvelenamento ? this.module.modalita_traumatismo_avvelenamento : '';
      },
      set(value) { this.module.modalita_traumatismo_avvelenamento = value },
    },

    luogoAccidente: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.luogo_accidente : '';
      },
      set(value) { this.module.luogo_accidente = value },
    },

    altroLuogoAccidente: {
      get() {
        return this.morteTraumaAvvelenamento ? this.module.altro_luogo_accidente : '';
      },
      set(value) { this.module.altro_luogo_accidente = value },
    },

    ///
    /// Utilities computed values.
    ///
    listLuogoAccidente: () => _.toArray(ListLuogoAccidenteStore.getLuogoAccidente()),
    listModalitaMorte: () => _.toArray(ListModalitaMorteStore.getModalitaMorte()),
  },

  watch: {
    // Watch used to notify parent component when 'pratica' updates.
    'pratica.relazione_morte': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },
  },

  methods: {
    ///
    /// Exposed methods.
    ///
    validate() {
      let errors = [];
      
      let preCount = 0;
      let notPreCount = 0;

      if (this.isNumeroRegistroVisible && this.isNumeroRegistroRequired && !this.module.numero_registro)
        errors.push("Numero del Registro");

      this.module.condizioni.condizione_morbosa.forEach((c, index) => {
        c.malattia || errors.push(`Malattia della ${(index + 1)}ᵃ condizione morbosa`);
        if (c.is_preesistente)
          preCount++;
        else
          notPreCount++;
      });

      if (preCount < this.minPre)
        errors.push(`Sono necessarie almeno ${this.minPre} condizioni preesistenti`);

      if (notPreCount < this.minNotPre)
        errors.push(`Sono necessarie almeno ${this.minNotPre} condizioni non preesistenti`);

      if (this.morteTraumaAvvelenamento) {
        this.module.circostanza_traumatismo_avvelenamento || errors.push("Circostanza di morte");

        if (this.showSpecificareLuogoAccidente && !this.module.altro_luogo_accidente)
          errors.push("Altro luogo accidente");
      }

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }

      // checking "morte violenta" conditions
      if (this.condizioniMorteViolenta.length) {
        this.dialogCondizioniMorteViolenta = true;
        return new Promise((resolve) => { this.resolveValidation = resolve });
      }

      // checking "riscontro autoptico" conditions
      if (this.condizioneRiscontroAutoptico) {
        this.dialogCondizioniRiscontroAutoptico = true;
        return new Promise((resolve) => { this.resolveValidation = resolve });
      }

      this._clearUnusedFields();
      return true;
    },

    // Clear fields if "morteTraumaAvvelenamento" is disabled.
    _clearUnusedFields() {
      if (!this.morteTraumaAvvelenamento) {
        this.module.circostanza_traumatismo_avvelenamento = "";
        this.module.modalita_traumatismo_avvelenamento = 'Accidentale';
        this.module.infortunio_lavoro_traumatismo_avvelenamento = 'NO';
        this.module.luogo_accidente = 'Casa';
        this.module.altro_luogo_accidente = "";
      }
    }
  },
}
</script>
