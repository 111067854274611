// utils
import CommandUtil from "services/commands/core/command_util";
// services
import WebInterop from 'services/webinterop';
// components
import DialogPrintPraticheList from "components/dialog/DialogPrintPraticheList";
import DialogAccept from "components/details/dialog/DialogAccept";
import DialogRefuse from "components/details/dialog/DialogRefuse";
// store
import { AppConfigStore } from 'services/commands/stores';

export default {
  components: {
    DialogPrintPraticheList,
    DialogAccept,
    DialogRefuse,
  },

  data() {
    return {
      // Print Pratiche list
      showPrintPraticheList: false,
      printPraticheList: [],

      showAcceptDialog: false,
      showRefuseDialog: false,

      acceptanceActionId: 0,
    };
  },

  methods: {
    onWebInteropError() {
      this.$store.commit('showDialogError',
        `
          Errore di rete: verificare di aver installato correttamente
          il plugin "WebInterop" o contattare l'assistenza tecnica.
        `
      );
    },

    onReadDocumentError() {
      this.$store.commit('showDialogError',
        `
          Si è verificato un errore durante il recupero di un documento da firmare.
          Riprovare, o contattare l'assistenza tecnica.
        `
      );
    },

    onSignSISSError() {
      this.$store.commit('showDialogError',
        `
          Si è verificato un errore durante la firma digitale dei documenti.
          Controllare che la carta SISS sia inserita correttamente nell'apposito lettore,
          che l'autenticazione al servizio SISS abbia avuto successo,
          e/o che il PIN di firma inserito sia corretto.
        `
      );
    },

    onSignNormalError(successCount, errorCount) {
      this.$store.commit('showDialogError',
        `
          Si è verificato un errore durante la firma digitale di alcuni documenti:
          ${successCount} firmati correttamente, ${errorCount} firmati in errore.
        `
      );
    },

    onSignNormalFatalError(fatalError) {
      this.$store.commit('showDialogError',
        `
          Errore fatale durante la firma digitale dei documenti:
          \n${fatalError}
        `
      );
    },

    onWriteDocumentError() {
      this.$store.commit('showDialogError',
        `
          Si è verificato un errore durante il salvataggio di un documento firmato.
          Riprovare, o contattare l'assistenza tecnica.
        `
      );
    },

    // method that deletes selected pratica using 'deletePratica' command.
    async deleteSelectedPratiche(selectedPraticheIds, onPraticheDeleted) {
      for (const id of selectedPraticheIds)
        await CommandUtil.deletePratica(id);

      _.attempt(onPraticheDeleted);
      this._CMDListPraticaInfo();
    },
    
    // documents sample structure:
    //
    // [
    //   {
    //     code: 1,
    //     name: "NAME_DOC_1",
    //     docIn: "IDENTIFIER_DOC_1",
    //     docOut: ""
    //   },
    //   {
    //     code: 2,
    //     name: "NAME_DOC_2",
    //     docIn: "IDENTIFIER_DOC_2",
    //     docOut: ""
    //   }
    // ]
    async signNormal(action, praticaId, documents) {
      let error = false;

      if (!AppConfigStore.isWebInteropPluginEnabled()) {
        error = true;
      } else {
        // verify that WebInterop is available
        await WebInterop.info().catch(() => {
          error = true;
        });
      }
      
      if (error) {
        this.onWebInteropError();
        return false;
      }

      // summary of the sign operation
      let summary = {
        success: 0,
        error: 0,
        fatal_error: null
      };

      for (let i = 0; i < documents.length; i++) {
        let doc = documents[i];
        const pinCode = (i == documents.length - 1) ? '!' : '?';
        
        const responses = await CommandUtil.signNormal(
          doc.docIn,
          praticaId,
          action,
          doc.name,
          doc.code,
          pinCode
        );

        // checking response status
        if (responses[3]?.status == 'success') {
          summary.success ++;
        } else {
          if (responses.errore) {
            if (responses.errore[0].codice == 'PROC-900') {
              summary.fatal_error = responses.errore[0].messaggio;
              break;
            }
            summary.error ++;
          }
        }
      }

      // displaying dialog based on response status
      if (summary.fatal_error) {
        this.onSignNormalFatalError(summary.fatal_error);
        this._CMDListPraticaInfo();
        return false;
      } else if (summary.error > 0) {
        this.onSignNormalError(summary.success, summary.error);
        return true;
      }

      return true;
    },

    // documents sample structure:
    //
    // [
    //   {
    //     code: 1,
    //     docIn: "IDENTIFIER_DOC_1",
    //     docOut: ""
    //   },
    //   {
    //     code: 2,
    //     docIn: "IDENTIFIER_DOC_2",
    //     docOut: ""
    //   }
    // ]
    async signSISS(action, praticaId, documents) {
      // outDocuments sample structure:
      // [
      //   {
      //     key: 'docOut1',
      //     value: 'NEW_IDENTIFIER_DOC_1|.pdf.p7m|DocumentoFirmato'
      //   }
      // ]
      let outDocuments = [];
      let response;

      for (const doc of documents) {
        const identifier = doc.docIn;
        
        try {
          response = await CommandUtil.signSISS(identifier);
        } catch (err) {
          this.onWebInteropError();
          this._CMDListPraticaInfo();
          return false;
        }

        if (!this._validateSignSISSResponse(response)) {
          return false;
        }
        
        // newIdentifier sample structure:
        // {
        //   "document_identifier": "JXMQJWH5U9BYOSDT"
        // }
        const newIdentifier = await CommandUtil.writeDocument(response.signed_document_list.signed_document[0]);

        if (!newIdentifier?.document_identifier) {
          this.onSignSISSError();
          this._CMDListPraticaInfo();
          return false;
        }

        let outDoc = {
          key: `docout${doc.code}`,
          value: `${newIdentifier.document_identifier}|.pdf.p7m|DocumentoFirmato`,
        };

        outDocuments.push(outDoc);
      }

      await CommandUtil.actionComplete(
        praticaId,
        action,
        outDocuments,
      );

      return true;
    },

    _validateSignSISSResponse(response) {
      let error = false;

      // read document error
      if (!response) {
        this.onReadDocumentError();
        error = true;
      }

      // sign siss error
      else if (!response.signed_document_list?.signed_document?.length) {
        this.onSignSISSError();
        error = true;
        
      }
      else if (!response.signed_document_list.signed_document[0]?.data) {
        this.onWriteDocumentError();
        error = true;
      }

      if (error) {
        this._CMDListPraticaInfo();
        return false;
      }
      return true;
    },

    // Exposed method used to update documents after sign command invoked.
    async onSignCompleted(processIds) {
      await this._CMDWaitForManualActivity({ processIds: processIds });
      await this._CMDListPraticaInfo();
    },

    // Exposed method used to invoke GET_PRINT_PRATICHE_LIST comamnd.
    async showPrintablePratiche() {
      // CMD get print pratiche list
      const response = await this._CMDGetPrintPraticheList();
      if (response) {
        this.printPraticheList = response;
        this.showPrintPraticheList = true;
      }
    },

    // Exposed method used to display accept dialog (Comune).
    async accept(actionId) {
      this.acceptanceActionId = actionId;
      const idsNumber = this.$store.getters.selectedPraticaIds.length;

      if (idsNumber == 1) {
        this.showAcceptDialog = true;
      } else {
        this.$store.commit(
          'showDialogError',
          `L'accettazione può essere eseguita solo su una pratica alla volta.`
        );
      }
    },

    // Exposed method used to display refuse dialog (Comune).
    async refuse(actionId) {
      this.acceptanceActionId = actionId;
      this.showRefuseDialog = true;
    },

    // Method invoked when refuse pratica is confirmed.
    async onConfirmAcceptEvent(year, number) {
      const id = this.$store.getters.selectedPraticaId;
      await CommandUtil.acceptPratica(id, this.acceptanceActionId, year, number);
      this._CMDListPraticaInfo();
    },

    // Method invoked when accept pratica is confirmed.
    async onConfirmRefuseEvent(message) {      
      const ids = this.$store.getters.selectedPraticaIds;
      await CommandUtil.refusePratiche(ids, this.acceptanceActionId, message);
      this._CMDListPraticaInfo();
    },

    ///
    /// Utilities methods
    ///
    
    /**
     * It performs ACTION_PRATICHE command
     * @param { Options } options
     *        Options attributes:
     *        - enter_edit_mode
     *        - praticaId
     *        - action_id
     *        - year
     *        - number
     */
    _CMDActionPratiche: async (options = {}) => await CommandUtil.actionPratiche(
      options.praticaId,
      options.action_id,
      options.year,
      options.number
    ),

    // It performs PRINT_PRATICHE_LIST command
    _CMDGetPrintPraticheList: async () => await CommandUtil.getPrintPraticheList(),

    // It performs LIST_PRATICA_INFO command
    _CMDListPraticaInfo: async (options = {}) => await CommandUtil.listPraticaInfo(options),

    // It performs NEW_PRATICA command
    _CMDNewPratica: async () => await CommandUtil.newPratica(),

    /**
     * It performs READ_PRATICA command
     * @param { Integer } options.id of the pratica
     */
    _CMDReadPratica: async (options = {}) => await CommandUtil.readPratica(options.id),

    /**
     * It performs RICERCA_RICOVERI command
     * @param { String } options.codicePaziente
     */
    _CMDRicercaRicoveri: async (options = {}) => await CommandUtil.ricercaRicoveri(options.codicePaziente),
    
    // It performs WRITE_PRATICA command
    _CMDWritePratica: async (options = {}) => await CommandUtil.writePratica(options.pratica, options.userID),

    /**
     * It performs WAIT_FOR_MANUAL_ACTIVITY command
     * @param { Integer } options.processIds of signed pratiche
     */
    _CMDWaitForManualActivity: async (options = {}) => await CommandUtil.waitForManualActivity(options.processIds),
  }
}