var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "auto",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "c-text-field",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "##/##/####",
                          expression: "'##/##/####'",
                        },
                      ],
                      attrs: {
                        label: _vm.label,
                        rules: _vm.rules,
                        placeholder: "GG/MM/AAAA",
                        "prepend-icon": "mdi-calendar",
                      },
                      model: {
                        value: _vm.dateFormatted,
                        callback: function ($$v) {
                          _vm.dateFormatted = $$v
                        },
                        expression: "dateFormatted",
                      },
                    },
                    "c-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDatePicker,
        callback: function ($$v) {
          _vm.showDatePicker = $$v
        },
        expression: "showDatePicker",
      },
    },
    [
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: { min: _vm.minDate, max: _vm.maxDate, "no-title": "" },
        on: {
          input: function ($event) {
            _vm.showDatePicker = false
          },
        },
        model: {
          value: _vm.dateISO,
          callback: function ($$v) {
            _vm.dateISO = $$v
          },
          expression: "dateISO",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }