let LIST_UNIT_ROLE_RESOURCE = {}

function init(jsonListMeta) {
    LIST_UNIT_ROLE_RESOURCE = {}
    
    _.forEach(jsonListMeta.unit_role_resource_list.unit_role_resource, unit => {
        LIST_UNIT_ROLE_RESOURCE[unit.id] = _.reduce(unit, function(result, value, key) {
            if (key != 'id') {
                result[key] = value
            }

            return result;
        }, {});
    })
}

function getUserById(id) {
    return LIST_UNIT_ROLE_RESOURCE[id]
}

function getNameSurnameById(id) {
    let user = getUserById(id)
    return (user.resource.name == null ? "" : user.resource.name) + " " + user.resource.surname
}

export default {
    init,
    getUserById,
    getNameSurnameById
}