var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-header-container" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "7", "align-self": "center" } }, [
            _c("h4", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("p", { staticClass: "form-header-subtitle" }, [
              _c("span", [_vm._v(_vm._s(_vm.subtitle))]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.showInfo
            ? _c(
                "v-col",
                { attrs: { cols: "5", "align-self": "center" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "v-card-info", attrs: { variant: "tonal" } },
                    [
                      _c("p", [_c("b", [_vm._v(_vm._s(_vm.description))])]),
                      _vm._v(" "),
                      _c("p", [_c("span", [_vm._v(_vm._s(_vm.info))])]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }