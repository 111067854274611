<template>
  <div v-if="startup" id="action-bar" class="d-flex align-center">
    <!-- List of Actions -->
    <div v-for="action in documentAction"
      :key="action.nome"
      @click="fireAction(action)"
    >

      <v-btn :title="getActionTitle(action)" color="primary" elevation="3" class="ml-2">
        <v-icon dark left>{{ getActionIcon(action) }}</v-icon>
        {{ getActionName(action) }}
      </v-btn>
    </div>

    <v-spacer />

    <!-- Intersail Engineering logo -->
    <img src="images/logo_intersail.png" alt="Logo intersail" class="img-logo-intersail">
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
// services
import { ICONS } from 'services/icon_util';
import ListActionStore from 'services/commands/stores/list_action_store';

const FILTERS = {
  "edit": (item) => item.decoration?.IS_EDIT_ACTION == "1" || item.decoration?.IS_VIEW_ACTION == "0",
  "view": (item) => item.decoration?.IS_VIEW_ACTION == "1" || item.decoration?.IS_EDIT_ACTION == "0",
}

export default {
  name: 'ActionBar',

  props: {
    mode: {
      type: String,
      required: true,
    },
    selection: {
      required: true
    }
  },

  data() {
    return {
      persistentAction: [],
      documentAction: [],
    }
  },

  watch: {
    'selection'() {
      if (_.isEmpty(this.selection) || _.isEmpty(this.selection[0])) {
        this.documentAction = this.persistentAction;
        return;
      }

      let allDocAction = []
      let action = []

      // WARN: non usare la selezione, es: per i dettagli devo usare un oggetto pratica impostato dopo Caronte.ReadPratica
      _.forEach(this.selection, function (obj) {
        allDocAction.push(obj.azioni.action)
      })
      for (let i = 0; i < allDocAction.length; i++) {
        if (i === 0) {
          action.push(allDocAction[0])
        }
        else {
          action[0] = _.intersectionWith(action[0], allDocAction[i], function (item1, item2) {
            if (item1['manager']['cardinality'] === "SINGLE" || (item2['manager']['cardinality'] === "SINGLE")) {
              return false
            } else {
              if (item1['manager']['id'] === item2['manager']['id']) {
                if (item1['manager']['name'] === item2['manager']['name']) {
                  return true
                }
              }
            }
            return false
          })
        }
      }

      this.documentAction = action[0].filter(FILTERS[this.mode]).sort((a, b) => {
        return a.decoration.ORDER - b.decoration.ORDER
      })
    }
  },
  
  computed: {
    ...mapGetters([
      'startup',
    ]),
  },

  methods: {
    ///
    /// Action utilities methods
    ///
    getActionName: (action) => action.decoration.NAME ?? action.name,
    getActionTitle: (action) => action.decoration.DESCRIPTION ?? action.descrizione,
    getActionIcon(action) {
      let id = action.decoration.ICON
      return ICONS[id] ? ICONS[id].mdi : ICONS.ICO_DEFAULT.mdi
    },

    // Action Button Clicked handler
    fireAction(action) {
      this.$emit('action-button-clicked', action)
    }
  },

  mounted() {
    this.persistentAction = _.toArray(ListActionStore.getListAction());
    this.documentAction = this.persistentAction;
  }
}
</script>