var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-ricoveri" } },
        [
          _c("v-card-title", [_vm._v("Seleziona paziente")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ag-grid-form" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-alpine",
                staticStyle: { width: "100%", height: "300px" },
                attrs: {
                  defaultColDef: _vm.defaultColDef,
                  columnDefs: _vm.columnDefs,
                  gridOptions: _vm.gridOptions,
                  rowData: _vm.ricoveriList,
                  overlayNoRowsTemplate: "Nessun ricovero",
                },
                on: { "grid-ready": _vm.onGridReady },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-action",
                  attrs: { outlined: "" },
                  on: { click: _vm.onCancelClick },
                },
                [_vm._v("\n        ANNULLA\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  class: _vm.selected && "btn-action",
                  attrs: { disabled: !_vm.selected, outlined: "" },
                  on: { click: _vm.onConfirmClick },
                },
                [_vm._v("\n        OK\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }