var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      attrs: { persistent: "", inset: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-interop-error" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [_vm._v("Attenzione")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-checkbox", {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    staticClass: "px-1 ma-0",
                    attrs: { label: "Non mostrare più", "hide-details": "" },
                    model: {
                      value: _vm.doNotShowAgain,
                      callback: function ($$v) {
                        _vm.doNotShowAgain = $$v
                      },
                      expression: "doNotShowAgain",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass: "btn-close",
                      attrs: { text: "", outlined: "", small: "", icon: "" },
                      on: { click: _vm.onCloseClick },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _c("br"),
          _vm._v(" "),
          _c("v-card-text", [
            _c(
              "p",
              { staticClass: "text" },
              [
                _vm._v(
                  "\n        Non è stato trovato il plugin locale.\n        "
                ),
                _vm.isWindows
                  ? [
                      _c("a", { on: { click: _vm.onAddPluginClick } }, [
                        _c("span", [_c("b", [_vm._v(_vm._s(_vm.text))])]),
                      ]),
                    ]
                  : [_c("br"), _vm._v(_vm._s(_vm.text) + "\n        ")],
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }