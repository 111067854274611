<template>
  <div>
    <v-row no-gutters class="ma-2">

      <!-- "Reparto" text field -->
      <v-col cols="5">
        <c-select
          v-model="cd.reparto"
          :items="listReparto"
          :disabled="!comboboxRepartoEnabled"
          item-text="name"
          item-value=""
          label="Reparto"
          prepend-icon="mdi-hospital-building"
          return-object
        />
      </v-col>

      <v-spacer></v-spacer>

      <!-- "Numero Letto" text field -->
      <v-col cols="5">
        <c-text-field
          label="Numero Letto"
          v-model="cd.numero_letto"
          type="number"
          prepend-icon="mdi-bed"
          hide-spin-buttons
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="ma-2">

      <!-- Date picker "Data di Morte" -->
      <v-col cols="5">
        <c-date-picker v-model="dataMorteISO" label="Data di Morte" :rules="requiredRule" />
      </v-col>

      <v-spacer></v-spacer>

      <!-- Time picker "Orario di Morte" -->
      <v-col cols="5">
        <c-time-picker v-model="oraMorteFormatted" label="Orario di Morte" :rules="requiredRule" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ListRepartoStore,
  UserInformationStore,
} from "services/commands/stores";
// services
import DateUtils from "services/date_util";

export default {
  name: "SectionDatiMorte",

  props: {
    cd: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      requiredRule: [(value) => !!value || "Obbligatorio."],

      // necessary to validate time
      timeValid: true,
      dialogError: false,
      errors: [],
    }
  },

  computed: {
    comboboxRepartoEnabled() {
      return UserInformationStore.getPuoSelezionareRepartoDecesso();
    },

    // "Data di Morte" is ISO format used in the c-date-picker.
    dataMorteISO: {
      get() { return DateUtils.formatDateAsIso(this.cd.dt_morte) },
      set(newValue) { this.setDataMorte(newValue, this.oraMorteFormatted) }
    },

    // "Ora di Morte" formatted (hh:mm); used in the c-date-picker.
    oraMorteFormatted: {
      get() { return DateUtils.formatTime(this.cd.dt_morte) },
      set(newValue) {
        if (DateUtils.validateTime(newValue)) {
          this.setDataMorte(this.dataMorteISO, newValue);
          this.timeValid = true;
        } else {
          this.timeValid = false;
        }
      }
    },

    ///
    /// Utilities computed values.
    ///
    listReparto: () => ListRepartoStore.getListReparto(),
  },

  methods: {
    // Method used to update 'dt_morte' with date and time.
    setDataMorte(date, time) {
      let hour = time.split(":")[0];
      let minute = time.split(":")[1];

      this.cd.dt_morte = moment(date).set({
        hour: hour,
        minute: minute
      }).format();
    },

    ///
    /// Exposed methods.
    ///
    validateTime() {
      return this.timeValid;
    },

    validateDataMorte() {
      return this.dataMorteISO && !DateUtils.isEmptyDate(this.dataMorteISO);
    },

    validateDataRicovero(dataRicovero) {
      return DateUtils.dateDiff(this.dataMorteISO, dataRicovero) >= 0;
    }
  },
}
</script>