<template>
  <div id="menu-bar" class="d-flex align-center">
    <v-container fluid>
      <v-row>

        <!-- Selectors col -->
        <v-col cols="5" align-self="center" class="pl-0">
          <v-row>

            <!-- Activities selector -->
            <v-col cols="6">
              <c-select
                :items="activities"
                :hide-details="true"
                item-text="name"
                item-value="key"
                v-model="selectedActivityCode"
                @change="updateListPratiche()"
                label="Attività"
              >
                <!-- prepend activity icon -->
                <template v-slot:prepend>
                  <v-icon :color="selectedActivity.icon.color">{{ selectedActivity.icon.mdi }}</v-icon>
                </template>

                <!-- Activity item -->
                <template v-slot:item="{item}">
                  <v-icon :color="item.icon.color" left>{{item.icon.mdi}}</v-icon>&nbsp;{{item.name}}
                </template>
              </c-select>
            </v-col>

            <!-- Period selector -->
            <v-col cols="6">
              <c-select
                :disabled="periodSelectorDisabled"
                :items="periodItems"
                :hide-details="true"
                item-value="sub_time"
                v-model="selectedPeriodCode"
                @change="updateListPratiche()"
                prepend-icon="mdi-calendar"
                label="Periodo"
              ></c-select>
            </v-col>
          </v-row>
        </v-col>

        <!-- Refersh and Select all col -->
        <v-col cols="1" align-self="center">
          <v-row>
            <v-spacer />

            <!-- 'Refresh pratiche' button-->
            <v-col cols="5" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon :disabled="false" @click="updateListPratiche">
                    <v-icon v-bind="attrs" v-on="on">mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span>Ricarica pratiche</span>
              </v-tooltip>
            </v-col>

            <!-- 'Select all' button-->
            <v-col cols="5" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon :disabled="false" @click="onSelectAllClicked">
                    <v-icon v-bind="attrs" v-on="on">mdi-text-box-multiple</v-icon>
                  </v-btn>
                </template>

                <!-- 'Select all' tooltip -->
                <span>Seleziona tutte le pratiche</span>
              </v-tooltip>
            </v-col>

            <v-spacer />
          </v-row>
        </v-col>

        <!-- Number of selected pratiche -->
        <v-col cols="3" align-self="center">
          <h5>{{ praticheSelected }}</h5>
        </v-col>

        <v-spacer />

        <!-- 'Cerca Pratica' col -->
        <v-col cols="3" align-self="center" class="pr-0">
          <v-row no-gutters>
            <v-spacer />

            <!-- 'Cerca Pratica' -->
            <v-col cols="9">
              <c-text-field
                v-model="numeroAtto"
                v-on:keyup.enter="searchPratica"
                label="Cerca pratica"
                v-mask="'####/#####'"
              />
            </v-col>

            <!-- Button search -->
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text icon color="green darken-3" :disabled="false" @click="searchPratica">
                    <v-icon v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                  </v-btn>
                </template>

                <!-- 'Select all' tooltip -->
                <span>Cerca pratica da numero atto</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// stores
import {
  AppConfigStore,
  ListIntervalloTemporaleStore,
  ListMetaStore,
} from 'services/commands/stores';
// services
import { ICONS } from 'services/icon_util';
import { VueMaskDirective } from "v-mask";
// vuex
import { mapGetters } from 'vuex';


export default {
  name: 'MenuBar',

  directives: {
    mask: VueMaskDirective,
  },

  data() {
    const periodItems = ListIntervalloTemporaleStore.getListIntervalloTemporale();
    // const periodSelected = periodItems[AppConfigStore.getCaronteDefaultIntervalloTemporaleIndex()].sub_time;
    
    return {
      /**
       * Activities list example [
       *    Attivita' da fare,
       *    Pratiche create,
       *    Comune,
       *    Accettate,
       *    Rifiutate,
       *    Archiviato
       * ]
       */
      activities: this.activitiesList(),

      /**
       * Structure of selectedActivity: {
       *    code: -104,
       *    icon: {
       *      color: "...",
       *      mdi: "...",
       *    }
       * }
       */
      // selectedActivity: this.getSelectedActivity(),

      periodItems: periodItems,
      // periodSelected: periodSelected,

      // used to search pratiche
      numeroAtto: '',
    };
  },

  computed: {
    ...mapGetters([
      'selectedPraticaIds',
      'praticheNumber',
      'selectedActivity',
      'selectedPeriod'
    ]),

    // [ #selectedNo ] / [ #totalNo ] pratiche selezionate
    praticheSelected() {
      let selectedNo = this.selectedPraticaIds.length;
      let totalNo = this.praticheNumber;
      return `${selectedNo}/${totalNo} pratiche selezionate`;
    },

    periodSelectorDisabled() {
      return this.selectedActivity.code == AppConfigStore.getAttivitaDaFareId();
    },

    selectedActivityCode: {
      get() {
        return this.selectedActivity.code;
      },
      set(newValue) {
        this.$store.commit('updateSelectedActivity', { ...this.selectedActivity, code: newValue });
      },
    },

    selectedPeriodCode: {
      get () { 
        return this.selectedPeriod;
      },
      set (newValue) {
        this.$store.commit('updateSelectedPeriod', newValue);
      }
    }
  },

  watch: {
    'selectedActivity.code'() {
      let icon = this.getActivityIcon(this.selectedActivity.code);
      this.selectedActivity.icon = icon || ICONS.ICO_TODO;
      
      // required to conditionally show the 'pratiche selezionate' chip
      this.$emit('activity-selected-change', this.selectedActivity.code);
    }
  },

  methods: {
    activitiesList() {
      // Prende tutti gli oggetti [A_GROUP] definiti nei META
      const stati = ListMetaStore.getMetaObjects("A_GROUP", "ACTIVITY_DEF", 0);

      // Filtra tenendo solo quelli che non hanno SHOW_IN_MENU o SHOW_IN_MENU=1
      // 1. Estrae le chiavi dal dictionary ritornato, che ha forma {key: meta}
      // 2. Le filtra tenendo solo quelle per cui l'oggetto ha SHOW_IN_MENU=1 o non ha SHOW_IN_MENU
      // 3. Le ordina per id crescente, considerando l'id come intero
      // 4. Ritorna un array di oggetti contenenti {key: name: icon:}
      const activities = Object.keys(stati)
                          .filter(k => !stati[k]?.SHOW_IN_MENU || stati[k].SHOW_IN_MENU == "1")
                          .sort((a, b) => Number(a) - Number(b))
                          .flatMap(k => {
                            return {
                              name: stati[k]?.NAME,
                              key: k,
                              icon: ICONS[stati[k]?.ICON]
                            }
                          });

      return activities;
    },

    /**
     * Gets the icon for an activity with the given ID.
     * 
     * @param {string} id - The ID of the activity to get the icon for.
     * @returns {string|null} The icon for the activity, or null if the activity is not found.
     */
    getActivityIcon(id) {
      const activity = _.find(this.activities, activity => activity.key == id);
      return activity ? activity.icon : null;
    },

    /**
     * Gets the default activity object, which includes the code and icon of the selected activity.
     * 
     * @returns {{code: string, icon: string}} The selected activity object.
     */
    getDefaultActivity() {
      let code = `${AppConfigStore.getAttivitaDaFareId()}`;
      let icon = this.getActivityIcon(code) || ICONS.ICO_TODO;
      return {
        code: code,
        icon: icon,
      };
    },

    getDefaultPeriod() {
      return this.periodItems[AppConfigStore.getCaronteDefaultIntervalloTemporaleIndex()].sub_time;
    },
    
    // method used to call 'LIST_PRATICA_INFO' command
    async updateListPratiche() {
      let activities = Number(this.selectedActivity.code);
      let sub_time = this.selectedPeriod;

      this.$emit('refresh-button-clicked', { activities, sub_time });
    },

    // method used to search pratica calling 'LIST_PRATICA_INFO' command
    async searchPratica() {
      if (!this.numeroAtto) {
        // if "numeroAtto" is empty updates the list of pratiche
        await this.updateListPratiche();
      } else {
        // required params to search "numeroAtto" using 'LIST_PRATICA_INFO' command
        let activities = _.map(this.activitiesList(), (a) => parseInt(a.key));
        let numero_atto = this.numeroAtto;
        let sub_time = -1;

        this.$emit('refresh-button-clicked', { activities, numero_atto, sub_time });
      }
    },

    // method used to make all the pratiche selected
    onSelectAllClicked() {
      this.$store.commit('selectAllIds');
    },
  },

  created() {
    if (_.isEmpty(this.selectedActivity)) {
      this.$store.commit('updateSelectedActivity', this.getDefaultActivity());
      this.$store.commit('updateSelectedPeriod', this.getDefaultPeriod())
    }
  }
}
</script>
