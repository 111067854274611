<script>
import { VSwitch } from "vuetify/lib"


export default {
  name: 'CSwitch',

  extends: VSwitch,

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    
    dense: {
      type: Boolean,
      default: true,
    },

    inset: {
      type: Boolean,
      default: true,
    }
  },
};
</script>