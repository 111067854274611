<template>
  <div id="form-istat-b">
    <c-expansion-sections
      :disabled="disabled"
      :sections="[
        'DECEDUTO',
        'UFFICIO DI STATO CIVILE',
        'NASCITA',
        'MORTE',
        'RESIDENZA',
        'ALTRE INFORMAZIONI'
      ]"
    >
      <!-- "DECEDUTO" section -->
      <template #section1>

        <!-- [ cognome, nome ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-text-field
              v-model="ib.cognome"
              :rules="requiredRule"
              label="Cognome"
              prepend-icon="mdi-account-outline"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-text-field
              v-model="ib.nome"
              :rules="requiredRule"
              label="Nome"
              prepend-icon="mdi-account-outline"
            />
          </v-col>
        </v-row>

        <!-- [ sesso, codice_fiscale ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              v-model="ib.sesso"
              :items="listSesso"
              :rules="requiredRule"
              label="Sesso"
              prepend-icon="mdi-gender-male-female"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-text-field
              v-model="ib.codice_fiscale"
              :relus="requiredRule"
              label="Codice Fiscale"
              prepend-icon="mdi-credit-card-outline"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "UFFICIO DI STATO CIVILE" section -->
      <template #section2>

        <!-- [ comune, provincia ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="comuneUfficioStatoCivile"
              :items="listPaesi"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              label="Comune"
              clearable
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="provinciaUfficioStatoCivile"
              :items="listProvince"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              label="Provincia"
              clearable
            />
          </v-col>
        </v-row>

        <!-- [ circoscrizione ufficio di stato civile ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-text-field
              v-model="ib.circoscrizione_ufficio_stato_civile"
              label="Circoscrizione"
              type="number"
              prepend-icon="mdi-ticket-confirmation-outline"
              hide-spin-buttons
            />
          </v-col>
        </v-row>

        <!-- [ telefono, email ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-text-field
              v-model="ib.telefono_ufficiale_stato_civile"
              label="Telefono"
              type="number"
              prepend-icon="mdi-phone"
              hide-spin-buttons
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-text-field
              v-model="ib.email_ufficiale_stato_civile"
              :rules="requiredRule"
              label="Email"
              prepend-icon="mdi-email"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "NASCITA" section -->
      <template #section3>

        <!-- [ data_di_nascita ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-date-picker
              v-model="dataNascitaISO"
              :minimumDate="minimumDate"
              :rules="requiredRule"
              label="Data di Nascita"
            />
          </v-col>
        </v-row>

        <!-- [ comune, provincia ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="comuneNascita"
              :items="listPaesi"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              label="Comune di Nascita"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="provinciaNascita"
              :items="listProvince"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              label="Provincia di Nascita"
            />
          </v-col>
        </v-row>

        <!-- [ stato, cittadinanza ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="statoNascita"
              :items="listStati"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-earth"
              label="Nazione di Nascita"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="cittadinanzaSpecifica"
              :items="listCittadinanze"
              :rules="requiredRule"
              label="Cittadinanza"
              item-text="descrizione"
              prepend-icon="mdi-flag"
              clearable
            />
          </v-col>
        </v-row>
      </template>

      <!-- "MORTE" section -->
      <template #section4>
        <!-- [ data_di_morte, orario_di_morte, età ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="3">
            <c-date-picker
              v-model="dataMorteISO"
              :minimumDate="minimumDate"
              :rules="requiredRule"
              label="Data di Morte"
            />
          </v-col>

          <v-spacer />

          <v-col cols="3">
            <c-time-picker
              v-model="oraMorteFormatted"
              :rules="requiredRule"
              label="Orario di Morte"
            />
          </v-col>

          <v-spacer />

          <v-col cols="3">
            <c-text-field
              :value="anni"
              :disabled="true"
              label="Età (anni)"
              prepend-icon="mdi-calendar"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "RESIDENZA" section -->
      <template #section5>
        <!-- [ comune, provincia ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="comuneResidenza"
              :items="listPaesi"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              label="Comune"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="provinciaResidenza"
              :items="listProvince"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              label="Provincia"
            />
          </v-col>
        </v-row>

        <!-- [ stato, cittadinanza ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="statoResidenza"
              :items="listStati"
              :rules="requiredRule"
              item-text="descrizione"
              prepend-icon="mdi-earth"
              label="Nazione di Nascita"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "ALTRE INFORMAZIONI" section -->
      <template #section6>
        <!-- [ stato_civile ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              v-model="ib.stato_civile"
              :items="listStatoCivile"
              :rules="requiredRule"
              label="Stato Civile"
              prepend-icon="mdi-human-male-female"
            />
          </v-col>
        </v-row>

        <v-banner
          :value="showAnnoMatrimonio"
          transition="slide-y-transition"
          single-line
          style="padding: 0"
        >
          <!-- [ anno_di_nascita_coniuge, anno_matrimonio ] -->
          <v-row no-gutters class="ma-2">

            <v-col cols="5">
              <c-text-field
                v-model="ib.anno_nascita_coniuge"
                label="Anno di nascita Coniuge"
                type="number"
                prepend-icon="mdi-calendar"
                hide-spin-buttons
              />
            </v-col>

            <v-spacer />

            <v-col cols="5">
              <c-text-field
                v-model="ib.anno_matrimonio"
                label="Anno Matrimonio"
                type="number"
                prepend-icon="mdi-ring"
                hide-spin-buttons
              />
            </v-col>
          </v-row>
        </v-banner>

        <!-- [ grado_istruzione, professione ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              v-model="ib.grado_istruzione"
              :items="listGradoIstruzione"
              :rules="requiredRule"
              label="Grado istruzione"
              prepend-icon="mdi-account-school"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-text-field
              v-model="ib.professione"
              label="Professione"
              :rules="requiredRule"
              prepend-icon="mdi-account-tie"
            />
          </v-col>
        </v-row>

        <!-- [ condizione_professionale, posizione_professionale ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              v-model="ib.condizione_professionale"
              :items="listCondizioneProfessionale"
              :rules="requiredRule"
              label="Condizione professionale"
              prepend-icon="mdi-briefcase"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-select
              v-model="ib.posizione_professione"
              :items="listPosizioneProfessionale"
              :rules="requiredRule"
              label="Posizione professionale"
              prepend-icon="mdi-account-group"
            />
          </v-col>
        </v-row>

        <!-- [ ramo_attivita ] -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-select
              v-model="ib.ramo_attivita"
              :items="listRamoAttivita"
              :rules="requiredRule"
              label="Ramo attività"
              prepend-icon="mdi-sitemap"
            />
          </v-col>
        </v-row>
      </template>
    </c-expansion-sections>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />
  </div>
</template>
<script>
// stores
import {
  GeographicStore,
  AppConfigStore,
  ListSessoStore,
  ListCondizioneProfessionaleStore,
  ListGradoIstruzioneStore,
  ListPosizioneProfessionaleStore,
  ListRamoAttivitaStore,
  ListStatoCivileStore,
} from 'services/commands/stores';
// services
import DateUtils from 'services/date_util';
import PraticaUtil from 'services/pratica_util';
import GeographicUtil from 'services/geographic_util';
import CodiceFiscaleUtil from "services/codice_fiscale_util";

export default {
  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixIstatB(this.praticaDetails, !this.disabled);

    return {
      pratica: pratica,
      ib: pratica.istat_b,

      requiredRule: [ value => !!value || 'Obbligatorio' ],

      // necessary to validate time
      timeValid: true,
      dialogError: false,
      errors: [],
    }
  },

  computed: {
    // "Anni" field of the form.
    anni() {
      const years = DateUtils.dateDiff(this.ib.dt_morte, this.ib.dt_nascita, 'years');
      return years > 0 ? years : 0;
    },
    
    // "Data di Nascita" is ISO format used in the c-date-picker.
    dataNascitaISO: {
      get() { return DateUtils.formatDateAsIso(this.ib.dt_nascita) },
      set(newValue) { this.ib.dt_nascita = moment(newValue).format() }
    },

    // "Data di Morte" is ISO format used in the c-date-picker.
    dataMorteISO: {
      get() { return DateUtils.formatDateAsIso(this.ib.dt_morte) },
      set(newValue) { this.setDataMorte(newValue, this.oraMorteFormatted) }
    },

    // "Ora di Morte" formatted (hh:mm); used in the c-date-picker.
    oraMorteFormatted: {
      get() { return DateUtils.formatTime(this.ib.dt_morte) },
      set(newValue) {
        if (DateUtils.validateTime(newValue)) {
          this.setDataMorte(this.dataMorteISO, newValue);
          this.timeValid = true;
        } else {
          this.timeValid = false;
        }
      }
    },

    showAnnoMatrimonio() {
      return this.ib.stato_civile === 'Coniugato' || this.ib.stato_civile === 'SeparatoLegalmente';
    },

    ///
    /// Geographic fields
    ///
    comuneUfficioStatoCivile: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'comune_ufficio_stato_civile', 'comune_ufficio_stato_civile_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'comune_ufficio_stato_civile', 'comune_ufficio_stato_civile_specifico') }
    },

    comuneNascita: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'comune_nascita', 'comune_nascita_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'comune_nascita', 'comune_nascita_specifico') }
    },

    comuneResidenza: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'comune_residenza', 'comune_residenza_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'comune_residenza', 'comune_residenza_specifico') }
    },

    provinciaUfficioStatoCivile: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'provincia_ufficio_stato_civile', 'provincia_ufficio_stato_civile_specifica') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'provincia_ufficio_stato_civile', 'provincia_ufficio_stato_civile_specifica') }
    },

    provinciaNascita: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'provincia_nascita', 'provincia_nascita_specifica') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'provincia_nascita', 'provincia_nascita_specifica') }
    },

    provinciaResidenza: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'provincia_residenza', 'provincia_residenza_specifica') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'provincia_residenza', 'provincia_residenza_specifica') }
    },

    statoNascita: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'stato_nascita', 'stato_nascita_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'stato_nascita', 'stato_nascita_specifico') }
    },

    statoResidenza: {
      set(newValue) { GeographicUtil.setGeographicField(this.ib, newValue, 'stato_residenza', 'stato_residenza_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ib, 'stato_residenza', 'stato_residenza_specifico') }
    },

    cittadinanzaSpecifica: {
      set(newValue) { this.ib.cittadinanza_specifica = newValue?.descrizione || newValue },
      get() { return this.ib.cittadinanza_specifica }
    },

    ///
    /// Utilities computed values.
    ///
    listPaesi: () => GeographicStore.listPaesi(),
    listProvince: () => GeographicStore.listProvince(),
    listStati: () => GeographicStore.listStati(),
    listCittadinanze: () => GeographicStore.listCittadinanze(),
    listSesso: () => ListSessoStore.getListSesso(),
    listCondizioneProfessionale: () => ListCondizioneProfessionaleStore.getListCondizioneProfessionale(),
    listGradoIstruzione: () => ListGradoIstruzioneStore.getListGradoIstruzione(),
    listPosizioneProfessionale: () => ListPosizioneProfessionaleStore.getListPosizioneProfessionale(),
    listRamoAttivita: () => ListRamoAttivitaStore.getListRamoAttivita(),
    listStatoCivile: () => ListStatoCivileStore.getListStatoCivile(),

    minimumDate: () => moment('' + AppConfigStore.getAnnoMinimoNascita(), "YYYY").format(),
  },

  watch: {
    // Watch used to notify parent component when 'pratica' updates.
    'pratica.istat_b': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },

    // Watch used to update 'Medico' informations
    disabled(newValue) {
      if (!newValue) {
        // adding user information
        this.pratica = PraticaUtil.fixIstatB(this.praticaDetails, true);
        this.ib = this.pratica?.istat_b;
      }
    },

    ///
    /// Comuni
    ///
    // watch used to automatically fill 'provincia_nascita' and 'stato_nascita' fields.
    comuneNascita(newValue) {
      GeographicUtil.setComuneSpecificoProvinciaStato(
        this.ib,
        newValue,
        'comune_nascita_specifico',
        'provincia_nascita',
        'provincia_nascita_specifica',
        'stato_nascita',
        'stato_nascita_specifico'
      );
    },

    // watch used to automatically fill 'provincia_residenza' and 'stato_residenza' fields.
    comuneResidenza(newValue) {
      GeographicUtil.setComuneSpecificoProvinciaStato(
        this.ib,
        newValue,
        'comune_residenza_specifico',
        'provincia_residenza',
        'provincia_residenza_specifica',
        'stato_residenza',
        'stato_residenza_specifico'
      );
    },

    comuneUfficioStatoCivile(newValue) {
      GeographicUtil.setComuneSpecificoProvincia(
        this.ib,
        newValue,
        'comune_ufficio_stato_civile_specifico',
        'provincia_ufficio_stato_civile',
        'provincia_ufficio_stato_civile_specifica'
      )
    },

    ///
    /// Stati
    ///
    // watch used to automatically fill 'cittadinanza' field.
    statoNascita(newValue) {
      this.ib.cittadinanza_specifica = newValue?.cittadinanza;
    },
  },

  methods: {
    setterGeographicField: (newValue, objPath, specificPath) => {
      // newValue.descrizione might be null:
      // newValue = null
      // newValue = "COMUNE SCONOSCIUTO"
      if (newValue?.descrizione == null) {
        this.ib[objPath] = null;
        this.ib[specificPath] = newValue;
      } else {
        this.ib[objPath] = newValue;
      }
    },

    getterGeographicField: (objPath, specificPath) => {
      return this.ib[objPath]?.descrizione
        ? this.ib[objPath]
        : this.ib[specificPath];
    },

    // Method used to update 'dt_morte' with date and time.
    setDataMorte(date, time) {
      let hour = time.split(":")[0];
      let minute = time.split(":")[1];

      this.ib.dt_morte = moment(date).set({
        hour: hour,
        minute: minute
      }).format();
    },

    ///
    /// Exposed methods.
    ///
    validate() {
      let errors = [];

      // cognome - nome
      this.ib.cognome || errors.push("Cognome deceduto");
      this.ib.nome || errors.push("Nome deceduto");

      // codice fiscale
      const { codice_fiscale } = this.ib;
      if (!codice_fiscale || !CodiceFiscaleUtil.validateCodiceFiscale(codice_fiscale)) {
        errors.push("Codice Fiscale deceduto (vuoto o invalido)");
      }

      // comune - provincia (dell'ufficio stato civile)
      this.ib.comune_ufficio_stato_civile_specifico || errors.push("Comune dell'Ufficio Stato Civile");
      this.ib.provincia_ufficio_stato_civile_specifica || errors.push("Provincia dell'Ufficio Stato Civile");

      // data di nascita
      const dataNascitaValid = this.dataNascitaISO && !DateUtils.isEmptyDate(this.dataNascitaISO);
      dataNascitaValid || errors.push("Data di Nascita (vuota o invalida)")

      // comune - provincia - nazione (di nascita) - cittadinanza
      const { stato_nascita_specifico } = this.ib;
      if (stato_nascita_specifico && stato_nascita_specifico === 'ITALIA') {
        this.ib.comune_nascita_specifico || errors.push("Comune di Nascita");
        this.ib.provincia_nascita_specifica || errors.push("Provincia di Nascita");
      }
      stato_nascita_specifico || errors.push("Nazione di Nascita");
      this.ib.cittadinanza_specifica || errors.push("Cittadinanza");

      // data di morte
      const dataMorteValid = this.dataMorteISO && !DateUtils.isEmptyDate(this.dataMorteISO);
      dataMorteValid || errors.push("Data di Morte (vuota o invalida)");

      // comune - provincia - nazione (di residenza)
      const { stato_residenza_specifico } = this.ib;
      if (stato_residenza_specifico && stato_nascita_specifico === 'ITALIA') {
        this.ib.comune_residenza_specifico || errors.push("Comune di Residenza");
        this.ib.provincia_residenza_specifica || errors.push("Provincia di Residenza");
      }
      stato_residenza_specifico || errors.push("Nazione di Residenza");

      // orario di morte
      this.timeValid || errors.push("Orario di Morte (vuoto o invalido)");

      // dataMorte must be after dataNascita
      if (dataMorteValid && this.timeValid && DateUtils.dateDiff(this.dataMorteISO, this.dataNascitaISO) < 0) {
        errors.push("Data di Morte invalida (deve essere successiva alla data di Nascita)");
      }

      // professione
      this.ib.professione || errors.push("Professione del deceduto");

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }

      this._clearUnusedFields();
      return true;
    },

    _clearUnusedFields() {
      if (!this.showAnnoMatrimonio) {
        this.ib.anno_nascita_coniuge = 0;
        this.ib.anno_matrimonio = 0;
      }
    },
  }
}
</script>