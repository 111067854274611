var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-accertamento" } },
    [
      _c("c-expansion-sections", {
        attrs: {
          disabled: _vm.disabled,
          sections: ["MEDICO", "RESIDENZA MEDICO", "DECEDUTO"],
        },
        scopedSlots: _vm._u([
          {
            key: "section1",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Cognome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.am.cognome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.am, "cognome_medico", $$v)
                            },
                            expression: "am.cognome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Nome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.am.nome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.am, "nome_medico", $$v)
                            },
                            expression: "am.nome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-date-picker", {
                          attrs: { label: "Data di Nascita" },
                          model: {
                            value: _vm.dataNascitaMedicoISO,
                            callback: function ($$v) {
                              _vm.dataNascitaMedicoISO = $$v
                            },
                            expression: "dataNascitaMedicoISO",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            label: "Comune di Nascita",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.comuneNascitaMedico,
                            callback: function ($$v) {
                              _vm.comuneNascitaMedico = $$v
                            },
                            expression: "comuneNascitaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            label: "Provincia di Nascita",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.provinciaNascitaMedico,
                            callback: function ($$v) {
                              _vm.provinciaNascitaMedico = $$v
                            },
                            expression: "provinciaNascitaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section2",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Via",
                            "prepend-icon": "mdi-home-map-marker",
                          },
                          model: {
                            value: _vm.am.via_residenza_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.am, "via_residenza_medico", $$v)
                            },
                            expression: "am.via_residenza_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "1" } }),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "1" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Civico",
                            type: "number",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.am.civico_residenza_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.am, "civico_residenza_medico", $$v)
                            },
                            expression: "am.civico_residenza_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            label: "Comune",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.comuneResidenzaMedico,
                            callback: function ($$v) {
                              _vm.comuneResidenzaMedico = $$v
                            },
                            expression: "comuneResidenzaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            label: "Provincia",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.provinciaResidenzaMedico,
                            callback: function ($$v) {
                              _vm.provinciaResidenzaMedico = $$v
                            },
                            expression: "provinciaResidenzaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Telefono",
                            "prepend-icon": "mdi-phone",
                            type: "number",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.am.telefono_residenza_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.am, "telefono_residenza_medico", $$v)
                            },
                            expression: "am.telefono_residenza_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section3",
            fn: function () {
              return [
                _c("SectionDeceduto", {
                  attrs: {
                    "anagrafica-deceduto": _vm.pratica.anagrafica_deceduto,
                    "show-cittadinanza": false,
                    "show-via-civico-domicilio": false,
                    "show-comune-provincia-domicilio": false,
                    "show-data-orario-morte": false,
                    "show-anni-mesi-giorni-ore": false,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "ma-2" },
        [
          _c(
            "div",
            { staticClass: "pace-maker-col pl-0 pb-1" },
            [
              _vm._v("\n      Cadavere portatore di pace-maker:\n\n      "),
              _c("c-switch", {
                attrs: { label: _vm.paceMaker ? "SI" : "NO" },
                model: {
                  value: _vm.paceMaker,
                  callback: function ($$v) {
                    _vm.paceMaker = $$v
                  },
                  expression: "paceMaker",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-form",
            { attrs: { disabled: _vm.disabled || !_vm.paceMaker } },
            [
              _c("c-textarea", {
                staticClass: "ma-2",
                attrs: {
                  "prepend-icon": _vm.iconPaceMaker,
                  label: "Note pace-maker",
                },
                model: {
                  value: _vm.paceMakerNota,
                  callback: function ($$v) {
                    _vm.paceMakerNota = $$v
                  },
                  expression: "paceMakerNota",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "ma-2" },
        [
          _c("v-divider"),
          _vm._v(" "),
          _c("c-textarea", {
            staticClass: "ma-2",
            attrs: {
              label: "Misure precauzionali igienico-sanitare",
              "prepend-icon": "mdi-lotion-plus",
            },
            model: {
              value: _vm.am.misure_igienico_sanitarie,
              callback: function ($$v) {
                _vm.$set(_vm.am, "misure_igienico_sanitarie", $$v)
              },
              expression: "am.misure_igienico_sanitarie",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "ma-2" },
        [
          _c("v-divider"),
          _vm._v(" "),
          _c("c-textarea", {
            staticClass: "ma-2",
            attrs: {
              label: "Motivi per la riduzione del periodo di osservazione",
              "prepend-icon": "mdi-account-eye",
            },
            model: {
              value: _vm.am.riduzione_periodo_osservazione,
              callback: function ($$v) {
                _vm.$set(_vm.am, "riduzione_periodo_osservazione", $$v)
              },
              expression: "am.riduzione_periodo_osservazione",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "ma-2" },
        [
          _c(
            "div",
            { staticClass: "morte-reato-col pl-0 pb-1" },
            [
              _vm._v("\n      Sospetto di morte dovuta a reato:\n\n      "),
              _c("c-switch", {
                attrs: { label: _vm.morteReato ? "SI" : "NO" },
                model: {
                  value: _vm.morteReato,
                  callback: function ($$v) {
                    _vm.morteReato = $$v
                  },
                  expression: "morteReato",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("c-textarea", {
            staticClass: "ma-2",
            attrs: {
              "prepend-icon": "mdi-account-tie-hat",
              label: "Note relative al sospetto di morte dovuta a reato",
            },
            model: {
              value: _vm.am.morte_reato_nota,
              callback: function ($$v) {
                _vm.$set(_vm.am, "morte_reato_nota", $$v)
              },
              expression: "am.morte_reato_nota",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }