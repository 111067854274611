var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-print-pratiche-list" } },
        [
          _c("v-card-title", [
            _vm._v(_vm._s(!this.empty ? "Stampa" : "Attenzione")),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _c("br"),
          _vm._v(" "),
          !this.empty
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        },
                        _vm._l(_vm.groupedByCategory, function (document) {
                          return _c("v-list-item", {
                            key: document.category,
                            attrs: { value: document },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var active = ref.active
                                    return [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-checkbox", {
                                            attrs: { "input-value": active },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "list-item-title" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.formatPrintPratica(
                                                      document
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("v-card-text", { staticClass: "text" }, [
                _vm._v("\n      " + _vm._s(_vm.emptyText) + "\n    "),
              ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-action",
                  attrs: { outlined: "" },
                  on: { click: _vm.onCancelClick },
                },
                [_vm._v("\n        ANNULLA\n      ")]
              ),
              _vm._v(" "),
              !this.empty
                ? _c(
                    "v-btn",
                    {
                      class: _vm.selected.length && "btn-action",
                      attrs: { disabled: !_vm.selected.length, outlined: "" },
                      on: { click: _vm.onPrintClick },
                    },
                    [_vm._v("\n        STAMPA\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }