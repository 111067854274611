<template>
  <div
    id="app-container"
    class="d-flex"
    :class="categoryStyle"
    :style="disabled ? disabledStyle : {}"
  >
    <div id="app-content-container" class="d-flex">
      <!-- Uncategorized contains pages without index -->
      <!-- Pages without index = groupedPages[null]-->
      <Uncategorized
        :pages="groupedPages[null]"
        :categoriesMode="categoriesMode"
        :splitUrl="splitUrl"
        :previewUrl="previewUrl"
        @insertNewPages="insertNewPages"
        @deletePage="deletePage"
      />

      <div id="categories-panel">
        <!-- Category contains pages with index -->
        <!-- 'index' indicates the 'category id' -->
        <Category
          v-for="category in categories"
          :categoriesMode="categoriesMode"
          :category="category"
          :pages="groupedPages[category.id]"
          :previewUrl="previewUrl"
          :key="category.id"
          :ref="category.id"
          @insertNewPages="insertNewPages"
          @removeFromCategory="removeFromCategory"
        />
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import interact from 'interactjs'
// components
import Category from './Category.vue'
import Uncategorized from './Uncategorized.vue'
// services
import JoinPages from './_services/join_pages.js'

export default {
  name: 'PdfSplitter',

  components: {
    Category,
    Uncategorized
  },

  props: {
    // categories: [
    //   {
    //     id: 1,
    //     label: '...',
    //     name: '...',
    //     required: true,
    //   },
    // ]
    value: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    splitUrl: {
      type: String,
      required: true,
    },
    joinUrl: {
      type: String,
      required: true,
    },
    // base url for pages previews
    previewUrl: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      // Array to store document pages
      pages: [],

      // Display mode for categories ('vertical' or 'horizontal')
      categoriesMode: 'vertical',

      disabledStyle: {
        pointerEvents: "none",
        opacity: 0.6,
      },
    }
  },

  computed: {
    // Group pages by category and sort them by order_index.
    groupedPages() {
      if (!this.pages) return {}

      let allPages = _.clone(this.pages)

      let currentPageGroups = _.groupBy(allPages, 'category_id')
      let categoriesId = _.map(this.categories, 'id').concat(null).sort()

      return _.reduce(categoriesId, (list, category) => {
        let categoryDocs = currentPageGroups[category] || []

        if (category) {
          categoryDocs = _.sortBy(categoryDocs, 'order_index')
        }

        list[category] = categoryDocs
        return list
      }, {})
    },

    // Dynamically determine the category style class based on categoriesMode.
    categoryStyle() {
      return `${this.categoriesMode}-categories`
    },
  },

  methods: {
    // Handle dropping a page on whitespace in a category.
    onDropZone(event) {
      let destinationCategory = parseInt(event.currentTarget.getAttribute('data-category-id'))
      let selectedItem = event.relatedTarget.getAttribute('data-page-id')

      let orderIndex = 0

      let element = _.find(this.pages, ['id', selectedItem])

      let srcCategory = _.clone(element.category_id)
      let lastElement = _.last(this.groupedPages[destinationCategory])

      if (lastElement) {
        orderIndex = parseInt(lastElement.order_index) + 1
      }

      element.category_id = destinationCategory
      element.order_index = orderIndex

      if (srcCategory) {
        this.resetPagesOrder(srcCategory)
      }
    },

    // Handle dropping a page on another page.
    onDropPage(event) {
      let pageElement = event.target.parentElement
      let positionToInsert = event.target.getAttribute('data-position')

      let newIndex = parseInt(pageElement.getAttribute('data-order'))
      let destCategory = parseInt(pageElement.parentElement.getAttribute('data-category-id'))
      let movedPage = event.relatedTarget.getAttribute('data-page-id')

      let element = _.find(this.pages, ['id', movedPage])
      let srcCategory = element.category_id

      let oldOrder = this.buildOrder(destCategory)

      // get old position if already present in this category and set value as null as placeholder
      let oldPosition = oldOrder.indexOf(movedPage)
      if (oldPosition >= 0) {
        oldOrder[oldPosition] = null
      }

      let newOrder = this.movePage({
        list: oldOrder,
        index: newIndex,
        item: movedPage,
        position: positionToInsert
      })

      // used to cleanup placeholder key if item was already in this category
      if (oldPosition >= 0) {
        newOrder = _.compact(newOrder)
      }

      element.category_id = destCategory

      _.each(this.getPagesByCategoryId(destCategory), page => {
        page.order_index = newOrder.indexOf(page.id)
      })

      if (srcCategory !== destCategory) {
        this.resetPagesOrder(srcCategory)
      }
    },

    // Initialize drag-and-drop interactions.
    initInteractions() {
      let clonedElement = null

      interact('.document-page').draggable({
        autoScroll: false,
        onstart(event) {
          event.stopPropagation()

          let imgElement = event.target.querySelector('img')
          let imgPosition = imgElement.getBoundingClientRect()

          let imgDetails = {
            src: imgElement.src,
            height: imgElement.clientHeight,
            width: imgElement.clientWidth,
            top: parseInt(imgPosition.y),
            left: parseInt(imgPosition.x),
            id: event.target.getAttribute('data-page-id')
          }

          let parentContainer = event.target.parentElement

          clonedElement = document.createElement('img')
          clonedElement.src = imgDetails.src
          clonedElement.dataset.id = imgDetails.id
          clonedElement.classList.add('cloned-page')
          clonedElement.style.height = imgDetails.height + "px"
          clonedElement.style.width = imgDetails.width + "px"
          clonedElement.style.top = imgDetails.top + "px"
          clonedElement.style.left = imgDetails.left + "px"

          parentContainer.appendChild(clonedElement)
        },
        onmove(event) {
          const target = clonedElement

          const dataX = target.getAttribute('data-x')
          const dataY = target.getAttribute('data-y')
          const initialX = parseFloat(dataX) || 0
          const initialY = parseFloat(dataY) || 0

          const deltaX = event.dx
          const deltaY = event.dy

          const newX = initialX + deltaX
          const newY = initialY + deltaY

          target.style.transform = `translate(${newX}px, ${newY}px)`

          target.setAttribute('data-x', newX)
          target.setAttribute('data-y', newY)
        },
        onend(event) {
          clonedElement.parentElement.removeChild(clonedElement)
        }
      })

      // 'onDropZone' event
      interact('.pages-list').dropzone({
        listeners: {
          drop: this.onDropZone
        }
      })

      // 'onDropPage' event
      interact('.page-dropzone').dropzone({
        listeners: {
          drop: this.onDropPage
        }
      })
    },

    // Move a page within a category's order.
    movePage({ list, index, item, position }) {
      if (position === 'after') index = index + 1
      if (index < 0) index = 0

      list.splice(index, 0, item)

      return list
    },

    // Get pages by category ID and sort them by order_index.
    getPagesByCategoryId(catId) {
      let list = _.filter(this.pages, ['category_id', catId])
      return _.sortBy(list, 'order_index')
    },

    // Build the order of pages within a category.
    buildOrder(catId) {
      let list = this.getPagesByCategoryId(catId)
      return _.map(list, 'id')
    },

    // Reset the order of pages within a category.
    resetPagesOrder(catId) {
      let order = this.buildOrder(catId)

      _.each(this.getPagesByCategoryId(catId), page => {
        page.order_index = order.indexOf(parseInt(page.order_index))
      })
    },

    // Reset the order of pages within a category.
    resetPagesOrder(catId, oldIndex) {
      _.each(this.getPagesByCategoryId(catId), page => {
        if (page.order_index > oldIndex) {
          page.order_index--;
        }
      })
    },

    // Add new pages to the component's data.
    insertNewPages(newPages) {
      this.pages = [...this.pages, ...newPages]
    },

    // Delete a page from the component's data.
    deletePage(pageId) {
      this.pages = _.filter(this.pages, page => page.id !== pageId)
    },

    // Remove a page from a category.
    removeFromCategory(pageId) {
      let element = _.find(this.pages, ['id', pageId])
      let oldCategory = _.clone(element.category_id)

      let oldOrderIndex = element.order_index

      element.category_id = null
      element.order_index = null

      this.resetPagesOrder(oldCategory, oldOrderIndex)
    },

    // Checks if required categories contains at least one page.
    async validate() {
      if (!this._validateCategories()) {
        return false
      }
      
      const newDocuments = await this._generateJoinedDocuments();
      this.$emit('input', newDocuments)

      return true
    },

    _validateCategories() {
      let validated = true

      _.forEach(this.categories, c => {
        if (c.required && this.groupedPages[c.id].length === 0) {
          // refs inside v-for are array by default
          this.$refs[c.id][0].highlight()
          validated = false
        }
      })

      return validated
    },

    // Generates the list of joined documents using JoinPages util.
    async _generateJoinedDocuments() {
      const joinedDocs = []

      for(const c of this.categories) {
        const cPages = this.groupedPages[c.id]

        if (cPages.length === 0) break;

        const pathList = _.map(cPages, 'id')
        const joined = await JoinPages.join(this.joinUrl, pathList)

        joinedDocs.push({
          data: joined,
          document_type: c.document_type,
        })
      }

      return joinedDocs;
    }
  },

  mounted() {
    // initialize drag-and-drop interactions when the component is mounted
    this.initInteractions()
  }
}
</script>