var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: _vm.disabled ? _vm.divStyle : {},
      attrs: { id: "documents-table-details" },
    },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-alpine",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          columnDefs: _vm.columnDefs,
          gridOptions: _vm.gridOptions,
          rowData: _vm.listPraticaInfo,
          overlayNoRowsTemplate: "Nessuna pratica",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }