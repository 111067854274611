var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-documenti" } },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-alpine",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          gridOptions: _vm.gridOptions,
          rowData: _vm.praticaDetails.documenti.documento,
          overlayNoRowsTemplate: "Nessun documento",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }