<template>
  <div class="page" id="details">
    <DocumentViewer v-if="documentFixed"
      v-model="dialogDocument"
      :document="documentFixed"
    />

    <HeaderBar :disabled="isEditMode" class="mb-2" />

    <div class="page-body">
      <div class="page-content">
        <!-- Document Table on the left -->
        <TableDetails :disabled="isEditMode" class="ml-2" />

        <!-- Container of Forms -->
        <div class="forms mx-2">
          <!-- Container of Forms header -->
          <FormHeader
            :title="formHeaderTitle"
            :subtitle="formHeaderSubtitle"
            :show-info="showPraticaInfo"
            :description="showPraticaInfo ? formPraticaDescription : ''"
            :info="showPraticaInfo ? formPraticaInfo : ''"
          />

          <!-- Optional Skeleton Loader -->
          <v-skeleton-loader v-if="showSkeletonLoader"
            type="table-thead, divider, card-heading, image"
            class="mb-6"
          />
      
          <FormContainer v-else
            @pratica-updated="onPraticaUpdate"
            @documents-generated="onDocumentsGenerated"
            @document-selected="onDocumentSelected"
            @ricerca-ricoveri="onRicercaRicoveri"
            :isEditMode="isEditMode"
            :moduleState="moduleState"
            :pratica="pratica"
            ref="formContainer"
          />
        </div>

        <!-- Container of "Allegati" -->
        <div v-if="showDocumentList" class="attachements-bar mr-2">
          <v-skeleton-loader v-if="showSkeletonLoader"
            class="mb-6"
            type="image"
          />

          <DocumentList v-else
            @document-selected="onDocumentSelected"
            :documentList="pratica.documenti.documento"
            :name="pratica.anagrafica_deceduto.nome"
            :surname="pratica.anagrafica_deceduto.cognome"
            :key="moduleState"
          />
        </div>
      </div>

      <ActionBar
        @action-button-clicked="onActionButtonClick"
        :mode="mode"
        :selection="[ this.pratica ]"
        class="ma-2"
      />
    </div>

    <!-- Dialog ricoveri shown in case of multiple ricoveri found -->
    <dialog-ricoveri
      v-model="showDialogRicoveri"
      :ricoveriList="ricoveriList"
      @ricovero-choosed="associaRicoveri"
    />

    <!-- Dialog Confirm shown before the pratica deletion -->
    <c-dialog-confirm ref="dialogConfirmDeletePratica" />

    <!-- Dialog Confirm shown to notify that a document is ready to be signed -->
    <c-dialog-confirm ref="dialogConfirmSignDocument" title="Firma documento" />

    <!-- Dialog Confirm shown to notify that a 'pratica' is ready to be sent to 'Medicina Legale' -->
    <c-dialog-confirm ref="dialogConfirmSendToMedicinaLegale" title="Invio pratica" />

    <!-- Dialog Print Pratiche List shown after print command execution -->
    <dialog-print-pratiche-list
      v-model="showPrintPraticheList"
      :print-pratiche-list="printPraticheList"
    />

    <!-- Dialog shown before AcceptPratiche command execution -->
    <dialog-accept
      v-model="showAcceptDialog"
      @confirm-accept="onConfirmAcceptEvent"
    />

    <!-- Dialog shown before RefusePratiche command execution -->
    <dialog-refuse
      v-model="showRefuseDialog"
      @confirm-refuse="onConfirmRefuseEvent"
    />
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
// services
import DateUtils from 'services/date_util';
import PraticaUtil from "services/pratica_util";
import DetailsUtil from 'services/details_util';
// stores
import {
  ListMetaStore,
  ListUnitRoleResourceStore,
} from "services/commands/stores";
// handlers
import ActionHandler from 'handlers';
// mixins
import sharedCommands from "mixins/shared_commands";

/**
 * Details possible modalities
 *    - Edit mode:   the editing of the last unsaved form is enabled
 *    - View mode:   forms are in read-only mode
 */
const Mode = {
  Edit: "edit",
  View: "view",
};


export default {
  name: "Details",

  mixins: [sharedCommands],

  components: {
    HeaderBar:                () => import("components/header/HeaderBar"),
    TableDetails:             () => import("components/details/table/TableDetails"),
    ActionBar:                () => import("components/ActionBar"),
    DocumentList:             () => import("components/details/document/DocumentList"),
    FormContainer:            () => import("components/details/FormContainer"),
    FormHeader:               () => import("components/details/FormHeader"),
    DocumentViewer:           () => import("components/details/document/DocumentViewer"),
    DialogRicoveri:           () => import("components/details/dialog/DialogRicoveri"),
  },

  props: {
    /**
     * Command to execute when Details is created
     * [ actionPratiche ]   to invoke ACTION_PRATICHE command
     * [ newPratica ]       to invoke NEW_PRATICA command
     */
    command: {
      type: String,
      required: false,
    },

    // Mode to set when Details is created
    detailsMode: {
      type: String,
      required: false,
      default: Mode.View,
      validator: (val) => Object.values(Mode).includes(val),
    },

    // Options passed to the optional command
    options: {
      type: Object,
      required: false,
    },
  },

  data() {
    return { 
      mode: this.detailsMode,

      pratica: null,

      // moduleState is used to 'notify' the module that the state changes
      moduleState: 0,

      // true to show Dialog Document
      dialogDocument: false,
      document: null,

      failureError: false,

      showDialogRicoveri: false,
      ricoveriList: [],
    };
  },

  computed: {
    ...mapGetters([
      'listPraticaInfo',
      'selectedPraticaId',
      'selectedPraticaInfo',
      'showOverlay',
    ]),

    // if the document type is 'ModuleIstatB', identifier of istatA is required
    documentFixed() {
      if (this.document?.tipo_documento == 'ModuloIstatB') {
        const docIstatA = _.find(this.pratica.documenti.documento, doc => doc.tipo_documento == 'ModuloIstatA');
        return {
          ...this.document,
          'istat_a_identifier': docIstatA?.identifier
        };
      }
      return this.document;
    },

    formHeaderTitle() {
      // If the skeleton loader is active, return a placeholder string.
      if (this.showSkeletonLoader) return ". . .";

      // If no practice is selected, return a message indicating so.
      if (!this.pratica) return "NESSUNA PRATICA SELEZIONATA";

      let name = this.pratica.anagrafica_deceduto.nome ?? "";
      let surname = this.pratica.anagrafica_deceduto.cognome ?? "";

      return `${surname} ${name}`;
    },

    formHeaderSubtitle() {
      if (this.showSkeletonLoader || !this.pratica) return "";

      let result = "";
      // Calculate the deceased's age at the time of death.
      let birthdayDate = moment(this.pratica.anagrafica_deceduto.dt_nascita);
      let deathDate = moment(this.pratica.comunicazione_decesso.dt_morte);

      const deathDateYears = deathDate.diff(birthdayDate, "years");
      const deathDateMonths = deathDate.diff(birthdayDate, "months");
      const deathDateDays = deathDate.diff(birthdayDate, "days");

      let age = {};

      // if age is less than 1 year, deathDateYears = 0 ==> deathDateMonth will be selected
      // if age is less than 1 month, deathDateMonth = 0 ==> deathDateDay will be selected
      if (deathDateYears > 0) {
        age.value = deathDateYears;
        age.unit = deathDateYears == 1 ? 'anno' : 'anni';
      } else if (deathDateMonths > 0) {
        age.value = deathDateMonths;
        age.unit = deathDateMonths == 1 ? 'mese' : 'mesi';
      } else {
        age.value = deathDateDays;
        age.unit = deathDateDays == 1 ? 'giorno' : 'giorni';
      }

      result += !DateUtils.isEmptyDate(birthdayDate)
        ? `Nato ${DateUtils.formatDate(birthdayDate)}`
        : "";

      result += !DateUtils.isEmptyDate(deathDate) && age.value >= 0
        ? ` --- Morto ${DateUtils.formatDate(deathDate)} (età di ${age.value} ${age.unit})`
        : "";

      return result;
    },

    /**
     * Returns the description of the selected 'pratica' to be displayed in the form header.
     * 
     * @returns {string} - The formatted 'pratica' description as a string.
     */
    formPraticaDescription() {
      return this.selectedPraticaInfo && this.pratica
        ? this.selectedPraticaInfo.nome + " del " + DateUtils.formatDate(this.pratica.dt_creazione)
        : "";
    },

    /**
     * Returns information about the selected 'pratica' to be displayed in a form.
     * 
     * @returns {string} - The formatted 'pratica' information as a string.
     */
    formPraticaInfo() {
      let role = ListUnitRoleResourceStore.getNameSurnameById(this.selectedPraticaInfo.user_id);
      return  this.selectedPraticaInfo
        ? "In carico a " + role + " (" + this.selectedPraticaInfo.activity_name + ")"
        : "";
    },

    /**
     * Determines if the current mode is the Edit mode.
     * 
     * @returns {boolean} - True if the current mode is edit mode, false otherwise.
     */
    isEditMode() {
      // this.pratica = null                => New Pratica
      // this.selectedPraticaInfo = null    => New Pratica
      if (!this.pratica || !this.selectedPraticaInfo)
        return this.mode == Mode.Edit;

      let infoActID = this.selectedPraticaInfo.activity_definition_id;
      let praticaActID = this.pratica.activity_definition_id;

      // infoActID != praticaActID means that the Pratica is inconsistent
      return this.mode == Mode.Edit && infoActID == praticaActID;
    },

    showPraticaInfo() {
      return this.pratica && this.selectedPraticaInfo && this.selectedPraticaInfo.nome && !this.showSkeletonLoader;
    },

    showSkeletonLoader() {
      // let isPraticaNew = (this.pratica && !this.pratica.stato);
      return this.failureError || (this.showOverlay && // !isPraticaNew &&
        (!this.pratica || this.pratica.id != this.selectedPraticaId || !this.selectedPraticaInfo));
    },

    showDocumentList() {
      return this.pratica?.documenti?.documento?.length;
    },
  },

  watch: {
    /**
     * Watcher for changes to the list of pratica info store objects.
     * 
     * @param {Array} newValue - The new list of pratica info objects.
     * @param {Array} oldValue - The previous list of pratica info objects.
     * @returns {Promise<void>} - A promise that resolves when the readPraticaOnValueChanged method has completed.
     */
    async listPraticaInfo(newValue, oldValue) {
      const newPraticaInfo = _.find(newValue, p => p.id == this.selectedPraticaId);
      const oldPraticaInfo = _.find(oldValue, p => p.id == this.selectedPraticaId);

      await this.readPraticaOnValueChanged(newPraticaInfo, oldPraticaInfo);
    },

    /**
     * Watcher for changes to the selecred pratica id store value.
     * 
     * @param {Integer} newValue - The new selectedPraticaId value.
     */
    async selectedPraticaId(newValue) {
      await this.readPraticaOnValueChanged(newValue, this.pratica ? this.pratica.id : undefined);
    },
  },

  methods: {
    // Method invoked on Action Button click
    onActionButtonClick(action) {
      ActionHandler.handleActionEvent(this, action);
    },

    onDocumentsGenerated(joinedDocs) {
      this.pratica.documenti.documento.push(...joinedDocs);
    },

    onDocumentSelected(event) {
      this.document = event;
      this.dialogDocument = true;
    },

    onPraticaUpdate(praticaUpdated) {
      // updating only reference to improve efficiency
      // in case of edit cancelled pratica will reset
      // after Caronte.readPratica command execution
      this.pratica = praticaUpdated;
    },

    async onRicercaRicoveri(codicePaziente) {
      // "Ricerca Ricoveri" command execution
      await this.wrapCommand(this._CMDRicercaRicoveri, {
        options: { codicePaziente: codicePaziente },
        after: {
          associaRicoveri: true,
        },
      });
    },

    associaRicoveri(ricoveri) {
      if (!ricoveri?.length) {
        this.$store.commit(
          'showDialogWarning',
          "Non sono state trovate informazioni riguardanti il paziente cercato.",
        );
      } else if (ricoveri.length == 1) {
        const ricovero = ricoveri[0];

        this.pratica = PraticaUtil.fixAnagraficaFromRicovero(this.pratica, ricovero);

        this.$store.commit(
          'showDialogWarning',
          `
            I dati dell'anagrafica sono stati aggiornati in base al
            ricovero del paziente ${ricovero.cognome} ${ricovero.nome}.
          `,
        );

        this.refreshModule();
      } else {
        // todo: dialog to choose paziente that will replace current anagrafica
        this.ricoveriList = ricoveri;
        this.showDialogRicoveri = true;
      }
    },

    // It reads the pratica if [ newValue != oldValue ]
    async readPraticaOnValueChanged(newValue, oldValue) {
      let id = this.selectedPraticaId;

      if (!id ) {
        this.pratica = null;
      } else if (id > 0 && !_.isEqual(newValue, oldValue)) {
        await this.wrapCommand(this._CMDReadPratica, {
          options: { id: id },
          after: {
            updatePratica: true,
            refreshModule: this.pratica != null,
            checkActions: this.pratica != null
          },
        });
      }
    },

    refreshModule() {
      this.moduleState++;
    },

    setViewMode() {
      // scrolls the form to the top
      // this?.$refs?.formContainer?.scroll();
      this.mode = Mode.View
    },

    ///
    /// Exposed methods
    ///

    /**
     * Exposed method used to make a refresh of the selected
     * pratica and to set the Details' mode to View mode
     * Equivalent to 'CANCEL' command
     */
    async exitEditMode() {
      let actionId = this.getNextActivityId('AUTO_ACTION_ON_CANCEL');

      if (actionId) {
        // executing 'ON_ACTION_PRATICHE'
        this.actionPratiche({ action_id: actionId });
      } else {
        // executing 'LIST_PRATICA_INFO'
        await this.wrapCommand(this._CMDListPraticaInfo, {
          before: { positivize: true, setViewMode: true },
          after: { clearPratica: !this.selectedPraticaId },
        });
        if (this.pratica) {
          // executing 'READ_PRATICA'
          await this.wrapCommand(this._CMDReadPratica, {
            options: { id: this.pratica.id },
            after: {
              updatePratica: true,
              refreshModule: true,
              checkActions: true
            },
          });
        }
      }
    },

    // Exposed method used to enter in Edit mode
    enterEditMode(options) {
      this?.$refs?.formContainer?.goToEditModule(options?.new);
      this.mode = Mode.Edit;
    },

    /**
     * Exposed method used to perform ACTION_PRATICHE command
     * 
     * @param { Options } options
     *        Options attributes:
     *        - enter_edit_mode
     *        - action_id
     */
    async actionPratiche(options = {}) {
      let enterEditMode = options.enter_edit_mode || false;

      return await this.wrapCommand(this._CMDActionPratiche, {
        options: {
          ...options,
          praticaId: this.pratica.id,
          year: this.pratica.anno_atto,
          number: this.pratica.numero_atto,
        },
        before: { fillProcessIds: true },
        after: {
          refreshPratiche: true,
          enterEditMode: enterEditMode,
          enterViewMode: !enterEditMode
        },
      })
    },

    // Exposed method used to perform NEW_PRATICA command
    async newPratica() {
      await this.wrapCommand(this._CMDNewPratica, {
        before: { negativize: true },
        after: {
          updatePratica: true,
          enterEditMode: true,
          new: true,
          refreshModule: true
        }
      });
    },

    /**
     * Exposed method used to Save modifies
     * @param { Options } options
     *        Options attributes:
     *        - skip_validation
     *        - enter_edit_mode
     *        - action_id
     */
    async save(options = {}) {
      const status = this.pratica?.activity_decoration?.STATUS || 'ModificaAnagrafica';
      const userId = DetailsUtil.getUserId(status);

      let validated = await this.wrapCommand(this._CMDWritePratica, {
        options: { pratica: this.pratica, userID: userId },
        before: { validate: true },
        after: { updatePratica: true },
      });
      if (!validated) return;

      // Filling [ options ] required attributes
      options.action_id = this.getNextActivityId('AUTO_ACTION_ON_SAVE') ?? this.pratica.activity_definition_id;
      
      // new pratica has empty action_id
      if (options.action_id) {
        let nextAction = _.find(this.pratica.azioni.action, a => a.id == options.action_id);
        
        if (nextAction)
          options.enter_edit_mode = nextAction.decoration.ENTER_EDIT_MODE ?? false;
      }

      let enterEditMode = options.enter_edit_mode ?? false;

      await this.wrapCommand(this._CMDActionPratiche, {
        options: {
          ...options,
          praticaId: this.pratica.id,
          year: this.pratica.anno_atto,
          number: this.pratica.numero_atto,
        },
        before: { fillProcessIds: true },
        after: {
          changeSelectedPraticaIds: true,
          selectedPraticaIds: [ this.pratica.id ],
          positivize: true,
          refreshPratiche: true,
          enterEditMode: enterEditMode,
          enterViewMode: !enterEditMode,
        }
      });
    },

    /**
     * Method used to validate selected form
     * It calls 'validate()' method of the selected form
     * 
     * 'Async' required in case of "CondizioniMorbose Dialog"
     */
    async validate() {
      return await this.$refs.formContainer.validate();
    },

    // Exposed method used to pop confirm dialog value
    pop(text) {
      return this.$refs.dialogConfirmDeletePratica.pop(text);
    },

    ///
    /// Utilities methods
    ///

    /**
     * Method used to get the activity id of the next action to be executed
     * It is used in 'save()' exposed method
     */
    getNextActivityId(autoAction) {
      const storeIds = ListMetaStore.getActivityParameter(this.pratica.activity_definition_id, autoAction);
      const ids = storeIds ? storeIds.split('|') : null;
      
      let actionIds = _.map(this.pratica.azioni.action, a => a.id);
      let id = _.find(ids, id => actionIds.includes(parseInt(id)));

      return id;
    },

    /**
     * It wraps a command adding 'before' and 'after' common options.
     * 
     * @param { Function } command to be wrapped
     */
    async wrapCommand(command, { options = {}, before = {}, after = {} } ) {
      /// 
      /// before section
      /// 
      if (before.validate)
        if (!await this.validate())
          return false;

      // positivize or negativize to give information about state
      before.positivize && this.$store.commit('positivizeSelectedPraticaIds');
      before.negativize && this.$store.commit('negativizeSelectedPraticaIds');

      if (before.fillProcessIds && !options.process_ids)
        options.process_ids = [ this.pratica.id ];
      
      before.setViewMode && this.setViewMode();
      
      ///
      /// command execution
      ///
      let result;
      try {
        result = await command.apply(this, [ options ]);
        this.failureError = false;
      } catch (err) {
        this.failureError = true;
        return false;
      }

      ///
      /// after section
      ///
      after.updatePratica && (this.pratica = result);
      after.clearPratica && (this.pratica = null);

      after.associaRicoveri && this.associaRicoveri(result);

      after.changeSelectedPraticaIds && this.$store.commit('changeSelectedPraticaIds', after.selectedPraticaIds);

      after.positivize && this.$store.commit('positivizeSelectedPraticaIds');
      
      after.refreshPratiche && await this._CMDListPraticaInfo();

      // change selected mode to Edit or View
      after.enterEditMode && this.enterEditMode({ new: after.new });
      after.enterViewMode && this.setViewMode();

      after.refreshModule && this.refreshModule();

      if (after.checkActions) {
        await this.checkActionSignDocument() || await this.checkActionSendToMedicinaLegale() || await this.checkActionSendToComune();
      }

      return true;
    },

    /**
     * Checks if the current 'pratica' has a document ready to be signed.
     * If it does, prompts the user to sign the document and executes the sign document action if the user agrees.
     * 
     * @returns {Promise<boolean>} A Promise that resolves to true if the sign document action was executed, false otherwise.
     */
    async checkActionSignDocument() {
      const actionSign = _.find(this.pratica.azioni.action, a => a.manager.name == 'ZapSign.SignDocument');
      if (!actionSign) return false;

      const text = "La pratica ha un documento pronto per la firma. Vuoi firmare subito il documento?";
      const value = await this.$refs.dialogConfirmSignDocument.pop(text);

      if (value) {
        ActionHandler.handleActionEvent(this, actionSign);
      }

      return true;
    },

    /**
     * Checks if the current 'pratica' is ready to be sent to 'medicina legale'.
     * If it does, prompts the user to send the document and executes the send action if the user agrees.
     * 
     * @returns {Promise<boolean>} A Promise that resolves to true if the send action was executed, false otherwise.
     */
    async checkActionSendToMedicinaLegale() {
      const actionSend = _.find(this.pratica.azioni.action, a => a.manager.name == 'Item.Validate.Ospedale.SendToMedicinaLegale');
      if (!actionSend) return false;

      const text = "La pratica è pronta per essere inviata alla Medicina Legale. Vuoi inviarla subito?";
      const value = await this.$refs.dialogConfirmSendToMedicinaLegale.pop(text);

      if (value) {
        // "true" if the command execute successfully
        const result = await this.actionPratiche({ action_id: actionSend.id });

        if (result) {
          this.$store.commit('showDialog', {
            title: "Informazioni",
            text: "La pratica è stata inviata correttamente alla Medicina Legale.",
          })
        }
      }

      return true;
    },

    // Checks if the current 'pratica' is ready to be sent to 'comune'.
    async checkActionSendToComune() {
      const actionSend = _.find(this.pratica.azioni.action, a => a.manager.name == 'Item.Validate.SendToComune');
      if (!actionSend) return false;

      this.$store.commit('showDialog', {
        title: "Informazione",
        text: `
          La pratica è pronta per essere inviata al comune.
          Ricordarsi di premere il pulsante di invio nella barra delle azioni
        `
      });

      return true;
    },
  },

  async created() {
    let id = this.$store.getters.selectedPraticaId;

    switch (this.command) {
      case 'newPratica':
        await this.newPratica();
        break;

      case 'actionPratiche':
        await this.wrapCommand(this._CMDReadPratica, {
          options: { id: id },
          after: { updatePratica: true },
        })
        await this.actionPratiche(this.options);
        break;

      default:
        if (id > 0) {
          await this.wrapCommand(this._CMDReadPratica, {
            options: { id: id },
            after: {
              updatePratica: true,
              refreshModule: true,
              checkActions: true
            },
          });
        }
    }
  }
};
</script>