<template>
  <!-- cell -->
  <div>
    <v-row no-gutters>

      <!-- Start icon -->
      <v-col cols="1" align-self="start">
        <v-icon>{{ icon }}</v-icon>
      </v-col>

      <!-- Document name -->
      <v-col cols="auto">
        <p class="doc-name">{{ params.node.data.name }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ICONS } from 'services/icon_util';

export default {
  data() {
    return {};
  },

  computed: {
    // It returns the icon related to the document's type
    icon() {
      let icon;
      switch (this.params.node.data.stato) {
        case 'Generato':
        case 'Incoerente':
        case 'NonEmesso':
          icon = ICONS.ICO_PDF.mdi;
          break;
        case 'Annullato':
        case 'Firmato':
        case 'FirmatoIncoerente':
          icon = ICONS.ICO_P7M.mdi;
          break;
        case 'Temporaneo':
          icon = ICONS.ICO_TEMPORARY.mdi;
          break;
        default:
          icon = ICONS.ICO_UNKNOWN_FILE_TYPE.mdi;
      }

      return icon;
    }
  }
};
</script>

