<template>
  <v-dialog
    v-model="value"
    max-width="800"
    persistent
  >
    <v-card id="dialog-riscontro-autoptico">
      <!-- Title -->
      <v-card-title> Condizioni Morbose - Riscontro Autoptico </v-card-title>

      <v-divider />

      <v-container>
        <!-- Text -->
        <p class="text">
          Indicare ({{ condition }}) come unica condizione morbosa senza farla precedere
          da un'altra ad essa connessa da un punto di vista ezio-patogenico implica
          l'obbligatorietà della richiesta del riscontro autoptico.
        </p>

        <v-radio-group v-model="selected">
          <!-- Modifica della diagnosi -->
          <v-radio value="delete-condition">
            <template v-slot:label><p class="radio-button-text">Elimina</p></template>
          </v-radio>

          <!-- Conferma e resta in Scheda morte naturale -->
          <v-radio value="open-new-conditions">
            <template v-slot:label>
              <div class="radio-div">
                <span class="radio-button-text radio-span">Conferma, ma apri</span>

                <c-text-field
                  v-model="newerNumber"
                  :max="maxNewConditionsNumber"
                  type="number"
                  min="1"
                  class="radio-text-field"
                />

                <span class="radio-button-text radio-span">campi e sposta la condizione morbosa all'ultimo posto</span>
              </div>
            </template>
          </v-radio>

          <!-- Conferma e passa in Scheda morte violenta -->
          <v-radio value="riscontro-autoptico-conditions">
            <template v-slot:label>
              <p class="radio-button-text">Conferma come unico campo e richiedi riscontro autoptico</p>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>

      <v-card-actions>
        <v-spacer />

        <!-- Confirm Button -->
        <v-btn
          @click="onConfirm"
          class="btn-confirm"
          outlined
        >
          CONFERMA SCELTA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogCondizioniRiscontroAutoptico",

  props: {
    value: {
      type: Boolean,
      required: false,
      default: true,
    },

    condition: {
      type: String,
      required: true,
    },

    // maximum new condition number
    maxNewConditionsNumber: {
      type: Number,
      required: true,
    },
  },

  // declaring events that will be emitted
  emits: ['delete-condition', 'open-new-conditions', 'riscontro-autoptico-conditions'],

  data() {
    return {
      newerNumber: 1,

      // selected radioButton value
      selected: "delete-condition",
    };
  },

  methods: {
    // emitting selected radio button corresponding event
    onConfirm(_) {
      if (this.selected === 'open-new-conditions') {
        this.$emit(this.selected, Math.min(this.newerNumber, this.maxNewConditionsNumber));
      } else {
        this.$emit(this.selected);
      }
      this.$emit('input', false);
    }
  },
};
</script>