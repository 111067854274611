<template>
  <div id="table">
    <!-- Pratiche grid -->
    <ag-grid-vue
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :gridOptions="gridOptions"
      :rowData="listPraticaInfo"
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      overlayNoRowsTemplate="Nessuna pratica"
    />
  </div>
</template>

<script>
// ag-grid-vue
import { AgGridVue } from "ag-grid-vue";
// vuex
import { mapGetters } from "vuex";
// stores
import {
  ListAttivitaDaFareStore,
  ListUnitRoleResourceStore,
  UserInformationStore,
} from "services/commands/stores";
// components
import TableCellRenderer from "components/TableCellRenderer";
// services
import DateUtils from "services/date_util";
import router from "router";

export default {
  name: "Table",

  components: {
    AgGridVue,
    TableCellRenderer,
  },

  data() {
    return {
      gridOptions: {
        rowSelection: 'multiple',
        animateRows: true,

        getRowId: (params) => params.data.id,

        // option used to update ids on selection change
        onSelectionChanged: this.updateSelectedPraticaIds,

        // option used to open details view on row double click
        onRowDoubleClicked: this.updateAndGoToDetails,
      },

      // default column definition
      defaultColDef: {
        sortable: false,
        resizable: true,
        autoHeight: true,
        suppressMovable: true,
      },

      columnDefs: [
        // "Atto/Deceduto" column
        {
          headerName: "Atto/Deceduto",
          field: "nome",
          cellRenderer: 'TableCellRenderer',
        },

        // "Data di morte" column
        {
          headerName: "Data di morte",
          field: "dt_morte",
          cellRenderer: (params) => {
            const { value, data } = params;
            const emptyDate = DateUtils.isEmptyDate(value);
            return /*html*/`
              <p class="text-first-line">${emptyDate ? "" : DateUtils.formatDate(value)}</p>
              <p class="text-second-line">${data?.reparto?.name}</p>
            `;
          },
        },

        // "Attività" column
        {
          headerName: "Attività",
          field: "attivita_da_fare.attivita",
          cellRenderer: (params) => {
            const [value1, value2] = params.value || [];
            const attivita1 = ListAttivitaDaFareStore.getAttivityByValue(value1) ?? "";
            const attivita2 = ListAttivitaDaFareStore.getAttivityByValue(value2) ?? "";
            return /*html*/`
              <p class="text-first-line">${attivita1}</p>
              <p class="text-second-line">${attivita2}</p>
            `;
          },
        },

        // "In carico a" column
        {
          headerName: "In carico a",
          field: "user_id",
          cellRenderer: (params) => {
            const nameSurname = ListUnitRoleResourceStore.getNameSurnameById(params.value);
            return /*html*/`<p class="text-first-line">${nameSurname}</p>`;
          },
        },

        // "Accettato/Rifiutato" column
        {
          headerName: "Accettato/Rifiutato",
          resizable: this.isComune(),
          field: "dt_accettazione",
          cellRenderer: (params) => {
            const { value } = params;
            const isEmptyDate = DateUtils.isEmptyDate(value);
            return /*html*/`<p class="text-first-line">${isEmptyDate ? "" : DateUtils.formatDate(value)}</p>`;
          },
        },
      ],

      // "Ricevuta il" column added to columnDefs if user.categoriaUtente = 'Comune'
      receivedColumn: {
        headerName: "Ricevuta il",
        resizable: true,
        field: "dt_ricezione",
        cellRenderer: (params) => {
          const { value } = params;
          return /*html*/`<p class="text-first-line">${DateUtils.formatDate(value)}</p>`;
        },
      },

      handlerColumn: {
        headerName: "Gestionale",
        resizable: false,
        field: "dt_ricezione",
        cellRenderer: (params) => {
          const { dt_invio_gestionale, codice_gestionale } = params.data;
          let text = '';

          if (dt_invio_gestionale && !DateUtils.isEmptyDate(dt_invio_gestionale)) {
            const gestionale = codice_gestionale ? `Codice: ${codice_gestionale}` : '';
            text = `${DateUtils.formatDate(dt_invio_gestionale)}|${gestionale}`;
          }
          return /*html*/`<p class="text-first-line">${text}</p>`;
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'selectedPraticaIds',
      'listPraticaInfo',
      'requestCount',
    ]),
  },

  watch: {
    // updating selectedNodes on selectedPraticaId's updated.
    selectedPraticaIds(newValue, oldValue) {
      this.setSelectedNodes(newValue, oldValue);
    },

    // required to update selection to show updated actions (ex: when Sign action performed)
    requestCount(newValue) {
      if (newValue === 0) {
        const { api } = this.gridOptions;
      
        // Emit a selection change event with the selected rows
        this.$emit('selectionChange', api.getSelectedRows());
      }
    }
  },

  methods: {
    isComune() {
      return UserInformationStore.getCategoriaUtente() == 'Comune';
    },

    /**
     * Handler for the grid ready event that sets up the grid API and column API,
     * resizes the columns to fit the container,and adds a listener for the resize window event.
     * 
     * @param {Object} params - The parameters for the grid ready event.
     * @param {Object} params.api - The grid API object.
     * @param {Object} params.columnApi - The column API object.
     */
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      
      params.api.sizeColumnsToFit();
      window.addEventListener("resize", this.resizeWindowHandler);
    },

    // Sets the selected nodes in the grid and ensures the visibility of the selected row.
    setSelectedNodes(newSelectedPraticaIds, oldSelectedPraticaIds) {
      const { api } = this.gridOptions;

      if (!api) return;

      if (newSelectedPraticaIds.length === 0 && oldSelectedPraticaIds.length > 0) {
        api.deselectAll();
      } else {
        _.forEach(newSelectedPraticaIds, id => {
          const selected = api.getRowNode(id);
          if (selected) {
            selected.setSelected(true);
            api.ensureIndexVisible(selected.rowIndex, null);
          }
        });
      }
    },

    // Updates the selected Pratica IDs in the store and emits a selection change event.
    updateSelectedPraticaIds() {
      const { api } = this.gridOptions;

      // Map the selected rows to an array of Pratica IDs
      const selectedPraticaIds = _.map(api.getSelectedRows(), (node) => Number(node.id));

      // Update the selected Pratica IDs in the Vuex store
      this.$store.commit('changeSelectedPraticaIds', selectedPraticaIds);
      
      // Emit a selection change event with the selected rows
      this.$emit('selectionChange', api.getSelectedRows());
    },

    /**
     * Updates the selected Pratica IDs in the store and navigates to the Details component.
     * 
     * @param {Object} event - The event that triggered the update and navigation.
     * @param {number} event.data.id - The ID of the selected Pratica.
     */
    updateAndGoToDetails(event) {
      this.$store.commit('changeSelectedPraticaIds', [event.data.id]);

      // route to Details component
      router.push({ name: 'Details' });
    },

    // Handler for the resize window event that resizes the columns in the grid.
    resizeWindowHandler() {
      const api = this.gridApi;
      setTimeout(() => api?.sizeColumnsToFit());
    },
  },
  
  created() {
    if (this.isComune()) {
      this.columnDefs.push(this.receivedColumn);
      this.columnDefs.push(this.handlerColumn);
    }
  },

  beforeDestroy() {
    // removing resize window listener
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
};
</script>
