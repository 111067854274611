import Core from './core'

const COMMAND_NAME = 'Caronte.GetPrintPraticheList'

function requestBody(params) {
  return {
    id_list: {
      element: params.id_list,
    },
    modules: params.modules,
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response)
    },
    error: (response) => {
      _.attempt(options.error, response)
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  })
}

export default {
  promise,
  call
}