import AppConfigStore from "./app_config_store";
import GeographicStore from "./geographic_store";
import ListActionStore from "./list_action_store";
import ListActivitiesStore from "./list_activities_store";
import ListAttivitaDaFareStore from "./list_attivita_da_fare_store";
import ListCondizioneProfessionaleStore from "./list_condizione_professionale_store";
import ListCondizioniMorboseStore from "./list_condizioni_morbose_store";
import ListDestinazioneSalmaStore from "./list_destinazione_salma_store";
import ListGradoIstruzioneStore from "./list_grado_istruzione_store";
import ListIntervalloTemporaleStore from "./list_intervallo_temporale_store";
import ListLuoghiDecessoStore from "./list_luoghi_decesso_store";
import ListLuogoAccidenteStore from "./list_luogo_accidente_store";
import ListMetaStore from "./list_meta_store";
import ListModalitaMorteStore from "./list_modalita_morte_store";
import ListPosizioneProfessionaleStore from "./list_posizione_professionale_store";
import ListProcessDefStore from "./list_process_def_store";
import ListRamoAttivitaStore from "./list_ramo_attivita_store";
import ListRuoloVittimaStore from "./list_ruolo_vittima_store";
import ListRepartoStore from "./list_reparto_store";
import ListSessoStore from "./list_sesso_store";
import ListStatoCivileStore from "./list_stato_civile_store";
import ListStatoGravidanzaStore from "./list_stato_gravidanza_store";
import ListTipoMedicoStore from "./list_tipo_medico_store";
import ListUnitRoleResourceStore from "./list_unit_role_resource_store";
import UserInformationStore from "./user_information_store";

export {
  AppConfigStore,
  GeographicStore,
  ListActionStore,
  ListActivitiesStore,
  ListAttivitaDaFareStore,
  ListCondizioneProfessionaleStore,
  ListCondizioniMorboseStore,
  ListDestinazioneSalmaStore,
  ListGradoIstruzioneStore,
  ListIntervalloTemporaleStore,
  ListLuoghiDecessoStore,
  ListLuogoAccidenteStore,
  ListMetaStore,
  ListModalitaMorteStore,
  ListPosizioneProfessionaleStore,
  ListProcessDefStore,
  ListRamoAttivitaStore,
  ListRuoloVittimaStore,
  ListRepartoStore,
  ListSessoStore,
  ListStatoCivileStore,
  ListStatoGravidanzaStore,
  ListTipoMedicoStore,
  ListUnitRoleResourceStore,
  UserInformationStore,
}