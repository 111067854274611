let DATA = {}

function init(json) {
  DATA = json
}

function getCustomer() {
  return _.cloneDeep(DATA.customer);
}

function getCodiceASL() {
  return getCustomer().codice_asl
}

function getLuogoDecesso() {
  return getCustomer().luogo_decesso
}

function getStruttura() {
  return _.cloneDeep(getCustomer().struttura);
}
function getTipoStruttura() {
  return getStruttura().tipo_struttura
}
function getCodiceStruttura() {
  return getStruttura().codice_struttura
}

function getPuoSelezionareRepartoDecesso() {
  return getCustomer().puo_selezionare_reparto_decesso;
}

function getModules() {
  return _.cloneDeep(DATA.modules);
}

function getUserInformations() {
  return _.cloneDeep(DATA.user_informations);
}

function getCategoriaUtente() {
  return _.cloneDeep(getUserInformations().categoria_utente);
}

function setUserInformations(userInformations) {
  DATA.user_informations = userInformations;
}

function setCustomer(customer) {
  DATA.customer = customer;
}

function getSignMethod() {
  return DATA.user_informations.sign_method;
}

export default {
  init,

  getCustomer,
  getLuogoDecesso,
  getCodiceASL,
  getStruttura,
  getCodiceStruttura,
  getTipoStruttura,
  getModules,
  getUserInformations,
  getSignMethod,
  getPuoSelezionareRepartoDecesso,
  getCategoriaUtente,

  setUserInformations,
  setCustomer,
}
