import Core from './core';

const COMMAND_NAME = 'ZapFlow.ActionComplete';

function requestBody(params) {
  // outParams required structure:
  //
  // outParams = {
  //   element: [
  //     {
  //       key: "docout1",
  //       value: "NEW_IDENTIFIER_DOC_1|.pdf.p7m|DocumentoFirmato"
  //     },
  //     {
  //       key: "docout2",
  //       value: "NEW_IDENTIFIER_DOC_2|.pdf.p7m|DocumentoFirmato"
  //     },
  //   ]
  // }
  return {
    process_id: params.process_id,
    action: params.action,
    out_params: params.out_params,
    comment: params.comment,
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params));
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response);
    },
    error: (response) => {
      _.attempt(options.error, response);
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  })
}

export default {
  promise,
  call
}