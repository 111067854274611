<template>
  <div id="uncategorized-panel" class="dropzone" data-category-id="null" :disabled="true">
    <!-- Displayed when no files are added yet -->
    <div v-if="showDzMessage" class="dz-message">
      <b>Trascina</b> qui i file
      <br>Oppure <b>scegli</b> i file da caricare
    </div>

    <!-- Container for displaying uploaded files -->
    <div class="uploaded-file-box">
      <div class="uploaded-file-queue dropzone-previews"></div>
    </div>

    <!-- Container for displaying uploaded pages -->
    <div v-if="pages.length > 0" class="document-group">
      <div class="document-header"></div>

      <!-- Display each uploaded page using the SinglePage component -->
      <SinglePage
        v-for="page in pages"
        data-type="page"
        :page="page"
        :key="`upage_${page.id}`"
        :previewUrl="previewUrl"
      >
        <!-- Tooltip for the "delete" button -->
        <template #left-button>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <!-- "Delete" button for the page -->
              <v-btn text icon :disabled="false" @click="deletePage(page.id)">
                <v-icon v-bind="attrs" v-on="on" color="white">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Rimuovi pagina</span>
          </v-tooltip>
        </template>
      </SinglePage>
    </div>

    <!-- Dialog Confirm shown to confirm the removal of a page -->
    <c-dialog-confirm ref="dialogConfirmPageRemove" title="Elimina pagina"/>
  </div>
</template>

<script>
// components
import SinglePage from './_commons/SinglePage.vue'
// mixins
import Dropzone from './_mixins/Dropzone.vue'

export default {
  name: 'Uncategorized',
  
  mixins: [Dropzone],

  components: {
    SinglePage
  },

  props: {
    categoriesMode: {
      type: String,
      required: false,
      default: 'vertical',
    },
    pages: {
      type: Array,
      rquired: true
    },
    splitUrl: {
      type: String,
      required: true,
    },
    previewUrl: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      addedfile: false,
    }
  },

  computed: {
    // Determines whether to display the dropzone message.
    showDzMessage() {
      return !this.addedfile && this.pages.length === 0;
    }
  },

  methods: {
    // Emits a deletePage event when the delete button is clicked.
    async deletePage(pageId) {
      const text = "Sei sicuro di voler eliminare questa pagina?";
      const value = await this.$refs.dialogConfirmPageRemove.pop(text);

      if (value) {
        this.$emit('deletePage', pageId)
      }
    },
  },

  mounted() {
    // Determine the orientation of the upload box.
    let orientationUploadBox = (this.categoriesMode === 'vertical')
      ? 'horizontal'
      : 'vertical'

    // Initialize the Dropzone component.
    this.initDropzone(
      {
        element: "div#uncategorized-panel",
        url: this.splitUrl,
        clickable: true,
        previewDiv: '.uploaded-file-queue',
        categoryId: null,
        orientation: orientationUploadBox,
      }
    )

    //
    // Event handlers for the Dropzone component.
    //
    this.dropzone.on('addedfile', (file) => {
      this.addedfile = true
    })

    this.dropzone.on('reset', (file) => {
      this.addedfile = false;
    })

    this.dropzone.on('complete', (file) => {
      this.dropzone.removeFile(file)
    })
  }
}
</script>