import Core from './core'

const COMMAND_NAME = 'ZapDoc.AddSignature'

function requestBody(params) {
  return {
    document_identifier: params.document_identifier,
    signature: params.signature,
    hash_type: params.hash_type,
    certificate: params.certificate,
    signer_info: params.signer_info
  }
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response)
    },
    error: (response) => {
      _.attempt(options.error, response)
    },
    failure: (error) => {
      _.get(options, 'failure', alert)(error)
    }
  })
}

export default {
  call,
  promise
}