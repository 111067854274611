var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1000",
        persistent: "",
        transition: "dialog-top-transition",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-avatar",
                { attrs: { color: "primary darken-1", size: "40" } },
                [
                  _c("span", { staticClass: "white--text text-h5" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.avatarText) + "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "ml-5" }, [
                _vm._v("Impostazioni Account"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-container", [
                _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                rules: _vm.requiredRule,
                                label: "Cognome",
                                "prepend-icon": "mdi-badge-account",
                              },
                              model: {
                                value: _vm.userInformations.cognome,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userInformations, "cognome", $$v)
                                },
                                expression: "userInformations.cognome",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                rules: _vm.requiredRule,
                                label: "Nome",
                                "prepend-icon": "mdi-badge-account",
                              },
                              model: {
                                value: _vm.userInformations.nome,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userInformations, "nome", $$v)
                                },
                                expression: "userInformations.nome",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-select", {
                              attrs: {
                                items: _vm.listSesso,
                                rules: _vm.requiredRule,
                                label: "Sesso",
                                "prepend-icon": "mdi-gender-male-female",
                              },
                              model: {
                                value: _vm.userInformations.sesso,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userInformations, "sesso", $$v)
                                },
                                expression: "userInformations.sesso",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-date-picker", {
                              attrs: {
                                label: "Data di Nascita",
                                "minimum-date": _vm.minimumDate,
                              },
                              model: {
                                value: _vm.dataNascitaISO,
                                callback: function ($$v) {
                                  _vm.dataNascitaISO = $$v
                                },
                                expression: "dataNascitaISO",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-combobox", {
                              attrs: {
                                items: _vm.listPaesi,
                                "return-object": false,
                                label: "Comune",
                                "item-text": "descrizione",
                                "item-value": "descrizione",
                                "prepend-icon": "mdi-map-marker",
                                clearable: "",
                              },
                              model: {
                                value: _vm.userInformations.comune_nascita,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.userInformations,
                                    "comune_nascita",
                                    $$v
                                  )
                                },
                                expression: "userInformations.comune_nascita",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-combobox", {
                              attrs: {
                                items: _vm.listProvince,
                                "return-object": false,
                                label: "Provincia",
                                "item-text": "descrizione",
                                "item-value": "descrizione",
                                "prepend-icon": "mdi-map-marker",
                                clearable: "",
                              },
                              model: {
                                value: _vm.userInformations.provincia_nascita,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.userInformations,
                                    "provincia_nascita",
                                    $$v
                                  )
                                },
                                expression:
                                  "userInformations.provincia_nascita",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-divider"),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "my-3", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                type: "number",
                                label: "Telefono",
                                "hide-spin-buttons": "",
                                "prepend-icon": "mdi-phone",
                              },
                              model: {
                                value: _vm.userInformations.telefono,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.userInformations,
                                    "telefono",
                                    $$v
                                  )
                                },
                                expression: "userInformations.telefono",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                type: "number",
                                label: "Telefono 2",
                                "hide-spin-buttons": "",
                                "prepend-icon": "mdi-phone",
                              },
                              model: {
                                value: _vm.userInformations.telefono2,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.userInformations,
                                    "telefono2",
                                    $$v
                                  )
                                },
                                expression: "userInformations.telefono2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Fax",
                                "prepend-icon": "mdi-fax",
                              },
                              model: {
                                value: _vm.userInformations.fax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userInformations, "fax", $$v)
                                },
                                expression: "userInformations.fax",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Email",
                                type: "email",
                                "prepend-icon": "mdi-email",
                              },
                              model: {
                                value: _vm.userInformations.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.userInformations, "email", $$v)
                                },
                                expression: "userInformations.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.repartoSectionVisible
                  ? _c(
                      "div",
                      [
                        _c("v-divider"),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "my-3", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("c-select", {
                                  attrs: {
                                    items: _vm.listTipoMedico,
                                    label: "Tipo Medico",
                                    "prepend-icon": "mdi-hospital",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.userInformations.tipo_medico,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userInformations,
                                        "tipo_medico",
                                        $$v
                                      )
                                    },
                                    expression: "userInformations.tipo_medico",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.comuneSectionVisible
                  ? _c(
                      "div",
                      [
                        _c("v-divider"),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "my-3", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("c-combobox", {
                                  attrs: {
                                    items: _vm.listPaesi,
                                    "return-object": false,
                                    label:
                                      "Comune dell'ufficiale di stato civile",
                                    "item-text": "descrizione",
                                    "item-value": "descrizione",
                                    "prepend-icon": "mdi-map-marker",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.userInformations
                                        .ufficiale_stato_civile_comune,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userInformations,
                                        "ufficiale_stato_civile_comune",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "userInformations.ufficiale_stato_civile_comune",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("c-combobox", {
                                  attrs: {
                                    items: _vm.listProvince,
                                    "return-object": false,
                                    label:
                                      "Provincia dell'ufficiale di stato civile",
                                    "item-text": "descrizione",
                                    "item-value": "descrizione",
                                    "prepend-icon": "mdi-map-marker",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.userInformations
                                        .ufficiale_stato_civile_provincia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userInformations,
                                        "ufficiale_stato_civile_provincia",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "userInformations.ufficiale_stato_civile_provincia",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mb-3", attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("c-text-field", {
                                  attrs: {
                                    label: "Circoscrizione",
                                    "prepend-icon": "mdi-number",
                                  },
                                  model: {
                                    value:
                                      _vm.userInformations
                                        .ufficiale_stato_civile_circoscrizione,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userInformations,
                                        "ufficiale_stato_civile_circoscrizione",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "userInformations.ufficiale_stato_civile_circoscrizione",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-1" },
                  on: { click: _vm.onCloseDialog },
                },
                [_vm._v("\n        ANNULLA\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-1" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("\n        SALVA\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }