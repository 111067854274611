<template>
  <div class="login">
    <!-- Header Bar -->
    <HeaderBar />

    <div class="center">
      <!-- Alert shown in case of errors -->
      <v-alert
        v-if="errors"
        type="error"
        icon="mdi-alert-circle"
        dismissible
      >
        {{ errors }}
      </v-alert>

      <!-- Login -->
      <div class="m-4 login-form">
        <v-card class="pa-10">
          <div @keyup.enter="login">

            <h2>Accesso Utente</h2>

            <!-- Utente -->
            <v-row>
              <c-text-field
                class="ma-2"
                label="Utente"
                :rules="rules"
                v-model="username"
              />
            </v-row>

            <!-- Password -->
            <v-row>
              <c-text-field
                class="ma-2"
                label="Password"
                v-model="password"
                :key="state"
                :rules="rules"
                :type="getPasswordType(showPassword)"
                :append-icon="getPasswordIcon(showPassword)"
                @click:append="showPassword = !showPassword"
              />
            </v-row>

            <!-- Conditional component - Nuova Password -->
            <transition name="change-password">
              <v-row v-if="changePasswordMode">
                <c-text-field
                  class="ma-2 mt-3"
                  label="Nuova Password"
                  :rules="rules"
                  v-model="newPassword"
                  :type="getPasswordType(showNewPassword)"
                  :append-icon="getPasswordIcon(showNewPassword)"
                  @click:append="showNewPassword = !showNewPassword"
                />
              </v-row>
            </transition>

            <!-- Conditional component - Ripeti Password -->
            <transition name="change-password">
              <v-row v-if="changePasswordMode">
                <c-text-field
                  class="ma-2"
                  label="Ripeti Password"
                  :rules="rules"
                  v-model="newPasswordRepeated"
                  :type="getPasswordType(showNewPasswordRepeated)"
                  :append-icon="getPasswordIcon(showNewPasswordRepeated)"
                  @click:append="showNewPasswordRepeated = !showNewPasswordRepeated"
                />
              </v-row>
            </transition>

            <!-- Cambia Password OR Torna al Login -->
            <v-row>
              <p class="ml-3 mt-1 mb-10">
                <a @click="changePasswordMode = !changePasswordMode" class="a-change-password">
                  <span><u>{{ changePasswordMode ? "Torna al Login" : "Cambia Password" }}</u></span>
                </a>
              </p>
            </v-row>

            <!-- Login OR Confirm button -->
            <v-row>
              <v-btn class="ma-2" color="primary" @click="changePasswordMode ? changePassword() : login()">
                <b>{{ changePasswordMode ? "CONFERMA" : "ENTRA" }}</b>
              </v-btn>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>

    <!-- Dialog Information shown to notify that the password is correctly changed -->
    <c-dialog-information
      v-model="dialogPasswordChange"
      title="Password cambiata"
      :text="changeResultMessage"
    />
  </div>
</template>

<script>
import router from "router";
// components
import HeaderBar from "components/header/HeaderBar";
// services
import CommandUtil from "services/commands/core/command_util";

export default {
  name: "Login",

  components: {
    HeaderBar,
  },

  data() {
    return {
      rules: [(value) => !!value || "Obbligatorio."],

      // [login] required information
      username: null,
      password: null,
      
      // [change password] required information
      newPassword: null,
      newPasswordRepeated: null,

      // true   if the user wants to change the password
      // false  otherwise
      changePasswordMode: false,

      // show passwords
      showPassword: false,
      showNewPassword: false,
      showNewPasswordRepeated: false,

      // required to update TextFields on mode change
      state: 0,

      changeResultMessage: "",
      dialogPasswordChange: false,

      errors: null,
    };
  },

  watch: {
    // handling mode changes
    changePasswordMode(newValue) {
      this.errors = null;
      if (newValue) {
        this.newPassword = null;
        this.newPasswordRepeated = null;
      }
    }
  },

  methods: {
    // password utilities
    getPasswordIcon: (show) => show ? 'mdi-eye' : 'mdi-eye-off',
    getPasswordType: (show) => show ? 'text' : 'password',

    // login controller
    login() {
      this.errors = null;
      const error = [ "Errore:" ];

      if (!this.username)
        error.push("Username vuoto.");
      
      if (!this.password)
        error.push("Password vuota.");

      if (error.length > 1) {
        this.errors = error.join(" ");
      } else {  
        // success callback
        const onSuccess = () => router.push({ name: 'Home' });
        
        // error callback
        const onError = (response) => this.errors = response;

        // CMD login
        CommandUtil.login(this.username, this.password, onSuccess, onError);
      }
    },

    // change password controller
    changePassword() {
      this.errors = null;
      const error = [ "Errore:" ];

      if (!this.username)
        error.push("Username vuoto.");
      
      if (!this.password || !this.newPassword || !this.newPasswordRepeated)
        error.push("Password vuota.");

      if (error.length == 1 && this.newPassword != this.newPasswordRepeated)
        error.push("Le password non corrispondono.");

      if (error.length == 1 && this.password == this.newPassword)
        error.push("La nuova password deve essere diversa dalla precedente.");

      if (error.length > 1) {
        this.errors = error.join(" ");
      } else {
        // success callback
        const onSuccess = () => this.onPasswordChanged();
        
        // error callback
        const onError = (response) => this.errors = response;

        // CMD change_password
        CommandUtil.changePassword(this.username, this.password, this.newPassword, onSuccess, onError);
      }
    },

    // password changed handler
    onPasswordChanged() {
      this.changePasswordMode = false;

      this.password = null;
      this.newPassword = null;
      this.newPasswordRepeated = null;

      // needed to update password text field state
      this.state++;

      this.changeResultMessage = "La password è stata cambiata con successo.";
      this.dialogPasswordChange = true;
    }
  },

  mounted() {
    $("#username").focus();
  },
};
</script>