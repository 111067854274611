<template>
  <v-dialog
    v-model="value"
    max-width="1000"
    persistent
    transition="dialog-top-transition"
  >
    <v-card>

      <!-- title with avatar -->
      <v-card-title>

        <!-- avatar text -->
        <v-avatar color="primary darken-1" size="40">
          <span class="white--text text-h5">
            {{ avatarText }}
          </span>
        </v-avatar>

        <!-- title -->
        <h3 class="ml-5">Impostazioni Account</h3>
      </v-card-title>

      <v-card-text>
        <v-container>

          <!-- Generic Data section -->
          <div>
            <!-- [ cognome, nome ] -->
            <v-row no-gutters class="mb-3">

              <!-- "Cognome" -->
              <v-col cols="5">
                <c-text-field
                  v-model="userInformations.cognome"
                  :rules="requiredRule"
                  label="Cognome"
                  prepend-icon="mdi-badge-account"
                />
              </v-col>

              <v-spacer />

              <!-- "Nome" -->
              <v-col cols="5">
                <c-text-field
                  v-model="userInformations.nome"
                  :rules="requiredRule"
                  label="Nome"
                  prepend-icon="mdi-badge-account"
                />
              </v-col>
            </v-row>

            <!-- [ sesso, data_di_nascita ] -->
            <v-row no-gutters class="mb-3">

              <!-- "Sesso" -->
              <v-col cols="5">
                <c-select
                  v-model="userInformations.sesso"
                  :items="listSesso"
                  :rules="requiredRule"
                  label="Sesso"
                  prepend-icon="mdi-gender-male-female"
                />
              </v-col>

              <v-spacer />

              <!-- "Data di Nascita" -->
              <v-col cols="5">
                <c-date-picker
                  v-model="dataNascitaISO"
                  label="Data di Nascita"
                  :minimum-date="minimumDate"
                />
              </v-col>
            </v-row>

            <!-- [ comune, provincia ] -->
            <v-row no-gutters class="mb-3">
              
              <!-- "Comune" -->
              <v-col cols="5">
                <c-combobox
                  v-model="userInformations.comune_nascita"
                  :items="listPaesi"
                  :return-object="false"
                  label="Comune"
                  item-text="descrizione"
                  item-value="descrizione"
                  prepend-icon="mdi-map-marker"
                  clearable
                />
              </v-col>

              <v-spacer />

              <!-- "Provincia" -->
              <v-col cols="5">
                <c-combobox
                  v-model="userInformations.provincia_nascita"
                  :items="listProvince"
                  :return-object="false"
                  label="Provincia"
                  item-text="descrizione"
                  item-value="descrizione"
                  prepend-icon="mdi-map-marker"
                  clearable
                />
              </v-col>
            </v-row>

            <v-divider />

            <!-- [ telefono, telefono_2 ] -->
            <v-row no-gutters class="my-3">

              <!-- "Telefono" -->
              <v-col cols="5">
                <c-text-field
                  v-model="userInformations.telefono"
                  type="number"
                  label="Telefono"
                  hide-spin-buttons
                  prepend-icon="mdi-phone"
                />
              </v-col>

              <v-spacer />

              <!-- "Telefono 2" -->
              <v-col cols="5">
                <c-text-field
                  v-model="userInformations.telefono2"
                  type="number"
                  label="Telefono 2"
                  hide-spin-buttons
                  prepend-icon="mdi-phone"
                />
              </v-col>
            </v-row>

            <!-- [ fax, email ] -->
            <v-row no-gutters class="mb-3">

              <!-- "FAX" -->
              <v-col cols="5">
                <c-text-field
                  v-model="userInformations.fax"
                  label="Fax"
                  prepend-icon="mdi-fax"
                />
              </v-col>

              <v-spacer />
              
              <!-- "Email" -->
              <v-col cols="5">
                <c-text-field
                  v-model="userInformations.email"
                  label="Email"
                  type="email"
                  prepend-icon="mdi-email"
                />
              </v-col>
            </v-row>
          </div>

          <!-- Reparto Data section -->
          <div v-if="repartoSectionVisible">
            <v-divider />

            <v-row no-gutters class="my-3">
              
              <!-- "Tipo Medico" -->
              <v-col cols="5">
                <c-select
                  v-model="userInformations.tipo_medico"
                  :items="listTipoMedico"
                  label="Tipo Medico"
                  prepend-icon="mdi-hospital"
                  clearable
                />
              </v-col>
            </v-row>
          </div>

          <!-- Comune Data section -->
          <div v-if="comuneSectionVisible">
            <v-divider />
            
            <v-row no-gutters class="my-3">

              <!-- "Comune" -->
              <v-col cols="5">
                <c-combobox
                  v-model="userInformations.ufficiale_stato_civile_comune"
                  :items="listPaesi"
                  :return-object="false"
                  label="Comune dell'ufficiale di stato civile"
                  item-text="descrizione"
                  item-value="descrizione"
                  prepend-icon="mdi-map-marker"
                  clearable
                />
              </v-col>

              <v-spacer />

              <!-- "Provincia" -->
              <v-col cols="5">
                <c-combobox
                  v-model="userInformations.ufficiale_stato_civile_provincia"
                  :items="listProvince"
                  :return-object="false"
                  label="Provincia dell'ufficiale di stato civile"
                  item-text="descrizione"
                  item-value="descrizione"
                  prepend-icon="mdi-map-marker"
                  clearable
                />
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-3">

              <v-col cols="5">
                <!-- "Circoscrizione" -->
                <c-text-field
                  v-model="userInformations.ufficiale_stato_civile_circoscrizione"
                  label="Circoscrizione"
                  prepend-icon="mdi-number"
                />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- "ANNULLA" button -->
        <v-btn @click="onCloseDialog" color="primary darken-1">
          ANNULLA
        </v-btn>

        <!-- "SALVA" button -->
        <v-btn @click="onSave" color="primary darken-1" >
          SALVA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// stores
import {
  UserInformationStore,
  ListTipoMedicoStore,
  GeographicStore,
  AppConfigStore,
  ListSessoStore,
} from "services/commands/stores";
// services
import CommandUtil from 'services/commands/core/command_util';
import DateUtils from "services/date_util";
import User from "services/user";

export default {
  name: "UserProfile",

  props: {
    // value used for v-model binding
    value: {
      type: Boolean,
      required: true,
    },

    avatarText: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      userInformations: {},

      requiredRule: [ value => !!value || 'Obbligatorio' ],
    };
  },

  computed: {
    // "Data di Nascita" is ISO format used in the c-date-picker.
    dataNascitaISO: {
      get () { return this.userInformations ? DateUtils.formatDateAsIso(this.userInformations.dt_nascita) : "" },
      set(newValue) { this.userInformations.dt_nascita = moment(newValue).format() },
    },

    minimumDate() { moment('' + AppConfigStore.getAnnoMinimoNascita(), "YYYY").format() },

    repartoSectionVisible() { return this.userInformations.categoria_utente === 'Reparto' },
    
    comuneSectionVisible() { return this.userInformations.categoria_utente === 'Comune' },

    ///
    /// Utilities computed values.
    ///
    listPaesi: () => GeographicStore.listPaesi(),
    listProvince: () => GeographicStore.listProvince(),
    listSesso: () => ListSessoStore.getListSesso(),
    listTipoMedico: () => ListTipoMedicoStore.getListTipoMedico(),
  },

  methods: {
    onCloseDialog() {
      // updates v-model in parent component
      this.$emit('input', false);
    },

    onSave() {
      const onSuccess = () => {
        this.$store.commit('showDialog', {
          title: "Impostazioni Account",
          text: "Impostazioni Account salvate con successo.",
        })
      }

      const onError = () => {
        this.$store.commit('showDialog', {
          title: "Impostazioni Account",
          text: "Errore durante il salvataggio delle Impostazioni Account.",
        })
      }

      CommandUtil.saveUserInformations(this.userInformations, User.getUserId(), onSuccess, onError);
      
      // updates v-model in parent component
      this.$emit('input', false);
    },
  },

  watch: {
    // updating user informations
    value(newValue) {
      if (newValue) {
        this.userInformations = UserInformationStore.getUserInformations();
      }
    },

    'userInformations.comune_nascita' (newValue) {
      const comuneObj = GeographicStore.getPaeseObjectFromName(newValue);
      const provinciaId = comuneObj?.provincia_id;

      if (provinciaId) {
        this.userInformations.provincia_nascita = GeographicStore.getProvinciaObject(provinciaId).descrizione;
      }
    },

    'userInformations.ufficiale_stato_civile_comune' (newValue) {
      const comuneObj = GeographicStore.getPaeseObjectFromName(newValue);
      const provinciaId = comuneObj?.provincia_id;

      if (provinciaId) {
        this.userInformations.ufficiale_stato_civile_provincia = GeographicStore.getProvinciaObject(provinciaId).descrizione;
      }
    }
  },
}
</script>