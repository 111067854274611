<template>
  <div class="forms">
    <!-- Container of VTabs -->
    <div v-if="pratica" class="form-tabs-container">
      <v-tabs
        v-model="selectedTabIndex"
        :key="tabsState"
        next-icon="mdi-chevron-right-circle"
        prev-icon="mdi-chevron-left-circle"
        height="38"
        show-arrows
        density="compact"
      >
        <v-tab v-for="tab in tabsOptions"
          :key="tab.key"
          :name="tab.name"
          :class="{ active: activeModule.key == tab.key }"
          :disabled="tab.disabled"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-divider v-if="!showFormDocumenti" />
    
    <!-- Form documenti -->
    <FormDocumenti v-if="showFormDocumenti"
      @document-selected="onDocumentSelected"
      :key="moduleState"
      :praticaDetails="pratica"
    />
    
    <!-- Container of the selected Form (FormAnagrafica, FormComunicazione, ...) -->
    <div v-show="!showFormDocumenti && pratica" class="form-component-container" ref="form-div">
      <v-form v-if="pratica" :disabled="readOnly" class="v-form-container">
        <KeepAlive :include="cachedComponent">
          <!-- moduleState key is used to update component when needed -->
          <component
            :key="moduleKey"
            :is="activeModule.component"
            :praticaDetails="pratica"
            :disabled="readOnly"
            :ref="activeModule.key"
            :comunicazioneEnabled="isComunicazioneEnabled"
            @pratica-updated="onPraticaUpdate"
            @documents-generated="onDocumentsGenerated"
            @document-selected="onDocumentSelected"
            @ricerca-ricoveri-clicked="onRicercaRicoveriClick"
          />
        </KeepAlive>
      </v-form>
    </div>
    
    <!-- Empty Form -->
    <!-- <div v-show="!pratica" class="form-component-container"> -->
    <div v-if="!pratica" class="form-component-container">
      <h4 class="ma-2">Seleziona una pratica o creane una nuova.</h4>
    </div>
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';

import DetailsUtil from 'services/details_util';


const defaultModule = {
  key: "anagrafica_module",
  component: "FormAnagrafica",
  name: "Anagrafica",
  disabled: false,
  editable: true,
  index: 0,
}


export default {
  name: "FormContainer",

  components: {
    FormAnagrafica:       () => import("components/details/form/FormAnagrafica"),
    FormComunicazione:    () => import("components/details/form/FormComunicazione"),
    FormDocumenti:        () => import("components/details/form/FormDocumenti"),
    FormIstatA:           () => import("components/details/form/FormIstatA"),
    FormIstatB:           () => import("components/details/form/FormIstatB"),
    FormRelazione:        () => import("components/details/form/FormRelazione"),
    FormAccertamento:     () => import("components/details/form/FormAccertamento"),
    FormNullaOsta:        () => import("components/details/form/FormNullaOsta"),
    FormTrasporto:        () => import("components/details/form/FormTrasporto"),
    FormUploader:         () => import("components/details/form/FormUploader"),
  },

  props: {
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },

    moduleState: {
      type: Number,
      required: false,
      default: 0,
    },

    pratica: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      activeModule: defaultModule,
      editModule: defaultModule,

      tabsOptions: [defaultModule],

      // cached modules used to remove previous versions
      // of modules based on the value of moduleState
      // example structure (key = moduleState):
      // {
      //   1: [
      //     ref1A,
      //     ref1B,
      //   ],
      //   2: [
      //     ref2A,
      //     ref2B,
      //   ]
      // }
      // cachedKeys: {},

      tabsState: 0,
    };
  },

  computed: {
    ...mapGetters([
      'selectedPraticaId',
      'selectedPraticaInfo',
    ]),

    cachedComponent() {
      return (this.isEditMode && this.editModule.component) || "";
    },

    isComunicazioneEnabled() {
      return DetailsUtil.isComunicazioneEnabled()
    },

    /**
     * Determines whether the current mode is read-only or not.
     * 
     * @return {boolean}  - `true` if the current mode is read-only
     *                    - `false` if the user can edit the form.
     */
    readOnly() {
      return !(this.isEditMode && this.activeModule.name == this.editModule.name);
    },

    moduleKey() {
      return `${this.activeModule.key}${this.moduleState}`;
    },

    /**
     * The index of the currently selected tab.
     * 
     * @type {Object}
     * @property {number} get - Returns the index of the currently selected tab.
     * @property {function} set - Sets the index of the currently selected tab to the provided value.
     */
    selectedTabIndex: {
      get() { return this.activeModule.index },
      set(index) {
        this.scrollToTop();
        this.activeModule = this.tabsOptions[index]
      },
    },

    showFormDocumenti() {
      return this.pratica && this.activeModule.component == 'FormDocumenti';
    },
  },

  watch: {
    moduleState(newValue, oldValue) {
      if (oldValue) {
        this.$refs[this.activeModule.key]?.$destroy();
      }
    },
    
    // Watcher for changes to the selecred pratica id store value.
    async selectedPraticaId() {
      // updating current tabsModules
      this.updateTabsOptions();
    },

    'pratica.activity_decoration.STATUS'() {
      // updating current tabsModules
      this.updateTabsOptions();
    },
  },

  methods: {
    onPraticaUpdate(praticaUpdated) {
      if (this.activeModule.key === this.editModule.key)
        this.$emit('pratica-updated', praticaUpdated);
    },

    onRicercaRicoveriClick(codicePaziente) {
      this.$emit('ricerca-ricoveri', codicePaziente);
    },

    // Updates the array of tab options to be used in v-tabs component.
    updateTabsOptions() {
      if (!this.pratica) return null;

      const oldLength = this.tabsOptions.length;
      const status = this.pratica?.activity_decoration?.STATUS || 'ModificaAnagrafica';
      
      this.tabsOptions = DetailsUtil.getFilteredModules(this.pratica, status);
      
      // initializing activeModule and editModule using activity_decoration
      let module = this.getEditModule();

      this.editModule = module;
      this.activeModule = this.editModule;

      // necessary in case of NULLA_OSTA tab added
      // otherwise bug related to index
      // last added will have the last index
      if (oldLength != this.tabsOptions.length)
        this.tabsState++;
    },

    /**
     * Returns the edit module for the current 'pratica' status.
     * If activity decoration is null, it returns the current edit module.
     * If a status exists, it finds the corresponding module and returns it.
     * 
     * @returns {object} The edit module.
     */
    getEditModule() {
      const status = this.pratica?.activity_decoration?.STATUS;
      if (!status)
        return this.editModule;
      
      const moduleName = DetailsUtil.getCurrentModuleName(status, this.pratica, this.selectedPraticaInfo, true);
      
      const module = _.find(this.tabsOptions,
        (tab) => tab.name === moduleName
      );

      return module ?? defaultModule;
    },

    goToEditModule(newPratica) {
      if (newPratica) this.editModule = defaultModule;
      this.activeModule = this.editModule;
    },

    onDocumentsGenerated(joinedDocs) {
      this.$emit('documents-generated', joinedDocs);
    },

    onDocumentSelected(document) {
      this.$emit('document-selected', document);
    },

    /**
     * Method used to validate selected form
     * It calls 'validate()' method of the selected form
     * 
     * 'Async' required in case of "CondizioniMorbose Dialog"
     */
    async validate() {
      this.goToEditModule();

      let result;
      if (this.$refs[this.editModule.key]) {
        result = await this.$refs[this.editModule.key].validate();
      }

      // scrolling to the top of the form
      this.scrollToTop();

      return result === true;
    },

    scrollToTop() {
      // "?" needed in case of Document Form selected
      this.$refs['form-div']?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },

  beforeCreate: () => DetailsUtil.initializeModuleMap(),

  created() {
    this.updateTabsOptions();
  }
}
</script>