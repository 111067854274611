var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-riscontro-autoptico" } },
        [
          _c("v-card-title", [
            _vm._v(" Condizioni Morbose - Riscontro Autoptico "),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-container",
            [
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "\n        Indicare (" +
                    _vm._s(_vm.condition) +
                    ") come unica condizione morbosa senza farla precedere\n        da un'altra ad essa connessa da un punto di vista ezio-patogenico implica\n        l'obbligatorietà della richiesta del riscontro autoptico.\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "delete-condition" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("p", { staticClass: "radio-button-text" }, [
                              _vm._v("Elimina"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { value: "open-new-conditions" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "radio-div" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "radio-button-text radio-span",
                                  },
                                  [_vm._v("Conferma, ma apri")]
                                ),
                                _vm._v(" "),
                                _c("c-text-field", {
                                  staticClass: "radio-text-field",
                                  attrs: {
                                    max: _vm.maxNewConditionsNumber,
                                    type: "number",
                                    min: "1",
                                  },
                                  model: {
                                    value: _vm.newerNumber,
                                    callback: function ($$v) {
                                      _vm.newerNumber = $$v
                                    },
                                    expression: "newerNumber",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "radio-button-text radio-span",
                                  },
                                  [
                                    _vm._v(
                                      "campi e sposta la condizione morbosa all'ultimo posto"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { value: "riscontro-autoptico-conditions" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("p", { staticClass: "radio-button-text" }, [
                              _vm._v(
                                "Conferma come unico campo e richiedi riscontro autoptico"
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-confirm",
                  attrs: { outlined: "" },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v("\n        CONFERMA SCELTA\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }