var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ag-header-component" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { "align-self": "center", cols: "4" } }, [
            _c("h3", [_vm._v("Lista pratiche")]),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                icon: "",
                                color: "green darken-3",
                                disabled: false,
                              },
                              on: { click: _vm.onHomeClicked },
                            },
                            [
                              _c(
                                "v-icon",
                                _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                                [_vm._v("mdi-home-outline")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v("Torna all'elenco delle pratiche")]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", icon: "", disabled: false },
                              on: { click: _vm.onRefreshClicked },
                            },
                            [
                              _c(
                                "v-icon",
                                _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                                [_vm._v("mdi-sync")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Ricarica pratiche")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }