var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("HeaderBar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          _vm.errors
            ? _c(
                "v-alert",
                {
                  attrs: {
                    type: "error",
                    icon: "mdi-alert-circle",
                    dismissible: "",
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.errors) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-4 login-form" },
            [
              _c("v-card", { staticClass: "pa-10" }, [
                _c(
                  "div",
                  {
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("h2", [_vm._v("Accesso Utente")]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("c-text-field", {
                          staticClass: "ma-2",
                          attrs: { label: "Utente", rules: _vm.rules },
                          model: {
                            value: _vm.username,
                            callback: function ($$v) {
                              _vm.username = $$v
                            },
                            expression: "username",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("c-text-field", {
                          key: _vm.state,
                          staticClass: "ma-2",
                          attrs: {
                            label: "Password",
                            rules: _vm.rules,
                            type: _vm.getPasswordType(_vm.showPassword),
                            "append-icon": _vm.getPasswordIcon(
                              _vm.showPassword
                            ),
                          },
                          on: {
                            "click:append": function ($event) {
                              _vm.showPassword = !_vm.showPassword
                            },
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "change-password" } },
                      [
                        _vm.changePasswordMode
                          ? _c(
                              "v-row",
                              [
                                _c("c-text-field", {
                                  staticClass: "ma-2 mt-3",
                                  attrs: {
                                    label: "Nuova Password",
                                    rules: _vm.rules,
                                    type: _vm.getPasswordType(
                                      _vm.showNewPassword
                                    ),
                                    "append-icon": _vm.getPasswordIcon(
                                      _vm.showNewPassword
                                    ),
                                  },
                                  on: {
                                    "click:append": function ($event) {
                                      _vm.showNewPassword = !_vm.showNewPassword
                                    },
                                  },
                                  model: {
                                    value: _vm.newPassword,
                                    callback: function ($$v) {
                                      _vm.newPassword = $$v
                                    },
                                    expression: "newPassword",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "transition",
                      { attrs: { name: "change-password" } },
                      [
                        _vm.changePasswordMode
                          ? _c(
                              "v-row",
                              [
                                _c("c-text-field", {
                                  staticClass: "ma-2",
                                  attrs: {
                                    label: "Ripeti Password",
                                    rules: _vm.rules,
                                    type: _vm.getPasswordType(
                                      _vm.showNewPasswordRepeated
                                    ),
                                    "append-icon": _vm.getPasswordIcon(
                                      _vm.showNewPasswordRepeated
                                    ),
                                  },
                                  on: {
                                    "click:append": function ($event) {
                                      _vm.showNewPasswordRepeated =
                                        !_vm.showNewPasswordRepeated
                                    },
                                  },
                                  model: {
                                    value: _vm.newPasswordRepeated,
                                    callback: function ($$v) {
                                      _vm.newPasswordRepeated = $$v
                                    },
                                    expression: "newPasswordRepeated",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-row", [
                      _c("p", { staticClass: "ml-3 mt-1 mb-10" }, [
                        _c(
                          "a",
                          {
                            staticClass: "a-change-password",
                            on: {
                              click: function ($event) {
                                _vm.changePasswordMode = !_vm.changePasswordMode
                              },
                            },
                          },
                          [
                            _c("span", [
                              _c("u", [
                                _vm._v(
                                  _vm._s(
                                    _vm.changePasswordMode
                                      ? "Torna al Login"
                                      : "Cambia Password"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-2",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.changePasswordMode
                                  ? _vm.changePassword()
                                  : _vm.login()
                              },
                            },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.changePasswordMode ? "CONFERMA" : "ENTRA"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("c-dialog-information", {
        attrs: { title: "Password cambiata", text: _vm.changeResultMessage },
        model: {
          value: _vm.dialogPasswordChange,
          callback: function ($$v) {
            _vm.dialogPasswordChange = $$v
          },
          expression: "dialogPasswordChange",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }