<template>
  <!-- V-Dialog fullscreen -->
  <v-dialog
    v-model="value"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card id="document-viewer">
      
      <!-- Toolbar -->
      <v-toolbar dark color="primary" class="toolbar">
        <!-- Close icon -->
        <v-btn icon dark @click="onCloseDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- Document name -->
        <v-toolbar-title>{{ document ? document.name : '' }}</v-toolbar-title>
      </v-toolbar>

      <!-- Overlay -->
      <v-overlay :value="showSkeletonLoader">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>

      <!-- Document iframe -->
      <div
        :class="showDocumentToolbar ? 'iframe-container' : 'iframe-container-hidden-toolbar'"
        v-show="!showSkeletonLoader"
        :key="iframeState"
      >
        <iframe
          :src="url"
          @load="hideSkeletonLoader"
          height="auto"
          width="100%"
        ></iframe>

        <!-- Version with Signatures section -->
        <!--
        <v-row no-gutters>
          Iframe Document
          <v-col cols="9" style="font-size: 0">
            <iframe
                :src="url"
                height="100%"
                width="100%"
            ></iframe>
          </v-col>

          Signatures section
          <v-col cols="3">
            <div class="mx-5 mb-10">
              <p>Verifica firme in corso...</p>
            </div>
            <div class="mx-2 mb-10">
              <v-card outlined height="150px">
              </v-card>
            </div>
            <div class="mx-2">
              <v-card outlined height="150px">
              </v-card>
            </div>
          </v-col>
        </v-row>
        -->
      </div>
    </v-card>

  </v-dialog>
</template>

<script>
import UrlUtil from "services/url_util";


export default {
  name: "DocumentViewer",

  props: {
    // v-model value
    value: {
      type: Boolean,
      required: true,
    },

    document: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showSkeletonLoader: true,
      url: '',
      iframeState: 0,
    };
  },

  computed: {
    // show document toolbar only when state of document is equal to 'Firmato', 'Generato' or 'Temporaneo'
    // otherwise, the toolbar is not shown to avoid printing aborted documents
    showDocumentToolbar() {
      const state = this.document.stato;
      return state == 'Firmato' || state == 'Generato' || state == 'Temporaneo';
    }
  },

  methods: {
    onCloseDialog() {
      // updates v-model in parent component
      this.$emit('input', false);
    },

    hideSkeletonLoader() {
      setTimeout(() => {
        this.showSkeletonLoader = false
      }, 800);
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.showSkeletonLoader = true;

          if (this.document.tipo_documento == 'ModuloIstatB') {
            this.url = UrlUtil.getViewUrlIstatB(this.document)
          } else {
            this.url = UrlUtil.getViewUrl(this.document);
          }
          
          this.iframeState++;
        }
      }
    }
  },
};
</script>
