var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "c-text-field",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "##:##",
                          expression: "'##:##'",
                        },
                      ],
                      attrs: {
                        label: _vm.label,
                        rules: _vm.rules,
                        placeholder: "hh:mm",
                        "prepend-icon": "mdi-clock-time-four-outline",
                      },
                      model: {
                        value: _vm.oraField,
                        callback: function ($$v) {
                          _vm.oraField = $$v
                        },
                        expression: "oraField",
                      },
                    },
                    "c-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.timePickerOraMorte,
        callback: function ($$v) {
          _vm.timePickerOraMorte = $$v
        },
        expression: "timePickerOraMorte",
      },
    },
    [
      _vm._v(" "),
      _vm.timePickerOraMorte
        ? _c("v-time-picker", {
            attrs: { format: "24hr", "full-width": "", "no-title": "" },
            on: {
              "click:minute": function ($event) {
                return _vm.$refs.menu.save(_vm.oraFormatted)
              },
            },
            model: {
              value: _vm.oraFormatted,
              callback: function ($$v) {
                _vm.oraFormatted = $$v
              },
              expression: "oraFormatted",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }