<template>
  <v-dialog
    v-model="value"
    max-width="600"
    persistent
  >
    <v-card id="dialog-refuse">
      <!-- Title -->
      <v-card-title>Rifiuto pratica</v-card-title>

      <v-divider />

      <!-- Body -->
      <c-textarea
        v-model="message"
        label="Motivo del rifiuto"
        class="ma-3"
      />

      <!-- Actions -->
      <v-card-actions>
        <v-spacer />

        <!-- 'Annulla' action -->
        <v-btn
          @click="onCancelClick"
          class="btn-action"
          outlined
        >
          ANNULLA
        </v-btn>

        <!-- 'OK' action -->
        <v-btn
          @click="onConfirmClick"
          :disabled="!message"
          :class="message && 'btn-action'"
          outlined
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'DialogRefuse',

  props: {
    // value:     true to make dialog visible
    //            false otherwise
    value: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      message: '',
    };
  },

  methods: {
    onCancelClick() {
      this.closeDialog();
    },

    onConfirmClick() {
      this.$emit('confirm-refuse', this.message);
      this.closeDialog();
    },

    closeDialog() {
      // restoring default values
      this.message = '';

      // closing the dialog
      this.$emit('input', false);
    }
  }
}
</script>