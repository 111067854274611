
async function join(url, path_list) {
  const body = JSON.stringify({ path_list })

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
  
  const pdfBlob = await response.blob()
  return await _pdfToBase64(pdfBlob)
}

async function _pdfToBase64(pdfBlob) {
  const toBase64 = f => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(f)
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = error => reject(error)
  })

  return await toBase64(pdfBlob)
}

export default {
  join,
}