<template>
  <v-sheet :style="disabled ? disabledStyle : {}" outlined>
    <v-row no-gutters>
      <!-- Item prepend icon -->
      <v-col v-if="prependIcon"
        align-self="center"
        cols="auto"
      >
        <v-icon :disabled="disabled" class="ml-2 mr-4">
          {{ prependIcon }}
        </v-icon>
      </v-col>

      <!-- Item text -->
      <!-- :disabled="disabled" -->
      <v-col v-if="text"
        align-self="center"
        cols="auto"
        v-ripple
      >
        <!-- <a v-if="!disabled" @click="$emit('text-click')"> -->
        <a @click="$emit('text-click')">
          {{ text }}
        </a>
        <!-- <p v-else>{{ text }}</p> -->
      </v-col>

      <v-spacer />

      <!-- Item action -->
      <v-col v-if="actionIcon"
        align-self="center"
        cols="auto"
      >
        <v-btn
          @click="$emit('action-click')"
          :disabled="disabled"
          icon
        >
          <v-icon :color="actionIconColor">
            {{ actionIcon }}
          </v-icon>
        </v-btn>
      </v-col>

    </v-row>
  </v-sheet>
</template>

<script>

export default {
  name: 'CItem',

  data() {
    return {
      disabledStyle: {
        backgroundColor: 'transparent',
      },
    };
  },
  
  props: {
    prependIcon: {
      type: String,
      required: false,
    },

    text: {
      type: String,
      required: true,
    },

    actionIcon: {
      type: String,
      required: false,
    },

    actionIconColor: {
      type: String,
      required: false,
      default: "",
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>
<style scoped>
  .v-sheet {
    height: 40px;
    padding: 1px;
  }
  
  p {
    margin-bottom: 0px;
    color: #00000061;
  }
</style>