import DefaultManager from "handlers/default_manager.js"
import store from 'store';

async function actionPerformed(context, action) {
  // "true" if the command execute successfully
  const result = await DefaultManager.actionPerformed(context, action);

  if (result) {
    store.commit('showDialog', {
      title: "Informazioni",
      text: "La pratica è stata inviata correttamente alla Medicina Legale.",
    });
  }
}

export default {
  actionPerformed,
}