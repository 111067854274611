import Cookies from 'js-cookie'

const COOKIE_NAME = '_caronte_user_data'

function setUserData(data) {
  Cookies.set(COOKIE_NAME, JSON.stringify(data) /*, { sameSite: "lax" } */)
}

function removeUserData() {
  Cookies.remove(COOKIE_NAME)
}

function getUserData() {
  let data = Cookies.get(COOKIE_NAME)
  return data ? JSON.parse(data) : {}
}

function getUser() {
  return getUserData().user
}

function getSessionId() {
  return getUserData().session_id
}

function getName() {
  return getUser().nome
}

function getSurname() {
  return getUser().cognome
}

function getUserId() {
  return getUser().id
}

function getFullName() {
  return `${getName()} ${getSurname()}`
}

function getGroupName() {
  return getUser().groups.group[0].name
}

export default {
  setUserData,
  removeUserData,
  getUser,
  getSessionId,
  getName,
  getSurname,
  getFullName,
  getUserId,
  getGroupName,
}
