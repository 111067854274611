import store from 'store';

function actionPerformed(context, action) {
  // TODO: Move logic to [sharedCommands.js]

  context.pop(text()).then((value) => {
    // if the user confirm to delete selected pratiche
    if (value) {
      const onPraticheDeleted = () => store.commit('changeSelectedPraticaIds', []);
      
      context.deleteSelectedPratiche(
        store.getters.selectedPraticaIds,
        onPraticheDeleted
      );
    }
  });
}

/**
 * Method used to generate text for the dialog component.
 * 
 * @returns { String } text
 */
function text() {
  let text = "";
  const listPraticheSelected = store.getters.listPraticheSelected;

  if (listPraticheSelected.length == 1) {
    text = `Eliminare definitivamente la pratica numero ${listPraticheSelected[0].nome}? (non sarà più possibile recuperarla)`;
  } else {
    text = `Eliminare definitivamente ${listPraticheSelected.length} pratiche? (non sarà più possibile recuperarle)`;
  }

  return text;
}

export default {
  actionPerformed,
}