<template>
  <div id="form-anagrafica">

    <c-expansion-sections
      :disabled="disabled"
      :sections="expansionSections"
    >
      <!-- "DECEDUTO" section -->
      <template #section1>
        <v-row no-gutters class="ma-2">
          <v-col cols="5" class="col-num-paziente">
            <!-- "Anno dell'Atto" field -->
            <c-text-field
              v-model="numeroPazienteAnnoFormatted"
              label="Anno dell'Atto"
              prepend-icon="mdi-archive-clock"
              type="number"
              hide-spin-buttons
            />

            <span>/</span>

            <!-- "Numero Paziente" field -->
            <c-text-field
              v-model="numeroPazienteIdFormatted"
              :rules="requiredRule"
              label="Numero Paziente"
              type="number"
              hide-spin-buttons
            >
              <v-tooltip slot="append" bottom>

                <!-- "Ricerca dati paziende append button icon" -->
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="disabled"
                    :ripple="false"
                    small
                    icon
                    @click="onRicercaRicoveriClick"
                  >
                    <v-icon color="primary">mdi-clipboard-search-outline</v-icon>
                  </v-btn>
                </template>

                <span>Ricerca dati paziente</span>
              </v-tooltip>
            </c-text-field>
          </v-col>

          <v-spacer />

          <!-- "Data del ricovero" -->
          <v-col cols="5">
            <c-date-picker
              v-model="dataRicoveroISO"
              :rules="requiredRule"
              label="Data del Ricovero"
            />
          </v-col>
        </v-row>

        <!-- "Cognome", and "Nome" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-text-field
              v-model="ad.cognome"
              :rules="requiredRule"
              label="Cognome"
              prepend-icon="mdi-account-outline"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-text-field
              label="Nome"
              :rules="requiredRule"
              v-model="ad.nome"
              prepend-icon="mdi-account-outline"
            />
          </v-col>
        </v-row>

        <!-- "Data di Nascita" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-date-picker
              v-model="dataNascitaISO"
              :minimumDate="minimumDate"
              label="Data di Nascita"
              :rules="requiredRule"
            />
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>

        <!-- "Comune di Nascita" and "Provincia di Nascita" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="comuneNascita"
              :items="listPaesi"
              :rules="requiredRule"
              label="Comune di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="provinciaNascita"
              :items="listProvince"
              :rules="requiredRule"
              label="Provincia di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>

        <!-- "Nazione di Nascita" and "Cittadinanza" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5">
            <c-combobox
              v-model="statoNascita"
              :items="listStati"
              :rules="requiredRule"
              label="Nazione di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-earth"
              clearable
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="cittadinanzaSpecifica"
              :items="listCittadinanze"
              :rules="requiredRule"
              label="Cittadinanza"
              item-text="descrizione"
              prepend-icon="mdi-flag"
              clearable
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-2">
          <!-- "Sesso" -->
          <v-col cols="5">
            <c-select
              label="Sesso"
              :rules="requiredRule"
              v-model="ad.sesso"
              :items="listSesso"
              prepend-icon="mdi-gender-male-female"
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            
            <!-- "Codice Fiscale" -->
            <c-text-field
              label="Codice Fiscale"
              v-model="ad.codice_fiscale"
              prepend-icon="mdi-credit-card-outline"
            />
          </v-col>
        </v-row>
      </template>

      <!-- "DATI MORTE" section -->
      <template v-if="!comunicazioneEnabled" #section2>
        <SectionDatiMorte
          ref="sectionDatiMorte"
          :cd="pratica.comunicazione_decesso"
        />
      </template>

      <!-- "RESIDENZA" section -->
      <template #[templateResidenza]>
        <!-- "Via - Numero" and "Comune" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="3">
            <c-text-field
              label="Via"
              :rules="requiredRule"
              v-model="ad.via_residenza"
              prepend-icon="mdi-home-map-marker"
            />
          </v-col>

          <v-col cols="1" />

          <v-col cols="1">
            <c-text-field
              v-model="ad.civico_residenza"
              label="Civico"
              type="number"
              hide-spin-buttons
            />
          </v-col>

          <v-spacer />

          <v-col cols="5">
            <c-combobox
              v-model="comuneResidenza"
              :items="listPaesi"
              :rules="requiredRule"
              label="Comune"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>

        <!-- "Provincia" and "Nazione" -->
        <v-row no-gutters class="ma-2">
          <v-col cols="5" class="col-residenza-via">
            <c-combobox
              v-model="provinciaResidenza"
              :items="listProvince"
              :rules="requiredRule"
              label="Provincia"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />
          
          <v-col cols="5">
            <c-combobox
              v-model="statoResidenza"
              :items="listStati"
              :rules="requiredRule"
              label="Nazione"
              item-text="descrizione"
              prepend-icon="mdi-earth"
              clearable
            />
          </v-col>
        </v-row>
      </template>

      <!-- "DOMICILIO" section -->
      <template #[templateDomicilio]>
        <div :key="domicilioState">
          <div class="domicilio-title">
            Domicilio
            
            <c-switch
              :input-value="!ad.uguali_domicilio_residenza"
              @change="changeUgualiDomicilioResidenza"
              label="Diverso da residenza"
            />
          </div>

          <!-- "Via - Numero" and "Comune" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="3">
              <c-text-field
                v-model="formattedViaDomicilio"
                :rules="!ad.uguali_domicilio_residenza ? requiredRule : []"
                :disabled="ad.uguali_domicilio_residenza"
                label="Via"
                prepend-icon="mdi-home-map-marker"
              />
            </v-col>

            <v-col cols="1"></v-col>

            <v-col cols="1">
              <c-text-field
                v-model="formattedNumeroDomicilio"
                :disabled="ad.uguali_domicilio_residenza"
                label="Civico"
                type="number"
                hide-spin-buttons
              />
            </v-col>

            <v-spacer />

            <v-col cols="5">
              <c-combobox
                v-model="comuneDomicilio"
                :items="listPaesi"
                :rules="!ad.uguali_domicilio_residenza ? requiredRule : []"
                :disabled="ad.uguali_domicilio_residenza"
                item-text="descrizione"
                label="Comune"
                prepend-icon="mdi-map-marker"
                clearable
              />
            </v-col>
          </v-row>

          <!-- "Provincia" and "Nazione" -->
          <v-row no-gutters class="ma-2">
            <v-col cols="5" class="col-domicilio-via">
              <c-combobox
                v-model="provinciaDomicilio"
                :items="listProvince"
                :rules="!ad.uguali_domicilio_residenza ? requiredRule : []"
                :disabled="ad.uguali_domicilio_residenza"
                item-text="descrizione"
                label="Provincia"
                prepend-icon="mdi-map-marker"
                clearable
              />
            </v-col>

            <v-spacer />
            
            <v-col cols="5">
              <c-combobox
                v-model="statoDomicilio"
                :items="listStati"
                :rules="!ad.uguali_domicilio_residenza ? requiredRule : []"
                :disabled="ad.uguali_domicilio_residenza"
                item-text="descrizione"
                label="Nazione"
                prepend-icon="mdi-earth"
                clearable
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </c-expansion-sections>
    
    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />
  </div>
</template>

<script>
// stores
import {
  GeographicStore,
  AppConfigStore,
  ListSessoStore,
  ListRepartoStore
} from "services/commands/stores";
// services
import DateUtils from "services/date_util";
import GeographicUtil from 'services/geographic_util';
import CodiceFiscaleUtil from "services/codice_fiscale_util";
import PraticaUtil from "services/pratica_util";
// components
import SectionDatiMorte from "../SectionDatiMorte.vue";

export default {
  name: "FormAnagrafica",

  components: { SectionDatiMorte },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },

    comunicazioneEnabled: {
      type: Boolean,
      required: true
    }
  },

  data() {
    // if comunicazione is not enabled then performs fix comunicazione
    const pratica = this.comunicazioneEnabled
      ? _.cloneDeep(this.praticaDetails)
      : PraticaUtil.fixComunicazione(this.praticaDetails);

    return {
      // local copy of 'pratica' object from parent component
      pratica: pratica,
      ad: pratica.anagrafica_deceduto,

      requiredRule: [ (value) => !!value || "Obbligatorio." ],
      
      // required to update domicilio section state
      domicilioState: 1,

      dialogError: false,
      errors: [],
    };
  },

  computed: {
    // "Anno dell'Atto" field of the form.
    numeroPazienteAnnoFormatted: {
      get() {
        return _.isEmpty(this.pratica)
          ? moment().format("YYYY")
          : this.ad.numero_paziente.split("/")[0]
      },
      set(newValue) {
        let splitted = this.ad.numero_paziente.split("/")
        this.ad.numero_paziente = `${newValue}/${splitted[1]}`
      }
    },

    // "Numero Paziente" field of the form.
    numeroPazienteIdFormatted: {
      get() { return this.ad.numero_paziente.split("/")[1] },
      set(newValue) {
        let splitted = this.ad.numero_paziente.split("/")
        this.ad.numero_paziente = splitted[0] + "/" + newValue
      }
    },

    // "Data del Ricovero" is ISO format used in the c-date-picker.
    dataRicoveroISO: {
      get() { return DateUtils.formatDateAsIso(this.ad.dt_ricovero) },
      set(newValue) { this.ad.dt_ricovero = moment(newValue).format() }
    },

    // "Data di Nascita" is ISO format used in the c-date-picker.
    dataNascitaISO: {
      get() { return DateUtils.formatDateAsIso(this.ad.dt_nascita) },
      set(newValue) { this.ad.dt_nascita = moment(newValue).format() }
    },

    // "Via" of the 'Domicilio' form section.
    formattedViaDomicilio: {
      get() {
        return !this.ad.uguali_domicilio_residenza ? this.ad.via_domicilio : "";
      },
      set(newValue) { this.ad.via_domicilio = newValue }
    },

    // "Numero" of the 'Domicilio' form section.
    formattedNumeroDomicilio: {
      get() {
        return !this.ad.uguali_domicilio_residenza ? this.ad.civico_domicilio : "";
      },
      set(newValue) { this.ad.civico_domicilio = newValue }
    },

    expansionSections() {
      const sections = [
        'DECEDUTO',
        'RESIDENZA',
        'DOMICILIO',
      ];

      if (!this.comunicazioneEnabled) {
        sections.splice(1, 0, 'DATI MORTE');
      }

      return sections;
    },

    templateResidenza() {
      return this.comunicazioneEnabled ? 'section2' : 'section3';
    },

    templateDomicilio() {
      return this.comunicazioneEnabled ? 'section3' : 'section4';
    },

    ///
    /// Geographic fields
    ///
    comuneNascita: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'comune_nascita', 'comune_nascita_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ad, 'comune_nascita', 'comune_nascita_specifico') }
    },

    comuneResidenza: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'comune_residenza', 'comune_residenza_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ad, 'comune_residenza', 'comune_residenza_specifico') }
    },

    comuneDomicilio: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'comune_domicilio', 'comune_domicilio_specifico') },
      get() {
        if (this.ad.uguali_domicilio_residenza) return "";
        return GeographicUtil.getGeographicField(this.ad, 'comune_domicilio', 'comune_domicilio_specifico');
      }
    },

    provinciaNascita: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'provincia_nascita', 'provincia_nascita_specifica') },
      get() { return GeographicUtil.getGeographicField(this.ad, 'provincia_nascita', 'provincia_nascita_specifica') }
    },

    provinciaResidenza: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'provincia_residenza', 'provincia_residenza_specifica') },
      get() { return GeographicUtil.getGeographicField(this.ad, 'provincia_residenza', 'provincia_residenza_specifica') }
    },

    provinciaDomicilio: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'provincia_domicilio', 'provincia_domicilio_specifica') },
      get() {
        if (this.ad.uguali_domicilio_residenza) return "";
        return GeographicUtil.getGeographicField(this.ad, 'provincia_domicilio', 'provincia_domicilio_specifica');
      }
    },

    statoNascita: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'stato_nascita', 'stato_nascita_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ad, 'stato_nascita', 'stato_nascita_specifico') }
    },

    statoResidenza: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'stato_residenza', 'stato_residenza_specifico') },
      get() { return GeographicUtil.getGeographicField(this.ad, 'stato_residenza', 'stato_residenza_specifico') }
    },

    statoDomicilio: {
      set(newValue) { GeographicUtil.setGeographicField(this.ad, newValue, 'stato_domicilio', 'stato_domicilio_specifico') },
      get() {
        if (this.ad.uguali_domicilio_residenza) return "";
        return GeographicUtil.getGeographicField(this.ad, 'stato_domicilio', 'stato_domicilio_specifico');
      }
    },

    cittadinanzaSpecifica: {
      set(newValue) { this.ad.cittadinanza_specifica = newValue?.descrizione || newValue },
      get() { return this.ad.cittadinanza_specifica }
    },
    ///
    /// Utilities computed values.
    ///
    listPaesi: () => GeographicStore.listPaesi(),
    listProvince: () => GeographicStore.listProvince(),
    listStati: () => GeographicStore.listStati(),
    listCittadinanze: () => GeographicStore.listCittadinanze(),
    listSesso: () => ListSessoStore.getListSesso(),
    listReparto: () => ListRepartoStore.getListReparto(),

    minimumDate: () => moment('' + AppConfigStore.getAnnoMinimoNascita(), "YYYY").format(),

    ///
    /// Section dati morte data required for module validation.
    ///
    timeValid() {
      return this.$refs.sectionDatiMorte.validateTime();
    },

    dataMorteValid() {
      return this.$refs.sectionDatiMorte.validateDataMorte();
    },

    dataRicoveroValid() {
      const dataRicovero = this.ad.dt_ricovero;
      return this.$refs.sectionDatiMorte.validateDataRicovero(dataRicovero);
    }
  },

  watch: {
    // watch used to notify parent component when 'pratica' updates.
    'pratica.anagrafica_deceduto': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },

    ///
    /// Comuni
    ///
    // watch used to automatically fill 'provincia_nascita' and 'stato_nascita' fields.
    comuneNascita(newValue) {
      GeographicUtil.setComuneSpecificoProvinciaStato(
        this.ad,
        newValue,
        'comune_nascita_specifico',
        'provincia_nascita',
        'provincia_nascita_specifica',
        'stato_nascita',
        'stato_nascita_specifico'
      );
    },

    // watch used to automatically fill 'provincia_residenza' and 'stato_residenza' fields.
    comuneResidenza(newValue) {
      GeographicUtil.setComuneSpecificoProvinciaStato(
        this.ad,
        newValue,
        'comune_residenza_specifico',
        'provincia_residenza',
        'provincia_residenza_specifica',
        'stato_residenza',
        'stato_residenza_specifico'
      );
    },

    // watch used to automatically fill 'provincia_residenza' and 'stato_residenza' fields.
    comuneDomicilio(newValue) {
      GeographicUtil.setComuneSpecificoProvinciaStato(
        this.ad,
        newValue,
        'comune_domicilio_specifico',
        'provincia_domicilio',
        'provincia_domicilio_specifica',
        'stato_domicilio',
        'stato_domicilio_specifico'
      );
    },

    ///
    /// Stati
    ///
    // watch used to automatically fill 'cittadinanza' field.
    statoNascita(newValue) {
      this.ad.cittadinanza_specifica = newValue?.cittadinanza;
    },
  },
  
  methods: {
    changeUgualiDomicilioResidenza() {
      const newValue = !this.ad.uguali_domicilio_residenza;
      this.ad.uguali_domicilio_residenza = newValue;

      // used to update domiciglio residenza section
      newValue || this.domicilioState++;
    },

    onRicercaRicoveriClick() {
      if (!this.numeroPazienteIdFormatted) {
        this.$store.commit('showDialogWarning',
          "E' necessario inserire il numero esatto del paziente per poter effettuare la ricerca."
        );
      } else if (!this.numeroPazienteAnnoFormatted) {
        this.$store.commit('showDialogWarning',
          "E' necessario inserire l'anno dell'atto per poter effettuare la ricerca."
        );
      } else {
        this.$emit('ricerca-ricoveri-clicked', this.ad.numero_paziente);
      }
    },

    ///
    /// Exposed methods.
    ///
    validate() {
      let errors = [];
      
      // numero paziente
      this.numeroPazienteIdFormatted || errors.push("Numero Paziente");
      
      // data del ricovero
      const dataRicoveroValid = this.dataRicoveroISO && !DateUtils.isEmptyDate(this.dataRicoveroISO);
      dataRicoveroValid || errors.push("Data del Ricovero (vuota o invalida)");

      // cognome - nome
      this.ad.cognome || errors.push("Cognome deceduto");
      this.ad.nome || errors.push("Nome deceduto");

      // data di nascita
      const dataNascitaValid = this.dataNascitaISO && !DateUtils.isEmptyDate(this.dataNascitaISO);
      dataNascitaValid || errors.push("Data di Nascita (vuota o invalida)");

      // dataRicovero must be after dataNascita
      if (dataNascitaValid && dataRicoveroValid && DateUtils.dateDiff(this.dataRicoveroISO, this.dataNascitaISO) < 0) {
        errors.push("Data del Ricovero invalida (deve essere successiva alla data di Nascita)");
      }

      // comune - provincia - nazione (di nascita) - cittadinanza
      const { stato_nascita_specifico } = this.ad;
      if (stato_nascita_specifico && stato_nascita_specifico === 'ITALIA') {
        this.ad.comune_nascita_specifico || errors.push("Comune di Nascita");
        this.ad.provincia_nascita_specifica || errors.push("Provincia di Nascita");
      }
      stato_nascita_specifico || errors.push("Nazione di Nascita");
      this.ad.cittadinanza_specifica || errors.push("Cittadinanza");

      // codice fiscale
      const { codice_fiscale } = this.ad;
      if (codice_fiscale && !CodiceFiscaleUtil.validateCodiceFiscale(codice_fiscale)) {
        errors.push("Codice Fiscale (invalido)");
      }

      // via - comune - provincia - nazione (di residenza)
      const { stato_residenza_specifico } = this.ad;
      if (stato_residenza_specifico === 'ITALIA') {
        this.ad.via_residenza || errors.push("Via di residenza");
        this.ad.comune_residenza_specifico || errors.push("Comune di residenza");
        this.ad.provincia_residenza_specifica || errors.push("Provincia di residenza");
      }
      stato_residenza_specifico || errors.push("Nazione di residenza");
      

      // via - comune - provincia - nazione (di domicilio)
      if (!this.ad.uguali_domicilio_residenza) {
        const { stato_domicilio_specifico } = this.ad;
        if (stato_domicilio_specifico === 'ITALIA') {
          this.ad.via_domicilio || errors.push("Via di domicilio");
          this.ad.comune_domicilio_specifico || errors.push("Comune di domicilio");
          this.ad.provincia_domicilio_specifica || errors.push("Provincia di domicilio");
        }
        stato_domicilio_specifico || errors.push("Nazione di domicilio");
      }

      if (!this.comunicazioneEnabled) {
        if (!this.pratica.comunicazione_decesso.numero_letto)
        this.pratica.comunicazione_decesso.numero_letto = 0;
      
        // data di morte
        this.dataMorteValid || errors.push("Data di Morte (vuota o invalida)");

        // orario di morte
        this.timeValid || errors.push("Orario di Morte (vuoto o invalido)");

        // dataMorte must be after dataRicovero
        if (this.dataMorteValid && this.timeValid && !this.dataRicoveroValid) {
          errors.push("Data di Morte invalida (deve essere successiva alla data del Ricovero)");
        }
      }

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }

      this._clearUnusedFields();
      return true;
    },

    _clearUnusedFields() {
      if (this.ad.uguali_domicilio_residenza) {
        this.ad.via_domicilio = "";
        this.ad.comune_domicilio = "";
        this.ad.provincia_domicilio = "";
        this.ad.stato_domicilio = "";
      }
    },
  },
};
</script>
