var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.errorStyle ? "error-single-category" : "single-category" },
    [
      _c(
        "div",
        { class: _vm.errorStyle ? "error-category-name" : "category-name" },
        [
          _c("div", { staticClass: "custom-row" }, [
            _c("div", { staticClass: "custom-col" }, [
              _c("p", [
                _vm._v(
                  "\n          " + _vm._s(_vm.category.label) + "\n          "
                ),
                _vm.category.required
                  ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "custom-spacer" }),
            _vm._v(" "),
            _c("div", { staticClass: "custom-col" }, [
              _c(
                "p",
                {
                  class: _vm.errorStyle
                    ? "error-custom-end-text"
                    : "custom-end-text",
                },
                [
                  _c("b", [_vm._v(_vm._s(_vm.pages.length))]),
                  _vm._v(
                    " pagin" +
                      _vm._s(_vm.pages.length == 1 ? "a" : "e") +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "toggle" },
        [
          _c(
            "v-btn-toggle",
            {
              staticClass: "toggle-size",
              attrs: { mandatory: "", dense: "" },
              model: {
                value: _vm.toggleSize,
                callback: function ($$v) {
                  _vm.toggleSize = $$v
                },
                expression: "toggleSize",
              },
            },
            [
              _c(
                "v-btn",
                { attrs: { "x-small": "" } },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("mdi-image-size-select-actual"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { "x-small": "" } },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("mdi-image-size-select-large"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.pagesClass,
          attrs: { "data-category-id": _vm.category.id },
        },
        [
          _c("div", { staticClass: "dz-message" }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.pages, function (page) {
            return _c("SinglePage", {
              key: "cpage_" + page.id,
              attrs: {
                page: page,
                imgStyle: _vm.imgStyle,
                previewUrl: _vm.previewUrl,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "left-button",
                    fn: function () {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFromCategory(
                                                page.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { color: "white" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-minus-thick")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v("Rimuovi dalla categoria")]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uploaded-file-box" }, [
      _c("div", { staticClass: "uploaded-file-queue dropzone-previews" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }