<template>
  <div id="form-nulla-osta">

    <c-expansion-sections
      :disabled="disabled"
      :sections="[
        'MEDICO',
        'RESIDENZA MEDICO',
        'DECEDUTO',
        'RILASCIO NULLA OSTA'
      ]"
    >
      <!-- "MEDICO" section -->
      <template #section1>
        <!-- [ cognome, nome ]-->
        <v-row no-gutters class="ma-2">

          <!-- "Cognome" medico -->
          <v-col cols="5">
            <c-text-field
              v-model="no.cognome_medico"
              :rules="requiredRule"
              label="Cognome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>

          <v-spacer />

          <!-- "Nome" medico -->
          <v-col cols="5">
            <c-text-field
              v-model="no.nome_medico"
              :rules="requiredRule"
              label="Nome"
              prepend-icon="mdi-badge-account"
            />
          </v-col>
        </v-row>

        <!-- [ data_nascita ]-->
        <v-row no-gutters class="ma-2">

          <!-- "Data di Nacita" medico -->
          <v-col cols="5">
            <c-date-picker
              v-model="dataNascitaMedicoISO"
              label="Data di Nascita"
            />
          </v-col>
        </v-row>

        <!-- [ comune, provincia ] -->
        <v-row no-gutters class="ma-2">

          <!-- "Comune di Nascita" medico -->
          <v-col cols="5">
            <c-combobox
              v-model="comuneNascitaMedico"
              :items="listPaesi"
              label="Comune di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />

          <!-- "Provincia di Nascita" medico -->
          <v-col cols="5">
            <c-combobox
              v-model="provinciaNascitaMedico"
              :items="listProvince"
              label="Provincia di Nascita"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>
      </template>

      <!-- "RESIDENZA MEDICO" section -->
      <template #section2>
        <!-- [ via, civico, comune ] -->
        <v-row no-gutters class="ma-2">
          
          <!-- "Via" residenza medico -->
          <v-col cols="3">
            <c-text-field
              v-model="no.via_residenza_medico"
              label="Via"
              prepend-icon="mdi-home-map-marker"
            />
          </v-col>

          <v-col cols="1"></v-col>

          <!-- "Civico" residenza medico -->
          <v-col cols="1">
            <c-text-field
              v-model="no.civico_residenza_medico"
              label="Civico"
              type="number"
              hide-spin-buttons
            />
          </v-col>

          <v-spacer />

          <!-- "Comune" residenza medico -->
          <v-col cols="5">
            <c-combobox
              v-model="comuneResidenzaMedico"
              :items="listPaesi"
              label="Comune"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>
        </v-row>

        <!-- [ provincia, telefono ] -->
        <v-row no-gutters class="ma-2">

          <!-- "Provincia" residenza medico -->
          <v-col cols="5">
            <c-combobox
              v-model="provinciaResidenzaMedico"
              :items="listProvince"
              label="Provincia"
              item-text="descrizione"
              prepend-icon="mdi-map-marker"
              clearable
            />
          </v-col>

          <v-spacer />

          <!-- "Telefono" medico -->
          <v-col cols="5">
            <c-text-field
              v-model="no.telefono_residenza_medico"
              label="Telefono"
              prepend-icon="mdi-phone"
              type="number"
              hide-spin-buttons
            />
          </v-col>
        </v-row>
      </template>

      <!-- "DECEDUTO" section -->
      <template #section3>
        <SectionDeceduto
          :anagrafica-deceduto="pratica.anagrafica_deceduto"
          :show-cittadinanza="false"
          :show-via-civico-domicilio="false"
          :show-comune-provincia-domicilio="false"
          :show-data-orario-morte="false"
          :show-anni-mesi-giorni-ore="false"
        />
      </template>

      <!-- "RILASCIO NULLA OSTA" section -->
      <template #section4>
        <!-- "Rilasciato Nulla Osta" -->
        <v-col cols="3">
          <c-select
            v-model="no.rilasciato_nulla_osta"
            :items="nullaOstaOptions"
            :prepend-icon="iconNullaOsta"
            label="Rilasciato Nulla Osta"
          />
        </v-col>
        
        <v-container>
          <!-- "Note rilascio Nulla Osta" textarea -->
          <c-textarea v-if="isNoteNullaOstaVisible"
            v-model="no.rilasciato_nulla_osta_nota"
            label="Note rilascio Nulla Osta"
            prepend-icon="mdi-text"
          />

          <v-row v-else>
            <!-- FileInput -->
            <v-col cols="5">
              <v-file-input :disabled="no.rilasciato_nulla_osta_attachment != null"
                v-model="attachment"
                accept=".pdf,.p7m"
                loading="loadingAttachment"
                loader-height="0"
                :error="fileInputError"
                show-size
                hide-details
                outlined
                dense
              />
            </v-col>
            
            <v-spacer />

            <!-- CItem used to display attachment loaded informations  -->
            <v-col v-if="no.rilasciato_nulla_osta_attachment" cols="6">
              <c-item
                @text-click="onAttachmentTitleClick"
                @action-click="onAttachmentDeleteClick"
                :text="attachmentTitle"
                :disabled="disabled"
                prependIcon="mdi-file-pdf-box"
                actionIcon="mdi-delete"
                actionIconColor="red"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </c-expansion-sections>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError" 
      :errors="errors" 
    />
  </div>
</template>
<script>
// stores
import {
  GeographicStore,
  AppConfigStore,
} from 'services/commands/stores';
// services
import PraticaUtil from 'services/pratica_util';
import DateUtils from 'services/date_util';
import GeographicUtil from 'services/geographic_util';
// components
import SectionDeceduto from '../SectionDeceduto.vue';

export default {
  name: 'FormNullaOsta',

  components: { SectionDeceduto },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixNullaOsta(this.praticaDetails, !this.disabled);

    return {
      pratica: pratica,
      no: pratica.nulla_osta,

      nullaOstaOptions: [
        { text: 'No', value: 'No' },
        { text: 'Si (Allega/Scansiona PDF)', value: 'SiAllegato' },
        { text: 'Si (Aggiungi Nota)', value: 'SiNota' },
      ],

      requiredRule: [ value => !!value || 'Obbligatorio' ],

      fileInputError: false,
      attachment: null,
      loadingAttachment: false,

      dialogError: false,
      errors: [],
    }
  },

  computed: {
    ///
    /// Medico section
    ///
    dataNascitaMedicoISO: {
      get() { return DateUtils.formatDateAsIso(this.no.dt_nascita_medico) },
      set(newValue) { this.no.dt_nascita_medico = moment(newValue).format() },
    },

    isNoteNullaOstaVisible() {
      const rilasciatoNullaOsta = this.no.rilasciato_nulla_osta;
      return rilasciatoNullaOsta == 'No' || rilasciatoNullaOsta == 'SiNota';
    },

    iconNullaOsta() {
      return this.isNoteNullaOstaVisible ? "mdi-check-decagram-outline" : "mdi-check-decagram";
    },


    attachmentTitle() {
      const a = this.no?.rilasciato_nulla_osta_attachment;
      return `${a?.name}${a?.type}`;
    },

    ///
    /// Geographic fields
    ///
    comuneNascitaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.no, newValue, 'comune_nascita_medico', 'comune_nascita_medico_specifico') },
      get() { return GeographicUtil.getGeographicField(this.no, 'comune_nascita_medico', 'comune_nascita_medico_specifico') }
    },

    comuneResidenzaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.no, newValue, 'comune_residenza_medico', 'comune_residenza_medico_specifico') },
      get() { return GeographicUtil.getGeographicField(this.no, 'comune_residenza_medico', 'comune_residenza_medico_specifico') }
    },

    provinciaNascitaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.no, newValue, 'provincia_nascita_medico', 'provincia_nascita_medico_specifico') },
      get() { return GeographicUtil.getGeographicField(this.no, 'provincia_nascita_medico', 'provincia_nascita_medico_specifico') }
    },

    provinciaResidenzaMedico: {
      set(newValue) { GeographicUtil.setGeographicField(this.no, newValue, 'provincia_residenza_medico', 'provincia_residenza_medico_specifica') },
      get() { return GeographicUtil.getGeographicField(this.no, 'provincia_residenza_medico', 'provincia_residenza_medico_specifica') }
    },

    ///
    /// Utilities computed values.
    ///
    listPaesi: () => GeographicStore.listPaesi(),
    listProvince: () => GeographicStore.listProvince(),
  },

  watch: {
    // Watch used to update 'Medico' informations
    disabled(newValue) {
      // if pratica enter in edit mode fixNullaOsta is required to fix medico section
      if (!newValue) {
        this.pratica = PraticaUtil.fixNullaOsta(this.praticaDetails, true);
        this.no = this.pratica?.nulla_osta;
      }
    },

    // Watch used to notify parent component when 'pratica' updates.
    'pratica.nulla_osta': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },

    async attachment(file) {
      if (!file) return;

      // maxFileSize in KB
      const maxFileSize = AppConfigStore.maxFileSize();

      if (file.size > maxFileSize * 1000) {
        this.fileInputError = true;

        this.$store.commit('showDialogWarning',
          `La dimensione dell'allegato selezionato eccede la dimensione massima supportata.
          Si ricorda che il limite imposto ai documenti per la firma digitale è di ${maxFileSize}KB.`
        );

        this.attachment = null;
        return;
      }
      this.fileInputError = false;

      this.loadingAttachment = true;
      const allegato = {};

      const toBase64 = f => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
      })

      allegato.data = await toBase64(file);
      // allegato.data = new Int8Array(await file.arrayBuffer());
      allegato.original_name = file.name;
      // folder not known!
      // allegato.folder = file.name.substring(0, file.name.lastIndexOf("\\"));
      // allegato.type = file.type;

      // example:   application/pdf => .pdf
      allegato.type = '.' + file.type.split('/').pop();
      // allegato.folder = 'Desktop';

      allegato.is_signed = allegato.type == '.p7m';
      allegato.name = "Nullaosta alla sepoltura dal magistrato";
      allegato.description = allegato.name;

      this.loadingAttachment = false;

      this.no.rilasciato_nulla_osta_attachment = allegato;
    },

    comuneNascitaMedico(newValue) {
      GeographicUtil.setComuneSpecificoProvincia(
        this.no,
        newValue,
        'comune_nascita_medico_specifico',
        'provincia_nascita_medico',
        'provincia_nascita_medico_specifico'
      );
    },

    comuneResidenzaMedico(newValue) {
      GeographicUtil.setComuneSpecificoProvincia(
        this.no,
        newValue,
        'comune_residenza_medico_specifico',
        'provincia_residenza_medico',
        'provincia_residenza_medico_specifica'
      );
    },
  },

  methods: {
    // handler related to CItem 'text-click' event
    onAttachmentTitleClick() {
      if (this.no.rilasciato_nulla_osta_attachment.identifier) {
        this.$emit('document-selected', this.no.rilasciato_nulla_osta_attachment);
      }
    },

    // handler related to CItem 'action-click' event
    onAttachmentDeleteClick() {
      this.no.rilasciato_nulla_osta_attachment = null;
      this.attachment = null;
    },

    ///
    /// Exposed methods.
    ///
    validate() {
      const errors = [];

      !this.loadingAttachment || errors.push('Attendere il caricamento del documento allegato');
      
      // cognome - nome (medico)
      this.no.cognome_medico || errors.push('Cognome Medico');
      this.no.nome_medico || errors.push('Nome Medico');

      // [ 'No', 'SiNota', 'SiAllegato' ]
      const rilasciatoNullaOsta = this.no.rilasciato_nulla_osta;

      if (rilasciatoNullaOsta == 'SiNota' && !this.no.rilasciato_nulla_osta_nota) {
        errors.push('Nota del rilascio Nulla Osta');
      } else if (rilasciatoNullaOsta == 'SiAllegato' && (!this.no.rilasciato_nulla_osta_attachment || this.fileInputError)) {
        // "allegato" empty or too big
        this.no.rilasciato_nulla_osta_attachment || errors.push('Allegato del rilascio Nulla Osta (vuoto o invalido)');
      }

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }

      this._clearUnusedFields();
      return true;
    },

    _clearUnusedFields() {
      if (this.no.rilasciato_nulla_osta == 'SiAllegato') {
        this.no.rilasciato_nulla_osta_nota = "";
      } else {
        this.no.rilasciato_nulla_osta_attachment = null;
      }
    }
  }
}
</script>
