var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-morte-violenta" } },
        [
          _c("v-card-title", [_vm._v("Condizioni Morbose - Morte Violenta")]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-container",
            [
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "\n        La diagnosi appena compilata contiene termini (" +
                    _vm._s(_vm.formattedConditions) +
                    ") che\n        potrebbero far pensare ad una causa di morte violenta. E' consigliabile\n        eliminare una diagnosi che contenga tali termini nei casi in cui essa\n        non sia in relazione diretta con la causa di morte: questa è l'evenienza\n        più frequente di interventi del Servizio di Medicina Legale per\n        correzioni/riformulazioni della descrizione degli eventi\n        morbosi.\n        "
                ),
                _c("br"),
                _vm._v(
                  "\n        Diversamente, la conferma della diagnosi potrebbe comportare\n        il passaggio alla compilazione della Scheda di morte violenta.\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "edit-conditions" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("p", { staticClass: "radio-button-text" }, [
                              _vm._v("Modifica della diagnosi"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { value: "confirm-conditions" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("p", { staticClass: "radio-button-text" }, [
                              _vm._v(
                                "Conferma la diagnosi e resta in Scheda di morte naturale"
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { value: "morte-violenta-conditions" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("p", { staticClass: "radio-button-text" }, [
                              _vm._v(
                                "Conferma la diagnosi e passa in Scheda di morte violenta"
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-confirm",
                  attrs: { outlined: "" },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v("\n        CONFERMA SCELTA\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }