import UserInformationStore from 'services/commands/stores/user_information_store'
import ListAttivitaDaFareStore from 'services/commands/stores/list_attivita_da_fare_store'

let LIST_META = {}
var VALUES

let META_CACHE = {}
let qualifiedString

/**
 * Inizializza il modulo JS con la lista di meta eseguendo anche dei merge tra i vari ogetti.
 *
 * @param jsonListMeta Lista dei meta
 */
function init(jsonListMeta) {
  LIST_META = {}
  VALUES = jsonListMeta.meta_list.meta;

  _.forEach(VALUES, meta => _addElement(meta));

  // Merge A_GROUP into ACTIVITY_DEF
  LIST_META.ACTIVITY_DEF = solveMetaGroup(LIST_META.ACTIVITY_DEF, '[A_GROUP]', true);
  delete LIST_META['[A_GROUP]'];

  // Merge DEFAULT_FIELDS_GROUP into DEFAULT_FIELDS
  LIST_META.DEFAULT_FIELDS = solveMetaGroup(LIST_META.DEFAULT_FIELDS, '[DEFAULT_FIELDS_GROUP]');
  delete LIST_META['[DEFAULT_FIELDS_GROUP]'];

  // Merge MANDATORY_FIELDS_GROUP into MANDATORY_FIELDS
  LIST_META.MANDATORY_FIELDS = solveMetaGroup(LIST_META.MANDATORY_FIELDS, '[MANDATORY_FIELDS_GROUP]');
  delete LIST_META['[MANDATORY_FIELDS_GROUP]'];

  // Merge VISIBILITY_FIELDS_GROUP into VISIBILITY_FIELDS
  LIST_META.VISIBILITY_FIELDS = solveMetaGroup(LIST_META.VISIBILITY_FIELDS, '[VISIBILITY_FIELDS_GROUP]');
  delete LIST_META['[VISIBILITY_FIELDS_GROUP]'];

  // Merge P_GROUP into PROCESS_DEF
  LIST_META.PROCESS_DEF = solveMetaGroup(LIST_META.PROCESS_DEF, '[P_GROUP]');
  delete LIST_META['[P_GROUP]'];

  // Merge T_GROUP into TRANSITION_DEF
  LIST_META.TRANSITION_DEF = solveMetaGroup(LIST_META.TRANSITION_DEF, '[T_GROUP]');
  delete LIST_META['[T_GROUP]'];

  // Merge [SEZIONE_GROUP] into SEZIONE
  LIST_META.SEZIONE = solveMetaGroup(LIST_META.SEZIONE, '[SEZIONE_GROUP]');
  delete LIST_META['[SEZIONE_GROUP]'];

  // Initialize ListAttivitaDaFare with LIST_META.SEZIONE
  ListAttivitaDaFareStore.init(LIST_META.SEZIONE, _qualifiedString());

  // Merge [REG_ITA_GROUP] into REGIONE_ITA
  LIST_META.REGIONE_ITA = solveMetaGroup(LIST_META.REGIONE_ITA, '[REG_ITA_GROUP]');
  delete LIST_META['[REG_ITA_GROUP]'];
}

function solveMetaGroup(META, group, nested = false) {
  group = group.toLowerCase();
  const GROUP = group.toUpperCase();
  return _.reduce(META, function(result, value, key) {
    if (!nested || value.hasOwnProperty(group)) {
      result[key] = Object.assign(getData(GROUP, value[group]), value);
      delete result[key][group];
    }
    else if (nested){
      result[key] = value;
    }

    return result;
  }, {});
}

function getMetaObjectName(name) {
  return isMetaObject(name) 
    ? name.substring(1, name.length - 1)
    : name;
}

function getData(metaCat, object_id = undefined, param = undefined) {
  if (object_id == undefined && param == undefined) {
    return LIST_META[metaCat]
  }
  else if (param == undefined) {
    return LIST_META[metaCat]
      ? LIST_META[metaCat][object_id]
      : '';
  }
  else {
    return LIST_META[metaCat] && LIST_META[metaCat][object_id]
      ? LIST_META[metaCat][object_id][param]
      : '';
  }
}

// index starts from 1
function getSezione(index) {
  return LIST_META.SEZIONE[index];
}

function _addElement(element) {
  let object_class = element.object_class
  let object_id = element.object_id
  let name = element.name

  if (object_class in LIST_META) {
    if (object_id in LIST_META[object_class]) {
      if (name in LIST_META[object_class][object_id]) {
        console.error('file: list_meta_service.js ~ _addElement ~ ERROR: DATA CONFLICT')
      }
      else {
        LIST_META[object_class][object_id][name.toLowerCase()] = element.value
      }
    }
    else {
      LIST_META[object_class][object_id] = {}
      _addElement(element)
    }
  }
  else {
    LIST_META[object_class] = {}
    _addElement(element)
  }
}

function _indexOf(list, meta, objectId) {
  for (var i = 0; i < list.length; i++) {
    let target = list[i]
    if (target['name'] == meta['name'] && target['object_class'] == meta['object_class'] && target['object_id'] == objectId) {
      return i
    }
  }
  return -1
}

function getActivityDef() {
  return _.cloneDeep(LIST_META['ACTIVITY_DEF']);
}

function getActivityParameter(id, name) {
  if (LIST_META['ACTIVITY_DEF'][id])
    return _.cloneDeep(LIST_META['ACTIVITY_DEF'][id][name.toLowerCase()]);
    // return _.cloneDeep(LIST_META['ACTIVITY_DEF'][id].name);
  return null;
}

function getTransitionDef(id) {
  return getData('TRANSITION_DEF', id)
}

// FIXME
function getEditTransitionDef() {
  let filtered = [];
  _.forEach(LIST_META['TRANSITION_DEF'], (meta, key) => {
    if (meta.is_edit_action == '1' || meta.is_view_action == '0') {
      meta.id = key;
      // FIXME Save action
      if (meta.id == -3) {
        meta.manager = { 
          id: 1003,
          name: 'Item.Save',
          cardinality: 'SINGLE'
        };
      }
      // FIXME Cancel action
      if (meta.id == -4) {
        meta.manager = {
          id: 1004,
          name: 'Item.Cancel',
          cardinality: 'SINGLE' 
        }
      }
      filtered.push(meta);
    }
  });


  return filtered;
}

function isMetaObject(name) {
  return name.startsWith('[') && name.endsWith(']')
}

// nomi qualificati dei meta (fqName) sono quelli con la qualifiedString, data da tipoStruttura + codiceStruttura
// Es:
// Se tipo struttura = OSPEDALE e codice struttura = 954, allora
// fqName di DESCRIPTION = DESCRIPTION.OSPEDALE.954
//
// i meta con fqName hanno la precedenza e fanno override dei meta con i nomi base (non qualificati)

// ritorna la stringa di qualificazione, data da tipo struttura + punto + codice struttura
// Es: OSPEDALE.954
function _qualifiedString() {
  if (qualifiedString === undefined) {
    qualifiedString = `${UserInformationStore.getTipoStruttura()}.${UserInformationStore.getCodiceStruttura()}`;
  }
  return qualifiedString;
}
// Ritorna true se il nome passato è un fqName, false altrimenti
function isFqName(name) {
  return name.endsWith(_qualifiedString())
}
// Costruisce il baseName (nome non qualificato) a partire da un fqName passato.
// Se il nome pasasto non è un fqName, ritorna il nome stesso.
function _baseName(name) {
  return isFqName(name) ? name.substring(0, name.length - _qualifiedString().length - 1) : name
}

// Example:
// metaName: '[A_GROUP]'
// objectClass_: 'ACTIVITY_DEF'
function getMetas(metaName, objectClass, objectId = 0) {
  const cached = META_CACHE[objectClass]?.[objectId]

  if (cached === undefined) {
    let res = {}

    VALUES.forEach(meta => {
      let baseName = _baseName(meta['name'])
      let key = `${baseName}.${meta['object_id'] == 0 ? objectId : meta['object_id']}`

      if(!res.hasOwnProperty(key) || meta['object_id'] != 0 || isFqName(meta['name'])) {
        meta['name'] = baseName
        if ((metaName == null || baseName == metaName) && (objectClass == null || meta['object_class'] == objectClass)) {
          if (objectId == 0) {
            res[key] = meta
          } else {
            if (objectId == meta['object_id']) {
              res[key] = meta
            } else if (meta['object_id'] == 0 && !res.hasOwnProperty(key)) {
              res[key] = meta
            }
          }
        }
      }
    })
    const metas = _.toArray(res)

    if (!META_CACHE.hasOwnProperty(objectClass)) {
      META_CACHE[objectClass] = {}
    }
    META_CACHE[objectClass][objectId] = metas

    return metas
  } else {
    return cached
  }
}

// Example:
// objectName: 'A_GROUP'
// objectClass: 'ACTIVITY_DEF'
// ...
//
//
function getMetaObjects(objectName, objectClass, objectId = 0) {
  const metaName = `[${objectName}]`
  const allMetas = getMetas(metaName, objectClass, objectId)

  let objects = {}

  _.forEach(allMetas, meta => {
    const id = parseInt(meta['value'])

    if (!isNaN(id)) {
      let existing = objects[id]

      if (existing == null) {
        var o = {
          id,
          objectName,
          parents: [
            {
              id: meta['object_id'],
              class: meta['object_class']
            }
          ]
        }

        getMetas(null, metaName, id).forEach(metaValue => {
          o[metaValue['name']] = metaValue['value']
        })

        objects[id] = o
      } else {
        existing['parents'].push({ id: meta['object_id'], class: meta['object_class'] })
      }
    }
  })

  return objects
}

// Resolve all meta and meta objects for an object
// returns a dictionary of objects:
// keys are the name of meta
// values can be simple values (string) or objects
function resolveAllMetas(objectClass, objectId) {
  let allMetas = getMetas(null, objectClass, objectId)

  var res = {}
  allMetas.forEach(meta => {
    if(isMetaObject(meta.name)) {
      let objects = getMetaObjects(getMetaObjectName(meta.name), objectClass, objectId)
      for(const k in objects) {
        let obj = objects[k]
        for(const p in obj) {
          if(!(p == 'id' || p == 'parents' || p == 'objectName')) {
            res[p] = obj[p]
          }
        }
      }
    } else {
      res[meta.name] = meta.value
    }
  })

  return res
}

function getMetaValue(objectClass, property) {
  const meta = _.find(LIST_META[objectClass], function(m) {
    return m.hasOwnProperty(property)
  });
  return meta?.[property];
}

export default {
  init,
  getActivityDef,
  getActivityParameter,
  getTransitionDef,
  getEditTransitionDef,
  getMetas,
  getMetaObjects,
  getSezione,
  resolveAllMetas,
  getMetaValue,
}