<template>
  <!-- v-time-picker handler -->
  <v-menu
    ref="menu"
    v-model="timePickerOraMorte"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <!-- container of formatted time -->
    <template v-slot:activator="{ on, attrs }">
      <c-text-field
        v-model="oraField"
        :label="label"
        :rules="rules"
        placeholder="hh:mm"
        prepend-icon="mdi-clock-time-four-outline"
        v-bind="attrs"
        v-on="on"
        v-mask="'##:##'"
      />
    </template>

    <!-- time picker -->
    <v-time-picker
      v-if="timePickerOraMorte"
      v-model="oraFormatted"
      format="24hr"
      full-width
      no-title
      @click:minute="$refs.menu.save(oraFormatted)"
    />
  </v-menu>
</template>

<script>
import DateUtils from "services/date_util";
import { VueMaskDirective } from "v-mask";

export default {
  name: 'CTimePicker',

  directives: {
    mask: VueMaskDirective,
  },

  // value:         date in ISO format
  // label:         label of the v-text-field
  // rules:         rules of the v-text-field
  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: "Time",
    },
    
    rules: {
      required: false,
    },
  },

  data() {
    return {
      // used from 'v-menu' to display v-time-picker when needed
      timePickerOraMorte: false,

      // format: hh:mm
      oraField: "",

      // format: hh:mm
      oraFormatted: "",
    };
  },

  watch: {
    // watch on prop 'value'
    value: {
      immediate: true,
      handler(_) {
        this.oraField = this.value;
        this.oraFormatted = this.value;
      }
    },

    // watch on oraField: oraFormatted updated in case of valid date
    oraField() {
      if (DateUtils.validateTime(this.oraField)) {
        if (this.oraField != this.oraFormatted)
          this.oraFormatted = this.oraField;
        else
          this.$emit('input', this.oraFormatted);
      } else {
        // emitting empty time
        this.$emit('input', '');
      }
    },

    // watch on oraFormatted: event emitted in case of change
    oraFormatted() {
      if (this.value != this.oraFormatted) {
        this.$emit('input', this.oraFormatted);
      }
    },
  }
};
</script>
