<script>
import { VSelect } from "vuetify/lib"


export default {
  name: 'CSelect',

  extends: VSelect,

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    
    dense: {
      type: Boolean,
      default: true,
    },
  },
};
</script>