<template>
  <div id="header-bar">
    <v-toolbar dark>
      <!-- Caronte Logo -->
      <a :href="logoUrl">
        <img class="logo-image" :src="logoSrc" alt="logo">
      </a>

      <v-col cols="auto">
        <!-- Caronte -->
        <v-row no-gutters class="row-title">
          <b>{{ title }}</b>
        </v-row>

        <!-- [Name] [Surname] ( [group] ) -->
        <v-row no-gutters class="row-title">
          {{ loggedUserInformations }}
        </v-row>
      </v-col>

      <!-- WebInteropStatus -->
      <v-col v-if="isWebInteropEnabled" cols="auto">
        <WebInteropStatus v-if="isLoggedIn" />
      </v-col>

      <v-spacer></v-spacer>

      <!-- '[N] pratiche in lavorazione' -->
      <v-chip v-if="showChipPratiche && isLoggedIn">
        <b>{{ chipPratiche }}</b>
      </v-chip>

      <v-divider class="mx-5" vertical />

      <!-- User Profile Avatar Button -->
      <v-tooltip v-if="isLoggedIn" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="showUserProfile = true"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-avatar
              size="40"
              color="primary darken-1"
            >
              <span class="white--text text-h5">
                {{ getAvatarText() }}
              </span>
            </v-avatar>
          </v-btn>
        </template>

        <span>Impostazioni Account</span>
      </v-tooltip>

      <!-- User Profile dialog -->
      <UserProfile v-if="isLoggedIn"
        v-model="showUserProfile"
        :avatarText="getAvatarText()"
      />

      <!-- Logout button -->
      <v-tooltip v-if="isLoggedIn" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="logout" :disabled="disabled" v-bind="attrs" v-on="on">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        
        <span>Logout</span>
      </v-tooltip>

    </v-toolbar>
  </div>
</template>

<script>
// components
import UserProfile from 'components/header/UserProfile'
import WebInteropStatus from 'components/interop/WebInteropStatus.vue'
// services
import Util from 'services/commands/core/util'
import User from 'services/user'
// stores
import { AppConfigStore } from 'services/commands/stores';
// vuex
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderBar',

  components: {
    UserProfile,
    WebInteropStatus,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    // true to make chip pratiche visible
    // false otherwise
    showChipPratiche: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // condition to show user profile dialog
      showUserProfile: false,
    }
  },

  computed: {
    ...mapGetters([
      'startup',
      'praticheNumber'
    ]),

    title() {
      return `Caronte ${this.version}`;
    },

    logoSrc() {
      return `images/${AppConfigStore.getLogoName()}.png`;
    },

    logoUrl() {
      return AppConfigStore.getLogoUrl();
    },

    loggedUserInformations() {
      return this.isLoggedIn ? this.userInformations : "...";
    },

    // "[N] pratiche in attesa di lavorazione"
    chipPratiche() {
      return `${this.praticheNumber || '0'} pratiche in attesa di lavorazione`;
    },

    // true     in case of startup completed
    // false    otherwise
    isLoggedIn() {
      return this.startup;
    },
    
    isWebInteropEnabled() {
      return AppConfigStore.isWebInteropPluginEnabled();
    },

    // User informations formatted like "Name Surname (group)"
    userInformations() {
      return `${User.getFullName()} (${User.getGroupName()})`;
    },

    // app version from config file
    version() {
      return AppConfigStore.getVersion();
    },
  },
  
  methods: {
    // method used to format avatar text
    getAvatarText() {
      return User.getName().charAt(0).toUpperCase() + User.getSurname().charAt(0).toUpperCase();
    },

    // User logout
    logout() {
      Util.logout();
    },
  },
}
</script>