var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.status == "off"
        ? _c(
            "v-alert",
            {
              directives: [{ name: "ripple", rawName: "v-ripple" }],
              staticClass: "ma-0 alert",
              attrs: {
                border: "left",
                type: "error",
                "colored-border": "",
                light: "",
                dense: "",
              },
              on: { click: _vm.showAlert },
            },
            [_c("b", [_vm._v("Attenzione: Plugin locale mancante!")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("DialogWebInteropError", {
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }