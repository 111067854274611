import { render, staticRenderFns } from "./TableHome.vue?vue&type=template&id=07035d6f&"
import script from "./TableHome.vue?vue&type=script&lang=js&"
export * from "./TableHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07035d6f')) {
      api.createRecord('07035d6f', component.options)
    } else {
      api.reload('07035d6f', component.options)
    }
    module.hot.accept("./TableHome.vue?vue&type=template&id=07035d6f&", function () {
      api.rerender('07035d6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/components/home/table/TableHome.vue"
export default component.exports