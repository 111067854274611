import Core from './core';

const COMMAND_NAME = 'Caronte.AccettazionePratiche';

function requestBody(params) {
  return {
    pratiche: {
      pratica: [
        {
          process_ids: {
            element: params.ids,
          },
          action_id: params.action_id,
          pratica_altre_info: {
            numero_atto: params.numero_atto ?? 0,
            anno_atto: params.anno_atto ?? 0,
            pratica_id: params.ids[0]
          }
        }
      ],
    },
    message: params.message
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params));
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response);
    },
    error: (response) => {
      _.attempt(options.error, response);
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  })
}

export default {
  promise,
  call
}