// stores
import {
  AppConfigStore,
  ListCondizioniMorboseStore,
} from 'services/commands/stores'
// components
import DialogCondizioniMorteViolenta from 'components/details/dialog/DialogCondizioniMorteViolenta'
import DialogCondizioniRiscontroAutoptico from 'components/details/dialog/DialogCondizioniRiscontroAutoptico'
// services
import PraticaUtil from 'services/pratica_util'

export default {
  components: {
    DialogCondizioniMorteViolenta,
    DialogCondizioniRiscontroAutoptico
  },

  data() {
    return {
      // Maximum and minimum lenght of conditions 'non preesistenti'
      minNotPre: AppConfigStore.getNumeroMinimoCondizioniMorbose(),
      maxNotPre: AppConfigStore.getNumeroMassimoCondizioniMorbose(),

      // Maximum and minimum lenght of conditions 'preesistenti'
      minPre: AppConfigStore.getNumeroMinimoCondizioniMorbosePreesistenti(),
      maxPre: AppConfigStore.getNumeroMassimoCondizioniMorbosePreesistenti(),

      dialogCondizioniMorteViolenta: false,
      dialogCondizioniRiscontroAutoptico: false,

      resolveValidation: null,
    };
  },

  computed: {
    // List of "Condizioni Morbose" associated with "Morte Violenta".
    condizioniMorteViolenta() {
      const result = [];

      if (this.module.morte_per_traumatismo_avvelenamento.toUpperCase() === "NO") {
        _.forEach(ListCondizioniMorboseStore.getListCondizioniMorteViolenta(), (cmv) => {
          const condizione = _.find(
            this.module.condizioni.condizione_morbosa,
            c => c.malattia.toUpperCase() === cmv.toUpperCase()
          );

          if (condizione) {
            result.push(condizione.malattia);
          }
        });
      }
      return result;
    },

    // Returns the "Condizione Morbosa" associated with "Riscontro Autoptico", if there is only one.
    condizioneRiscontroAutoptico() {
      const result = [];

      if (this.module.richiesto_riscontro_diagnostico.toUpperCase() === "NO") {
        if (this.module.condizioni.condizione_morbosa.length == 1) {
          _.forEach(ListCondizioniMorboseStore.getListCondizioniRiscontroAutoptico(), (cra) => {
            const condizione = _.find(
              this.module.condizioni.condizione_morbosa,
              c => c.malattia.toUpperCase() == cra.toUpperCase()
            );

            if (condizione) {
              result.push(condizione.malattia);
            }
          })
        }
      }
      
      return result.length == 1 ? result[0] : "";
    },
  },

  methods: {
    ///
    /// "Condizioni Morbose" validation events handling.
    ///
    onEditConditionsEvent() {
      this.resolveValidation(false);
    },

    onConfirmConditionsEvent() {
      this._clearUnusedFields();
      this.resolveValidation(true);
    },

    onMorteViolentaConditionsEvent() {
      this.module.morte_per_traumatismo_avvelenamento = "Si";
      this.resolveValidation(false);
    },

    onDeleteConditionEvent() {
      this.module.condizioni.condizione_morbosa = [PraticaUtil.emptyCondizioneMorbosa()];
      this.resolveValidation(false);
    },

    onOpenNewConditionsEvent(number) {
      // "maxNotPre - 1" because one condition is already stored
      const newer = Math.min(number, this.maxNotPre - 1);
      const valueMax = this.module.condizioni.condizione_morbosa[0].value;

      // first condition will shift "newer + 1" positions
      this.module.condizioni.condizione_morbosa[0].ordine = newer + 1;

      for (let i = 0; i < newer; i++) {
        this.module.condizioni.condizione_morbosa.unshift(PraticaUtil.emptyCondizioneMorbosa({
          ordine: newer - i,
          value: -(Math.abs(valueMax) + i + 1),
        }));
      }
      this.resolveValidation(false);
    },

    onRiscontroAutopticoConditionsEvent() {
      this.module.richiesto_riscontro_diagnostico = "Si";
      
      this._clearUnusedFields();
      this.resolveValidation(true);
    },
  }
}