var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forms" },
    [
      _vm.pratica
        ? _c(
            "div",
            { staticClass: "form-tabs-container" },
            [
              _c(
                "v-tabs",
                {
                  key: _vm.tabsState,
                  attrs: {
                    "next-icon": "mdi-chevron-right-circle",
                    "prev-icon": "mdi-chevron-left-circle",
                    height: "38",
                    "show-arrows": "",
                    density: "compact",
                  },
                  model: {
                    value: _vm.selectedTabIndex,
                    callback: function ($$v) {
                      _vm.selectedTabIndex = $$v
                    },
                    expression: "selectedTabIndex",
                  },
                },
                _vm._l(_vm.tabsOptions, function (tab) {
                  return _c(
                    "v-tab",
                    {
                      key: tab.key,
                      class: { active: _vm.activeModule.key == tab.key },
                      attrs: { name: tab.name, disabled: tab.disabled },
                    },
                    [_vm._v("\n        " + _vm._s(tab.name) + "\n      ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showFormDocumenti ? _c("v-divider") : _vm._e(),
      _vm._v(" "),
      _vm.showFormDocumenti
        ? _c("FormDocumenti", {
            key: _vm.moduleState,
            attrs: { praticaDetails: _vm.pratica },
            on: { "document-selected": _vm.onDocumentSelected },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showFormDocumenti && _vm.pratica,
              expression: "!showFormDocumenti && pratica",
            },
          ],
          ref: "form-div",
          staticClass: "form-component-container",
        },
        [
          _vm.pratica
            ? _c(
                "v-form",
                {
                  staticClass: "v-form-container",
                  attrs: { disabled: _vm.readOnly },
                },
                [
                  _c(
                    "KeepAlive",
                    { attrs: { include: _vm.cachedComponent } },
                    [
                      _c(_vm.activeModule.component, {
                        key: _vm.moduleKey,
                        ref: _vm.activeModule.key,
                        tag: "component",
                        attrs: {
                          praticaDetails: _vm.pratica,
                          disabled: _vm.readOnly,
                          comunicazioneEnabled: _vm.isComunicazioneEnabled,
                        },
                        on: {
                          "pratica-updated": _vm.onPraticaUpdate,
                          "documents-generated": _vm.onDocumentsGenerated,
                          "document-selected": _vm.onDocumentSelected,
                          "ricerca-ricoveri-clicked":
                            _vm.onRicercaRicoveriClick,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.pratica
        ? _c("div", { staticClass: "form-component-container" }, [
            _c("h4", { staticClass: "ma-2" }, [
              _vm._v("Seleziona una pratica o creane una nuova."),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }