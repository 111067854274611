<template>
  <div class="ag-header-component">
    <v-row no-gutters>
      <!-- Title of the header-->
      <v-col align-self="center" cols="4">
        <h3>Lista pratiche</h3>
      </v-col>

      <v-spacer></v-spacer>
      
      <!-- Home button of the header-->
      <v-col cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="green darken-3" :disabled="false" @click="onHomeClicked">
              <v-icon v-bind="attrs" v-on="on">mdi-home-outline</v-icon>
            </v-btn>
          </template>
          <span>Torna all'elenco delle pratiche</span>
        </v-tooltip>
      </v-col>

      <!-- Refresh button of the header-->
      <v-col cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon :disabled="false" @click="onRefreshClicked">
              <v-icon v-bind="attrs" v-on="on">mdi-sync</v-icon>
            </v-btn>
          </template>
          <span>Ricarica pratiche</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommandUtil from 'services/commands/core/command_util'

export default {
  name: "TableDetailsHeader",

  data() {
    return { };
  },

  methods: {
    // method used to route to Home
    onHomeClicked() {
      this.$router.push({ name: 'Home' });
    },

    // method used to call 'LIST_PRATICA_INFO' command
    async onRefreshClicked() {
      await CommandUtil.listPraticaInfo();
    },
  },
};
</script>