import Core from './core'

const COMMAND_NAME = 'Caronte.RicercaRicoveri'

// Values to execute Caronte.RicercaRicoveri test
// codice_paziente: 2016/12345
function requestBody(params) {
  return {
    codice_paziente: params.codice_paziente,
  };
}

function promise(params) {
  return Core.promise(COMMAND_NAME, requestBody(params))
}

function call(params, options = {}) {
  Core.send(COMMAND_NAME, requestBody(params), {
    success: (response) => {
      _.attempt(options.success, response)
    },
    error: (response) => {
      _.attempt(options.error, response)
    },
    failure: (error) => {
      _.attempt(options.failure, error);
    }
  })
}

export default {
  promise,
  call
}