var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cell-renderer-details" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            "open-on-click": false,
            color: "primary",
            "open-delay": "2000",
            "nudge-left": "100",
            right: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-row",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "row-flex",
                          attrs: { "no-gutters": "" },
                        },
                        "v-row",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-2", attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { staticClass: "py-3" }, [
                            _vm._v(_vm._s(_vm.icon)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pl-0", attrs: { cols: "auto" } },
                        [
                          _c("div", [
                            _c("p", { staticClass: "text-first-line" }, [
                              _vm._v(_vm._s(_vm.codicePratica)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-second-line" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.deceduto) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-second-line" }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              [
                _vm._v("\n        Deceduto: "),
                _c("b", [_vm._v(_vm._s(_vm.deceduto))]),
                _vm._v(" "),
                _c("br"),
                _vm._v("Pratica: "),
                _c("b", [_vm._v(_vm._s(_vm.codicePratica))]),
                _vm._v(" "),
                _c("br"),
                _vm._v("Data Morte: "),
                _c("b", [_vm._v(_vm._s(_vm.dataMorte))]),
                _vm._v(" "),
                _c("br"),
                _vm._v("Reparto: "),
                _c("b", [_vm._v(_vm._s(_vm.reparto))]),
                _vm._v(" "),
                _vm.isComune
                  ? [
                      _vm.dataRicezioneFormatted
                        ? [
                            _c("br"),
                            _vm._v("Ricevuta il: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.dataRicezioneFormatted)),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataInvioGestionaleFormatted
                        ? [
                            _c("br"),
                            _vm._v("Inviata al gestionale il: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.dataInvioGestionaleFormatted)),
                            ]),
                            _vm._v(" "),
                            _vm.codiceGestionale
                              ? [
                                  _c("br"),
                                  _vm._v("Codice pratica gestionale: "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.codiceGestionale)),
                                  ]),
                                ]
                              : _vm.erroreGestionale
                              ? [
                                  _c("br"),
                                  _vm._v("Errore gestionale: "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.erroreGestionale)),
                                  ]),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm.isAccettata
                        ? [
                            _c("br"),
                            _vm._v("Accettata il: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.dataAccettazioneFormatted)),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isRifiutata
                        ? [
                            _c("br"),
                            _vm._v("Rifiutata il: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.dataAccettazioneFormatted)),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("Motivo rifiuto: "),
                            _c("b", [
                              _vm._v(_vm._s(_vm.messaggioAccettazione)),
                            ]),
                          ]
                        : _vm._e(),
                    ],
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }