import ListMetaStore from 'services/commands/stores/list_meta_store'
import UserInformationStore from 'services/commands/stores/user_information_store'
import GeographicStore from 'services/commands/stores/geographic_store'

import DateUtils from 'services/date_util'

import AppConfigStore from "services/commands/stores/app_config_store"

/**
 * @typedef {Object} Pratica
 * @property {Object} anagrafica_deceduto
 * @property {Object} istat_a
 * @property {Object} istat_b
 * @property {Object} azioni
 */

/**
 * Empty Pratica.anagrafica_deceduto Object.
 * 
 * @returns {Pratica.anagrafica_deceduto}
 */
function emptyAnagraficaDeceduto() {
  return {
    anno_matrimonio: 0,
    anno_nascita_coniuge: 0,
    cittadinanza_specifica: "",
    civico_domicilio: "",
    // civico_residenza: null,
    // codice_fiscale: null,
    cognome: "",
    // cognome_coniuge: null,
    comune_domicilio: null,
    comune_domicilio_specifico: "",
    comune_nascita: null,
    // {
      // cod_catastale: "A001",
      // cod_istat: "028001",
      // descrizione: "ABANO TERME",
      // value: 28001,
      // provincia: {
      //   "sigla": "PD",
      //   "descrizione": "PADOVA",
      //   "cod_istat": "028",
      //   "value": 28,
      //   regione: {
      //     value: 5,
      //     descrizione: "Veneto",
      //   },
      // }
    // },
    comune_nascita_specifico: "",
    comune_residenza: null,
    // {
      // cod_catastale: "A001",
      // cod_istat: "028001",
      // descrizione: "ABANO TERME",
      // value: 28001,
      // provincia: {
      //   "sigla": "PD",
      //   "descrizione": "PADOVA",
      //   "cod_istat": "028",
      //   "value": 28,
      //   regione: {
      //     value: 5,
      //     descrizione: "Veneto",
      //   },
      // },
    // },
    comune_residenza_specifico: "",
    condizione_professionale: 'Occupato',
    dt_nascita: DateUtils.EMPTY_DATE,
    dt_ricovero: DateUtils.EMPTY_DATE,
    grado_istruzione: 'Laurea',
    nome: "",
    // nome_coniuge: null,
    numero_paziente: "",
    posizione_professione: 'AutonomoImprenditoreOLiberoProfessionista',
    provincia_domicilio: null,
    provincia_domicilio_specifica: "",
    provincia_nascita: null,
    // {
      // "sigla": "PD",
      // "descrizione": "PADOVA",
      // "cod_istat": "028",
      // "value": 28,
      // regione: {
      //   value: 5,
      //   descrizione: "Veneto",
      // },
    // },
    provincia_nascita_specifica: "",
    provincia_residenza: null,
    // {
      // "sigla": "PD",
      // "descrizione": "PADOVA",
      // "cod_istat": "028",
      // "value": 28,
      // regione: {
      //   value: 5,
      //   descrizione: "Veneto",
      // },
    // },
    provincia_residenza_specifica: "",
    ramo_attivita: 'AgricolturaCacciaPesca',
    sesso: 'Maschio',
    stato_civile: 'CelibeNubile',
    stato_domicilio: null,
    stato_domicilio_specifico: "",
    stato_nascita: null,
    // {
      // cittadinanza: null,
      // cod_catastale: null,
      // cod_istat: null,
      // codice1: null,
      // codice2: null,
      // descrizione: null,
      // value: null,
    // },
    stato_nascita_specifico: "",
    stato_residenza: null,
    // {
      // cittadinanza: null,
      // cod_catastale: null,
      // cod_istat: null,
      // codice1: null,
      // codice2: null,
      // descrizione: null,
      // value: null,
    // },
    stato_residenza_specifico: "",
    uguali_domicilio_residenza: true,
    // value: null,
    via_domicilio: "",
    via_residenza: "",
  }
}

/**
 * Empty Pratica.comunicazione_decesso Object.
 * 
 * @returns {Pratica.anagrafica_deceduto}
 */
function emptyComunicazioneDecesso() {
  return {
    diagnosi: "",
    dt_morte: DateUtils.EMPTY_DATE,
    numero_letto: 0,
    osservazioni: "",
  };
}

/**
 * Empty Pratica.istat_a Object.
 * 
 * @returns {Pratica.istat_a}
 */
function emptyIstatA() {
  return {
    luogo_decesso: "",
    // comune_luogo_decesso: {
    //   descrizione: "BRESCIA",
    //   cod_catastale: "B157",
    //   cod_istat: "017029",
    //   value: 17029,
    //   provincia: {
    //     sigla: "BS",
    //     descrizione: "BRESCIA",
    //     cod_istat: "017",
    //     value: 17,
    //     regione: {
    //       value: 3,
    //       descrizione: "Lombardia",
    //     }
    //   },
    // },
    // provincia_luogo_decesso: {
    //   sigla: "BS",
    //   descrizione: "BRESCIA",
    //   cod_istat: "017",
    //   value: 17,
    //   regione: {
    //     value: 3,
    //     descrizione: "Lombardia",
    //   }
    // },
    condizioni: {},
    altro_luogo_decesso: "",
    codice_asl_decesso: "",
    richiesto_riscontro_diagnostico: 'No',
    stato_gravidanza_deceduta: 'NessunaGravidanza',
    morte_per_traumatismo_avvelenamento: 'No',
    circostanza_traumatismo_avvelenamento: "",
    modalita_traumatismo_avvelenamento: 'Accidentale',
    infortunio_lavoro_traumatismo_avvelenamento: 'No',
    incidente_trasporto_traumatismo_avvelenamento: 'No',
    mezzo_incidente_trasporto: "",
    ruolo_incidente_trasporto: 'Pedone',
    tipo_incidente_trasporto: "",
    oggetto_veicolo_incidente_trasporto: "",
    dt_accidente: DateUtils.EMPTY_DATE,
    luogo_accidente: 'Casa',
    altro_luogo_accidente: "",
    cognome_medico: "",
    nome_medico: "",
    tipo_medico: "",
    telefono_medico: "",
    telefono2_medico: "",
    versione: 0,
    value: 0,
  }
}

/**
 * Empty Pratica.istat_b Object.
 * 
 * @returns {Pratica.istat_b}
 */
function emptyIstatB() {
  return {
    dt_morte: DateUtils.EMPTY_DATE,
  }
}

/**
 * Empty Pratica.relazione_morte Object.
 * 
 * @returns {Pratica.relazione_morte}
 */
function emptyRelazione() {
  return {
    richiesto_nulla_osta: 'No',
    condizioni: {},
    richiesto_riscontro_diagnostico: 'No',
    morte_per_traumatismo_avvelenamento: 'No',
    modalita_traumatismo_avvelenamento: 'Accidentale',
    infortunio_lavoro_traumatismo_avvelenamento: 'No',
    luogo_accidente: 'Casa',
  }
}

/**
 * Empty Pratica.accertamento_morte Object.
 * 
 * @returns {Pratica.accertamento_morte}
 */
function emptyAccertamento() {
  return {
    portatore_pacemaker: 'No',
    morte_reato: 'No',
    // medico section
    cognome_medico: "",
    nome_medico: "",
    comune_nascita_medico_specifico: "",
    provincia_nascita_medico_specifico: "",
    dt_nascita_medico: DateUtils.EMPTY_DATE,
    via_residenza_medico: "",
    civico_residenza_medico: "",
    comune_residenza_medico_specifico: "",
    provincia_residenza_medico_specifica: "",
    telefono_residenza_medico: "",
    // TODO tipo_delega ??
  };
}

/**
 * Empty Pratica.nulla_osta Object.
 * 
 * @returns {Pratica.accertamento_morte}
 */
function emptyNullaOsta() {
  return {
    rilasciato_nulla_osta: 'No',
    rilasciato_nulla_osta_nota: "",
    rilasciato_nulla_osta_attachment: null,
    // medico section
    cognome_medico: "",
    nome_medico: "",
    comune_nascita_medico_specifico: "",
    provincia_nascita_medico_specifico: "",
    dt_nascita_medico: DateUtils.EMPTY_DATE,
    via_residenza_medico: "",
    civico_residenza_medico: "",
    comune_residenza_medico_specifico: "",
    provincia_residenza_medico_specifica: "",
    telefono_residenza_medico: "",
    // TODO tipo_delega ??
  };
}

/**
 * Empty Pratica.trasporto_deceduto Object.
 * 
 * @returns {Pratica.accertamento_morte}
 */
function emptyTrasporto() {
  return {
    luogo_destinazione: 'DepositoOsservazioneComunale',
    comune_luogo_destinazione: null,
    comune_luogo_destinazione_specifico: "",
    provincia_luogo_destinazione: null,
    provincia_luogo_destinazione_specifica: "",
    cognome_medico: "",
    nome_medico: "",
    sesso_medico: 'Maschio',
  };
}

/**
 * Returns a 'pratica object' with 'anagrafica_deceduto property' fixed.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'anagrafica_deceduto property' fixed
 */
function fixAnagraficaFromRicovero(pratica, ricovero) {
  let p = _.cloneDeep(pratica);

  const comuneNascita = GeographicStore.getPaeseObject(Number(ricovero.nascita_luogo?.comune_cod));
  const provinciaNascita = GeographicStore.getProvinciaObject(Number(ricovero.nascita_luogo?.provincia_cod));
  const statoNascita = GeographicStore.getStatoObject(Number(ricovero.nascita_luogo?.stato_cod));

  const comuneResidenza = GeographicStore.getPaeseObject(Number(ricovero.residenza?.luogo?.comune_cod));
  const provinciaResidenza = GeographicStore.getProvinciaObject(Number(ricovero.residenza?.luogo?.provincia_cod));
  const statoResidenza = GeographicStore.getStatoObject(Number(ricovero.residenza?.luogo?.stato_cod));

  const comuneDomicilio = GeographicStore.getPaeseObject(Number(ricovero.domicilio?.luogo?.comune_cod));
  const provinciaDomicilio = GeographicStore.getProvinciaObject(Number(ricovero.domicilio?.luogo?.provincia_cod));
  const statoDomicilio = GeographicStore.getStatoObject(Number(ricovero.domicilio?.luogo?.stato_cod));


  p.anagrafica_deceduto = {
    ...p.anagrafica_deceduto,
    'numero_paziente': ricovero.ricovero_numero,
    'numero_paziente': ricovero.ricovero_numero,
    'dt_ricovero': ricovero.accettazione_data,
    'cognome': ricovero.cognome,
    'nome': ricovero.nome,
    'dt_nascita': ricovero.nascita_data,
    'comune_nascita': comuneNascita,
    'comune_nascita_specifico': comuneNascita?.descrizione,
    'provincia_nascita': provinciaNascita,
    'provincia_nascita_specifica': provinciaNascita?.descrizione,
    'stato_nascita': statoNascita,
    'stato_nascita_specifico': statoNascita?.descrizione,
    'cittadinanza_specifica': ricovero.cittadinanza,
    'sesso': ricovero.sesso == 'M' ? 'Maschio' : 'Femmina',
    'codice_fiscale': ricovero.codice_fiscale,
    'via_residenza': ricovero.residenza?.indirizzo?.via,
    'civico_residenza': ricovero.residenza?.indirizzo?.civico,
    'comune_residenza': comuneResidenza,
    'comune_residenza_specifico': comuneResidenza?.descrizione,
    'provincia_residenza': provinciaResidenza,
    'provincia_residenza_specifica': provinciaResidenza?.descrizione,
    'stato_residenza': statoResidenza,
    'stato_residenza_specifico': statoResidenza?.descrizione,
    'via_domicilio': ricovero.domicilio?.indirizzo?.via,
    'civico_domicilio': ricovero.domicilio?.indirizzo?.civico,
    'comune_domicilio': comuneDomicilio,
    'comune_domicilio_specifico': comuneDomicilio?.descrizione,
    'provincia_domicilio': provinciaDomicilio,
    'provincia_domicilio_specifica': provinciaDomicilio?.descrizione,
    'stato_domicilio': statoDomicilio,
    'stato_domicilio_specifico': statoDomicilio?.descrizione,
    'stato_civile': ricovero.stato_sivile_cod ?? 'Undefined',
    'grado_istruzione': ricovero.grado_istruzione_cod ?? 'Undefined',
    'professione': ricovero.professione?.descrizione,
    'condizione_professionale': ricovero.professione?.condizione_cod ?? 'Undefined',
    'posizione_professionale': ricovero.professione?.posizione_cod ?? 'Undefined',
    'ramo_attivita': ricovero.professione?.ramo_cod ?? 'Undefined',
    'uguali_domicilio_residenza': _.isEqual(ricovero.domicilio, ricovero.residenza),
  }

  return p;
}

/**
 * Returns a 'pratica object' with 'comunicazione_decesso property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'comunicazione_decesso property' fixed
 */
function fixComunicazione(pratica) {
  let p = _.cloneDeep(pratica);
  if (!p.comunicazione_decesso?.struttura?.codice_struttura) {
    p.comunicazione_decesso.struttura = UserInformationStore.getStruttura();
  }
  return p;
}

/**
 * Returns a 'pratica object' with 'istat_a property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'istat_a property' fixed
 */
function fixIstatA(pratica, fixMedico = false) {
  const ACTIVITY_DEF = ListMetaStore.getActivityDef();

  let p = _.cloneDeep(pratica);
  const STATUS = ACTIVITY_DEF[p.activity_definition_id].status;

  const STATUS_LIST = [
    'ModificaIstatAReparto',
    'ModificaIstatAMedicinaLegale',
    'ReadyReparto',
    'ReadyMedicinaLegale'
  ];

  if (!p.istat_a && _.includes(STATUS_LIST, STATUS)) {
    p.istat_a = emptyIstatA();
    p.istat_a.luogo_decesso = UserInformationStore.getLuogoDecesso() || 'IstitutoCura';
  }

  if (!p.istat_a.comune_luogo_decesso_specifico) {
    const comuneSpecifico = AppConfigStore.getIstatAComuneLuogoDecesso();
    p.istat_a.comune_luogo_decesso_specifico = comuneSpecifico;
    p.istat_a.comune_luogo_decesso = GeographicStore.getPaeseObjectFromName(comuneSpecifico);
  }

  if (!p.istat_a.provincia_luogo_decesso_specifica) {
    const provinciaSpecifica = AppConfigStore.getIstatAProvinciaLuogoDecesso();
    p.istat_a.provincia_luogo_decesso_specifica = provinciaSpecifica;
    p.istat_a.provincia_luogo_decesso = GeographicStore.getProvinciaObjectFromName(provinciaSpecifica);
  }

  if (!p.istat_a.codice_asl_decesso)
    p.istat_a.codice_asl_decesso = UserInformationStore.getCodiceASL();

  if (!p.istat_a.condizioni.condizione_morbosa)
    p.istat_a.condizioni.condizione_morbosa = [ emptyCondizioneMorbosa() ];

  if (fixMedico && (STATUS == STATUS_LIST[0] || STATUS == STATUS_LIST[1])) {
    const alwaysRemove = ListMetaStore.getActivityParameter(p.activity_definition_id, 'ALWAYS_REMOVE_DOCTOR_DATA');
    const autoCompile = ListMetaStore.getActivityParameter(p.activity_definition_id, 'AUTO_COMPILE_DOCTOR_DATA');

    if (autoCompile || alwaysRemove) {
      let userInformation = UserInformationStore.getUserInformations();

      p.istat_a.tipo_medico       = autoCompile ? userInformation['tipo_medico'] : "";
      p.istat_a.cognome_medico    = autoCompile ? userInformation['cognome'] : "";
      p.istat_a.nome_medico       = autoCompile ? userInformation['nome'] : "";
      p.istat_a.telefono_medico   = autoCompile ? userInformation['telefono'] : "";
      p.istat_a.telefono2_medico  = autoCompile ? userInformation['telefono2'] : "";
    }
  }

  return p;
}

/**
 * Returns a 'pratica object' with 'relazione_morte property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'relazione_morte property' fixed
 */
function fixRelazione(pratica) {
  const ACTIVITY_DEF = ListMetaStore.getActivityDef();
  
  let p = _.cloneDeep(pratica);
  const STATUS = ACTIVITY_DEF[p.activity_definition_id].status;

  const STATUS_LIST = [
    'ModificaRelazione',
    'ReadyMedicinaLegale',
    'ModificaAccertamento',
    'ModificaTrasporto',
    'ModificaIstatAMedicinaLegale',
  ];

  if (!p.relazione_morte /*&& _.includes(STATUS_LIST, STATUS)*/) {
    p.relazione_morte = emptyRelazione();

    if (p.istat_a && _.includes(STATUS_LIST, STATUS)) {
      if (!p.istat_a.condizioni.condizione_morbosa) {
        p.istat_a.condizioni.condizione_morbosa = [ emptyCondizioneMorbosa() ];
      }

      // initializing condizione_morbosa list
      p.relazione_morte.condizioni.condizione_morbosa = [];

      _.forEach(p.istat_a.condizioni,
        function(value) {
          let newCond = _.cloneDeep(value);

          // SC requires negative value for new conditions
          newCond.value = -pratica.relazione_morte.condizioni.condizione_morbosa.length -1;
          p.relazione_morte.condizioni.condizione_morbosa.push(newCond);
        }
      );

      const COPY_LIST = [
        'richiesto_riscontro_diagnostico',
        'morte_per_traumatismo_avvelenamento',
        'circostanza_traumatismo_avvelenamento',
        'modalita_traumatismo_avvelenamento',
        'infortunioLavoro_traumatismo_avvelenamento',
        'luogo_accidente',
        'altro_luogo_accidente',
      ];

      _.forEach(COPY_LIST, function(value) {
        p.relazione_morte[value] = p.istat_a[value];
      });
    }
  }

  return p;


}

/**
 * Returns a 'pratica object' with 'accertamento_morte property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'accertamento_morte property' fixed
 */
function fixAccertamento(pratica, fixMedico = false) {
  let ACTIVITY_DEF = ListMetaStore.getActivityDef();
  
  let p = _.cloneDeep(pratica);
  const STATUS = ACTIVITY_DEF[p.activity_definition_id].status;

  const STATUS_LIST = [
    'ModificaAccertamento',
    'ReadyMedicinaLegale',
  ];

  if (!p.accertamento_morte /*&& _.includes(STATUS_LIST, STATUS)*/)
    p.accertamento_morte = emptyAccertamento();

  if (fixMedico && STATUS == STATUS_LIST[0]) {
    let alwaysRemove = ListMetaStore.getActivityParameter(p.activity_definition_id, 'ALWAYS_REMOVE_DOCTOR_DATA');
    let autoCompile = ListMetaStore.getActivityParameter(p.activity_definition_id, 'AUTO_COMPILE_DOCTOR_DATA');

    if (autoCompile || alwaysRemove) {
      _fixMedicoSection(p, 'accertamento_morte', autoCompile);
    }

    // TODO: to be tested
    if (!p.accertamento_morte.misure_igienico_sanitarie) {
      // set the default value if specified
      const misureIgienicoSanitarie = ListMetaStore.getMetaValue('DEFAULT_FIELDS', 'ACCERTAMENTO_MISURE_IGIENICO_SANITARIE');
      if (misureIgienicoSanitarie) {
        p.accertamento_morte.misure_igienico_sanitarie = misureIgienicoSanitarie;
      }
    }

    // TODO: to be tested
    if (!p.accertamento_morte.riduzione_periodo_osservazione) {
      // set the default value if specified
      const riduzionePeriodoOsservazione = ListMetaStore.getMetaValue('DEFAULT_FIELDS', 'ACCERTAMENTO_RIDUZIONE_PERIODO_OSSERVAZIONE');
      if (riduzionePeriodoOsservazione) {
        p.accertamento_morte.riduzione_periodo_osservazione = riduzionePeriodoOsservazione;
      }
    }
  }

  return p;
}

/**
 * Returns a 'pratica object' with 'nulla_osta property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'nulla_osta property' fixed
 */
function fixNullaOsta(pratica, fixMedico = false) {
  let ACTIVITY_DEF = ListMetaStore.getActivityDef();
  
  let p = _.cloneDeep(pratica);
  const STATUS = ACTIVITY_DEF[p.activity_definition_id].status;

  const STATUS_LIST = [
    'ModificaNullaOsta',
    'ReadyMedicinaLegale',
  ];

  if (!p.nulla_osta /* && _.includes(STATUS_LIST, STATUS)*/)
    p.nulla_osta = emptyNullaOsta();

  if (fixMedico && STATUS == STATUS_LIST[0]) {
    let alwaysRemove = ListMetaStore.getActivityParameter(p.activity_definition_id, 'ALWAYS_REMOVE_DOCTOR_DATA');
    let autoCompile = ListMetaStore.getActivityParameter(p.activity_definition_id, 'AUTO_COMPILE_DOCTOR_DATA');

    if (autoCompile || alwaysRemove) {
      _fixMedicoSection(p, 'nulla_osta', autoCompile);
    }
  }

  return p;
}

/**
 * Returns a 'pratica object' with 'trasporto_deceduto property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'trasporto_deceduto property' fixed
 */
function fixTrasporto(pratica, fixMedico = false) {
  let ACTIVITY_DEF = ListMetaStore.getActivityDef();
  
  let p = _.cloneDeep(pratica);
  const STATUS = ACTIVITY_DEF[p.activity_definition_id].status;

  const STATUS_LIST = [
    'ModificaTrasporto',
    'ReadyMedicinaLegale',
  ];

  if (!p.modulo_trasporto /* && _.includes(STATUS_LIST, STATUS)*/)
    p.modulo_trasporto = emptyTrasporto();

  if (fixMedico && STATUS == STATUS_LIST[0]) {
    let alwaysRemove = ListMetaStore.getActivityParameter(p.activity_definition_id, 'ALWAYS_REMOVE_DOCTOR_DATA');
    let autoCompile = ListMetaStore.getActivityParameter(p.activity_definition_id, 'AUTO_COMPILE_DOCTOR_DATA');

    if (autoCompile || alwaysRemove) {
      let userInformation = UserInformationStore.getUserInformations();

      p.modulo_trasporto.cognome_medico = autoCompile ? userInformation['cognome'] : "";
      p.modulo_trasporto.nome_medico    = autoCompile ? userInformation['nome'] : "";
      p.modulo_trasporto.sesso_medico   = autoCompile ? userInformation['sesso'] : "Undefined";
    }
  }

  return p;
}

/**
 * Returns a 'pratica object' with 'istat_b property' initialized.
 * 
 * @param     {Pratica} pratica 'pratica object' to fix
 * @returns   {Pratica} 'pratica object' with 'istat_b property' fixed
 */
function fixIstatB(pratica, fixMedico = false) {
  const ACTIVITY_DEF = ListMetaStore.getActivityDef();

  let p = _.cloneDeep(pratica);
  const STATUS = ACTIVITY_DEF[p.activity_definition_id].status;

  const STATUS_LIST = [
    'ModificaIstatB',
  ];

  if (!p.istat_b && _.includes(STATUS_LIST, STATUS)) {
    p.istat_b = emptyIstatB();
    p.istat_b.tipo_cittadinanza = p.anagrafica_deceduto.cittadinanza_specifica === 'ITALIANA'
      ? 'ItalianaPerNascita'
      : 'Straniera';
  }

  if (p.istat_b.grado_istruzione === 'Undefined') {
    p.istat_b.grado_istruzione = 'ElementareONessuno';
  }
  if (p.istat_b.condizione_professionale === 'Undefined') {
    p.istat_b.condizione_professionale = 'Occupato';
  }
  if (p.istat_b.posizione_professionale === 'Undefined') {
    p.istat_b.posizione_professionale = 'AutonomoImprenditoreOLiberoProfessionista';
  }
  if (p.istat_b.ramo_attivita === 'Undefined') {
    p.istat_b.ramo_attivita = 'AgricolturaCacciaPesca';
  }

  if (fixMedico && (STATUS == STATUS_LIST[0])) {
    const userInformation = UserInformationStore.getUserInformations();

    p.istat_b.circoscrizione_ufficio_stato_civile = userInformation['ufficiale_stato_civile_circoscrizione'];

    // comune ufficio stato civile
    const comuneSpecifico = userInformation['ufficiale_stato_civile_comune'];
    p.istat_b.comune_ufficio_stato_civile_specifico = comuneSpecifico;
    p.istat_b.comune_ufficio_stato_civile = GeographicStore.getPaeseObjectFromName(comuneSpecifico);

    // provincia ufficio stato civile
    const provinciaSpecifica = userInformation['ufficiale_stato_civile_provincia'];
    p.istat_b.provincia_ufficio_stato_civile_specifica = provinciaSpecifica;
    p.istat_b.provincia_ufficio_stato_civile = GeographicStore.getProvinciaObjectFromName(provinciaSpecifica);

    p.istat_b.telefono_ufficiale_stato_civile = userInformation['telefono'];
    p.istat_b.email_ufficiale_stato_civile = userInformation['email'];
  }

  return p;
}

/**
 * It fixes 'azioni' of the 'pratica object' adding decorations.
 * 
 * @param     {Pratica.azioni} azioni actions to be fixed
 * @returns   {Pratica.azioni}
 */
function fixAzioni(azioni) {
  let fixed = [];
  _.forEach(azioni.action, (act) => {
    act.decoration = ListMetaStore.resolveAllMetas('TRANSITION_DEF', act.id);
    fixed.push(act);
  });
  return fixed;
}

/**
 * Updates the 'medico' section of a given object with information from the currently logged-in user, if available.
 * 
 * @param {Object} p - The 'pratica' object containing the 'medico' section to be updated.
 * @param {string} moduleName - The name of the 'medico' section.
 * @param {boolean} [autoCompile=true] - Whether to automatically compile the information from the logged-in user.
 * @returns {void}
 */
function _fixMedicoSection(p, moduleName, autoCompile) {
  const userInformation = UserInformationStore.getUserInformations();

  p[moduleName].cognome_medico                       = autoCompile ? userInformation['cognome'] : "";
  p[moduleName].nome_medico                          = autoCompile ? userInformation['nome'] : "";
  p[moduleName].comune_nascita_medico_specifico      = autoCompile ? userInformation['comune_nascita'] : "";
  p[moduleName].provincia_nascita_medico_specifico   = autoCompile ? userInformation['provincia_nascita'] : "";
  p[moduleName].dt_nascita_medico                    = autoCompile ? userInformation['dt_nascita'] : DateUtils.EMPTY_DATE;
  p[moduleName].via_residenza_medico                 = autoCompile ? userInformation['via_residenza'] : "";
  p[moduleName].civico_residenza_medico              = autoCompile ? userInformation['civico_residenza'] : "";
  p[moduleName].comune_residenza_medico_specifico    = autoCompile ? userInformation['comune_residenza'] : "";
  p[moduleName].provincia_residenza_medico_specifica = autoCompile ? userInformation['provincia_residenza'] : "";
  p[moduleName].telefono_residenza_medico            = autoCompile ? userInformation['telefono_residenza'] : "";
  p[moduleName].tipo_delega_medico                   = autoCompile ? userInformation['tipo_delega_medico'] : "DelegatoDirettoreSanitario";

  if (p[moduleName].comune_nascita_medico_specifico) {
    const comuneSpecifico = p[moduleName].comune_nascita_medico_specifico;
    p[moduleName].comune_nascita_medico = GeographicStore.getPaeseObjectFromName(comuneSpecifico);
  }

  if (p[moduleName].provincia_nascita_medico_specifico) {
    const provinciaSpecifica = p[moduleName].provincia_nascita_medico_specifico;
    p[moduleName].provincia_nascita_medico = GeographicStore.getProvinciaObjectFromName(provinciaSpecifica);
  }

  if (p[moduleName].comune_residenza_medico_specifico) {
    const comuneSpecifico = p[moduleName].comune_residenza_medico_specifico;
    p[moduleName].comune_residenza_medico = GeographicStore.getPaeseObjectFromName(comuneSpecifico);
  }

  if (p[moduleName].provincia_residenza_medico_specifica) {
    const provinciaSpecifica = p[moduleName].provincia_residenza_medico_specifica;
    p[moduleName].provincia_residenza_medico = GeographicStore.getProvinciaObjectFromName(provinciaSpecifica);
  }
}

/**
 * Creates an empty medical condition object with default values.
 * 
 * @param {Object} [options={}] - Optional arguments for the medical condition.
 * @param {number} [options.ordine = 1] - The order of the condition.
 * @param {boolean} [options.is_preesistente = false] - Indicates whether the condition is pre-existing.
 * @param {number} [options.value = -1] - The value associated with the condition.
 * @returns {Object} An empty "Condizione Morbosa" object with default values.
 */
function emptyCondizioneMorbosa({ ordine = 1, is_preesistente = false, value = -1 } = {}) {
  return {
    malattia: "",
    ordine,
    anni_durata: 0,
    mesi_durata: 0,
    giorni_durata: 0,
    is_preesistente,
    value,
  };
}

export default {
  emptyAnagraficaDeceduto,
  emptyComunicazioneDecesso,
  fixAnagraficaFromRicovero,
  fixComunicazione,
  fixIstatA,
  fixRelazione,
  fixAccertamento,
  fixNullaOsta,
  fixTrasporto,
  fixIstatB,
  fixAzioni,
  emptyCondizioneMorbosa,
}