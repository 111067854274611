import store from 'store'
import router from "router"

import User from 'services/user'

function isSuccess(response) {
  return !response.errori
}

function isAuthError(error) {
  return error.codice == 'EXCEPTION-001'
}

function getErrors(response) {
  return _.get(response, 'data.errori.errore')
}

function isAuthorized(response) {
  return _.every(getErrors(response), (error) => {
    return !isAuthError(error)
  })
}

function isAllAuthorized(responses) {
  return _.every(responses, (response) => {
    return isAuthorized(response)
  })
}

function logout() {
  User.removeUserData()
  store.commit('startupReset')
  router.push({name: 'Login'})
  window.location.reload()
}

export default {
  isSuccess,
  isAuthorized,
  isAllAuthorized,
  logout
}