<template>
  <!-- cell -->
  <div id="cell-renderer-details">
    <v-tooltip
      :open-on-click="false"
      color="primary"
      open-delay="2000"
      nudge-left="100"
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row
          class="row-flex"
          no-gutters
          v-bind="attrs"
          v-on="on"
        >

          <!-- Icon related to Pratica state -->
          <v-col cols="auto" class="mr-2">
            <v-icon class="py-3">{{ icon }}</v-icon>
          </v-col>

          <v-col cols="auto" class="pl-0">
            <div>

              <!-- Pratica identifier -->
              <p class="text-first-line">{{ codicePratica }}</p>

              <!-- Name and Surname -->
              <p class="text-second-line">
                {{ deceduto }}
              </p>
              <p class="text-second-line"></p>
            </div>
          </v-col>
        </v-row>
      </template>

      <div>
        <span>
          Deceduto: <b>{{ deceduto }}</b>
          <br>Pratica: <b>{{ codicePratica }}</b>
          <br>Data Morte: <b>{{ dataMorte }}</b>
          <br>Reparto: <b>{{ reparto }}</b>

          <!-- Tooltip shown in case of userType = Comune  -->
          <template v-if="isComune">
            <template v-if="dataRicezioneFormatted">
              <br>Ricevuta il: <b>{{ dataRicezioneFormatted }}</b>
            </template>

            <template v-if="dataInvioGestionaleFormatted">
              <br>Inviata al gestionale il: <b>{{ dataInvioGestionaleFormatted }}</b>

              <template v-if="codiceGestionale">
                <br>Codice pratica gestionale: <b>{{ codiceGestionale }}</b>
              </template>

              <template v-else-if="erroreGestionale">
                <br>Errore gestionale: <b>{{ erroreGestionale }}</b>
              </template>
            </template>
          </template>

          <!-- Tooltip shown in case of userType != Comune  -->
          <template v-else>
            <!-- Accettata from Comune -->
            <template v-if="isAccettata">
              <br>Accettata il: <b>{{ dataAccettazioneFormatted }}</b>
            </template>

            <!-- Rifiutata from Comune -->
            <template v-if="isRifiutata">
              <br>Rifiutata il: <b>{{ dataAccettazioneFormatted }}</b>
              <br>Motivo rifiuto: <b>{{ messaggioAccettazione }}</b>
            </template>
          </template>
        </span>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
// utilities
import DateUtils from "services/date_util";
// stores
import { UserInformationStore } from "services/commands/stores";
// services
import { ICONS } from 'services/icon_util';

export default ({
  data() {
    return {
      icon: "",
      deceduto: "",
      codicePratica: "",
      dataMorte: "",
      reparto: "",

      statoAccettazione: "",
      messaggioAccettazione: "",
      dataAccettazioneFormatted: "",

      dataRicezioneFormatted: "",
      dataInvioGestionaleFormatted: "",
      codiceGestionale: "",
      erroreGestionale: "",
    };
  },

  computed: {
    isComune: () => UserInformationStore.getCategoriaUtente() == 'Comune',

    isAccettata() { return this.statoAccettazione == 'Accettato' },
    isRifiutata() { return this.statoAccettazione == 'Rifiutato' },
  },

  methods: {
    /**
     * Params' structure:
     * params: {
     *  value: #,
     *  node: {
     *    data: {
     *      cognome_deceduto: #,
     *      nome_deceduto: #,
     *      [...]
     *    }
     *  },
     *  [...] 
     * }
     * 
     * @param {*} params 
     */
    refresh(params) {
      this.params = params;
    },

    getIcon: (key) => ICONS[key].outlined ?? ICONS[key].mdi,
  },

  beforeMount() {
    const { data } = this.params;

    this.deceduto = `${data.nome_deceduto} ${data.cognome_deceduto}`;
    this.codicePratica = data.nome;
    this.dataMorte = DateUtils.formatDate(data.dt_morte);
    this.reparto = data.reparto.name;

    this.statoAccettazione = data.stato_accettazione;
    this.messaggioAccettazione = data.messaggio_accettazione;
    this.dataAccettazioneFormatted = DateUtils.formatDate(data.dt_accettazione);

    this.dataRicezioneFormatted = !DateUtils.isEmptyDate(data.dt_ricezione)
      ? DateUtils.formatDate(data.dt_ricezione)
      : null;

    this.dataInvioGestionaleFormatted = !DateUtils.isEmptyDate(data.dt_invio_gestionale)
      ? DateUtils.formatDate(data.dt_invio_gestionale)
      : null;

    this.codiceGestionale = data.codice_gestionale;
    this.erroreGestionale = data.errore_gestionale;

    this.icon = this.getIcon(data.activity_decoration.ICON);
  }
})
</script>