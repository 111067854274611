var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.init
    ? _c(
        "div",
        { staticClass: "router-view-container" },
        [
          _c(
            "v-app",
            { attrs: { id: "inspire" } },
            [
              _c("c-dialog-information", {
                attrs: { title: _vm.titleDialog, text: _vm.textDialog },
                model: {
                  value: _vm.showDialog,
                  callback: function ($$v) {
                    _vm.showDialog = $$v
                  },
                  expression: "showDialog",
                },
              }),
              _vm._v(" "),
              _c(
                "v-overlay",
                {
                  staticStyle: { "z-index": "100" },
                  attrs: { value: _vm.showOverlay },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }