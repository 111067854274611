<script>
import { VTextarea } from "vuetify/lib"

import inputUpperCase from "mixins/input_upper_case";

export default {
  name: 'CTextarea',

  extends: VTextarea,

  mixins: [inputUpperCase],

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    
    dense: {
      type: Boolean,
      default: true,
    },

    autoGrow: {
      type: Boolean,
      default: true,
    },

    rows: {
      type: String,
      default: "4",
    },
  },
};
</script>