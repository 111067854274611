var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.page.id,
      staticClass: "document-page",
      attrs: {
        "data-page-id": _vm.page.id,
        "data-order": _vm.page.order_index,
      },
      on: {
        mouseover: function ($event) {
          $event.stopPropagation()
          _vm.isHovered = true
        },
        mouseleave: function ($event) {
          $event.stopPropagation()
          _vm.isHovered = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-header", class: _vm.barStyle },
        [
          _vm._t("left-button"),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: false, text: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.zoomPreview()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                { attrs: { color: "white" } },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("mdi-magnify-plus")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" "), _c("span", [_vm._v("Anteprima")])]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.enablePositionWrapper
        ? [
            _c("div", {
              staticClass: "back-side page-dropzone",
              attrs: { "data-position": "before" },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "front-side page-dropzone",
              attrs: { "data-position": "after" },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-handle",
        style: _vm.imgStyle,
        attrs: { src: _vm.smallPreviewUrl },
      }),
      _vm._v(" "),
      _vm.showPageFooter
        ? _c("div", { staticClass: "page-footer" }, [
            _c("p", [_vm._v(_vm._s(_vm.page.original_name))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "overlay-opacity": "0.8", "max-width": "600" },
          model: {
            value: _vm.showPreview,
            callback: function ($$v) {
              _vm.showPreview = $$v
            },
            expression: "showPreview",
          },
        },
        [
          _c("img", {
            staticClass: "img-preview",
            attrs: { src: _vm.largePreviewUrl },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }