<script>
import { VCombobox } from "vuetify/lib"

export default {
  name: 'CComboBox',

  extends: VCombobox,

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },

    dense: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    // custom onInput to make input UpperCase
    onInput (e) {
      if (
        this.selectedIndex > -1 ||
        !e.target
      ) return

      const target = e.target
      const value = target.value.toUpperCase()

      // If typing and menu is not currently active
      if (target.value) this.activateMenu()

      if (!this.multiple && value === '') this.deleteCurrentItem()

      this.internalSearch = value
      this.badInput = target.validity && target.validity.badInput
    },

    // necessary to update CComboBox value on blur
    onBlur (e) { this.blur() }
  },
};
</script>