var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropzone",
      attrs: {
        id: "uncategorized-panel",
        "data-category-id": "null",
        disabled: true,
      },
    },
    [
      _vm.showDzMessage
        ? _c("div", { staticClass: "dz-message" }, [
            _c("b", [_vm._v("Trascina")]),
            _vm._v(" qui i file\n    "),
            _c("br"),
            _vm._v("Oppure "),
            _c("b", [_vm._v("scegli")]),
            _vm._v(" i file da caricare\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.pages.length > 0
        ? _c(
            "div",
            { staticClass: "document-group" },
            [
              _c("div", { staticClass: "document-header" }),
              _vm._v(" "),
              _vm._l(_vm.pages, function (page) {
                return _c("SinglePage", {
                  key: "upage_" + page.id,
                  attrs: {
                    "data-type": "page",
                    page: page,
                    previewUrl: _vm.previewUrl,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "left-button",
                        fn: function () {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                disabled: false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deletePage(page.id)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: { color: "white" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [_vm._v("Rimuovi pagina")]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("c-dialog-confirm", {
        ref: "dialogConfirmPageRemove",
        attrs: { title: "Elimina pagina" },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "uploaded-file-box" }, [
      _c("div", { staticClass: "uploaded-file-queue dropzone-previews" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }