var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-trasporto" } },
    [
      _c("c-expansion-sections", {
        attrs: {
          disabled: _vm.disabled,
          sections: ["MEDICO CHIRURGO", "DECEDUTO", "TRASPORTO SALMA"],
        },
        scopedSlots: _vm._u([
          {
            key: "section1",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Cognome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.mt.cognome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.mt, "cognome_medico", $$v)
                            },
                            expression: "mt.cognome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Nome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.mt.nome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.mt, "nome_medico", $$v)
                            },
                            expression: "mt.nome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listSesso,
                            rules: _vm.requiredRule,
                            label: "Sesso",
                            "prepend-icon": "mdi-gender-male-female",
                          },
                          model: {
                            value: _vm.mt.sesso_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.mt, "sesso_medico", $$v)
                            },
                            expression: "mt.sesso_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section2",
            fn: function () {
              return [
                _c("SectionDeceduto", {
                  attrs: {
                    "anagrafica-deceduto": _vm.pratica.anagrafica_deceduto,
                    "comunicazione-decesso": _vm.pratica.comunicazione_decesso,
                    "show-via-civico-domicilio": false,
                    "show-comune-provincia-domicilio": false,
                    "show-anni-mesi-giorni-ore": false,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "section3",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.destinazioneSalma,
                            rules: _vm.requiredRule,
                            label: "Destinazione",
                            "prepend-icon": "mdi-coffin",
                          },
                          model: {
                            value: _vm.mt.luogo_destinazione,
                            callback: function ($$v) {
                              _vm.$set(_vm.mt, "luogo_destinazione", $$v)
                            },
                            expression: "mt.luogo_destinazione",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showRagioneSociale,
                        expression: "showRagioneSociale",
                      },
                    ],
                    staticClass: "ma-2",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Ragione Sociale",
                            "prepend-icon": "mdi-text-box-outline",
                          },
                          model: {
                            value: _vm.mt.ragione_sociale_luogo_destinazione,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mt,
                                "ragione_sociale_luogo_destinazione",
                                $$v
                              )
                            },
                            expression: "mt.ragione_sociale_luogo_destinazione",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showViaDestinazione,
                        expression: "showViaDestinazione",
                      },
                    ],
                    staticClass: "ma-2",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Via",
                            "prepend-icon": "mdi-home-map-marker",
                          },
                          model: {
                            value: _vm.mt.via_luogo_destinazione,
                            callback: function ($$v) {
                              _vm.$set(_vm.mt, "via_luogo_destinazione", $$v)
                            },
                            expression: "mt.via_luogo_destinazione",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            rules: _vm.requiredRule,
                            label: "Comune",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.comuneLuogoDestinazione,
                            callback: function ($$v) {
                              _vm.comuneLuogoDestinazione = $$v
                            },
                            expression: "comuneLuogoDestinazione",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            rules: _vm.requiredRule,
                            label: "Provincia",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.provinciaLuogoDestinazione,
                            callback: function ($$v) {
                              _vm.provinciaLuogoDestinazione = $$v
                            },
                            expression: "provinciaLuogoDestinazione",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }