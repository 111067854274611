var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ma-2", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("c-select", {
                attrs: {
                  items: _vm.listReparto,
                  disabled: !_vm.comboboxRepartoEnabled,
                  "item-text": "name",
                  "item-value": "",
                  label: "Reparto",
                  "prepend-icon": "mdi-hospital-building",
                  "return-object": "",
                },
                model: {
                  value: _vm.cd.reparto,
                  callback: function ($$v) {
                    _vm.$set(_vm.cd, "reparto", $$v)
                  },
                  expression: "cd.reparto",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("c-text-field", {
                attrs: {
                  label: "Numero Letto",
                  type: "number",
                  "prepend-icon": "mdi-bed",
                  "hide-spin-buttons": "",
                },
                model: {
                  value: _vm.cd.numero_letto,
                  callback: function ($$v) {
                    _vm.$set(_vm.cd, "numero_letto", $$v)
                  },
                  expression: "cd.numero_letto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "ma-2", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("c-date-picker", {
                attrs: { label: "Data di Morte", rules: _vm.requiredRule },
                model: {
                  value: _vm.dataMorteISO,
                  callback: function ($$v) {
                    _vm.dataMorteISO = $$v
                  },
                  expression: "dataMorteISO",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("c-time-picker", {
                attrs: { label: "Orario di Morte", rules: _vm.requiredRule },
                model: {
                  value: _vm.oraMorteFormatted,
                  callback: function ($$v) {
                    _vm.oraMorteFormatted = $$v
                  },
                  expression: "oraMorteFormatted",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }