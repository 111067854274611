<template>
  <v-expansion-panels
    v-model="panels"
    :disabled="disabled"
    accordion
    multiple
    hover
    tile
  >
    <v-expansion-panel
      v-for="(section, index) in sections"
      :key="index"
      class="panel"
    >
      <v-expansion-panel-header :class="disabled ? {} : 'panel-title'">
        <b>{{ section }}</b>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <slot :name="`section${index + 1}`"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
    
  </v-expansion-panels>
</template>

<script>

export default {
  name: "CExpansionSections",

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    sections: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      panels: _.map(this.sections, (_, index) => index),
    };
  },
}
</script>

<style scoped>
  .panel {
    background: #F2F2F7 !important;
  }

  .panel-title {
    color: #144272;
  }

  .v-expansion-panel::before {
    box-shadow: none !important;
  }
  
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: unset;
  }

  .v-expansion-panel-header {
    background-color: #EBEBF0;
    min-height: unset;
    padding: 12px 12px;
  }
</style>