<template>
  <!-- Single page  -->
  <div
    class="document-page"
    :data-page-id="page.id"
    :data-order="page.order_index"
    :key="page.id"
    @mouseover.stop="isHovered = true"
    @mouseleave.stop="isHovered = false"
  >
    <div class="page-header" :class="barStyle">
      <slot name="left-button"></slot>

      <v-spacer />

      <!-- Zoom preview button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="false"
            @click="zoomPreview()"
            text
            icon
          >
            <v-icon v-bind="attrs" v-on="on" color="white">mdi-magnify-plus</v-icon>
          </v-btn>
        </template>
        <span>Anteprima</span>
      </v-tooltip>
    </div>

    <template v-if="enablePositionWrapper">
      <div class="back-side page-dropzone" data-position="before"></div>
      <div class="front-side page-dropzone" data-position="after"></div>
    </template>

    <!-- Small page preview -->
    <img
      class="img-handle"
      :src="smallPreviewUrl"
      :style="imgStyle"
    >

    <!-- Original page name -->
    <div v-if="showPageFooter" class="page-footer">
      <p>{{ page.original_name }}</p>
    </div>

    <!-- Page preview dialog -->
    <v-dialog
      v-model="showPreview"
      overlay-opacity="0.8"
      max-width="600"
    >
      <img
        class="img-preview"
        :src="largePreviewUrl"
      > 
    </v-dialog>
  </div>
</template>

<script>
import UrlUtil from 'services/url_util';

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
    enablePositionWrapper: {
      type: Boolean,
      required: false,
      default: true,
    },
    imgStyle: {
      type: Object,
      required: false,
      default: () => {}
    },
    previewUrl: {
      type: String,
      required: true,
    },
    smallPreviewResolution: {
      type: Number,
      required: false,
      default: 500,
    },
    largePreviewResolution: {
      type: Number,
      required: false,
      default: 1000,
    }
  },

  data() {
    return {
      isHovered: false,

      // if true, it indicates that preview dialog should be shown
      showPreview: false,
    };
  },

  computed: {
    barStyle() {
      if (!this.isHovered)
        return 'hidden'
      return ''
    },

    showPageFooter() {
      return this.title != ""
    },

    smallPreviewUrl() {
      return UrlUtil.getPdfServicePreviewUrl({
        path: this.page.preview_path,
        resolution: this.smallPreviewResolution,
      })
    },

    largePreviewUrl() {
      return UrlUtil.getPdfServicePreviewUrl({
        path: this.page.preview_path,
        resolution: this.largePreviewResolution,
      })
    },
  },
  
  methods: {
    // Display large preview dialog.
    zoomPreview() {
      this.showPreview = true
    },
  },
}
</script>