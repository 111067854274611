import list from 'data/list_paesi_province_regioni.json'

var STORE_STATI = {};

var LIST_PAESI = []
var LIST_PROVINCE = []
var LIST_REGIONI = []
var LIST_STATI = []
var LIST_CITTADINANZE = []

function init(jsonListMeta) {
  STORE_STATI = {};
  LIST_PAESI = [];
  LIST_PROVINCE = [];
  LIST_REGIONI = [];
  LIST_STATI = [];
  LIST_CITTADINANZE = [];
  
  _.forEach(jsonListMeta.stati.stato, function(stato) {
    STORE_STATI[stato.value] = stato;
  });
}

///
/// Lists getters
///

// LIST_PAESI object example
//
// 0: {
//   "descrizione": "ABANO TERME",
//   "cod_catastale": "A001",
//   "cod_istat": "028001",
//   "value": 28001,
//   "provincia_id": 28
// }
function listPaesi() {
  if (LIST_PAESI.length === 0) {
    LIST_PAESI = _generateOrderedList(list.paesi);
  }
  return LIST_PAESI;
}

function listProvince() {
  if (LIST_PROVINCE.length === 0) {
    LIST_PROVINCE = _generateOrderedList(list.province);
  }
  return LIST_PROVINCE;
}

function listRegioni() {
  if (LIST_REGIONI.length === 0) {
    LIST_REGIONI = _generateOrderedList(list.regioni);
  }
  return LIST_REGIONI;
}

function listStati() {
  if (LIST_STATI.length === 0) {
    LIST_STATI = _generateOrderedList(STORE_STATI);
  }
  return LIST_STATI;
}

function listCittadinanze() {
  if (LIST_CITTADINANZE.length === 0) {
    _.forEach(STORE_STATI, function(stato) {
      LIST_CITTADINANZE.push({
        "descrizione": stato.cittadinanza,
      });
    });
  }
  return LIST_CITTADINANZE;
}

function _generateOrderedList(source, sortByProp = 'descrizione') {
  const array = [];
  _.forEach(source, (e) => array.push(e));
  return _.sortBy(array, sortByProp);
}

///
/// Object Getters
///

function getPaeseObject(key) {
  return key ? list.paesi[key] : null;
}

function getPaeseObjectFromName(name) {
  return _.find(list.paesi, ['descrizione', name]);
}

function getProvinciaObject(key) {
  return key ? list.province[key] : null;
}

function getProvinciaObjectFromName(name) {
  return _.find(list.province, ['descrizione', name]);
}

function getRegioneObject(key) {
  return key ? list.regioni[key] : null;
}

function getStatoObject(key) {
  return key ? STORE_STATI[key] : null;
}

///
/// Util Object Getters
///

function getProvinciaObjectByPaese(key) {
  if (!list.paesi[key])
    return null;

  const { provincia_id } = list.paesi[key];
  return list.province[provincia_id];
}

function getRegioneObjectByProvincia(key) {
  if (!list.province[key])
    return null;

  const { regione_id } = list.province[key];
  return list.regione[regione_id];
}

function getRegioneObjectByPaese(key) {
  if (!list.paesi[key])
    return null;

  const { provincia_id } = list.paesi[key];
  return getRegioneObjectByProvincia(provincia_id);
}

function getStatoObjectByRegione(key) {
  if (!list.regioni[key])
    return null;

  const { stato_id } = list.regioni[key];
  return STORE_STATI[stato_id];
}

function getStatoObjectByProvincia(key) {
  if (!list.province[key])
    return null;

  const { regione_id } = list.province[key];
  return getStatoObjectByRegione(regione_id);
}

function getStatoObjectByPaese(key) {
  if (!list.paesi[key])
    return null;
  const { provincia_id } = list.paesi[key];
  return getStatoObjectByProvincia(provincia_id);
}

function getCittadinanzaByStato(key) {
  return STORE_STATI[key].cittadinanza ?? "";
}


export default {
  init,
  listPaesi,
  listProvince,
  listRegioni,
  listStati,
  listCittadinanze,
  getPaeseObject,
  getPaeseObjectFromName,
  getProvinciaObject,
  getProvinciaObjectFromName,
  getRegioneObject,
  getStatoObject,
  getProvinciaObjectByPaese,
  getRegioneObjectByProvincia,
  getRegioneObjectByPaese,
  getStatoObjectByRegione,
  getStatoObjectByProvincia,
  getStatoObjectByPaese,
  getCittadinanzaByStato,
}