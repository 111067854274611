var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-istat-b" } },
    [
      _c("c-expansion-sections", {
        attrs: {
          disabled: _vm.disabled,
          sections: [
            "DECEDUTO",
            "UFFICIO DI STATO CIVILE",
            "NASCITA",
            "MORTE",
            "RESIDENZA",
            "ALTRE INFORMAZIONI",
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "section1",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Cognome",
                            "prepend-icon": "mdi-account-outline",
                          },
                          model: {
                            value: _vm.ib.cognome,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "cognome", $$v)
                            },
                            expression: "ib.cognome",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Nome",
                            "prepend-icon": "mdi-account-outline",
                          },
                          model: {
                            value: _vm.ib.nome,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "nome", $$v)
                            },
                            expression: "ib.nome",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listSesso,
                            rules: _vm.requiredRule,
                            label: "Sesso",
                            "prepend-icon": "mdi-gender-male-female",
                          },
                          model: {
                            value: _vm.ib.sesso,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "sesso", $$v)
                            },
                            expression: "ib.sesso",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            relus: _vm.requiredRule,
                            label: "Codice Fiscale",
                            "prepend-icon": "mdi-credit-card-outline",
                          },
                          model: {
                            value: _vm.ib.codice_fiscale,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "codice_fiscale", $$v)
                            },
                            expression: "ib.codice_fiscale",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section2",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            label: "Comune",
                            clearable: "",
                          },
                          model: {
                            value: _vm.comuneUfficioStatoCivile,
                            callback: function ($$v) {
                              _vm.comuneUfficioStatoCivile = $$v
                            },
                            expression: "comuneUfficioStatoCivile",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            label: "Provincia",
                            clearable: "",
                          },
                          model: {
                            value: _vm.provinciaUfficioStatoCivile,
                            callback: function ($$v) {
                              _vm.provinciaUfficioStatoCivile = $$v
                            },
                            expression: "provinciaUfficioStatoCivile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Circoscrizione",
                            type: "number",
                            "prepend-icon": "mdi-ticket-confirmation-outline",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.ib.circoscrizione_ufficio_stato_civile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ib,
                                "circoscrizione_ufficio_stato_civile",
                                $$v
                              )
                            },
                            expression:
                              "ib.circoscrizione_ufficio_stato_civile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Telefono",
                            type: "number",
                            "prepend-icon": "mdi-phone",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.ib.telefono_ufficiale_stato_civile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ib,
                                "telefono_ufficiale_stato_civile",
                                $$v
                              )
                            },
                            expression: "ib.telefono_ufficiale_stato_civile",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Email",
                            "prepend-icon": "mdi-email",
                          },
                          model: {
                            value: _vm.ib.email_ufficiale_stato_civile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ib,
                                "email_ufficiale_stato_civile",
                                $$v
                              )
                            },
                            expression: "ib.email_ufficiale_stato_civile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section3",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-date-picker", {
                          attrs: {
                            minimumDate: _vm.minimumDate,
                            rules: _vm.requiredRule,
                            label: "Data di Nascita",
                          },
                          model: {
                            value: _vm.dataNascitaISO,
                            callback: function ($$v) {
                              _vm.dataNascitaISO = $$v
                            },
                            expression: "dataNascitaISO",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            label: "Comune di Nascita",
                          },
                          model: {
                            value: _vm.comuneNascita,
                            callback: function ($$v) {
                              _vm.comuneNascita = $$v
                            },
                            expression: "comuneNascita",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            label: "Provincia di Nascita",
                          },
                          model: {
                            value: _vm.provinciaNascita,
                            callback: function ($$v) {
                              _vm.provinciaNascita = $$v
                            },
                            expression: "provinciaNascita",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listStati,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-earth",
                            label: "Nazione di Nascita",
                          },
                          model: {
                            value: _vm.statoNascita,
                            callback: function ($$v) {
                              _vm.statoNascita = $$v
                            },
                            expression: "statoNascita",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listCittadinanze,
                            rules: _vm.requiredRule,
                            label: "Cittadinanza",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-flag",
                            clearable: "",
                          },
                          model: {
                            value: _vm.cittadinanzaSpecifica,
                            callback: function ($$v) {
                              _vm.cittadinanzaSpecifica = $$v
                            },
                            expression: "cittadinanzaSpecifica",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section4",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-date-picker", {
                          attrs: {
                            minimumDate: _vm.minimumDate,
                            rules: _vm.requiredRule,
                            label: "Data di Morte",
                          },
                          model: {
                            value: _vm.dataMorteISO,
                            callback: function ($$v) {
                              _vm.dataMorteISO = $$v
                            },
                            expression: "dataMorteISO",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-time-picker", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Orario di Morte",
                          },
                          model: {
                            value: _vm.oraMorteFormatted,
                            callback: function ($$v) {
                              _vm.oraMorteFormatted = $$v
                            },
                            expression: "oraMorteFormatted",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            value: _vm.anni,
                            disabled: true,
                            label: "Età (anni)",
                            "prepend-icon": "mdi-calendar",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section5",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            label: "Comune",
                          },
                          model: {
                            value: _vm.comuneResidenza,
                            callback: function ($$v) {
                              _vm.comuneResidenza = $$v
                            },
                            expression: "comuneResidenza",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            label: "Provincia",
                          },
                          model: {
                            value: _vm.provinciaResidenza,
                            callback: function ($$v) {
                              _vm.provinciaResidenza = $$v
                            },
                            expression: "provinciaResidenza",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listStati,
                            rules: _vm.requiredRule,
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-earth",
                            label: "Nazione di Nascita",
                          },
                          model: {
                            value: _vm.statoResidenza,
                            callback: function ($$v) {
                              _vm.statoResidenza = $$v
                            },
                            expression: "statoResidenza",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section6",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listStatoCivile,
                            rules: _vm.requiredRule,
                            label: "Stato Civile",
                            "prepend-icon": "mdi-human-male-female",
                          },
                          model: {
                            value: _vm.ib.stato_civile,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "stato_civile", $$v)
                            },
                            expression: "ib.stato_civile",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-banner",
                  {
                    staticStyle: { padding: "0" },
                    attrs: {
                      value: _vm.showAnnoMatrimonio,
                      transition: "slide-y-transition",
                      "single-line": "",
                    },
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Anno di nascita Coniuge",
                                type: "number",
                                "prepend-icon": "mdi-calendar",
                                "hide-spin-buttons": "",
                              },
                              model: {
                                value: _vm.ib.anno_nascita_coniuge,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ib, "anno_nascita_coniuge", $$v)
                                },
                                expression: "ib.anno_nascita_coniuge",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "5" } },
                          [
                            _c("c-text-field", {
                              attrs: {
                                label: "Anno Matrimonio",
                                type: "number",
                                "prepend-icon": "mdi-ring",
                                "hide-spin-buttons": "",
                              },
                              model: {
                                value: _vm.ib.anno_matrimonio,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ib, "anno_matrimonio", $$v)
                                },
                                expression: "ib.anno_matrimonio",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listGradoIstruzione,
                            rules: _vm.requiredRule,
                            label: "Grado istruzione",
                            "prepend-icon": "mdi-account-school",
                          },
                          model: {
                            value: _vm.ib.grado_istruzione,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "grado_istruzione", $$v)
                            },
                            expression: "ib.grado_istruzione",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Professione",
                            rules: _vm.requiredRule,
                            "prepend-icon": "mdi-account-tie",
                          },
                          model: {
                            value: _vm.ib.professione,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "professione", $$v)
                            },
                            expression: "ib.professione",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listCondizioneProfessionale,
                            rules: _vm.requiredRule,
                            label: "Condizione professionale",
                            "prepend-icon": "mdi-briefcase",
                          },
                          model: {
                            value: _vm.ib.condizione_professionale,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "condizione_professionale", $$v)
                            },
                            expression: "ib.condizione_professionale",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listPosizioneProfessionale,
                            rules: _vm.requiredRule,
                            label: "Posizione professionale",
                            "prepend-icon": "mdi-account-group",
                          },
                          model: {
                            value: _vm.ib.posizione_professione,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "posizione_professione", $$v)
                            },
                            expression: "ib.posizione_professione",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-select", {
                          attrs: {
                            items: _vm.listRamoAttivita,
                            rules: _vm.requiredRule,
                            label: "Ramo attività",
                            "prepend-icon": "mdi-sitemap",
                          },
                          model: {
                            value: _vm.ib.ramo_attivita,
                            callback: function ($$v) {
                              _vm.$set(_vm.ib, "ramo_attivita", $$v)
                            },
                            expression: "ib.ramo_attivita",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }