// services
import User from 'services/user';
// stores
import UserInformationStore from 'services/commands/stores/user_information_store';
import ListMetaStore from 'services/commands/stores/list_meta_store';

/**
 * Map containing all the information needed to manage modules
 * [ visible ], [ editable ] and [ enabled ] values are added in beforeMount() method
 * [ checkEnabled(_) ] function is used to perform additional controls on [ enabled ]
 */
var MODULE_MAP = {
  anagrafica_module: {
    name: "Anagrafica",
    component: "FormAnagrafica",
    value: 1,
    checkEnabled(pratica) {
      if (pratica == null || !this.enabled)
        return false;
      return true;
    },
  },

  comunicazione_module: {
    name: "Comunicazione",
    component: "FormComunicazione",
    value: 2,
    doc_type: "ComunicazioneDecesso",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_comunicazione_decesso;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;

      if (pratica.in_carico_a_medicina_legale)
        return true;

      let isComunicazioneAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ModificaComunicazione"].order;
      if (pratica.comunicazione_decesso != null)
        isComunicazioneAvailable = true;

      return pratica.id > 0 && isComunicazioneAvailable;
    },
    checkConditions(_) {
      // Comunicazione conditions
      let customer = UserInformationStore.getCustomer();
      return customer != null && customer.struttura.tipo_struttura == "OSPEDALE";
    }
  },

  istat_a_module: {
    name: "Istat A",
    component: "FormIstatA",
    value: 3,
    doc_type: "ModuloIstatA",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_istat_a;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;
      
      if (pratica.in_carico_a_medicina_legale)
        return true;

      let isIstatAAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ModificaIstatAReparto"].order;
      if (pratica.istat_a != null)
        isIstatAAvailable = true;

      return pratica.id > 0 && isIstatAAvailable;
    }
  },

  // medicina legale
  relazione_morte_module: {
    name: "Relazione Morte",
    component: "FormRelazione",
    value: 4,
    doc_type: "RelazioneMorte",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_relazione_morte;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;
      
      if (pratica.in_carico_a_medicina_legale)
        return true;

      let isRelazioneAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ReadyMedicinaLegale"].order;
      if (pratica.relazione_morte != null)
        isRelazioneAvailable = true;

      return pratica.id > 0 && isRelazioneAvailable;
    },
    checkConditions(pratica) {
      // Relazione Morte conditions
      return pratica == null || pratica.comunicazione_decesso == null ||
          pratica.comunicazione_decesso.struttura == null ||
          pratica.comunicazione_decesso.struttura.tipo_struttura != "CASA_RIPOSO";
    }
  },

  accertamento_morte_module: {
    name: "Accertamento Morte",
    component: "FormAccertamento",
    value: 5,
    doc_type: "AccertamentoMorte",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_accertamento_morte;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;

      if (pratica.in_carico_a_medicina_legale)
        return true;

      let isAccertamentoAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ReadyMedicinaLegale"].order;
      if (pratica.accertamento_morte != null)
        isAccertamentoAvailable = true;

      return pratica.id > 0 && isAccertamentoAvailable;
    },
  },
  
  nulla_osta_module: {
    name: "Nulla Osta",
    component: "FormNullaOsta",
    value: 8,
    doc_type: "NullaOsta",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_nulla_osta;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;
      
      if (pratica.in_carico_a_medicina_legale)
        return true;

      let isNullaOstaAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ReadyMedicinaLegale"].order;
      if (pratica.nulla_osta != null)
        isNullaOstaAvailable = true;

      return pratica.id > 0 && isNullaOstaAvailable;
    },
    checkConditions(pratica) {
      return pratica != null && (
        (pratica?.accertamento_morte?.morte_reato == "Si") ||
        (pratica?.relazione_morte?.richiesto_nulla_osta == "Si"));
    }
  },

  modulo_trasporto_module: {
    name: "Modulo Trasporto",
    component: "FormTrasporto",
    value: 6,
    doc_type: "ModuloTrasporto",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_modulo_trasporto;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;
      
      if (pratica.in_carico_a_medicina_legale)
        return true;

      let isTrasportoAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ReadyMedicinaLegale"].order;
      if (pratica.modulo_trasporto != null)
        isTrasportoAvailable = true;

      return pratica.id > 0 && isTrasportoAvailable;
    }
  },

  istat_b_module: {
    name: "Istat B",
    component: "FormIstatB",
    value: 7,
    doc_type: "ModuloIstatB",
    tipoGenerazioneAllegato(customer) {
      return customer.tipo_generazione_allegato_istat_b;
    },
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;

      let isIstatBAvailable = STATUS_PRATICA[status].order >= STATUS_PRATICA["ReadyMedicinaLegale"].order;
      if (pratica.istat_b != null)
        isIstatBAvailable = true;

      return pratica.id > 0 && isIstatBAvailable;
    }
  },

  upload_documenti_module: {
    name: "Carica Documenti",
    component: "FormUploader",
    value: 9,
    checkEnabled(pratica, status) {
      if (pratica == null || !this.enabled)
        return false;
      return status === "CaricaDocumenti";
    },
  },

  documenti_module: {
    name: "Documenti",
    component: "FormDocumenti",
    value: 10,
    checkEnabled() {
      return true;
    },
    checkConditions(pratica) {
      if (pratica && pratica.documenti && pratica.documenti.documento.length)
        return true;
      return false;
    },
    visible: true,
  },  
};

// Map containing all the information needed to set the active module
const STATUS_PRATICA = {
  "ModificaAnagrafica": {
    module: (_) => MODULE_MAP.anagrafica_module,
    order: 0,
  },
  "ModificaComunicazione": {
    module: (_) => MODULE_MAP.comunicazione_module,
    order: 1,
  },
  "FirmaComunicazione": {
    module: (_) => MODULE_MAP.comunicazione_module,
    order: 2,
  },
  "ModificaIstatAReparto": {
    module: (_) => MODULE_MAP.istat_a_module,
    order: 3,
  },
  "FirmaIstatAReparto": {
    module: (_) => MODULE_MAP.istat_a_module,
    order: 4,
  },
  "ReadyReparto": {
    module: (_) => MODULE_MAP.istat_a_module,
    order: 5,
  },
  "ReadyMedicinaLegale": {
    module: function(pratica, praticaInfo) {
      // in caso di firma della medicina legale => visualizzo la prossima sezione da compilare
      if (!praticaInfo.is_relazione_morte_completa && MODULE_MAP.relazione_morte_module.enabled)
        return MODULE_MAP.relazione_morte_module
      if (!praticaInfo.is_accertamento_morte_completo && MODULE_MAP.accertamento_morte_module)
        return MODULE_MAP.accertamento_morte_module
      if (pratica.accertamento_morte != null && pratica.accertamento_morte.morte_reato == "Si" 
            && !praticaInfo.is_nulla_osta_completo && MODULE_MAP.accertamento_morte_module)
        return MODULE_MAP.nulla_osta_module
      // default case
      return MODULE_MAP.modulo_trasporto_module;
    },
    order: 6,
  },
  "ModificaAccertamento": {
    module: (_) => MODULE_MAP.accertamento_morte_module,
    order: 7,
  },
  "FirmaAccertamento": {
    module: (_) => MODULE_MAP.accertamento_morte_module,
    order: 8,
  },
  "ModificaNullaOsta": {
    module: (_) => MODULE_MAP.nulla_osta_module,
    order: 9,
  },
  "FirmaNullaOsta": {
    module: (_) => MODULE_MAP.nulla_osta_module,
    order: 10,
  },
  "ModificaRelazione": {
    module: (_) => MODULE_MAP.relazione_morte_module,
    order: 11,
  },
  "FirmaRelazione": {
    module: (_) => MODULE_MAP.relazione_morte_module,
    order: 12,
  },
  "ModificaTrasporto": {
    module: (_) => MODULE_MAP.modulo_trasporto_module,
    order: 13,
  },
  "FirmaTrasporto": {
    module: (_) => MODULE_MAP.modulo_trasporto_module,
    order: 14,
  },
  "ModificaIstatAMedicinaLegale": {
    module: (_) => MODULE_MAP.istat_a_module,
    order: 15,
  },
  "FirmaIstatAMedicinaLegale": {
    module: (_) => MODULE_MAP.istat_a_module,
    order: 16,
  },
  "InviatoComune": {
    module: (_) => MODULE_MAP.istat_b_module,
    order: 17,
  },
  "RifiutatoComune": {
    module: (_) => MODULE_MAP.istat_b_module,
    order: 18,
  },
  "AccettatoComune": {
    module: (_) => MODULE_MAP.istat_b_module,
    order: 19,
  },
  "ModificaIstatB": {
    module: (_) => MODULE_MAP.istat_b_module,
    order: 20,
  },
  "FirmaIstatBComune": {
    // TODO module must be verified in previous Caronte project
    module: (_) => MODULE_MAP.anagrafica_module,
    order: 21,
  },
  "ErroreInvioGestionale": {
    // TODO module must be verified in previous Caronte project
    module: (_) => MODULE_MAP.istat_b_module,
    order: 22,
  },
  "Archiviato": {
    module: (_) => MODULE_MAP.istat_b_module,
    order: 23,
  },
  "CaricaDocumenti": {
    module: (_) => MODULE_MAP.upload_documenti_module,
    order: 24
  }
};

/**
 * Initializes the MODULE_MAP object with the correct values by iterating through the
 * modules from UserInformationStore and adding the visible, enabled, and editable
 * properties to each module. It also checks for properties overrides from META and
 * sets the module name, description, and doc_name accordingly.
 */
function initializeModuleMap() {
  _.forEach(
    UserInformationStore.getModules(),
    function(value, key) {

      let module = MODULE_MAP[key];

      module.visible = value.visible;
      module.enabled = value.enabled;
      module.editable = value.editable;

      let section = ListMetaStore.getSezione(module.value);

      let structure = UserInformationStore.getTipoStruttura().toLowerCase();
      let code = UserInformationStore.getCodiceStruttura();

      // checking properties overrides from META
      // meta object example:
      // {
      //   description: "Relazione di Morte",
      //   description.ospedale.954: "Dichiarazione di Morte",
      //   doc_name: "Relazione di Morte",
      //   doc_name.ospedale.954: "Dichiarazione di Morte",
      //   name: "Relazione",
      //   name.ospedale.954: "Dichiarazione"
      // }
      module.name = section?.['name.' + structure + '.' + code] || section?.name;
      module.description = section?.['description.' + structure + '.' + code] || section?.description;
      module.doc_name = section?.['doc_name.' + structure + '.' + code] || section?.doc_name;
    }
  );
}

/**
 * Returns an array of modules filtered based on the given status.
 * 
 * @param {string} status - The status to filter by.
 * @returns {Object[]}  An array of tab options containing those properties:
 *                      { name, key, disabled, editable, component, index }
 */
function getFilteredModules(pratica, status) {
  let index = 0;
  const filteredModules = [];

  for (const [key, value] of Object.entries(MODULE_MAP)) {
    const isValid = value.checkConditions != null;
    let isEnabled = true;
    if (isValid) {
      isEnabled = value.checkConditions(pratica);
    }

    if (value.visible && isEnabled) {
      filteredModules.push({
        name: value.name,
        key: key,
        disabled: !value.checkEnabled(pratica, status),
        editable: value.editable,
        component: value.component,
        index: index++,
      });
    }
  }

  return filteredModules;
}

// TODO: add JsDoc
function getDocumentCategories(pratica) {
  const customer = UserInformationStore.getCustomer();
  const categories = [];

  _.forEach(MODULE_MAP, module => {
    if (module.tipoGenerazioneAllegato) {
      const tga = module.tipoGenerazioneAllegato(customer);
      let isEnabled = true;

      if (module.checkConditions != null) {
        isEnabled = module.checkConditions(pratica);
      }

      if (isEnabled && (tga === 'ZAPPDF' || tga === 'IMPORTAZIONE')) {
        categories.push({
          label: module.name,
          id: module.value,
          document_name: module.doc_name,
          document_type: module.doc_type,
          required: true, // TODO
        })
      }
    }
  })
  return categories;
}

/**
 * Returns the name of the module for the current status and 'pratica'.
 * 
 * @param {String} status - The status of the 'pratica'.
 * @param {Object} pratica - The 'pratica' object.
 * @param {Object} praticaInfo - The 'pratica' information object.
 * @param {Boolean} enabledRequired - Flag indicating whether only enabled modules should be considered.
 * @returns {String} The name of the module.
 */
function getCurrentModuleName(status, pratica, praticaInfo, enabledRequired) {
  let module = STATUS_PRATICA[status].module(pratica, praticaInfo);

  return !module.enabled && enabledRequired
    ? MODULE_MAP[0]?.name
    : module.name;
}

/**
 * Returns the User ID based on the current status.
 * 
 * @param {String} status - The status of the 'pratica'.
 * @returns {Number} The User ID.
 */
function getUserId(status) {
  const STATUS_LIST = [
    'ModificaComunicazione',
    'ModificaIstatAReparto',
    'ModificaRelazione',
    'ModificaAccertamento',
    'ModificaNullaOsta',
    'ModificaTrasporto',
    'ModificaIstatAMedicinaLegale',
    'ModificaIstatB',
    'CaricaDocumenti'
  ];

  return _.includes(STATUS_LIST, status)
    ? User.getUserId()
    : 0;
}

function isComunicazioneEnabled() {
  return MODULE_MAP['comunicazione_module'].enabled;
}

export default {
  initializeModuleMap,
  getFilteredModules,
  getDocumentCategories,
  getCurrentModuleName,
  getUserId,
  isComunicazioneEnabled
};