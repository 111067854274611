import Vue from 'vue';
import Vuex from 'vuex';
import ListMetaStore from 'services/commands/stores/list_meta_store';

Vue.use(Vuex);

let overlayTimeout;

export default new Vuex.Store({
  state: {
    /**
     * Object used to cache commands.
     * Command structure:
     * {
     *    command: command_name,
     *    body: command_body
     * }
     */
    commandsCache: {},

    // list of all "Pratiche".
    listPraticaInfo: [],

    // selected activity and period used to perform listPraticaInfo command.
    selectedActivity: {},
    selectedPeriod: -1,

    // list of selected "Pratiche" ids
    selectedPraticaIds: [],
    
    // keeps track of number of requests in progress to the SC.
    requestCount: 0,

    /**
     * true   if data is success preloaded.
     * false  otherwise.
     */
    startup: false,

    // if true, indicate to show 'v-overlay' component.
    showOverlay: false,

    dialog: {
      // title to be displayed when showDialog is true.
      titleDialog: "",
      // if true, indicates to show dialog in Home component.
      showDialog: false,
      // text to be displayed when showDialog is true.
      textDialog: "",
    },

    // if false, indicate that "Do not show againg" was selected.
    showableWebInteropAlert: true,
  },
  
  mutations: {
    /**
     * Caches a command inside state.
     *
     * @param {Object} payload    - command to cache
     */
    cacheCommand: (state, payload) => Vue.set(state.commandsCache, payload.command, payload.body),

    changeSelectedPraticaIds(state, payload) {
      if (!_.isEqual(state.selectedPraticaIds, payload))
        state.selectedPraticaIds = payload
    },

    updateShowOverlay: (state) => state.showOverlay = state.requestCount > 0,

    updateSelectedActivity: (state, payload) => state.selectedActivity = payload,
    updateSelectedPeriod: (state, payload) => state.selectedPeriod = payload,

    hideDialog: (state) => state.dialog.showDialog = false,

    // payload: "Text to be displayed in the dialog error".
    showDialogError: (state, payload) => {
      // Alternative titleDialog: "Attenzione, si è verificato un errore"
      Vue.set(state.dialog, 'titleDialog', "Errore");
      Vue.set(state.dialog, 'showDialog', true);
      Vue.set(state.dialog, 'textDialog', payload);
    },

    // payload: "Text to be displayed in the dialog warning".
    showDialogWarning: (state, payload) => {
      Vue.set(state.dialog, 'titleDialog', "Attenzione");
      Vue.set(state.dialog, 'showDialog', true);
      Vue.set(state.dialog, 'textDialog', payload);
    },

    showDialog: (state, payload) => {
      Vue.set(state.dialog, 'titleDialog', payload?.title);
      Vue.set(state.dialog, 'showDialog', true);
      Vue.set(state.dialog, 'textDialog', payload?.text);
    },

    setShowableWebInteropAlert: (state, payload) => state.showableWebInteropAlert = payload,

    updateListPraticaInfo(state, json) {
      let selected = []
      json.forEach(pratica => {
        pratica.azioni.action.forEach(act => {
          act.decoration = ListMetaStore.resolveAllMetas('TRANSITION_DEF', act.id)
        })
        if (_.includes(state.selectedPraticaIds, pratica.id))
          selected.push(pratica.id)
      })
      state.listPraticaInfo = json
      state.selectedPraticaIds = selected
    },

    incrementRequestCount: (state) => state.requestCount++,
    decrementRequestCount: (state) => state.requestCount--,

    negativizeSelectedPraticaIds: (state) => state.selectedPraticaIds = _.map(state.selectedPraticaIds, id => -Math.abs(id)),
    positivizeSelectedPraticaIds: (state) => state.selectedPraticaIds = _.map(state.selectedPraticaIds, id => Math.abs(id)),

    selectAllIds(state) {
      state.selectedPraticaIds = [];
      _.forEach(state.listPraticaInfo, p => state.selectedPraticaIds.push(p.id));
    },

    // Sets startup to true.
    startupComplete: (state) => state.startup = true,

    /**
     * Sets startup to false and clears command cache list.
     * (to be used in case of error or logout).
     */
    startupReset(state) {
      state.commandsCache = {}
      state.listPraticaInfo = []
      state.selectedPraticaIds = []

      state.startup = false
    },
  },

  actions: {
    incrementRequestCountAsync({ commit }) {
      clearTimeout(overlayTimeout)

      commit('incrementRequestCount')
      commit('updateShowOverlay')
    },

    decrementRequestCountAsync({ commit }) {
      clearTimeout(overlayTimeout)

      commit('decrementRequestCount')

      overlayTimeout = setTimeout(() => {
        commit('updateShowOverlay')
      }, 300)
    },
  },

  getters: {
    cachedCommand: (state) => (command) => _.cloneDeep(state.commandsCache[command]),

    userModules: (state, getters) => {
      let userInformations = getters.cachedCommand('CaronteGetUserInformations');
      return userInformations?.modules;
    },

    listPraticaInfo: (state) => _.cloneDeep(state.listPraticaInfo),

    listPraticheSelected: (state) => _.cloneDeep(
      _.filter(state.listPraticaInfo, p => _.includes(state.selectedPraticaIds, p.id))
    ),

    praticheNumber: (state) => state.listPraticaInfo.length,

    requestCount: (state) => state.requestCount,

    dialog: (state) => state.dialog,

    selectedActivity: (state) => state.selectedActivity,

    selectedPeriod: (state) => state.selectedPeriod,

    selectedPraticaIds: (state) => state.selectedPraticaIds,
    
    selectedPraticaId: (state) =>_.find(state?.selectedPraticaIds, id => id > 0) || state?.selectedPraticaIds[0],

    selectedPraticaInfo: (state, getters) => getters.listPraticheSelected[0],

    showOverlay: (state) => state.showOverlay,

    showableWebInteropAlert: (state) => state.showableWebInteropAlert,

    startup: (state) => state.startup,
  }
})
