var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-uploader" } },
    [
      _c("DocumentSplitter", {
        ref: "document-splitter",
        attrs: {
          categories: _vm.categories,
          splitUrl: _vm.splitUrl,
          joinUrl: _vm.joinUrl,
          previewUrl: _vm.previewUrl,
          disabled: _vm.disabled,
        },
        model: {
          value: _vm.documents,
          callback: function ($$v) {
            _vm.documents = $$v
          },
          expression: "documents",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }