var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-bar" } },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "" } },
        [
          _c("a", { attrs: { href: _vm.logoUrl } }, [
            _c("img", {
              staticClass: "logo-image",
              attrs: { src: _vm.logoSrc, alt: "logo" },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { staticClass: "row-title", attrs: { "no-gutters": "" } },
                [_c("b", [_vm._v(_vm._s(_vm.title))])]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "row-title", attrs: { "no-gutters": "" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.loggedUserInformations) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isWebInteropEnabled
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_vm.isLoggedIn ? _c("WebInteropStatus") : _vm._e()],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.showChipPratiche && _vm.isLoggedIn
            ? _c("v-chip", [_c("b", [_vm._v(_vm._s(_vm.chipPratiche))])])
            : _vm._e(),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-5", attrs: { vertical: "" } }),
          _vm._v(" "),
          _vm.isLoggedIn
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: { disabled: _vm.disabled, icon: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.showUserProfile = true
                                      },
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    attrs: {
                                      size: "40",
                                      color: "primary darken-1",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "white--text text-h5" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.getAvatarText()) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1820540033
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v("Impostazioni Account")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoggedIn
            ? _c("UserProfile", {
                attrs: { avatarText: _vm.getAvatarText() },
                model: {
                  value: _vm.showUserProfile,
                  callback: function ($$v) {
                    _vm.showUserProfile = $$v
                  },
                  expression: "showUserProfile",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoggedIn
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: { icon: "", disabled: _vm.disabled },
                                    on: { click: _vm.logout },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-logout-variant")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1296685324
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v("Logout")])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }