var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-nulla-osta" } },
    [
      _c("c-expansion-sections", {
        attrs: {
          disabled: _vm.disabled,
          sections: [
            "MEDICO",
            "RESIDENZA MEDICO",
            "DECEDUTO",
            "RILASCIO NULLA OSTA",
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "section1",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Cognome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.no.cognome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.no, "cognome_medico", $$v)
                            },
                            expression: "no.cognome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            rules: _vm.requiredRule,
                            label: "Nome",
                            "prepend-icon": "mdi-badge-account",
                          },
                          model: {
                            value: _vm.no.nome_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.no, "nome_medico", $$v)
                            },
                            expression: "no.nome_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-date-picker", {
                          attrs: { label: "Data di Nascita" },
                          model: {
                            value: _vm.dataNascitaMedicoISO,
                            callback: function ($$v) {
                              _vm.dataNascitaMedicoISO = $$v
                            },
                            expression: "dataNascitaMedicoISO",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            label: "Comune di Nascita",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.comuneNascitaMedico,
                            callback: function ($$v) {
                              _vm.comuneNascitaMedico = $$v
                            },
                            expression: "comuneNascitaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            label: "Provincia di Nascita",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.provinciaNascitaMedico,
                            callback: function ($$v) {
                              _vm.provinciaNascitaMedico = $$v
                            },
                            expression: "provinciaNascitaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section2",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Via",
                            "prepend-icon": "mdi-home-map-marker",
                          },
                          model: {
                            value: _vm.no.via_residenza_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.no, "via_residenza_medico", $$v)
                            },
                            expression: "no.via_residenza_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "1" } }),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "1" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Civico",
                            type: "number",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.no.civico_residenza_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.no, "civico_residenza_medico", $$v)
                            },
                            expression: "no.civico_residenza_medico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listPaesi,
                            label: "Comune",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.comuneResidenzaMedico,
                            callback: function ($$v) {
                              _vm.comuneResidenzaMedico = $$v
                            },
                            expression: "comuneResidenzaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "ma-2", attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-combobox", {
                          attrs: {
                            items: _vm.listProvince,
                            label: "Provincia",
                            "item-text": "descrizione",
                            "prepend-icon": "mdi-map-marker",
                            clearable: "",
                          },
                          model: {
                            value: _vm.provinciaResidenzaMedico,
                            callback: function ($$v) {
                              _vm.provinciaResidenzaMedico = $$v
                            },
                            expression: "provinciaResidenzaMedico",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "5" } },
                      [
                        _c("c-text-field", {
                          attrs: {
                            label: "Telefono",
                            "prepend-icon": "mdi-phone",
                            type: "number",
                            "hide-spin-buttons": "",
                          },
                          model: {
                            value: _vm.no.telefono_residenza_medico,
                            callback: function ($$v) {
                              _vm.$set(_vm.no, "telefono_residenza_medico", $$v)
                            },
                            expression: "no.telefono_residenza_medico",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "section3",
            fn: function () {
              return [
                _c("SectionDeceduto", {
                  attrs: {
                    "anagrafica-deceduto": _vm.pratica.anagrafica_deceduto,
                    "show-cittadinanza": false,
                    "show-via-civico-domicilio": false,
                    "show-comune-provincia-domicilio": false,
                    "show-data-orario-morte": false,
                    "show-anni-mesi-giorni-ore": false,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "section4",
            fn: function () {
              return [
                _c(
                  "v-col",
                  { attrs: { cols: "3" } },
                  [
                    _c("c-select", {
                      attrs: {
                        items: _vm.nullaOstaOptions,
                        "prepend-icon": _vm.iconNullaOsta,
                        label: "Rilasciato Nulla Osta",
                      },
                      model: {
                        value: _vm.no.rilasciato_nulla_osta,
                        callback: function ($$v) {
                          _vm.$set(_vm.no, "rilasciato_nulla_osta", $$v)
                        },
                        expression: "no.rilasciato_nulla_osta",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-container",
                  [
                    _vm.isNoteNullaOstaVisible
                      ? _c("c-textarea", {
                          attrs: {
                            label: "Note rilascio Nulla Osta",
                            "prepend-icon": "mdi-text",
                          },
                          model: {
                            value: _vm.no.rilasciato_nulla_osta_nota,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.no,
                                "rilasciato_nulla_osta_nota",
                                $$v
                              )
                            },
                            expression: "no.rilasciato_nulla_osta_nota",
                          },
                        })
                      : _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5" } },
                              [
                                _c("v-file-input", {
                                  attrs: {
                                    disabled:
                                      _vm.no.rilasciato_nulla_osta_attachment !=
                                      null,
                                    accept: ".pdf,.p7m",
                                    loading: "loadingAttachment",
                                    "loader-height": "0",
                                    error: _vm.fileInputError,
                                    "show-size": "",
                                    "hide-details": "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.attachment,
                                    callback: function ($$v) {
                                      _vm.attachment = $$v
                                    },
                                    expression: "attachment",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-spacer"),
                            _vm._v(" "),
                            _vm.no.rilasciato_nulla_osta_attachment
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c("c-item", {
                                      attrs: {
                                        text: _vm.attachmentTitle,
                                        disabled: _vm.disabled,
                                        prependIcon: "mdi-file-pdf-box",
                                        actionIcon: "mdi-delete",
                                        actionIconColor: "red",
                                      },
                                      on: {
                                        "text-click":
                                          _vm.onAttachmentTitleClick,
                                        "action-click":
                                          _vm.onAttachmentDeleteClick,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("c-dialog-error", {
        attrs: { errors: _vm.errors },
        model: {
          value: _vm.dialogError,
          callback: function ($$v) {
            _vm.dialogError = $$v
          },
          expression: "dialogError",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }