<template>
  <div id="form-comunicazione">
    <v-container class="form">
      <SectionDatiMorte
        ref="sectionDatiMorte"
        :cd="pratica.comunicazione_decesso"
      />
    </v-container>

    <v-divider></v-divider>

    <!-- "Diagnosi" -->
    <v-container class="form">
      <v-row no-gutters>
        <v-col cols="12">
          <c-textarea
            v-model="cd.diagnosi"
            label="Diagnosi"
            prepend-icon="mdi-microscope"
            rows="5"
            class="ma-2"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <!-- "Osservazioni" -->
    <v-container class="form">
      <v-row no-gutters>
        <v-col cols="12">
          <c-textarea
            v-model="cd.osservazioni"
            label="Osservazioni"
            prepend-icon="mdi-medical-bag"
            rows="5"
            class="ma-2"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog Error shown in case of failed validation -->
    <c-dialog-error
      v-model="dialogError"
      :errors="errors"
    />
  </div>
</template>

<script>
// services
import PraticaUtil from "services/pratica_util";
// components
import SectionDatiMorte from "../SectionDatiMorte.vue";

export default {
  name: "FormComunicazioni",

  components: { SectionDatiMorte },

  props: {
    praticaDetails: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    // Fixed copy of 'pratica' object from parent component
    const pratica = PraticaUtil.fixComunicazione(this.praticaDetails);

    return {
      pratica: pratica,
      cd: pratica.comunicazione_decesso,

      // necessary to validate time
      dialogError: false,
      errors: [],
    };
  },

  computed: {
    /// Section dati morte data required for validation
    timeValid() {
      return this.$refs.sectionDatiMorte.validateTime();
    },

    dataMorteValid() {
      return this.$refs.sectionDatiMorte.validateDataMorte();
    },

    dataRicoveroValid() {
      const dataRicovero = this.pratica.anagrafica_deceduto.dt_ricovero;
      return this.$refs.sectionDatiMorte.validateDataRicovero(dataRicovero);
    }
  },

  watch: {
    // Watch used to notify parent component when 'pratica' updates.
    'pratica.comunicazione_decesso': {
      immediate: true,
      deep: true,

      handler() {
        this.disabled || this.$emit('pratica-updated', this.pratica);
      },
    },
  },

  methods: {
    ///
    /// Exposed methods.
    ///
    validate() {
      let errors = [];

      if (!this.cd.numero_letto)
        this.cd.numero_letto = 0;
      
      // data di morte
      this.dataMorteValid || errors.push("Data di Morte (vuota o invalida)");

      // orario di morte
      this.timeValid || errors.push("Orario di Morte (vuoto o invalido)");

      // dataMorte must be after dataRicovero
      if (this.dataMorteValid && this.timeValid && !this.dataRicoveroValid) {
        errors.push("Data di Morte invalida (deve essere successiva alla data del Ricovero)");
      }

      if (errors.length) {
        this.errors = errors;

        // showing error dialog
        this.dialogError = true;
        return false;
      }
      return true;
    },
  },
};
</script>
