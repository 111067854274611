import router from "router"
import store from "store"
// stores
import { AppConfigStore } from 'services/commands/stores';
// services
import { ICONS } from 'services/icon_util';

const route = 'Details';

function actionPerformed(context, action) {
  // FIXME that should be moved into newPratica function
  // setting default activity before new pratica action
  //
  // required if activity != "Attività da fare"
  store.commit('updateSelectedActivity', {
    code: `${AppConfigStore.getAttivitaDaFareId()}`,
    icon: ICONS.ICO_TODO,
  });

  if (context.$route.name != route)
    router.push({ name: route, params: { command: 'newPratica' }});
  else
    context.newPratica();
}

export default {
  actionPerformed,
}