import Vue from 'vue';

import router from 'router';
import store from 'store';
import App from 'App.vue';

import vuetify from "plugins/vuetify";

import {
  CTextField,
  CCombobox,
  CSelect,
  CSwitch,
  CTextarea,
  CDatePicker,
  CTimePicker,
  CConditionList,
  CDialogError,
  CDialogConfirm,
  CDialogInformation,
  CExpansionSections,
  CItem,
} from "components/common";

Vue.config.productionTip = false;

///
/// Adding Global Custom Components
///

// Custom component that extends v-text-field
Vue.component('c-text-field', CTextField);

// Custom component that extends v-combobox
Vue.component('c-combobox', CCombobox);

// Custom component that extends v-select
Vue.component('c-select', CSelect);

// Custom component that extends v-switch
Vue.component('c-switch', CSwitch);

// Custom component that extends v-textarea
Vue.component('c-textarea', CTextarea);

// Custom component that uses v-date-picker and v-text-field
Vue.component('c-date-picker', CDatePicker);

// Custom component that uses v-time-picker and v-text-field
Vue.component('c-time-picker', CTimePicker);

// Custom component used to handle istat_a conditions
Vue.component('c-condition-list', CConditionList);

// Custom component used to display an error dialog
Vue.component('c-dialog-error', CDialogError);

// Custom component used to display an information or confirm dialog
Vue.component('c-dialog-confirm', CDialogConfirm);

// Custom component used to display an information dialog
Vue.component('c-dialog-information', CDialogInformation);

// Custom component used to handle expansion sections
Vue.component('c-expansion-sections', CExpansionSections);

// Custom component with prepend-icon, text and action
Vue.component('c-item', CItem);

document.addEventListener("DOMContentLoaded", function () {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app-root');
});