var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { attrs: { disabled: true } },
    [
      _vm.showNomeCognome
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.cognome,
                      label: "Cognome",
                      "prepend-icon": "mdi-account-outline",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.nome,
                      label: "Nome",
                      "prepend-icon": "mdi-account-outline",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDataNascita
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-date-picker", {
                    attrs: {
                      value: _vm.dataNascitaDecedutoISO,
                      label: "Data di Nascita",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showComuneProvinciaNascita
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.comune_nascita_specifico,
                      label: "Comune di Nascita",
                      "prepend-icon": "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.provincia_nascita_specifica,
                      label: "Provincia di Nascita",
                      "prepend-icon": "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCittadinanza
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.cittadinanza_specifica,
                      label: "Cittadinanza",
                      "prepend-icon": "mdi-flag",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showViaCivicoResidenza
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.via_residenza,
                      label: "Via di Residenza",
                      "prepend-icon": "mdi-home-map-marker",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.civico_residenza,
                      label: "Civico",
                      "prepend-icon": "mdi-home-map-marker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showComuneProvinciaResidenza
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-combobox", {
                    attrs: {
                      value: _vm.anagraficaDeceduto.comune_residenza_specifico,
                      label: "Comune di Residenza",
                      "prepend-icon": "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-combobox", {
                    attrs: {
                      value:
                        _vm.anagraficaDeceduto.provincia_residenza_specifica,
                      label: "Provincia di Residenza",
                      "prepend-icon": "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showViaCivicoDomicilio
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      label: "Via di Domicilio",
                      value: _vm.domicilio.via,
                      "prepend-icon": "mdi-home-account",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      label: "Civico",
                      value: _vm.domicilio.civico,
                      "prepend-icon": "mdi-home-account",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showComuneProvinciaDomicilio
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.domicilio.comune,
                      label: "Comune di Domicilio",
                      "prepend-icon": "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      value: _vm.domicilio.provincia,
                      label: "Provincia di Domicilio",
                      "prepend-icon": "mdi-map-marker",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDataOrarioMorte
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-date-picker", {
                    attrs: {
                      value: _vm.dataMorteDecedutoISO,
                      label: "Data di Morte",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-time-picker", {
                    attrs: {
                      value: _vm.oraMorteFormatted,
                      label: "Orario di Morte",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAnniMesiGiorniOre
        ? _c(
            "v-row",
            { staticClass: "ma-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      label: "Anni",
                      value: _vm.deathAge.years,
                      "prepend-icon": "mdi-coffin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("c-text-field", {
                    attrs: { label: "Mesi", value: _vm.deathAge.months },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("c-text-field", {
                    attrs: { label: "Giorni", value: _vm.deathAge.days },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("c-text-field", {
                    attrs: { label: "Ore", value: _vm.deathAge.hours },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }