var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "" },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { id: "dialog-accept" } },
        [
          _c("v-card-title", [_vm._v("Accetta pratica")]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "mt-2" }, [
            _c("p", { staticClass: "text" }, [
              _vm._v(
                "Per completare l'accettazione, assegna alla pratica l'anno ed il numero:"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-5", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      label: "Anno",
                      type: "number",
                      "hide-spin-buttons": "",
                      "prepend-icon": "mdi-archive-clock",
                    },
                    model: {
                      value: _vm.year,
                      callback: function ($$v) {
                        _vm.year = $$v
                      },
                      expression: "year",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("c-text-field", {
                    attrs: {
                      label: "Numero",
                      type: "number",
                      "hide-spin-buttons": "",
                      "prepend-icon": "mdi-clipboard-list-outline",
                    },
                    model: {
                      value: _vm.number,
                      callback: function ($$v) {
                        _vm.number = $$v
                      },
                      expression: "number",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "mt-10" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "btn-action",
                  attrs: { outlined: "" },
                  on: { click: _vm.onCancelClick },
                },
                [_vm._v("\n        ANNULLA\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  class: _vm.ready && "btn-action",
                  attrs: { disabled: !_vm.ready, outlined: "" },
                  on: { click: _vm.onConfirmClick },
                },
                [_vm._v("\n        OK\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }