var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center", attrs: { id: "menu-bar" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pl-0",
                  attrs: { cols: "5", "align-self": "center" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("c-select", {
                            attrs: {
                              items: _vm.activities,
                              "hide-details": true,
                              "item-text": "name",
                              "item-value": "key",
                              label: "Attività",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateListPratiche()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color:
                                            _vm.selectedActivity.icon.color,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedActivity.icon.mdi)
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "item",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: item.icon.color,
                                          left: "",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.icon.mdi))]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(item.name) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedActivityCode,
                              callback: function ($$v) {
                                _vm.selectedActivityCode = $$v
                              },
                              expression: "selectedActivityCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("c-select", {
                            attrs: {
                              disabled: _vm.periodSelectorDisabled,
                              items: _vm.periodItems,
                              "hide-details": true,
                              "item-value": "sub_time",
                              "prepend-icon": "mdi-calendar",
                              label: "Periodo",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateListPratiche()
                              },
                            },
                            model: {
                              value: _vm.selectedPeriodCode,
                              callback: function ($$v) {
                                _vm.selectedPeriodCode = $$v
                              },
                              expression: "selectedPeriodCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "1", "align-self": "center" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled: false,
                                          },
                                          on: { click: _vm.updateListPratiche },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-autorenew")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v("Ricarica pratiche")]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled: false,
                                          },
                                          on: { click: _vm.onSelectAllClicked },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-text-box-multiple")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("Seleziona tutte le pratiche"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "3", "align-self": "center" } }, [
                _c("h5", [_vm._v(_vm._s(_vm.praticheSelected))]),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pr-0",
                  attrs: { cols: "3", "align-self": "center" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("c-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "####/#####",
                                expression: "'####/#####'",
                              },
                            ],
                            attrs: { label: "Cerca pratica" },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchPratica.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.numeroAtto,
                              callback: function ($$v) {
                                _vm.numeroAtto = $$v
                              },
                              expression: "numeroAtto",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            color: "green darken-3",
                                            disabled: false,
                                          },
                                          on: { click: _vm.searchPratica },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-magnify")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("Cerca pratica da numero atto"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }