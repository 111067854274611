import * as Commands from 'services/commands'

const DEFAULT_OPTIONS = {
  abortOnError: true,
  abortOnFailure: true,
  failure: alert
}

function pushToStack(stack, command, params, response, status, error = null) {
  stack.push({
    command: command,
    params: params,
    response: response,
    status: status,
    error: error
  })
}

function buildParams(params, stack) {
  if (_.isFunction(params)) {
    return params(stack)
  } else {
    return params
  }
}

function sendNextCommand(commands, options, stack) {
  let command = commands.shift()

  if (command) {
    let params = buildParams(command.params, stack)

    Commands[command.name].call(params, {
      success: (response) => {
        _.attempt(_.get(command, 'options.success'), response)

        pushToStack(stack, command.name, params, response, 'success')
        sendNextCommand(commands, options, stack)
      },
      error: (response) => {
        _.attempt(_.get(command, 'options.error'), response)

        pushToStack(stack, command.name, params, response, 'error')

        _.attempt(options.error, response, stack)

        if (!options.abortOnError) {
          sendNextCommand(commands, options, stack)
        }
      },
      failure: (error) => {
        _.attempt(_.get(command, 'options.failure'), error)

        pushToStack(stack, command.name, params, response, 'failure', error)

        _.get(options, 'failure', alert)(error, stack)

        if (!options.abortOnFailure) {
          sendNextCommand(commands, options, stack)
        }
      }
    })
  } else {
    _.attempt(options.success, stack)
  }
}

/**
 * Callback eseguita in caso di successo del comando.
 *
 * @callback commandSuccessCallback
 * @param {Object} response - Il risultato del comando
 */

/**
 * Callback eseguita se il comando ritorna degli errori.
 *
 * @callback commandErrorCallback
 * @param {Object} errors - Gli errori del comando
 */

/**
 * Callback eseguita in caso di fallimento del comando.
 *
 * @callback commandFailureCallback
 * @param {Object} error - L'errore di rete
 */

/**
 * Callback eseguita se la sequenza viene portata a termine.
 *
 * @callback successCallback
 * @param {Array} response - I risultati dei comandi
 */

/**
 * Callback eseguita se un comando ritorna degli errori.
 *
 * @callback errorCallback
 * @param {Array} response - I risultati dei comandi
 */

/**
 * Callback eseguita in caso di fallimento di un comando.
 *
 * @callback failureCallback
 * @param {Object} error - L'errore di rete
 */

/**
 * Callback eseguita per generare i parametri del comando.
 *
 * @callback paramsCallback
 * @param {Object[]}    stack               - Lo stack di risultati dei comandi precedenti
 * @param {string}      stack[].command     - Nome del comando.
 * @param {Object}      stack[].params      - Parametri del comando .
 * @param {Object}      stack[].response    - Risposta del comando.
 * @param {string}      stack[].status      - Status del comando: "success", "error", o "failure".
 * @param {Object}      stack[].error       - Errore di rete (presente solo se status="failure").
 */

/**
 * Invia una serie di comandi in sequenza all'SC.
 *
 * @param {Object[]}                  commands                            - I comandi da eseguire.
 * @param {string}                    commands[].name                     - Nome del comando.
 * @param {Object|paramsCallback}     commands[].params                   - Parametri del comando o una callback usata per generarli.
 * @param {Object}                    [commands[].options]                - Opzioni.
 * @param {commandSuccessCallback}    [commands[].options.success]        - Callback eseguita in caso di successo del comando.
 * @param {commandErrorCallback}      [commands[].options.error]          - Callback eseguita se il comando ritorna degli errori.
 * @param {commandFailureCallback}    [commands[].options.failure]        - Callback eseguita in caso di fallimento del comando.
 * @param {boolean}                   [commands[].options.cache=false]    - Se mettere in cache o meno la risposta del comando.
 * @param {Object}                    [options]                           - Opzioni.
 * @param {boolean}                   [options.abortOnError=true]         - Se abortire la sequenza in caso uno dei comandi ritorni degli errori.
 * @param {boolean}                   [options.abortOnFailure=true]       - Se abortire la sequenza in caso di un errore di rete.
 * @param {successCallback}           [options.success]                   - Callback eseguita se la sequenza viene portata a termine.
 * @param {errorCallback}             [options.error]                     - Callback eseguita se un comando ritorna degli errori.
 * @param {failureCallback}           [options.failure]                   - Callback eseguita in caso di fallimento di un comando.
 * @param {boolean}                   [options.cache=false]               - Se mettere in cache o meno la risposta dei comandi.
 */
export default function sendSequence(commands = [], options = {}) {
  const stack = []

  options = _.merge({}, DEFAULT_OPTIONS, options)

  sendNextCommand(commands, options, stack)
}
